import { defineForm } from '@client/lib/form/helpers';
import { FormValues } from '@client/lib/form/types';
import { UserWorkStatusType, UserWorkStatusTypeHelper } from '@officely/models';
import { MultiSelectOption } from '@client/components/ui/custom/multi-select';
import { SimpleForm, SimpleFormProps } from '@client/components/shared/simple-form';
import { useMemo } from 'react';

const FORM_ID = 'account-statuses-form' as const;

export const POSSIBLE_DISABLED_STATUSES = [
  UserWorkStatusType.REMOTE,
  UserWorkStatusType.ANNUAL_LEAVE,
  UserWorkStatusType.OFF_SICK,
  UserWorkStatusType.NON_WORKING_DAY,
  UserWorkStatusType.CLIENT_OFFICE,
  UserWorkStatusType.WORK_TRAVEL,
];
const DISABLED_STATUS_OPTIONS: MultiSelectOption[] = POSSIBLE_DISABLED_STATUSES.map(
  (status) => new UserWorkStatusTypeHelper(status)
).map((helper) => ({
  value: helper.data,
  label: `${helper.profile.emoji}   ${helper.profile.name}`,
}));

const getDefinition = () =>
  defineForm({
    id: FORM_ID,
    fields: {
      enabled: {
        type: 'switch',
        text: 'Enable working statuses',
      },
      disabledStatuses: {
        type: 'multi-select',
        label: 'Disabled Statuses',
        options: DISABLED_STATUS_OPTIONS,
        placeholder: 'None',
      },
    },
    conditions: (values) => ({
      hidden: {
        disabledStatuses: !values.enabled,
      },
    }),
  });

type FormDefinition = ReturnType<typeof getDefinition>;
type Props = Omit<SimpleFormProps<FormDefinition>, 'definition'>;

export type AccountStatusesFormValues = FormValues<FormDefinition>;

const AccountStatusesForm = (props: Props) => {
  const { data, onSubmit, ...restForForm } = props;
  const definition = useMemo(() => getDefinition(), []);

  return <SimpleForm definition={definition} onSubmit={onSubmit} data={data} {...restForForm} />;
};

AccountStatusesForm.id = FORM_ID;

export { AccountStatusesForm };

import { useTheme } from '@client/theme';
import { cn } from '@client/lib/utils';

import { Mrkdwn } from '@client/components/ui/custom/mrkdwn';

// TODO: ensure wrapping works

export const ContextBlock = (props: { elements: string[] }) => {
  const { elements } = props;

  const { theme } = useTheme();

  return (
    <div className={cn('text-sm', theme === 'light' ? 'text-[#616061]' : 'text-[#ababad]')}>
      {elements.map((element, index) => (
        <span key={index} className={index < elements.length - 1 ? 'mr-4' : ''}>
          <Mrkdwn mrkdwn={element} />
        </span>
      ))}
    </div>
  );
};

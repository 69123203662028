/* eslint-disable sonarjs/no-small-switch */
import { DefaultLayout } from '@client/components/layout/DefaultLayout';
import { TypographyH1 } from '@client/components/ui/custom/typography-h1';
import { TypographyP } from '@client/components/ui/custom/typography-p';
import { Button } from '@client/components/ui/button';
import { FaceWithMonocle } from '@client/components/ui/custom/svg/face-with-monocle';
import { PartyingFace } from '@client/components/ui/custom/svg/partying-face';
import { useDeepLink } from '@client/lib/useDeepLink';
import { Link } from '@client/components/ui/custom/link';
import { useTeams } from '@client/lib/useTeams';
import { useEffect, useMemo } from 'react';
import { config } from '@client/lib/config';
import { DOCS_HELP_ADD_TO_TEAM } from '@client/features/app/const';

type InstallationErrorProps = {
  variant: 'installation';
  appId?: string;
  name?: string;
};

type AdminConsentErrorProps = {
  variant: 'admin-consent';
  name?: string;
};

type UnknownErrorProps = {
  variant: 'unknown';
  error?: any;
};

type UnauthenticatedErrorProps = {
  variant: 'unauthenticated';
};

type Props = InstallationErrorProps | UnauthenticatedErrorProps | UnknownErrorProps | AdminConsentErrorProps;

const InstallationErrorLayout = (props: InstallationErrorProps) => {
  const { name, appId } = props;
  const message = name
    ? `Hey ${name}, you've installed Officely for yourself, but you need to install it into a team before you can use it.`
    : "You've installed Officely for yourself, but you need to install it into a team before you can use it.";

  const deepLink = appId ? useDeepLink({ appId }) : undefined;

  return (
    <DefaultLayout>
      <div className="flex mt-12">
        <PartyingFace size={120} />
        <div className="ml-6">
          <TypographyH1 className="">Almost There!</TypographyH1>
          <TypographyP className="mt-2">{message}</TypographyP>

          {deepLink ? (
            <Button className="mt-4" onClick={deepLink.openAppInstallDialog}>
              Add Officely To Team
            </Button>
          ) : (
            <div>
              <Link to={DOCS_HELP_ADD_TO_TEAM} newTab>
                How to add Officely to your team
              </Link>
            </div>
          )}

          {/* <TypographyMuted className="mt-2">Click on the dropdown arrow, then select 'Add To Team'</TypographyMuted> */}
        </div>
      </div>
    </DefaultLayout>
  );
};

const AdminConsentErrorLayout = (props: AdminConsentErrorProps) => {
  const { name } = props;
  const { context } = useTeams();
  const message = name
    ? `Hey ${name}, you need to provide admin consent before you can use Officely.`
    : 'You need to provide admin consent before you can use Officely.';

  const url = useMemo(() => {
    if (!context) return undefined;
    const scopes = ['https://graph.microsoft.com/.default'];
    const tenant = context.user?.tenant?.id;
    const redirectUri = window.location.origin + '/admin-consent-callback';
    return `\
https://login.microsoftonline.com/${tenant}/v2.0/adminconsent\
?redirect_uri=${redirectUri}\
&client_id=${encodeURIComponent(config.clientId!)}\
&scope=${encodeURIComponent(scopes.join(' '))}`;
  }, [context]);

  if (!url) return null;

  return (
    <DefaultLayout>
      <div className="flex mt-12">
        <PartyingFace size={120} />
        <div className="ml-6">
          <TypographyH1 className="">Almost There!</TypographyH1>
          <TypographyP className="mt-2">{message}</TypographyP>
          <Button className="mt-4" asChild>
            <a href={url} target="_blank">
              Provide Admin Consent
            </a>
          </Button>
        </div>
      </div>
    </DefaultLayout>
  );
};

const UnauthenticatedErrorLayout = (props: UnauthenticatedErrorProps) => {
  return (
    <DefaultLayout>
      <div className="flex mt-12">
        <FaceWithMonocle size={92} />
        <div className="ml-4">
          <TypographyH1 className="">Something went wrong</TypographyH1>
          <TypographyP className="mt-2">
            We couldn't log you in. Please try refreshing the page or closing and reopening Teams. If the issue
            persists, please contact support.
          </TypographyP>
        </div>
      </div>
    </DefaultLayout>
  );
};

const UnknownErrorLayout = (props: UnknownErrorProps) => {
  useEffect(() => {
    if (props.error) {
      console.error(props.error);
    }
  }, [props.error]);

  return (
    <DefaultLayout>
      <div className="flex mt-12">
        <FaceWithMonocle size={92} />
        <div className="ml-4">
          <TypographyH1 className="">Something went wrong</TypographyH1>
          <TypographyP className="mt-2">
            We're not sure what's happened. Please try refreshing the page or closing and reopening Teams. If the
            problem persists, please contact support.
          </TypographyP>
        </div>
      </div>
    </DefaultLayout>
  );
};

export const ErrorLayout = (props: Props) => {
  switch (props.variant) {
    case 'admin-consent':
      return <AdminConsentErrorLayout {...props} />;
    case 'installation':
      return <InstallationErrorLayout {...props} />;
    case 'unauthenticated':
      return <UnauthenticatedErrorLayout {...props} />;
    case 'unknown':
      return <UnknownErrorLayout {...props} />;
    default:
      return <></>;
  }
};

import { emojis } from '@client/lib/emojis';
import { cn } from '@client/lib/utils';

export const Emoji = (
  props: React.HTMLAttributes<HTMLElement> & {
    sm?: boolean;
    lg?: boolean;
    xl?: boolean;
    name?: keyof typeof emojis;
  }
) => {
  const { sm = false, lg = false, xl = false, name, ...rest } = props;
  const resolved = name && emojis[name];
  return (
    <span
      {...rest}
      className={cn(rest.className, {
        'text-xl': !sm && !lg && !xl,
        'text-2xl': lg,
        'text-4xl': xl,
      })}
      role="img"
      aria-label="emoji">
      {resolved ?? props.children}
    </span>
  );
};

export enum TrpcCustomHeader {
  SsoToken = 'ofl-sso-token',
  TeamId = 'ofl-team-id',
  TestMode = 'ofl-test-mode',
  Timezone = 'ofl-timezone',
}

export enum ApiErrorCode {
  Unlicenced = 'unlicenced',
  NotOnboarded = 'not_onboarded',
}

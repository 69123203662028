import { ButtonProps } from '@client/components/ui/button';

export enum MaskValue {
  Today = 'today',
  Tomorrow = 'tomorrow',
  Yesterday = 'yesterday',
  WeekStart = 'week_start',
  WeekEnd = 'week_end',
  NextWeekStart = 'next_week_start',
  NextWeekEnd = 'next_week_end',
  LastWeekStart = 'last_week_start',
  LastWeekEnd = 'last_week_end',
}

export type SelectDateRangeValue = {
  from: Date | undefined;
  to?: Date;
};

export type SelectDateRangeProps = {
  selected?: SelectDateRangeValue;
  placeholder?: string;
  placeholderIcon?: React.ReactNode;
  loading?: boolean;
  disabled?: boolean;
  asBtn?: boolean;
  asBtnProps?: ButtonProps;
  inForm?: boolean;
  allowClear?: boolean;
  allowSameDayRange?: boolean;
  disableRange?: boolean;
  closeOnChange?: boolean;
  placeholderIconPosition?: 'left' | 'right';
  onChange?: (value: SelectDateRangeValue) => void;
  mask?: {
    min?: Date | MaskValue;
    max?: Date | MaskValue;
    custom?: (date: Date) => boolean;
  };
};

import { yyyy_MM_dd } from '@client/lib/const';
import { differenceInWeeks, format, parse, setISODay } from 'date-fns';

export const isoWeekdayToString = (isoWeekday: number, display?: 'M' | 'Mo' | 'Mon' | 'Monday') => {
  const mapping = {
    M: 'iiiii',
    Mo: 'iiiiii',
    Mon: 'iii',
    Monday: 'iiii',
  };
  return format(setISODay(new Date(), isoWeekday), display ? mapping[display] : 'iiii');
};

export const formatDate = (date: Date, _format?: string) => format(date, _format ?? yyyy_MM_dd);
export const parseDate = (date: string, _format?: string) => parse(date, _format ?? yyyy_MM_dd, new Date());

export const getWeekdaysBetweenDates = (from: Date, to: Date) => {
  const weekdays = new Set<number>();
  for (let date = new Date(from); date <= to; date.setDate(date.getDate() + 1)) {
    weekdays.add(date.getDay());
  }
  return Array.from(weekdays);
};

export const getWeekSpanBetweenDates = (from?: Date, to?: Date): number => {
  if (!from || !to) return 0;
  return differenceInWeeks(to, from);
};
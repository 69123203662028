import { cn } from '@client/lib/utils';

export const PartyingFace = (props: { size?: number; className?: string }) => {
  const { size = 48 } = props;
  return (
    <svg
      className={cn(`min-w-[${size}px]`, props.className)}
      width={size.toFixed(0)}
      height={size.toFixed(0)}
      viewBox="0 0 43 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M22.4297 38.625C33.807 38.625 39.4922 30.9859 39.4922 21.5625C39.4922 12.1391 33.807 4.5 22.4297 4.5C11.0524 4.5 5.36719 12.1391 5.36719 21.5625C5.36719 30.9859 11.0524 38.625 22.4297 38.625Z"
        fill="url(#paint0_radial_333_35101)"
      />
      <path
        d="M22.4297 38.625C33.807 38.625 39.4922 30.9859 39.4922 21.5625C39.4922 12.1391 33.807 4.5 22.4297 4.5C11.0524 4.5 5.36719 12.1391 5.36719 21.5625C5.36719 30.9859 11.0524 38.625 22.4297 38.625Z"
        fill="url(#paint1_radial_333_35101)"
      />
      <path
        d="M22.4297 38.625C33.807 38.625 39.4922 30.9859 39.4922 21.5625C39.4922 12.1391 33.807 4.5 22.4297 4.5C11.0524 4.5 5.36719 12.1391 5.36719 21.5625C5.36719 30.9859 11.0524 38.625 22.4297 38.625Z"
        fill="url(#paint2_radial_333_35101)"
      />
      <path
        d="M22.4297 38.625C33.807 38.625 39.4922 30.9859 39.4922 21.5625C39.4922 12.1391 33.807 4.5 22.4297 4.5C11.0524 4.5 5.36719 12.1391 5.36719 21.5625C5.36719 30.9859 11.0524 38.625 22.4297 38.625Z"
        fill="url(#paint3_radial_333_35101)"
      />
      <path
        d="M22.4297 38.625C33.807 38.625 39.4922 30.9859 39.4922 21.5625C39.4922 12.1391 33.807 4.5 22.4297 4.5C11.0524 4.5 5.36719 12.1391 5.36719 21.5625C5.36719 30.9859 11.0524 38.625 22.4297 38.625Z"
        fill="url(#paint4_radial_333_35101)"
      />
      <path
        d="M22.4297 38.625C33.807 38.625 39.4922 30.9859 39.4922 21.5625C39.4922 12.1391 33.807 4.5 22.4297 4.5C11.0524 4.5 5.36719 12.1391 5.36719 21.5625C5.36719 30.9859 11.0524 38.625 22.4297 38.625Z"
        fill="url(#paint5_radial_333_35101)"
      />
      <path
        d="M22.4297 38.625C33.807 38.625 39.4922 30.9859 39.4922 21.5625C39.4922 12.1391 33.807 4.5 22.4297 4.5C11.0524 4.5 5.36719 12.1391 5.36719 21.5625C5.36719 30.9859 11.0524 38.625 22.4297 38.625Z"
        fill="url(#paint6_radial_333_35101)"
      />
      <path
        d="M22.4297 38.625C33.807 38.625 39.4922 30.9859 39.4922 21.5625C39.4922 12.1391 33.807 4.5 22.4297 4.5C11.0524 4.5 5.36719 12.1391 5.36719 21.5625C5.36719 30.9859 11.0524 38.625 22.4297 38.625Z"
        fill="url(#paint7_radial_333_35101)"
      />
      <path
        d="M22.4297 38.625C33.807 38.625 39.4922 30.9859 39.4922 21.5625C39.4922 12.1391 33.807 4.5 22.4297 4.5C11.0524 4.5 5.36719 12.1391 5.36719 21.5625C5.36719 30.9859 11.0524 38.625 22.4297 38.625Z"
        fill="url(#paint8_radial_333_35101)"
      />
      <path
        d="M22.4297 38.625C33.807 38.625 39.4922 30.9859 39.4922 21.5625C39.4922 12.1391 33.807 4.5 22.4297 4.5C11.0524 4.5 5.36719 12.1391 5.36719 21.5625C5.36719 30.9859 11.0524 38.625 22.4297 38.625Z"
        fill="url(#paint9_radial_333_35101)"
      />
      <path
        d="M22.4297 38.625C33.807 38.625 39.4922 30.9859 39.4922 21.5625C39.4922 12.1391 33.807 4.5 22.4297 4.5C11.0524 4.5 5.36719 12.1391 5.36719 21.5625C5.36719 30.9859 11.0524 38.625 22.4297 38.625Z"
        fill="url(#paint10_radial_333_35101)"
      />
      <path
        d="M22.4297 38.625C33.807 38.625 39.4922 30.9859 39.4922 21.5625C39.4922 12.1391 33.807 4.5 22.4297 4.5C11.0524 4.5 5.36719 12.1391 5.36719 21.5625C5.36719 30.9859 11.0524 38.625 22.4297 38.625Z"
        fill="url(#paint11_radial_333_35101)"
      />
      <path
        d="M22.4297 38.625C33.807 38.625 39.4922 30.9859 39.4922 21.5625C39.4922 12.1391 33.807 4.5 22.4297 4.5C11.0524 4.5 5.36719 12.1391 5.36719 21.5625C5.36719 30.9859 11.0524 38.625 22.4297 38.625Z"
        fill="url(#paint12_radial_333_35101)"
      />
      <path
        d="M22.4297 38.625C33.807 38.625 39.4922 30.9859 39.4922 21.5625C39.4922 12.1391 33.807 4.5 22.4297 4.5C11.0524 4.5 5.36719 12.1391 5.36719 21.5625C5.36719 30.9859 11.0524 38.625 22.4297 38.625Z"
        fill="url(#paint13_radial_333_35101)"
      />
      <path
        d="M22.4297 38.625C33.807 38.625 39.4922 30.9859 39.4922 21.5625C39.4922 12.1391 33.807 4.5 22.4297 4.5C11.0524 4.5 5.36719 12.1391 5.36719 21.5625C5.36719 30.9859 11.0524 38.625 22.4297 38.625Z"
        fill="url(#paint14_radial_333_35101)"
      />
      <path
        d="M22.4297 38.625C33.807 38.625 39.4922 30.9859 39.4922 21.5625C39.4922 12.1391 33.807 4.5 22.4297 4.5C11.0524 4.5 5.36719 12.1391 5.36719 21.5625C5.36719 30.9859 11.0524 38.625 22.4297 38.625Z"
        fill="url(#paint15_radial_333_35101)"
      />
      <path
        d="M22.4297 38.625C33.807 38.625 39.4922 30.9859 39.4922 21.5625C39.4922 12.1391 33.807 4.5 22.4297 4.5C11.0524 4.5 5.36719 12.1391 5.36719 21.5625C5.36719 30.9859 11.0524 38.625 22.4297 38.625Z"
        fill="url(#paint16_radial_333_35101)"
      />
      <path
        d="M22.4297 38.625C33.807 38.625 39.4922 30.9859 39.4922 21.5625C39.4922 12.1391 33.807 4.5 22.4297 4.5C11.0524 4.5 5.36719 12.1391 5.36719 21.5625C5.36719 30.9859 11.0524 38.625 22.4297 38.625Z"
        fill="url(#paint17_radial_333_35101)"
      />
      <path
        d="M22.4297 38.625C33.807 38.625 39.4922 30.9859 39.4922 21.5625C39.4922 12.1391 33.807 4.5 22.4297 4.5C11.0524 4.5 5.36719 12.1391 5.36719 21.5625C5.36719 30.9859 11.0524 38.625 22.4297 38.625Z"
        fill="url(#paint18_radial_333_35101)"
      />
      <path
        d="M22.4297 38.625C33.807 38.625 39.4922 30.9859 39.4922 21.5625C39.4922 12.1391 33.807 4.5 22.4297 4.5C11.0524 4.5 5.36719 12.1391 5.36719 21.5625C5.36719 30.9859 11.0524 38.625 22.4297 38.625Z"
        fill="url(#paint19_radial_333_35101)"
      />
      <path
        d="M23.7422 4.5L5.36719 21.5625V18.0273L6.14773 15.9874L5.36719 14.5419V11.4581L6.2441 9.38611L5.36719 7.97269V6.5C5.36719 5.39543 6.26262 4.5 7.36719 4.5H8.86214L9.95433 5.33859L12.3154 4.5H15.4313L16.7645 5.19403L18.9006 4.5H23.7422Z"
        fill="#BB1D80"
      />
      <path
        d="M23.7422 4.5L5.36719 21.5625V18.0273L6.14773 15.9874L5.36719 14.5419V11.4581L6.2441 9.38611L5.36719 7.97269V6.5C5.36719 5.39543 6.26262 4.5 7.36719 4.5H8.86214L9.95433 5.33859L12.3154 4.5H15.4313L16.7645 5.19403L18.9006 4.5H23.7422Z"
        fill="url(#paint20_linear_333_35101)"
      />
      <path d="M12.4733 4.5L5.36719 11.6062V7.89384L8.76103 4.5H12.4733Z" fill="#5092FF" />
      <path d="M19.0358 4.5L5.36719 18.1687V14.4563L15.3235 4.5H19.0358Z" fill="#5092FF" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.8516 7.125C38.3952 7.125 38.8359 7.56572 38.8359 8.10938C38.8359 10.9944 36.4314 13.0312 33.9141 13.0312C33.3704 13.0312 32.9297 12.5905 32.9297 12.0469C32.9297 11.5032 33.3704 11.0625 33.9141 11.0625C35.4353 11.0625 36.8672 9.81809 36.8672 8.10938C36.8672 7.56572 37.3079 7.125 37.8516 7.125Z"
        fill="url(#paint21_radial_333_35101)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.2266 32.0625C14.7702 32.0625 15.2109 32.5032 15.2109 33.0469C15.2109 35.9319 12.8064 37.9688 10.2891 37.9688C9.74541 37.9688 9.30469 37.528 9.30469 36.9844C9.30469 36.4407 9.74541 36 10.2891 36C11.8103 36 13.2422 34.7556 13.2422 33.0469C13.2422 32.5032 13.6829 32.0625 14.2266 32.0625Z"
        fill="url(#paint22_radial_333_35101)"
      />
      <circle cx="35.5547" cy="37.3125" r="1.3125" fill="url(#paint23_radial_333_35101)" />
      <circle cx="13.2422" cy="26.8125" r="1.3125" fill="url(#paint24_radial_333_35101)" />
      <circle cx="32.9297" cy="4.5" r="1.3125" fill="url(#paint25_radial_333_35101)" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.5823 17.2136C27.2941 17.4872 27.1203 17.8379 27.0036 18.1347C26.7651 18.7419 26.0794 19.0407 25.4722 18.8022C24.865 18.5636 24.5662 17.8779 24.8048 17.2707C24.9702 16.8498 25.2988 16.1239 25.9557 15.5002C26.6425 14.8483 27.6255 14.3682 28.9618 14.3682C31.6848 14.3682 32.7636 16.4083 33.115 17.2456C33.3675 17.8471 33.0845 18.5394 32.483 18.7919C31.8814 19.0444 31.1891 18.7614 30.9366 18.1599C30.6855 17.5616 30.193 16.7307 28.9618 16.7307C28.2454 16.7307 27.8407 16.9683 27.5823 17.2136Z"
        fill="url(#paint26_radial_333_35101)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.5374 17.2136C15.2492 17.4872 15.0753 17.8379 14.9587 18.1347C14.7201 18.7419 14.0345 19.0407 13.4273 18.8022C12.8201 18.5636 12.5213 17.8779 12.7599 17.2707C12.9253 16.8498 13.2539 16.1239 13.9108 15.5002C14.5976 14.8483 15.5806 14.3682 16.9169 14.3682C19.6398 14.3682 20.7187 16.4083 21.0701 17.2456C21.3226 17.8471 21.0396 18.5394 20.4381 18.7919C19.8365 19.0444 19.1442 18.7614 18.8917 18.1599C18.6406 17.5616 18.1481 16.7307 16.9169 16.7307C16.2004 16.7307 15.7957 16.9683 15.5374 17.2136Z"
        fill="url(#paint27_radial_333_35101)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.9199 26.5903C24.9199 26.0467 25.3606 25.606 25.9043 25.606H36.213C36.7567 25.606 37.1974 26.0467 37.1974 26.5903C37.1974 27.134 36.7567 27.5747 36.213 27.5747H25.9043C25.3606 27.5747 24.9199 27.134 24.9199 26.5903Z"
        fill="#00A6ED"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.9199 26.5903C24.9199 26.0467 25.3606 25.606 25.9043 25.606H36.213C36.7567 25.606 37.1974 26.0467 37.1974 26.5903C37.1974 27.134 36.7567 27.5747 36.213 27.5747H25.9043C25.3606 27.5747 24.9199 27.134 24.9199 26.5903Z"
        fill="url(#paint28_radial_333_35101)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.9199 26.5903C24.9199 26.0467 25.3606 25.606 25.9043 25.606H36.213C36.7567 25.606 37.1974 26.0467 37.1974 26.5903C37.1974 27.134 36.7567 27.5747 36.213 27.5747H25.9043C25.3606 27.5747 24.9199 27.134 24.9199 26.5903Z"
        fill="url(#paint29_radial_333_35101)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.9199 26.5903C24.9199 26.0467 25.3606 25.606 25.9043 25.606H36.213C36.7567 25.606 37.1974 26.0467 37.1974 26.5903C37.1974 27.134 36.7567 27.5747 36.213 27.5747H25.9043C25.3606 27.5747 24.9199 27.134 24.9199 26.5903Z"
        fill="url(#paint30_radial_333_35101)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.9199 26.5903C24.9199 26.0467 25.3606 25.606 25.9043 25.606H36.213C36.7567 25.606 37.1974 26.0467 37.1974 26.5903C37.1974 27.134 36.7567 27.5747 36.213 27.5747H25.9043C25.3606 27.5747 24.9199 27.134 24.9199 26.5903Z"
        fill="url(#paint31_linear_333_35101)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.5273 18.6025C35.5273 18.0589 35.9681 17.6182 36.5117 17.6182H36.5935C39.1902 17.6182 41.2744 19.7621 41.2011 22.3578C41.1858 22.9012 40.7328 23.3293 40.1893 23.314C39.6459 23.2986 39.2178 22.8456 39.2331 22.3022C39.2751 20.8152 38.0811 19.5869 36.5935 19.5869H36.5117C35.9681 19.5869 35.5273 19.1462 35.5273 18.6025Z"
        fill="#00A6ED"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.5273 18.6025C35.5273 18.0589 35.9681 17.6182 36.5117 17.6182H36.5935C39.1902 17.6182 41.2744 19.7621 41.2011 22.3578C41.1858 22.9012 40.7328 23.3293 40.1893 23.314C39.6459 23.2986 39.2178 22.8456 39.2331 22.3022C39.2751 20.8152 38.0811 19.5869 36.5935 19.5869H36.5117C35.9681 19.5869 35.5273 19.1462 35.5273 18.6025Z"
        fill="url(#paint32_radial_333_35101)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.5273 18.6025C35.5273 18.0589 35.9681 17.6182 36.5117 17.6182H36.5935C39.1902 17.6182 41.2744 19.7621 41.2011 22.3578C41.1858 22.9012 40.7328 23.3293 40.1893 23.314C39.6459 23.2986 39.2178 22.8456 39.2331 22.3022C39.2751 20.8152 38.0811 19.5869 36.5935 19.5869H36.5117C35.9681 19.5869 35.5273 19.1462 35.5273 18.6025Z"
        fill="url(#paint33_radial_333_35101)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.5273 18.6025C35.5273 18.0589 35.9681 17.6182 36.5117 17.6182H36.5935C39.1902 17.6182 41.2744 19.7621 41.2011 22.3578C41.1858 22.9012 40.7328 23.3293 40.1893 23.314C39.6459 23.2986 39.2178 22.8456 39.2331 22.3022C39.2751 20.8152 38.0811 19.5869 36.5935 19.5869H36.5117C35.9681 19.5869 35.5273 19.1462 35.5273 18.6025Z"
        fill="url(#paint34_radial_333_35101)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.5273 18.6025C35.5273 18.0589 35.9681 17.6182 36.5117 17.6182H36.5935C39.1902 17.6182 41.2744 19.7621 41.2011 22.3578C41.1858 22.9012 40.7328 23.3293 40.1893 23.314C39.6459 23.2986 39.2178 22.8456 39.2331 22.3022C39.2751 20.8152 38.0811 19.5869 36.5935 19.5869H36.5117C35.9681 19.5869 35.5273 19.1462 35.5273 18.6025Z"
        fill="url(#paint35_radial_333_35101)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.5273 18.6025C35.5273 18.0589 35.9681 17.6182 36.5117 17.6182H36.5935C39.1902 17.6182 41.2744 19.7621 41.2011 22.3578C41.1858 22.9012 40.7328 23.3293 40.1893 23.314C39.6459 23.2986 39.2178 22.8456 39.2331 22.3022C39.2751 20.8152 38.0811 19.5869 36.5935 19.5869H36.5117C35.9681 19.5869 35.5273 19.1462 35.5273 18.6025Z"
        fill="url(#paint36_radial_333_35101)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.2437 21.3461C40.7871 21.3615 41.2152 21.8144 41.1999 22.3579L41.1892 22.7371C41.1131 25.4307 38.9076 27.5748 36.2129 27.5748C35.6692 27.5748 35.2285 27.1341 35.2285 26.5904C35.2285 26.0468 35.6692 25.6061 36.2129 25.6061C37.8419 25.6061 39.1752 24.3099 39.2212 22.6815L39.2319 22.3023C39.2473 21.7589 39.7003 21.3308 40.2437 21.3461Z"
        fill="#00A6ED"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.2437 21.3461C40.7871 21.3615 41.2152 21.8144 41.1999 22.3579L41.1892 22.7371C41.1131 25.4307 38.9076 27.5748 36.2129 27.5748C35.6692 27.5748 35.2285 27.1341 35.2285 26.5904C35.2285 26.0468 35.6692 25.6061 36.2129 25.6061C37.8419 25.6061 39.1752 24.3099 39.2212 22.6815L39.2319 22.3023C39.2473 21.7589 39.7003 21.3308 40.2437 21.3461Z"
        fill="url(#paint37_radial_333_35101)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.2437 21.3461C40.7871 21.3615 41.2152 21.8144 41.1999 22.3579L41.1892 22.7371C41.1131 25.4307 38.9076 27.5748 36.2129 27.5748C35.6692 27.5748 35.2285 27.1341 35.2285 26.5904C35.2285 26.0468 35.6692 25.6061 36.2129 25.6061C37.8419 25.6061 39.1752 24.3099 39.2212 22.6815L39.2319 22.3023C39.2473 21.7589 39.7003 21.3308 40.2437 21.3461Z"
        fill="url(#paint38_radial_333_35101)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.2437 21.3461C40.7871 21.3615 41.2152 21.8144 41.1999 22.3579L41.1892 22.7371C41.1131 25.4307 38.9076 27.5748 36.2129 27.5748C35.6692 27.5748 35.2285 27.1341 35.2285 26.5904C35.2285 26.0468 35.6692 25.6061 36.2129 25.6061C37.8419 25.6061 39.1752 24.3099 39.2212 22.6815L39.2319 22.3023C39.2473 21.7589 39.7003 21.3308 40.2437 21.3461Z"
        fill="url(#paint39_radial_333_35101)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.2437 21.3461C40.7871 21.3615 41.2152 21.8144 41.1999 22.3579L41.1892 22.7371C41.1131 25.4307 38.9076 27.5748 36.2129 27.5748C35.6692 27.5748 35.2285 27.1341 35.2285 26.5904C35.2285 26.0468 35.6692 25.6061 36.2129 25.6061C37.8419 25.6061 39.1752 24.3099 39.2212 22.6815L39.2319 22.3023C39.2473 21.7589 39.7003 21.3308 40.2437 21.3461Z"
        fill="url(#paint40_radial_333_35101)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M40.2437 21.3461C40.7871 21.3615 41.2152 21.8144 41.1999 22.3579L41.1892 22.7371C41.1131 25.4307 38.9076 27.5748 36.2129 27.5748C35.6692 27.5748 35.2285 27.1341 35.2285 26.5904C35.2285 26.0468 35.6692 25.6061 36.2129 25.6061C37.8419 25.6061 39.1752 24.3099 39.2212 22.6815L39.2319 22.3023C39.2473 21.7589 39.7003 21.3308 40.2437 21.3461Z"
        fill="url(#paint41_radial_333_35101)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.709 21.4209C32.709 19.3207 34.4115 17.6182 36.5117 17.6182H36.5934C37.1371 17.6182 37.5778 18.0589 37.5778 18.6025C37.5778 19.1462 37.1371 19.5869 36.5934 19.5869H36.5117C35.4988 19.5869 34.6777 20.408 34.6777 21.4209V21.833C34.6777 22.3767 34.237 22.8174 33.6934 22.8174C33.1497 22.8174 32.709 22.3767 32.709 21.833V21.4209Z"
        fill="#00A6ED"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.709 21.4209C32.709 19.3207 34.4115 17.6182 36.5117 17.6182H36.5934C37.1371 17.6182 37.5778 18.0589 37.5778 18.6025C37.5778 19.1462 37.1371 19.5869 36.5934 19.5869H36.5117C35.4988 19.5869 34.6777 20.408 34.6777 21.4209V21.833C34.6777 22.3767 34.237 22.8174 33.6934 22.8174C33.1497 22.8174 32.709 22.3767 32.709 21.833V21.4209Z"
        fill="url(#paint42_radial_333_35101)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.709 21.4209C32.709 19.3207 34.4115 17.6182 36.5117 17.6182H36.5934C37.1371 17.6182 37.5778 18.0589 37.5778 18.6025C37.5778 19.1462 37.1371 19.5869 36.5934 19.5869H36.5117C35.4988 19.5869 34.6777 20.408 34.6777 21.4209V21.833C34.6777 22.3767 34.237 22.8174 33.6934 22.8174C33.1497 22.8174 32.709 22.3767 32.709 21.833V21.4209Z"
        fill="url(#paint43_radial_333_35101)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.709 21.4209C32.709 19.3207 34.4115 17.6182 36.5117 17.6182H36.5934C37.1371 17.6182 37.5778 18.0589 37.5778 18.6025C37.5778 19.1462 37.1371 19.5869 36.5934 19.5869H36.5117C35.4988 19.5869 34.6777 20.408 34.6777 21.4209V21.833C34.6777 22.3767 34.237 22.8174 33.6934 22.8174C33.1497 22.8174 32.709 22.3767 32.709 21.833V21.4209Z"
        fill="url(#paint44_radial_333_35101)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.709 21.4209C32.709 19.3207 34.4115 17.6182 36.5117 17.6182H36.5934C37.1371 17.6182 37.5778 18.0589 37.5778 18.6025C37.5778 19.1462 37.1371 19.5869 36.5934 19.5869H36.5117C35.4988 19.5869 34.6777 20.408 34.6777 21.4209V21.833C34.6777 22.3767 34.237 22.8174 33.6934 22.8174C33.1497 22.8174 32.709 22.3767 32.709 21.833V21.4209Z"
        fill="url(#paint45_radial_333_35101)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.709 21.4209C32.709 19.3207 34.4115 17.6182 36.5117 17.6182H36.5934C37.1371 17.6182 37.5778 18.0589 37.5778 18.6025C37.5778 19.1462 37.1371 19.5869 36.5934 19.5869H36.5117C35.4988 19.5869 34.6777 20.408 34.6777 21.4209V21.833C34.6777 22.3767 34.237 22.8174 33.6934 22.8174C33.1497 22.8174 32.709 22.3767 32.709 21.833V21.4209Z"
        fill="url(#paint46_radial_333_35101)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.0967 20.7866C37.6403 20.7866 38.081 21.2273 38.081 21.771V21.833C38.081 23.3165 36.8785 24.519 35.395 24.519C33.9116 24.519 32.709 23.3165 32.709 21.833C32.709 21.2894 33.1497 20.8486 33.6934 20.8486C34.237 20.8486 34.6777 21.2894 34.6777 21.833C34.6777 22.2292 34.9989 22.5503 35.395 22.5503C35.7911 22.5503 36.1123 22.2292 36.1123 21.833V21.771C36.1123 21.2273 36.553 20.7866 37.0967 20.7866Z"
        fill="#00A6ED"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.0967 20.7866C37.6403 20.7866 38.081 21.2273 38.081 21.771V21.833C38.081 23.3165 36.8785 24.519 35.395 24.519C33.9116 24.519 32.709 23.3165 32.709 21.833C32.709 21.2894 33.1497 20.8486 33.6934 20.8486C34.237 20.8486 34.6777 21.2894 34.6777 21.833C34.6777 22.2292 34.9989 22.5503 35.395 22.5503C35.7911 22.5503 36.1123 22.2292 36.1123 21.833V21.771C36.1123 21.2273 36.553 20.7866 37.0967 20.7866Z"
        fill="url(#paint47_radial_333_35101)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.0967 20.7866C37.6403 20.7866 38.081 21.2273 38.081 21.771V21.833C38.081 23.3165 36.8785 24.519 35.395 24.519C33.9116 24.519 32.709 23.3165 32.709 21.833C32.709 21.2894 33.1497 20.8486 33.6934 20.8486C34.237 20.8486 34.6777 21.2894 34.6777 21.833C34.6777 22.2292 34.9989 22.5503 35.395 22.5503C35.7911 22.5503 36.1123 22.2292 36.1123 21.833V21.771C36.1123 21.2273 36.553 20.7866 37.0967 20.7866Z"
        fill="url(#paint48_radial_333_35101)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.0967 20.7866C37.6403 20.7866 38.081 21.2273 38.081 21.771V21.833C38.081 23.3165 36.8785 24.519 35.395 24.519C33.9116 24.519 32.709 23.3165 32.709 21.833C32.709 21.2894 33.1497 20.8486 33.6934 20.8486C34.237 20.8486 34.6777 21.2894 34.6777 21.833C34.6777 22.2292 34.9989 22.5503 35.395 22.5503C35.7911 22.5503 36.1123 22.2292 36.1123 21.833V21.771C36.1123 21.2273 36.553 20.7866 37.0967 20.7866Z"
        fill="url(#paint49_radial_333_35101)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.0967 20.7866C37.6403 20.7866 38.081 21.2273 38.081 21.771V21.833C38.081 23.3165 36.8785 24.519 35.395 24.519C33.9116 24.519 32.709 23.3165 32.709 21.833C32.709 21.2894 33.1497 20.8486 33.6934 20.8486C34.237 20.8486 34.6777 21.2894 34.6777 21.833C34.6777 22.2292 34.9989 22.5503 35.395 22.5503C35.7911 22.5503 36.1123 22.2292 36.1123 21.833V21.771C36.1123 21.2273 36.553 20.7866 37.0967 20.7866Z"
        fill="url(#paint50_radial_333_35101)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.0967 20.7866C37.6403 20.7866 38.081 21.2273 38.081 21.771V21.833C38.081 23.3165 36.8785 24.519 35.395 24.519C33.9116 24.519 32.709 23.3165 32.709 21.833C32.709 21.2894 33.1497 20.8486 33.6934 20.8486C34.237 20.8486 34.6777 21.2894 34.6777 21.833C34.6777 22.2292 34.9989 22.5503 35.395 22.5503C35.7911 22.5503 36.1123 22.2292 36.1123 21.833V21.771C36.1123 21.2273 36.553 20.7866 37.0967 20.7866Z"
        fill="url(#paint51_radial_333_35101)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.0967 20.7866C37.6403 20.7866 38.081 21.2273 38.081 21.771V21.833C38.081 23.3165 36.8785 24.519 35.395 24.519C33.9116 24.519 32.709 23.3165 32.709 21.833C32.709 21.2894 33.1497 20.8486 33.6934 20.8486C34.237 20.8486 34.6777 21.2894 34.6777 21.833C34.6777 22.2292 34.9989 22.5503 35.395 22.5503C35.7911 22.5503 36.1123 22.2292 36.1123 21.833V21.771C36.1123 21.2273 36.553 20.7866 37.0967 20.7866Z"
        fill="url(#paint52_radial_333_35101)"
      />
      <path
        d="M25.0547 21.8774C24.3298 21.8774 23.7422 22.4651 23.7422 23.1899C23.7422 23.9148 24.3298 24.5024 25.0547 24.5024C25.2695 24.5024 25.4425 24.6755 25.4425 24.8902C25.4425 25.105 25.2695 25.278 25.0547 25.278C24.3298 25.278 23.7422 25.8657 23.7422 26.5905C23.7422 26.5906 23.7422 26.5905 23.7422 26.5905C23.7422 27.3154 24.3298 27.9031 25.0547 27.9031C25.2695 27.9031 25.4425 28.0761 25.4425 28.2909C25.4425 28.5056 25.2695 28.6787 25.0547 28.6787C24.3298 28.6787 23.7422 29.2663 23.7422 29.9912C23.7422 30.7161 24.3298 31.3037 25.0547 31.3037C26.7192 31.3037 28.0675 29.9554 28.0675 28.2909C28.0675 27.6599 27.8738 27.0744 27.5425 26.5906C27.8738 26.1067 28.0675 25.5212 28.0675 24.8902C28.0675 23.2257 26.7192 21.8774 25.0547 21.8774Z"
        fill="url(#paint53_radial_333_35101)"
      />
      <defs>
        <radialGradient
          id="paint0_radial_333_35101"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(34.0101 13.0319) rotate(132.839) scale(45.7108)">
          <stop stopColor="#FFF478" />
          <stop offset="0.474827" stopColor="#FFB02E" />
          <stop offset="1" stopColor="#F70A8D" />
        </radialGradient>
        <radialGradient
          id="paint1_radial_333_35101"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(24.007 21.1182) rotate(121.67) scale(18.774)">
          <stop stopOpacity="0" />
          <stop offset="0.692364" stopOpacity="0.0118229" />
          <stop offset="1" stopOpacity="0.09" />
        </radialGradient>
        <radialGradient
          id="paint2_radial_333_35101"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(22.431 17.2979) rotate(90) scale(21.7995)">
          <stop offset="0.823911" stopColor="#F59639" stopOpacity="0" />
          <stop offset="1" stopColor="#FF63C4" />
        </radialGradient>
        <radialGradient
          id="paint3_radial_333_35101"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(19.3464 18.0192) rotate(82.316) scale(1.92469 1.73978)">
          <stop stopColor="#CA7A1B" stopOpacity="0.85" />
          <stop offset="0.55915" stopColor="#CA7A1B" stopOpacity="0.336184" />
          <stop offset="0.868092" stopColor="#CA7A1B" stopOpacity="0.0764313" />
          <stop offset="1" stopColor="#CA7A1B" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint4_radial_333_35101"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(34.0466 27.5406) rotate(90) scale(1.143 7.13898)">
          <stop stopColor="#BB582B" stopOpacity="0.34" />
          <stop offset="0.64156" stopColor="#BB582B" stopOpacity="0.0937459" />
          <stop offset="1" stopColor="#BB582B" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint5_radial_333_35101"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(35.3455 27.4646) rotate(83.4781) scale(1.10037 2.67208)">
          <stop stopColor="#BB582B" stopOpacity="0.34" />
          <stop offset="0.204707" stopColor="#BB582B" stopOpacity="0.0937459" />
          <stop offset="1" stopColor="#BB582B" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint6_radial_333_35101"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(33.7673 23.6679) rotate(127.999) scale(2.0291 2.64435)">
          <stop stopColor="#BB582B" stopOpacity="0.47" />
          <stop offset="0.744792" stopColor="#BB582B" stopOpacity="0.105729" />
          <stop offset="1" stopColor="#BB582B" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint7_radial_333_35101"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(37.2127 23.6491) rotate(-164.616) scale(1.25841 1.74591)">
          <stop stopColor="#BB582B" stopOpacity="0.46" />
          <stop offset="0.743212" stopColor="#BB582B" stopOpacity="0.105729" />
          <stop offset="1" stopColor="#BB582B" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint8_radial_333_35101"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(33.2766 21.7655) rotate(180) scale(2.14423 2.79439)">
          <stop stopColor="#BB582B" stopOpacity="0.52" />
          <stop offset="0.437811" stopColor="#BB582B" stopOpacity="0.33" />
          <stop offset="0.744792" stopColor="#BB582B" stopOpacity="0.105729" />
          <stop offset="1" stopColor="#BB582B" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint9_radial_333_35101"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(36.5754 25.3344) rotate(-124.317) scale(1.46563 2.0334)">
          <stop stopColor="#BB582B" stopOpacity="0.35" />
          <stop offset="0.743212" stopColor="#BB582B" stopOpacity="0.105729" />
          <stop offset="1" stopColor="#BB582B" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint10_radial_333_35101"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(36.1489 20.9562) rotate(107.996) scale(0.971588 1.36064)">
          <stop stopColor="#BB582B" stopOpacity="0.26" />
          <stop offset="0.634263" stopColor="#BB582B" stopOpacity="0.12" />
          <stop offset="1" stopColor="#BB582B" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint11_radial_333_35101"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(34.7776 20.5527) rotate(87.7094) scale(1.18276 1.65637)">
          <stop stopColor="#BB582B" stopOpacity="0.37" />
          <stop offset="0.567708" stopColor="#BB582B" stopOpacity="0.19" />
          <stop offset="1" stopColor="#BB582B" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint12_radial_333_35101"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(17.7266 16.3994) rotate(106.109) scale(1.10592 2.64515)">
          <stop stopColor="#CA7A1B" stopOpacity="0.85" />
          <stop offset="0.53696" stopColor="#CA7A1B" stopOpacity="0.336184" />
          <stop offset="1" stopColor="#CA7A1B" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint13_radial_333_35101"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(18.6194 17.214) rotate(120.805) scale(1.08579 2.61851)">
          <stop stopColor="#CA7A1B" stopOpacity="0.85" />
          <stop offset="0.254368" stopColor="#CA7A1B" stopOpacity="0.336184" />
          <stop offset="1" stopColor="#CA7A1B" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint14_radial_333_35101"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(31.3461 17.8072) rotate(118.322) scale(1.79053)">
          <stop stopColor="#CA7A1B" stopOpacity="0.8" />
          <stop offset="0.695556" stopColor="#CA7A1B" stopOpacity="0.158114" />
          <stop offset="1" stopColor="#CA7A1B" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint15_radial_333_35101"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(31.581 18.3302) rotate(126.357) scale(1.62106 1.58881)">
          <stop stopColor="#CA7A1B" stopOpacity="0.38" />
          <stop offset="0.556517" stopColor="#CA7A1B" stopOpacity="0.158114" />
          <stop offset="1" stopColor="#CA7A1B" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint16_radial_333_35101"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(30.467 16.8619) rotate(124.482) scale(1.04501 2.84342)">
          <stop stopColor="#CA7A1B" stopOpacity="0.8" />
          <stop offset="0.727564" stopColor="#CA7A1B" stopOpacity="0.158114" />
          <stop offset="1" stopColor="#CA7A1B" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint17_radial_333_35101"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(33.1543 13.4893) rotate(105.526) scale(2.04851)">
          <stop stopColor="#E37847" stopOpacity="0.37" />
          <stop offset="0.427196" stopColor="#E37847" stopOpacity="0.268229" />
          <stop offset="1" stopColor="#E37847" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint18_radial_333_35101"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(29.4499 27.3624) rotate(89.2151) scale(1.43429 6.84046)">
          <stop stopColor="#BB582B" stopOpacity="0.32" />
          <stop offset="0.702064" stopColor="#BB582B" stopOpacity="0.15" />
          <stop offset="1" stopColor="#BB582B" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint19_radial_333_35101"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(28.2788 27.3271) rotate(91.0809) scale(1.29841 4.10659)">
          <stop stopColor="#BB582B" stopOpacity="0.22" />
          <stop offset="0.702064" stopColor="#BB582B" stopOpacity="0.09" />
          <stop offset="1" stopColor="#BB582B" stopOpacity="0" />
        </radialGradient>
        <linearGradient
          id="paint20_linear_333_35101"
          x1="5.36719"
          y1="25.4282"
          x2="22.4297"
          y2="5.98682"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#5092FF" />
          <stop offset="0.859375" stopColor="#5092FF" stopOpacity="0" />
        </linearGradient>
        <radialGradient
          id="paint21_radial_333_35101"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(38.0361 12.2622) rotate(-129.912) scale(4.25111 18.0063)">
          <stop offset="0.359375" stopColor="#FF42B1" />
          <stop offset="0.854167" stopColor="#F70A8D" />
          <stop offset="1" stopColor="#CE336E" />
        </radialGradient>
        <radialGradient
          id="paint22_radial_333_35101"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(14.4111 37.1997) rotate(-129.912) scale(4.25111 18.0063)">
          <stop offset="0.359375" stopColor="#FF42B1" />
          <stop offset="0.854167" stopColor="#F70A8D" />
          <stop offset="1" stopColor="#CE336E" />
        </radialGradient>
        <radialGradient
          id="paint23_radial_333_35101"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(36.2109 36.6562) rotate(135) scale(1.85616)">
          <stop stopColor="#45C9FF" />
          <stop offset="1" stopColor="#3E80FF" />
        </radialGradient>
        <radialGradient
          id="paint24_radial_333_35101"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(13.8984 26.1562) rotate(135) scale(1.85616)">
          <stop stopColor="#45C9FF" />
          <stop offset="1" stopColor="#3E80FF" />
        </radialGradient>
        <radialGradient
          id="paint25_radial_333_35101"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(33.5859 3.84375) rotate(135) scale(1.85616)">
          <stop stopColor="#45C9FF" />
          <stop offset="1" stopColor="#3E80FF" />
        </radialGradient>
        <radialGradient
          id="paint26_radial_333_35101"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(28.965 17.9046) rotate(-91.5327) scale(8.58271 12.0312)">
          <stop offset="0.166667" stopColor="#482641" />
          <stop offset="0.276042" stopColor="#503A4A" />
          <stop offset="0.401042" stopColor="#483637" />
        </radialGradient>
        <radialGradient
          id="paint27_radial_333_35101"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(16.9201 17.9046) rotate(-91.5327) scale(8.58271 12.0312)">
          <stop offset="0.166667" stopColor="#482641" />
          <stop offset="0.276042" stopColor="#503A4A" />
          <stop offset="0.401042" stopColor="#483637" />
        </radialGradient>
        <radialGradient
          id="paint28_radial_333_35101"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(29.5659 20.6702) rotate(9.65183) scale(6.11587 10.3563)">
          <stop offset="0.328125" stopColor="#1B6AD1" />
          <stop offset="1" stopColor="#2A80F0" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint29_radial_333_35101"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(35.8002 21.6341) rotate(27.8973) scale(1.5779 2.27197)">
          <stop stopColor="#1B6AD1" />
          <stop offset="1" stopColor="#2A80F0" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint30_radial_333_35101"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(37.892 29.4475) rotate(-92.9038) scale(5.66743 23.321)">
          <stop stopColor="#1B6AD1" />
          <stop offset="0.601914" stopColor="#2A80F0" stopOpacity="0" />
        </radialGradient>
        <linearGradient
          id="paint31_linear_333_35101"
          x1="31.0587"
          y1="25.6083"
          x2="31.0587"
          y2="27.5747"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#A0D9FF" stopOpacity="0" />
          <stop offset="0.238015" stopColor="#A0D9FF" stopOpacity="0.413046" />
          <stop offset="0.360818" stopColor="#A0D9FF" stopOpacity="0.52" />
          <stop offset="0.465258" stopColor="#A0D9FF" stopOpacity="0.534742" />
          <stop offset="0.590242" stopColor="#A0D9FF" stopOpacity="0.411458" />
          <stop offset="0.85064" stopColor="#A0D9FF" stopOpacity="0" />
        </linearGradient>
        <radialGradient
          id="paint32_radial_333_35101"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(30.1454 17.0007) rotate(9.29314) scale(5.80652 9.48025)">
          <stop offset="0.328125" stopColor="#1B6AD1" />
          <stop offset="1" stopColor="#2A80F0" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint33_radial_333_35101"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(36.0706 17.8821) rotate(26.9934) scale(1.48742 2.09469)">
          <stop stopColor="#1B6AD1" />
          <stop offset="1" stopColor="#2A80F0" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint34_radial_333_35101"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(38.0587 25.0271) rotate(-93.0178) scale(5.18312 22.1622)">
          <stop stopColor="#1B6AD1" />
          <stop offset="0.601914" stopColor="#2A80F0" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint35_radial_333_35101"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(37.0539 22.079) rotate(-54.7726) scale(4.05921 3.84774)">
          <stop stopColor="#A0D9FF" stopOpacity="0" />
          <stop offset="0.623619" stopColor="#A0D9FF" stopOpacity="0.183577" />
          <stop offset="0.727437" stopColor="#A0D9FF" stopOpacity="0.47" />
          <stop offset="0.798816" stopColor="#A0D9FF" stopOpacity="0.5" />
          <stop offset="0.83338" stopColor="#A0D9FF" stopOpacity="0.52" />
          <stop offset="0.8682" stopColor="#A0D9FF" stopOpacity="0.46" />
          <stop offset="1" stopColor="#A0D9FF" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint36_radial_333_35101"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(37.1548 17.6454) rotate(98.9305) scale(0.363291 1.37464)">
          <stop stopColor="#00A6ED" />
          <stop offset="0.739378" stopColor="#00A6ED" />
          <stop offset="1" stopColor="#00A6ED" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint37_radial_333_35101"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(29.5658 20.6704) rotate(9.65183) scale(6.11587 10.3563)">
          <stop offset="0.328125" stopColor="#1B6AD1" />
          <stop offset="1" stopColor="#2A80F0" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint38_radial_333_35101"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(35.8001 21.6343) rotate(27.8973) scale(1.5779 2.27197)">
          <stop stopColor="#1B6AD1" />
          <stop offset="1" stopColor="#2A80F0" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint39_radial_333_35101"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(37.8919 29.4478) rotate(-92.9038) scale(5.66743 23.321)">
          <stop stopColor="#1B6AD1" />
          <stop offset="0.601914" stopColor="#2A80F0" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint40_radial_333_35101"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(36.0703 22.3798) rotate(37.0007) scale(5.17027 4.95668)">
          <stop offset="0.626079" stopColor="#A0D9FF" stopOpacity="0" />
          <stop offset="0.779522" stopColor="#A0D9FF" stopOpacity="0.47" />
          <stop offset="0.798816" stopColor="#A0D9FF" stopOpacity="0.5" />
          <stop offset="0.83338" stopColor="#A0D9FF" stopOpacity="0.52" />
          <stop offset="0.8682" stopColor="#A0D9FF" stopOpacity="0.46" />
          <stop offset="1" stopColor="#A0D9FF" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint41_radial_333_35101"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(41.1239 21.8067) rotate(161.94) scale(0.363083 1.55237)">
          <stop stopColor="#00A6ED" />
          <stop offset="0.628895" stopColor="#00A6ED" stopOpacity="0.537426" />
          <stop offset="1" stopColor="#00A6ED" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint42_radial_333_35101"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(28.0921 17.0545) rotate(9.87654) scale(4.98969 8.63826)">
          <stop offset="0.328125" stopColor="#1B6AD1" />
          <stop offset="1" stopColor="#2A80F0" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint43_radial_333_35101"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(33.175 17.8591) rotate(28.4569) scale(1.29323 1.88644)">
          <stop stopColor="#1B6AD1" />
          <stop offset="1" stopColor="#2A80F0" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint44_radial_333_35101"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(34.8805 24.3807) rotate(-92.8366) scale(4.73015 19.0149)">
          <stop stopColor="#1B6AD1" />
          <stop offset="0.601914" stopColor="#2A80F0" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint45_radial_333_35101"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(36.6981 21.6685) rotate(-130.314) scale(3.69453 3.56398)">
          <stop stopColor="#A0D9FF" stopOpacity="0" />
          <stop offset="0.493221" stopColor="#A0D9FF" stopOpacity="0.0663814" />
          <stop offset="0.735331" stopColor="#A0D9FF" stopOpacity="0.47" />
          <stop offset="0.798816" stopColor="#A0D9FF" stopOpacity="0.5" />
          <stop offset="0.83338" stopColor="#A0D9FF" stopOpacity="0.52" />
          <stop offset="0.8682" stopColor="#A0D9FF" stopOpacity="0.46" />
          <stop offset="0.941594" stopColor="#A0D9FF" stopOpacity="0.203845" />
          <stop offset="1" stopColor="#A0D9FF" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint46_radial_333_35101"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(34.7976 21.35) rotate(-158.86) scale(0.667055 1.25622)">
          <stop stopColor="#00A6ED" />
          <stop offset="0.56047" stopColor="#00A6ED" stopOpacity="0.610887" />
          <stop offset="1" stopColor="#00A6ED" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint47_radial_333_35101"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(27.6149 20.382) rotate(6.46289) scale(5.45848 6.25452)">
          <stop offset="0.328125" stopColor="#1B6AD1" />
          <stop offset="1" stopColor="#2A80F0" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint48_radial_333_35101"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(35.1049 25.6413) rotate(-94.3549) scale(3.40133 20.9453)">
          <stop stopColor="#1B6AD1" />
          <stop offset="0.601914" stopColor="#2A80F0" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint49_radial_333_35101"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(36.324 20.5785) rotate(31.5169) scale(1.03148 3.26885)">
          <stop stopColor="#1B6AD1" />
          <stop offset="1" stopColor="#2A80F0" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint50_radial_333_35101"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(35.4927 21.4565) rotate(96.2035) scale(2.64512 2.35973)">
          <stop stopColor="#A0D9FF" stopOpacity="0" />
          <stop offset="0.418873" stopColor="#A0D9FF" stopOpacity="0.0663814" />
          <stop offset="0.505564" stopColor="#A0D9FF" stopOpacity="0.244587" />
          <stop offset="0.583785" stopColor="#A0D9FF" stopOpacity="0.44" />
          <stop offset="0.682644" stopColor="#A0D9FF" stopOpacity="0.54" />
          <stop offset="0.725141" stopColor="#A0D9FF" stopOpacity="0.54" />
          <stop offset="0.772791" stopColor="#A0D9FF" stopOpacity="0.52" />
          <stop offset="0.816441" stopColor="#A0D9FF" stopOpacity="0.46" />
          <stop offset="0.91367" stopColor="#A0D9FF" stopOpacity="0.203845" />
          <stop offset="1" stopColor="#A0D9FF" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint51_radial_333_35101"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(37.4894 21.199) rotate(90) scale(0.65701 1.38107)">
          <stop stopColor="#00A6ED" />
          <stop offset="1" stopColor="#00A6ED" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint52_radial_333_35101"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(37.3627 21.3867) rotate(135) scale(0.549524 0.544505)">
          <stop stopColor="#A0D9FF" stopOpacity="0.5" />
          <stop offset="0.369792" stopColor="#A0D9FF" stopOpacity="0.38" />
          <stop offset="0.640625" stopColor="#A0D9FF" stopOpacity="0.22" />
          <stop offset="0.796875" stopColor="#A0D9FF" stopOpacity="0.0988406" />
          <stop offset="1" stopColor="#A0D9FF" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint53_radial_333_35101"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(23.7422 26.5906) scale(7.77826 8.47568)">
          <stop stopColor="#3E3537" />
          <stop offset="0.399715" stopColor="#442932" />
          <stop offset="0.675586" stopColor="#6A5662" />
        </radialGradient>
      </defs>
    </svg>
  );
};

import { cn } from '@client/lib/utils';

export const FaceWithMonocle = (props: { size?: number; className?: string }) => {
  const { size = 48 } = props;
  return (
    <svg
      className={cn(`min-w-[${size}px]`, props.className)}
      width={size.toFixed(0)}
      height={size.toFixed(0)}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.9989 29.9978C25.3333 29.9978 29.9978 23.7303 29.9978 15.9989C29.9978 8.26751 25.3333 2 15.9989 2C6.66443 2 2 8.26751 2 15.9989C2 23.7303 6.66443 29.9978 15.9989 29.9978Z"
        fill="url(#paint0_radial_730_31262)"
      />
      <path
        d="M15.9989 29.9978C25.3333 29.9978 29.9978 23.7303 29.9978 15.9989C29.9978 8.26751 25.3333 2 15.9989 2C6.66443 2 2 8.26751 2 15.9989C2 23.7303 6.66443 29.9978 15.9989 29.9978Z"
        fill="url(#paint1_radial_730_31262)"
      />
      <path
        d="M15.9989 29.9978C25.3333 29.9978 29.9978 23.7303 29.9978 15.9989C29.9978 8.26751 25.3333 2 15.9989 2C6.66443 2 2 8.26751 2 15.9989C2 23.7303 6.66443 29.9978 15.9989 29.9978Z"
        fill="url(#paint2_radial_730_31262)"
      />
      <path
        d="M15.9989 29.9978C25.3333 29.9978 29.9978 23.7303 29.9978 15.9989C29.9978 8.26751 25.3333 2 15.9989 2C6.66443 2 2 8.26751 2 15.9989C2 23.7303 6.66443 29.9978 15.9989 29.9978Z"
        fill="url(#paint3_radial_730_31262)"
        fillOpacity="0.6"
      />
      <path
        d="M15.9989 29.9978C25.3333 29.9978 29.9978 23.7303 29.9978 15.9989C29.9978 8.26751 25.3333 2 15.9989 2C6.66443 2 2 8.26751 2 15.9989C2 23.7303 6.66443 29.9978 15.9989 29.9978Z"
        fill="url(#paint4_radial_730_31262)"
      />
      <path
        d="M15.9989 29.9978C25.3333 29.9978 29.9978 23.7303 29.9978 15.9989C29.9978 8.26751 25.3333 2 15.9989 2C6.66443 2 2 8.26751 2 15.9989C2 23.7303 6.66443 29.9978 15.9989 29.9978Z"
        fill="url(#paint5_radial_730_31262)"
      />
      <path
        d="M15.9989 29.9978C25.3333 29.9978 29.9978 23.7303 29.9978 15.9989C29.9978 8.26751 25.3333 2 15.9989 2C6.66443 2 2 8.26751 2 15.9989C2 23.7303 6.66443 29.9978 15.9989 29.9978Z"
        fill="url(#paint6_radial_730_31262)"
      />
      <path
        d="M15.9989 29.9978C25.3333 29.9978 29.9978 23.7303 29.9978 15.9989C29.9978 8.26751 25.3333 2 15.9989 2C6.66443 2 2 8.26751 2 15.9989C2 23.7303 6.66443 29.9978 15.9989 29.9978Z"
        fill="url(#paint7_radial_730_31262)"
      />
      <path
        d="M15.9989 29.9978C25.3333 29.9978 29.9978 23.7303 29.9978 15.9989C29.9978 8.26751 25.3333 2 15.9989 2C6.66443 2 2 8.26751 2 15.9989C2 23.7303 6.66443 29.9978 15.9989 29.9978Z"
        fill="url(#paint8_radial_730_31262)"
      />
      <path
        d="M15.9989 29.9978C25.3333 29.9978 29.9978 23.7303 29.9978 15.9989C29.9978 8.26751 25.3333 2 15.9989 2C6.66443 2 2 8.26751 2 15.9989C2 23.7303 6.66443 29.9978 15.9989 29.9978Z"
        fill="url(#paint9_radial_730_31262)"
      />
      <path
        d="M15.9989 29.9978C25.3333 29.9978 29.9978 23.7303 29.9978 15.9989C29.9978 8.26751 25.3333 2 15.9989 2C6.66443 2 2 8.26751 2 15.9989C2 23.7303 6.66443 29.9978 15.9989 29.9978Z"
        fill="url(#paint10_linear_730_31262)"
      />
      <path
        d="M15.9989 29.9978C25.3333 29.9978 29.9978 23.7303 29.9978 15.9989C29.9978 8.26751 25.3333 2 15.9989 2C6.66443 2 2 8.26751 2 15.9989C2 23.7303 6.66443 29.9978 15.9989 29.9978Z"
        fill="url(#paint11_radial_730_31262)"
      />
      <path
        d="M15.9989 29.9978C25.3333 29.9978 29.9978 23.7303 29.9978 15.9989C29.9978 8.26751 25.3333 2 15.9989 2C6.66443 2 2 8.26751 2 15.9989C2 23.7303 6.66443 29.9978 15.9989 29.9978Z"
        fill="url(#paint12_radial_730_31262)"
      />
      <path
        d="M15.9989 29.9978C25.3333 29.9978 29.9978 23.7303 29.9978 15.9989C29.9978 8.26751 25.3333 2 15.9989 2C6.66443 2 2 8.26751 2 15.9989C2 23.7303 6.66443 29.9978 15.9989 29.9978Z"
        fill="url(#paint13_radial_730_31262)"
      />
      <path
        d="M15.9989 29.9978C25.3333 29.9978 29.9978 23.7303 29.9978 15.9989C29.9978 8.26751 25.3333 2 15.9989 2C6.66443 2 2 8.26751 2 15.9989C2 23.7303 6.66443 29.9978 15.9989 29.9978Z"
        fill="url(#paint14_radial_730_31262)"
      />
      <path
        d="M15.9989 29.9978C25.3333 29.9978 29.9978 23.7303 29.9978 15.9989C29.9978 8.26751 25.3333 2 15.9989 2C6.66443 2 2 8.26751 2 15.9989C2 23.7303 6.66443 29.9978 15.9989 29.9978Z"
        fill="url(#paint15_radial_730_31262)"
      />
      <path
        d="M15.9989 29.9978C25.3333 29.9978 29.9978 23.7303 29.9978 15.9989C29.9978 8.26751 25.3333 2 15.9989 2C6.66443 2 2 8.26751 2 15.9989C2 23.7303 6.66443 29.9978 15.9989 29.9978Z"
        fill="url(#paint16_radial_730_31262)"
      />
      <path
        d="M15.9989 29.9978C25.3333 29.9978 29.9978 23.7303 29.9978 15.9989C29.9978 8.26751 25.3333 2 15.9989 2C6.66443 2 2 8.26751 2 15.9989C2 23.7303 6.66443 29.9978 15.9989 29.9978Z"
        fill="url(#paint17_radial_730_31262)"
      />
      <path
        d="M15.9989 29.9978C25.3333 29.9978 29.9978 23.7303 29.9978 15.9989C29.9978 8.26751 25.3333 2 15.9989 2C6.66443 2 2 8.26751 2 15.9989C2 23.7303 6.66443 29.9978 15.9989 29.9978Z"
        fill="url(#paint18_radial_730_31262)"
      />
      <path
        d="M15.9989 29.9978C25.3333 29.9978 29.9978 23.7303 29.9978 15.9989C29.9978 8.26751 25.3333 2 15.9989 2C6.66443 2 2 8.26751 2 15.9989C2 23.7303 6.66443 29.9978 15.9989 29.9978Z"
        fill="url(#paint19_radial_730_31262)"
      />
      <circle cx="21.4544" cy="11.9231" r="4.964" fill="white" />
      <circle cx="10.22" cy="11.97" r="4.21875" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.312 4.87945C19.0314 5.10067 18.6245 5.05391 18.4014 4.77417C18.1776 4.49348 18.2238 4.08452 18.5045 3.86072L18.5052 3.8601L18.5061 3.8594L18.5082 3.85779L18.5133 3.85373L18.5282 3.84236C18.54 3.83339 18.5557 3.82169 18.5752 3.80766C18.6142 3.77961 18.6685 3.74215 18.7372 3.69848C18.8744 3.61131 19.0704 3.4985 19.3179 3.38662C19.8117 3.16338 20.5216 2.93884 21.3822 2.93884C22.2565 2.93884 23.0306 3.20412 23.5717 3.45554C23.8452 3.58261 24.0673 3.70994 24.2229 3.80682C24.3009 3.85539 24.3627 3.89664 24.4066 3.92685C24.4285 3.94197 24.446 3.95436 24.4587 3.96356C24.4587 3.96356 24.4683 3.97045 24.4743 3.97493C24.4764 3.9764 24.4795 3.97871 24.4795 3.97871L24.4814 3.98011L24.4825 3.98095C24.7697 4.19634 24.8282 4.60399 24.6128 4.89118C24.3976 5.17816 23.9906 5.23649 23.7034 5.02166L23.6988 5.01831C23.693 5.01415 23.6829 5.00694 23.6686 4.99711C23.6401 4.97743 23.5952 4.94741 23.5358 4.91044C23.4167 4.83625 23.2413 4.7355 23.0239 4.63449C22.5832 4.42974 22.002 4.23884 21.3822 4.23884C20.7487 4.23884 20.2224 4.40437 19.8534 4.57118C19.6695 4.65433 19.5275 4.73655 19.4345 4.79565C19.3882 4.82512 19.3544 4.8486 19.334 4.86321C19.3239 4.87051 19.3171 4.87557 19.3139 4.87803L19.312 4.87945Z"
        fill="url(#paint20_radial_730_31262)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.40339 7.09097C8.40163 7.09257 8.39995 7.09409 8.39835 7.09553C8.13202 7.33624 7.72099 7.31547 7.48028 7.04915C7.23957 6.78282 7.26034 6.37179 7.52666 6.13108C7.82605 5.8605 8.64161 5.20691 10.3963 5.20691C11.3158 5.20691 12.0011 5.38508 12.5099 5.61784C13.0121 5.84752 13.3143 6.11855 13.4664 6.25498L13.4737 6.26152C13.741 6.50113 13.7635 6.91208 13.5239 7.17939C13.2843 7.4467 12.8733 7.46915 12.606 7.22954L12.6009 7.22494C12.4761 7.11304 12.2913 6.94738 11.9692 6.80004C11.6412 6.65001 11.1419 6.50691 10.3963 6.50691C9.04802 6.50691 8.52661 6.97933 8.40339 7.09097Z"
        fill="url(#paint21_radial_730_31262)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.5816 19.3792C13.569 19.3864 13.5564 19.3936 13.5438 19.4008C13.1121 19.6472 12.5624 19.497 12.316 19.0653C12.0696 18.6336 12.2198 18.0839 12.6515 17.8375C12.6674 17.8285 12.6841 17.8189 12.7016 17.8088C12.9693 17.6551 13.426 17.3929 14.0556 17.2241C14.7349 17.042 15.5811 16.975 16.6294 17.1807C18.5683 17.5611 19.302 18.8668 19.6908 19.5737C19.9304 20.0092 19.7716 20.5565 19.336 20.7961C18.9005 21.0356 18.3533 20.8768 18.1137 20.4413C17.7748 19.8251 17.3881 19.1638 16.2829 18.947C15.4996 18.7933 14.9314 18.8529 14.5217 18.9627C14.123 19.0696 13.8417 19.2304 13.5816 19.3792Z"
        fill="url(#paint22_radial_730_31262)"
      />
      <rect x="26.25" y="10.5" width="2" height="16" rx="1" fill="url(#paint23_linear_730_31262)" />
      <rect x="26.25" y="10.5" width="2" height="16" rx="1" fill="url(#paint24_linear_730_31262)" />
      <rect x="26.25" y="10.5" width="2" height="16" rx="1" fill="url(#paint25_radial_730_31262)" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.3571 12.9579C27.1481 13.079 26.9556 13.2517 26.889 13.4839C26.2116 15.8468 24.0348 17.576 21.4542 17.576C18.332 17.576 15.801 15.045 15.801 11.9229C15.801 8.80077 18.332 6.26978 21.4542 6.26978C24.0348 6.26978 26.2116 7.99896 26.889 10.3619C26.9556 10.5941 27.1481 10.7668 27.3571 10.8879C27.7141 11.0946 27.9542 11.4807 27.9542 11.9229C27.9542 12.3651 27.7141 12.7512 27.3571 12.9579ZM25.9073 11.9229C25.9073 14.3823 23.9135 16.376 21.4542 16.376C18.9948 16.376 17.001 14.3823 17.001 11.9229C17.001 9.46351 18.9948 7.46978 21.4542 7.46978C23.9135 7.46978 25.9073 9.46351 25.9073 11.9229Z"
        fill="url(#paint26_radial_730_31262)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.3571 12.9579C27.1481 13.079 26.9556 13.2517 26.889 13.4839C26.2116 15.8468 24.0348 17.576 21.4542 17.576C18.332 17.576 15.801 15.045 15.801 11.9229C15.801 8.80077 18.332 6.26978 21.4542 6.26978C24.0348 6.26978 26.2116 7.99896 26.889 10.3619C26.9556 10.5941 27.1481 10.7668 27.3571 10.8879C27.7141 11.0946 27.9542 11.4807 27.9542 11.9229C27.9542 12.3651 27.7141 12.7512 27.3571 12.9579ZM25.9073 11.9229C25.9073 14.3823 23.9135 16.376 21.4542 16.376C18.9948 16.376 17.001 14.3823 17.001 11.9229C17.001 9.46351 18.9948 7.46978 21.4542 7.46978C23.9135 7.46978 25.9073 9.46351 25.9073 11.9229Z"
        fill="url(#paint27_linear_730_31262)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.3571 12.9579C27.1481 13.079 26.9556 13.2517 26.889 13.4839C26.2116 15.8468 24.0348 17.576 21.4542 17.576C18.332 17.576 15.801 15.045 15.801 11.9229C15.801 8.80077 18.332 6.26978 21.4542 6.26978C24.0348 6.26978 26.2116 7.99896 26.889 10.3619C26.9556 10.5941 27.1481 10.7668 27.3571 10.8879C27.7141 11.0946 27.9542 11.4807 27.9542 11.9229C27.9542 12.3651 27.7141 12.7512 27.3571 12.9579ZM25.9073 11.9229C25.9073 14.3823 23.9135 16.376 21.4542 16.376C18.9948 16.376 17.001 14.3823 17.001 11.9229C17.001 9.46351 18.9948 7.46978 21.4542 7.46978C23.9135 7.46978 25.9073 9.46351 25.9073 11.9229Z"
        fill="url(#paint28_radial_730_31262)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.3571 12.9579C27.1481 13.079 26.9556 13.2517 26.889 13.4839C26.2116 15.8468 24.0348 17.576 21.4542 17.576C18.332 17.576 15.801 15.045 15.801 11.9229C15.801 8.80077 18.332 6.26978 21.4542 6.26978C24.0348 6.26978 26.2116 7.99896 26.889 10.3619C26.9556 10.5941 27.1481 10.7668 27.3571 10.8879C27.7141 11.0946 27.9542 11.4807 27.9542 11.9229C27.9542 12.3651 27.7141 12.7512 27.3571 12.9579ZM25.9073 11.9229C25.9073 14.3823 23.9135 16.376 21.4542 16.376C18.9948 16.376 17.001 14.3823 17.001 11.9229C17.001 9.46351 18.9948 7.46978 21.4542 7.46978C23.9135 7.46978 25.9073 9.46351 25.9073 11.9229Z"
        fill="url(#paint29_radial_730_31262)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.3571 12.9579C27.1481 13.079 26.9556 13.2517 26.889 13.4839C26.2116 15.8468 24.0348 17.576 21.4542 17.576C18.332 17.576 15.801 15.045 15.801 11.9229C15.801 8.80077 18.332 6.26978 21.4542 6.26978C24.0348 6.26978 26.2116 7.99896 26.889 10.3619C26.9556 10.5941 27.1481 10.7668 27.3571 10.8879C27.7141 11.0946 27.9542 11.4807 27.9542 11.9229C27.9542 12.3651 27.7141 12.7512 27.3571 12.9579ZM25.9073 11.9229C25.9073 14.3823 23.9135 16.376 21.4542 16.376C18.9948 16.376 17.001 14.3823 17.001 11.9229C17.001 9.46351 18.9948 7.46978 21.4542 7.46978C23.9135 7.46978 25.9073 9.46351 25.9073 11.9229Z"
        fill="url(#paint30_radial_730_31262)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.3571 12.9579C27.1481 13.079 26.9556 13.2517 26.889 13.4839C26.2116 15.8468 24.0348 17.576 21.4542 17.576C18.332 17.576 15.801 15.045 15.801 11.9229C15.801 8.80077 18.332 6.26978 21.4542 6.26978C24.0348 6.26978 26.2116 7.99896 26.889 10.3619C26.9556 10.5941 27.1481 10.7668 27.3571 10.8879C27.7141 11.0946 27.9542 11.4807 27.9542 11.9229C27.9542 12.3651 27.7141 12.7512 27.3571 12.9579ZM25.9073 11.9229C25.9073 14.3823 23.9135 16.376 21.4542 16.376C18.9948 16.376 17.001 14.3823 17.001 11.9229C17.001 9.46351 18.9948 7.46978 21.4542 7.46978C23.9135 7.46978 25.9073 9.46351 25.9073 11.9229Z"
        fill="url(#paint31_radial_730_31262)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.3571 12.9579C27.1481 13.079 26.9556 13.2517 26.889 13.4839C26.2116 15.8468 24.0348 17.576 21.4542 17.576C18.332 17.576 15.801 15.045 15.801 11.9229C15.801 8.80077 18.332 6.26978 21.4542 6.26978C24.0348 6.26978 26.2116 7.99896 26.889 10.3619C26.9556 10.5941 27.1481 10.7668 27.3571 10.8879C27.7141 11.0946 27.9542 11.4807 27.9542 11.9229C27.9542 12.3651 27.7141 12.7512 27.3571 12.9579ZM25.9073 11.9229C25.9073 14.3823 23.9135 16.376 21.4542 16.376C18.9948 16.376 17.001 14.3823 17.001 11.9229C17.001 9.46351 18.9948 7.46978 21.4542 7.46978C23.9135 7.46978 25.9073 9.46351 25.9073 11.9229Z"
        fill="url(#paint32_radial_730_31262)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.3571 12.9579C27.1481 13.079 26.9556 13.2517 26.889 13.4839C26.2116 15.8468 24.0348 17.576 21.4542 17.576C18.332 17.576 15.801 15.045 15.801 11.9229C15.801 8.80077 18.332 6.26978 21.4542 6.26978C24.0348 6.26978 26.2116 7.99896 26.889 10.3619C26.9556 10.5941 27.1481 10.7668 27.3571 10.8879C27.7141 11.0946 27.9542 11.4807 27.9542 11.9229C27.9542 12.3651 27.7141 12.7512 27.3571 12.9579ZM25.9073 11.9229C25.9073 14.3823 23.9135 16.376 21.4542 16.376C18.9948 16.376 17.001 14.3823 17.001 11.9229C17.001 9.46351 18.9948 7.46978 21.4542 7.46978C23.9135 7.46978 25.9073 9.46351 25.9073 11.9229Z"
        fill="url(#paint33_linear_730_31262)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.3571 12.9579C27.1481 13.079 26.9556 13.2517 26.889 13.4839C26.2116 15.8468 24.0348 17.576 21.4542 17.576C18.332 17.576 15.801 15.045 15.801 11.9229C15.801 8.80077 18.332 6.26978 21.4542 6.26978C24.0348 6.26978 26.2116 7.99896 26.889 10.3619C26.9556 10.5941 27.1481 10.7668 27.3571 10.8879C27.7141 11.0946 27.9542 11.4807 27.9542 11.9229C27.9542 12.3651 27.7141 12.7512 27.3571 12.9579ZM25.9073 11.9229C25.9073 14.3823 23.9135 16.376 21.4542 16.376C18.9948 16.376 17.001 14.3823 17.001 11.9229C17.001 9.46351 18.9948 7.46978 21.4542 7.46978C23.9135 7.46978 25.9073 9.46351 25.9073 11.9229Z"
        fill="url(#paint34_radial_730_31262)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.3571 12.9579C27.1481 13.079 26.9556 13.2517 26.889 13.4839C26.2116 15.8468 24.0348 17.576 21.4542 17.576C18.332 17.576 15.801 15.045 15.801 11.9229C15.801 8.80077 18.332 6.26978 21.4542 6.26978C24.0348 6.26978 26.2116 7.99896 26.889 10.3619C26.9556 10.5941 27.1481 10.7668 27.3571 10.8879C27.7141 11.0946 27.9542 11.4807 27.9542 11.9229C27.9542 12.3651 27.7141 12.7512 27.3571 12.9579ZM25.9073 11.9229C25.9073 14.3823 23.9135 16.376 21.4542 16.376C18.9948 16.376 17.001 14.3823 17.001 11.9229C17.001 9.46351 18.9948 7.46978 21.4542 7.46978C23.9135 7.46978 25.9073 9.46351 25.9073 11.9229Z"
        fill="url(#paint35_radial_730_31262)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 9.5C19.8952 9.5 19 10.3952 19 11.5C19 12.6048 19.8952 13.5 21 13.5C22.1048 13.5 23 12.6048 23 11.5C23 10.3952 22.1048 9.5 21 9.5ZM10 10C9.17137 10 8.5 10.6714 8.5 11.5C8.5 12.3286 9.17137 13 10 13C10.8286 13 11.5 12.3286 11.5 11.5C11.5 10.6714 10.8286 10 10 10Z"
        fill="#402A32"
      />
      <defs>
        <radialGradient
          id="paint0_radial_730_31262"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(25.5 9) rotate(131.878) scale(38.9487)">
          <stop stopColor="#FFF478" />
          <stop offset="0.474827" stopColor="#FFB02E" />
          <stop offset="1" stopColor="#F70A8D" />
        </radialGradient>
        <radialGradient
          id="paint1_radial_730_31262"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(15.9989 32.9971) rotate(7.16272) scale(22.581 13.2713)">
          <stop offset="0.0560558" stopColor="#E66D4A" />
          <stop offset="1" stopColor="#E66D4A" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint2_radial_730_31262"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(16 12.5) rotate(101.31) scale(17.8466 22.8581)">
          <stop offset="0.787821" stopColor="#F59639" stopOpacity="0" />
          <stop offset="0.972509" stopColor="#FF7DCE" />
        </radialGradient>
        <radialGradient
          id="paint3_radial_730_31262"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(18 14) rotate(135) scale(41.0122)">
          <stop offset="0.314853" stopOpacity="0" />
          <stop offset="1" />
        </radialGradient>
        <radialGradient
          id="paint4_radial_730_31262"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(16 17) rotate(77.692) scale(28.1469)">
          <stop offset="0.507903" stopColor="#7D6133" stopOpacity="0" />
          <stop offset="1" stopColor="#715B32" />
        </radialGradient>
        <radialGradient
          id="paint5_radial_730_31262"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(15.9989 16.8882) rotate(12.7761) scale(12.2768 8.95137)">
          <stop stopColor="#F6A445" />
          <stop offset="0.900412" stopColor="#F5A345" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint6_radial_730_31262"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(17.5 15) rotate(43.9708) scale(59.0529)">
          <stop offset="0.185425" stopOpacity="0" />
          <stop offset="1" stopOpacity="0.4" />
        </radialGradient>
        <radialGradient
          id="paint7_radial_730_31262"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(16.9825 15.9989) rotate(95.0382) scale(11.2003)">
          <stop stopColor="#FFA852" />
          <stop offset="1" stopColor="#FFA852" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint8_radial_730_31262"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(9.78412 12.2814) rotate(45) scale(4.7532)">
          <stop offset="0.510638" stopColor="#D46A37" />
          <stop offset="0.666353" stopColor="#D46A37" stopOpacity="0.758583" />
          <stop offset="1" stopColor="#D46A37" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint9_radial_730_31262"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(20.8035 12.6679) rotate(110.158) scale(6.51237)">
          <stop offset="0.598958" stopColor="#BE673A" />
          <stop offset="0.869792" stopColor="#AD652B" stopOpacity="0.32" />
          <stop offset="1" stopColor="#AD652B" stopOpacity="0" />
        </radialGradient>
        <linearGradient
          id="paint10_linear_730_31262"
          x1="26.8318"
          y1="19.9703"
          x2="22.5272"
          y2="19.9703"
          gradientUnits="userSpaceOnUse">
          <stop offset="0.076054" stopColor="#DF7644" stopOpacity="0" />
          <stop offset="0.330587" stopColor="#DF7644" stopOpacity="0.37" />
          <stop offset="0.462692" stopColor="#DF7644" stopOpacity="0.45" />
          <stop offset="0.729695" stopColor="#DF7644" stopOpacity="0.0830233" />
          <stop offset="1" stopColor="#DF7644" stopOpacity="0" />
        </linearGradient>
        <radialGradient
          id="paint11_radial_730_31262"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(27.2534 26.0228) rotate(-150.593) scale(2.55088 1.24792)">
          <stop stopColor="#BA521A" />
          <stop offset="1" stopColor="#BA521A" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint12_radial_730_31262"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(25.7017 5.12679) rotate(100.823) scale(3.97093)">
          <stop offset="0.399124" stopColor="#FEEC71" />
          <stop offset="1" stopColor="#FEEC71" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint13_radial_730_31262"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(19.3349 5.49011) rotate(80.9455) scale(1.56928 2.31312)">
          <stop stopColor="#D46A37" stopOpacity="0.29" />
          <stop offset="0.391338" stopColor="#D46A37" stopOpacity="0.207163" />
          <stop offset="0.715045" stopColor="#D46A37" stopOpacity="0.108333" />
          <stop offset="1" stopColor="#D46A37" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint14_radial_730_31262"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(21.3646 5.05681) rotate(105.322) scale(1.72614 2.54433)">
          <stop stopColor="#D46A37" stopOpacity="0.28" />
          <stop offset="0.445859" stopColor="#D46A37" stopOpacity="0.163874" />
          <stop offset="0.610403" stopColor="#D46A37" stopOpacity="0.108333" />
          <stop offset="1" stopColor="#D46A37" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint15_radial_730_31262"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(23.0522 5.4445) rotate(110.924) scale(1.66025 2.44722)">
          <stop stopColor="#D46A37" stopOpacity="0.26" />
          <stop offset="0.401076" stopColor="#D46A37" stopOpacity="0.190403" />
          <stop offset="0.610403" stopColor="#D46A37" stopOpacity="0.108333" />
          <stop offset="1" stopColor="#D46A37" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint16_radial_730_31262"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(26.6194 25.6607) rotate(112.38) scale(1.0545 2.47149)">
          <stop stopColor="#B95A2D" stopOpacity="0.53" />
          <stop offset="0.505208" stopColor="#B95A2D" stopOpacity="0.15625" />
          <stop offset="0.880827" stopColor="#B95A2D" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint17_radial_730_31262"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(11.7647 7.55476) rotate(109.346) scale(1.84881 2.31733)">
          <stop stopColor="#AC552B" stopOpacity="0.28" />
          <stop offset="0.410771" stopColor="#AC552B" stopOpacity="0.15" />
          <stop offset="0.816081" stopColor="#AC552B" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint18_radial_730_31262"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(8.77418 7.34401) rotate(69.4687) scale(1.17281 2.28306)">
          <stop stopColor="#AC552B" stopOpacity="0.24" />
          <stop offset="0.501914" stopColor="#AC552B" stopOpacity="0.13" />
          <stop offset="1" stopColor="#AC552B" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint19_radial_730_31262"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(9.99775 7.15214) rotate(88.1524) scale(1.59551 3.10592)">
          <stop stopColor="#AC552B" stopOpacity="0.24" />
          <stop offset="0.723958" stopColor="#AC552B" stopOpacity="0.13" />
          <stop offset="1" stopColor="#AC552B" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint20_radial_730_31262"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(21.1707 5.37047) rotate(-90.9301) scale(6.55912 12.1858)">
          <stop offset="0.069602" stopColor="#2E1529" />
          <stop offset="0.29711" stopColor="#5F4357" />
          <stop offset="0.437882" stopColor="#4B2744" />
        </radialGradient>
        <radialGradient
          id="paint21_radial_730_31262"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(11.3068 8.06776) rotate(-82.5602) scale(7.7607 15.5933)">
          <stop offset="0.069602" stopColor="#2E1529" />
          <stop offset="0.29711" stopColor="#5F4357" />
          <stop offset="0.437882" stopColor="#4B2744" />
        </radialGradient>
        <radialGradient
          id="paint22_radial_730_31262"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(15.5106 22.1284) rotate(-79.2045) scale(15.0157 16.295)">
          <stop offset="0.069602" stopColor="#2E1529" />
          <stop offset="0.29711" stopColor="#5F4357" />
          <stop offset="0.437882" stopColor="#4B2744" />
        </radialGradient>
        <linearGradient
          id="paint23_linear_730_31262"
          x1="26.25"
          y1="19"
          x2="28.25"
          y2="19"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#3A1F31" />
          <stop offset="1" stopColor="#5A4A4F" />
        </linearGradient>
        <linearGradient
          id="paint24_linear_730_31262"
          x1="26.25"
          y1="26.2736"
          x2="27.7612"
          y2="18.9651"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#4F2652" />
          <stop offset="1" stopColor="#4F2652" stopOpacity="0" />
        </linearGradient>
        <radialGradient
          id="paint25_radial_730_31262"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(26.25 14.6733) rotate(13.3017) scale(1.22386 3.56903)">
          <stop stopColor="#2B1323" />
          <stop offset="1" stopColor="#2E1525" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint26_radial_730_31262"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(24.6885 10.5635) rotate(116.114) scale(7.80971 8.39468)">
          <stop stopColor="#55434D" />
          <stop offset="1" stopColor="#4B294C" />
        </radialGradient>
        <linearGradient
          id="paint27_linear_730_31262"
          x1="22.8135"
          y1="13.0948"
          x2="27.9542"
          y2="19.001"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#574150" stopOpacity="0" />
          <stop offset="1" stopColor="#715467" />
        </linearGradient>
        <radialGradient
          id="paint28_radial_730_31262"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(22.5323 7.84477) rotate(106.682) scale(9.68901 8.41753)">
          <stop offset="0.861199" stopColor="#4D2951" stopOpacity="0" />
          <stop offset="1" stopColor="#4E2754" />
        </radialGradient>
        <radialGradient
          id="paint29_radial_730_31262"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(21.2611 12.3185) rotate(-120.05) scale(4.64395)">
          <stop offset="0.749092" stopColor="#554A4B" />
          <stop offset="1" stopColor="#554A4B" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint30_radial_730_31262"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(26.9252 10.8788) rotate(169.796) scale(0.492047 1.09189)">
          <stop stopColor="#736367" />
          <stop offset="1" stopColor="#5C4C50" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint31_radial_730_31262"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(27.6275 11.6665) rotate(143.484) scale(1.31547)">
          <stop stopColor="#67595B" />
          <stop offset="1" stopColor="#5E5052" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint32_radial_730_31262"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(21.4266 11.7486) rotate(79.9175) scale(5.9189 6.12921)">
          <stop stopColor="#564050" />
          <stop offset="0.773594" stopColor="#564050" stopOpacity="0" />
          <stop offset="0.902414" stopColor="#5C4656" />
          <stop offset="1" stopColor="#564050" stopOpacity="0" />
        </radialGradient>
        <linearGradient
          id="paint33_linear_730_31262"
          x1="25.5989"
          y1="1.49695"
          x2="19.751"
          y2="17.126"
          gradientUnits="userSpaceOnUse">
          <stop offset="0.67927" stopColor="#4D304C" stopOpacity="0" />
          <stop offset="1" stopColor="#4D304C" />
        </linearGradient>
        <radialGradient
          id="paint34_radial_730_31262"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(27.4742 13.0891) rotate(130.778) scale(1.11731 0.49738)">
          <stop stopColor="#3B2435" />
          <stop offset="1" stopColor="#3B2435" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint35_radial_730_31262"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(27.0164 13.9799) rotate(115.88) scale(1.38891 0.618287)">
          <stop stopColor="#3B2435" />
          <stop offset="1" stopColor="#3B2435" stopOpacity="0" />
        </radialGradient>
      </defs>
    </svg>
  );
};

import { useTheme } from '@client/theme';
import { cn } from '@client/lib/utils';

import { Mrkdwn } from '@client/components/ui/custom/mrkdwn';

type Props = { text?: string; fields?: string[] };

export const SectionBlock = (props: Props) => {
  const { text, fields } = props;

  const { theme } = useTheme();

  return (
    <div>
      {text ? (
        <div className={cn('py-2', theme === 'light' ? 'text-[#1d1c1d]' : 'text-[#d1d2d3]')}>
          <Mrkdwn mrkdwn={text} />
        </div>
      ) : (
        ''
      )}

      {fields ? (
        <div className="grid grid-cols-2 gap-2">
          {fields.map((field, index) => (
            <div key={index} className={cn('py-2', theme === 'light' ? 'text-[#1d1c1d]' : 'text-[#d1d2d3]')}>
              <Mrkdwn mrkdwn={field} />
            </div>
          ))}
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

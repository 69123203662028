import { Emoji } from '@client/components/ui/custom/emoji';
import { LoadingBar } from '@client/components/shared/LoadingBar';
import { SelectOffice } from '@client/components/shared/SelectOffice';
import { TypographyMuted } from '@client/components/ui/custom/typography-muted';
import { Button } from '@client/components/ui/button';
import { StepComponentProps } from '@client/features/onboarding/types';
import { useCallback, useEffect, useState } from 'react';
import { useDirectoryContext } from '@client/context/directory';

export const OfficeSelection = (props: StepComponentProps) => {
  const { state, updateState, onNext } = props;
  const [hasClicked, setHasClicked] = useState(false);
  const { offices } = useDirectoryContext();

  const handleChange = useCallback(
    (id: string | undefined) => {
      updateState({ officeId: id });
      setHasClicked(true);
    },
    [updateState]
  );

  useEffect(() => {
    if (hasClicked) {
      onNext();
    }
  }, [hasClicked]);

  const officeCount = Object.keys(offices.data ?? {}).length;

  if (offices.loading) {
    return <LoadingBar />;
  }

  return (
    <div>
      {officeCount < 5 ? (
        <div className="my-6 flex flex-col gap-4">
          {Object.values(offices.data).map((office) => (
            <Button
              key={office.id}
              onClick={() => handleChange(office.id)}
              variant={'outline'}
              className="p-10 w-full justify-start overflow-hidden">
              <Emoji className="mr-4" xl>
                {office.emoji}
              </Emoji>
              <div className="text-left flex-1 min-w-0">
                <div className="truncate">{office.name}</div>
                <TypographyMuted className="truncate">{office.address}</TypographyMuted>
              </div>
            </Button>
          ))}
        </div>
      ) : (
        <SelectOffice value={state.officeId ?? ''} onChange={handleChange} />
      )}
    </div>
  );
};

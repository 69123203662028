/* eslint-disable sonarjs/no-small-switch */
import { CommonPropsForModal } from '@client/lib/modal';
import { ModalLayout, ModalLayoutProps } from '@client/components/ui/custom/modal-layout';
import { SetupNbhForm, SetupNbhFormValues } from '@client/features/onboarding/components/setup-nbh-form';
import { useCallback, useMemo, useState } from 'react';
import { NbhData } from '@client/features/onboarding/types';

type Props = CommonPropsForModal & {
  categories: string[];
  onSubmit: (data: NbhData) => void;
  existing?: NbhData;
};

export const SetupNbhModal = (props: Props) => {
  const { onDone, existing, categories, onSubmit, onClose, ...restForModal } = props;

  const [step, setStep] = useState<0 | 1>(0);
  const handleSubmit = useCallback(
    (value: SetupNbhFormValues) => {
      if (step === 0) {
        setStep(1);
      } else {
        onSubmit({
          name: value.name,
          capacity: value.capacity,
          category: value.newCategory ?? value.category,
          peopleIds: value.visibility === 'public' || !value.peopleIds?.length ? undefined : value.peopleIds!,
        });
      }
    },
    [step, setStep, onSubmit]
  );

  const handleClose = useCallback(() => {
    if (step === 1) {
      setStep(0);
    } else {
      onClose?.();
    }
  }, [step, setStep, onClose]);

  const data = useMemo<SetupNbhFormValues>(() => {
    return {
      name: existing?.name ?? '',
      visibility: existing?.peopleIds?.length ? 'private' : 'public',
      capacity: existing?.capacity,
      category: existing?.category,
      peopleIds: existing?.peopleIds,
    };
  }, [existing]);

  const commonModalProps: ModalLayoutProps = {
    title: existing ? 'Edit Neighbourhood' : 'Create Neighbourhood',
    description: 'Neighborhoods allow you to organise your office into bookable spaces',
    submitFormId: SetupNbhForm.id,
    submitText: step === 0 ? 'Next' : 'Submit',
    closeText: step === 0 ? 'Cancel' : 'Back',
    onClose: handleClose,
    ...restForModal,
  };

  return (
    <ModalLayout {...commonModalProps}>
      <SetupNbhForm step={step} categories={categories} onSubmit={handleSubmit} data={data} />
    </ModalLayout>
  );
};

import { MultiSelect, MultiSelectProps } from '@client/components/ui/custom/multi-select';
import { Person, useDirectoryContext } from '@client/context/directory';
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';

// --------- TYPES ---------

type MultiSelectPeopleFilter = {
  ids?: string[]; // restricts the list of people to the provided ids

  // ideas for future filters
  // userIds?: string[]; // restricts the list of users to the provided userIds
  // officeIds?: string[]; // restricts the list of users to the provided officeIds
  // onboarded?: boolean; // restricts the list to onboarded users only
};

type MultiSelectPeopleProps = Pick<
  MultiSelectProps<'people'>,
  'placeholder' | 'searchPlaceholder' | 'noResultsMessage' | 'className' | 'closeOnSelect' | 'disabled'
> & {
  value: string[];
  filter?: MultiSelectPeopleFilter;
  onChange: (values: string[]) => void;
};

const LIMIT = 8;

export const MultiSelectPeople = (props: MultiSelectPeopleProps) => {
  const {
    value,
    filter,
    onChange,
    placeholder = 'Select people...',
    searchPlaceholder = 'Search people...',
    noResultsMessage = 'No results found.',
    ...propsForPrimitive
  } = props;

  // ------- STATE --------

  const [searchTerm, setSearchTerm] = useState('');

  // ------- DATA FETCHING --------

  const {
    people: { data: peopleData, loading },
    actions,
  } = useDirectoryContext();

  // eager fetch people
  useEffect(() => {
    const ids = _.uniq([...value, ...(filter?.ids ?? [])]);
    if (!!ids.length) {
      actions.eagerLoadPeople(ids);
    }
  }, [value, filter?.ids]);

  // ------- PEOPLE --------

  const people = useMemo(() => {
    return Object.values(peopleData);
  }, [peopleData]);

  const selectedPeople = useMemo(() => {
    return people.filter((person) => value.includes(person.id));
  }, [value, people]);

  const filteredPeople = useMemo(() => {
    return (
      people
        // filter on filter
        .filter((person) => {
          if (!filter?.ids) return true;
          return filter.ids.includes(person.id);
        })
        // filter on search term
        .filter((person) => applySearch(person, searchTerm))
    );
  }, [people, filter, searchTerm]);

  // ------- OPTIONS --------

  const options = filteredPeople.map(personToOption).slice(0, LIMIT);
  const selected = selectedPeople.map(personToOption);

  // ------- RENDER --------

  return (
    <MultiSelect
      {...propsForPrimitive}
      variant="people"
      options={options}
      selected={selected}
      searchTerm={searchTerm}
      onSearchChange={setSearchTerm}
      onChange={onChange}
      noResultsMessage={noResultsMessage}
      placeholder={placeholder}
      searchPlaceholder={searchPlaceholder}
      loading={loading}
    />
  );
};

// ---------- HELPERS ----------------
const personToOption = (person: Person): MultiSelectProps<'people'>['options'][number] => ({
  value: person.id,
  name: person.name,
});

const applySearch = (person: Person, searchTerm?: string) => {
  if (!searchTerm) return true;
  const pattern = new RegExp(searchTerm, 'i'); // 'i' for case-insensitive
  return pattern.test(person.name);
};

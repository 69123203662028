import { DialogId } from '@client/features/ms-dialog/const';
import { createFileRoute } from '@tanstack/react-router';
import { Navigate } from '@tanstack/react-router';
import SuperJSON from 'superjson';
import { z } from 'zod';

export const Route = createFileRoute('/teams-dialog/$id')({
  validateSearch: z.object({
    data: z.any().optional(),
  }),
  component: TeamsDialog,
});

function TeamsDialog() {
  const searchParams = Route.useSearch();
  const { id } = Route.useParams();
  const data = searchParams.data && SuperJSON.deserialize(searchParams.data);

  switch (id) {
    case DialogId.FavouriteCoworkers:
      return <Navigate to="/dialog/favourites" />;
    case DialogId.OfficelyInvite:
      return <Navigate to="/dialog/officely-invite" />;
    case DialogId.Feedback:
      return <Navigate to="/dialog/feedback" />;
    case DialogId.Billing:
      return <Navigate to="/dialog/billing" />;
    case DialogId.NotificationPrefs:
      return <Navigate to="/dialog/notifications" />;
    case DialogId.OfficeBooking:
      return <Navigate to="/dialog/office-booking" search={{ ...(data as any) }} />;
    default:
      throw new Error('Not implemented');
  }
}

import { TypographyH4 } from '@client/components/ui/custom/typography-h4';
import { TypographyP } from '@client/components/ui/custom/typography-p';
import { Button } from '@client/components/ui/button';
import { ButtonGroup } from '@client/components/ui/custom/button-group';
import { useCallback, useMemo } from 'react';
import { UserList } from '@client/features/home/components/users-list';
import { NbhHeading } from '@client/features/home/components/nbh-heading';
import { getFiendlyDate, neighbourhoodSort } from '@client/features/home/helpers';
import { DayAction, DayActionType, OfficeDayData, OnDayAction, UserListItem } from '@client/features/home/types';
import { useScreenSize } from '@client/lib/useScreenSize';
import { cn } from '@client/lib/utils';
import { emojis } from '@client/lib/emojis';
import { Separator } from '@client/components/ui/separator';
import { DayActionsSelect } from '@client/features/home/components/day-actions-select';
import { DayNotices } from '@client/features/home/components/day-notices';

const DayOfficeOnlyNames = (props: {
  date: string;
  bookings: NonNullable<OfficeDayData['bookings']>;
  bookedNbhs: NonNullable<OfficeDayData['bookedNbhs']>;
  bookedExtras: NonNullable<OfficeDayData['bookedExtras']>;
  onEditNote: () => void;
  onSelfClick?: (user: UserListItem) => void;
  onSeeOverflow: (nbhId: string | undefined) => void;
}) => {
  const { date, bookings, bookedExtras, bookedNbhs, onEditNote, onSelfClick, onSeeOverflow } = props;
  const { isMobile } = useScreenSize();

  const sortedNbhs = useMemo(() => bookedNbhs.sort(neighbourhoodSort), [bookedNbhs]);
  const extraMap = useMemo(() => Object.fromEntries(bookedExtras.map((extra) => [extra.id, extra])), [bookedExtras]);

  const rows = useMemo(
    () =>
      sortedNbhs.map((nbh) => ({
        ...nbh,
        bookings: bookings
          .filter((booking) => booking.nbhId === nbh.id)
          .map((booking) => ({
            ...booking,
            extraEmojis: booking.extraIds?.map((extraId) => extraMap[extraId]?.emoji).filter(Boolean),
          })),
      })),
    [sortedNbhs, bookings, extraMap]
  );

  return (
    <div
      className={cn('grid grid-cols-2 gap-4', {
        'grid-cols-1': isMobile || rows.length === 1,
      })}>
      {rows.map((row) => {
        return (
          <div key={row.id} className="col-span-1">
            <NbhHeading {...row} />
            <UserList
              list={row.bookings}
              date={date}
              minNumSingleItemRows={0}
              maxItems={6}
              onEditNote={onEditNote}
              onSelfClick={onSelfClick}
              onSeeOverflow={() => onSeeOverflow(row.id)}
            />
          </div>
        );
      })}
    </div>
  );
};

export const DayOfficeOnly = (props: {
  data: OfficeDayData;
  emphasied?: boolean;
  canModifyBooking: boolean;
  isOfficeAdmin: boolean;
  onDayAction: OnDayAction;
}) => {
  const { data, emphasied, canModifyBooking, isOfficeAdmin, onDayAction } = props;
  const {
    date,
    officeId,
    isToday,
    bookedNbhs = [],
    bookings = [],
    totalGoing = 0,
    announcements = [],
    bookedExtras = [],
    waitlist = [],
    isClosed = false,
    closureReason,
    spacesLeft,
    canBook,
    canModify,
    altOfficeId,
  } = data;

  const booking = useMemo(() => bookings.find((x) => x.isSelf), [bookings]);
  const userAlreadyBooked = !!booking;
  const btnSize = 'default';
  const rounded = false;
  const ctaBtnClass = 'min-w-16' + (rounded ? ' rounded-full' : '');

  // const iconClassNames = 'h-4 w-4';

  const handleEditNote = useCallback(() => {
    onDayAction({
      type: DayActionType.ChangeNote,
      date,
    });
  }, [date, onDayAction]);

  const handleSelfClick = useMemo(() => {
    if (booking?.canModify) {
      return (user: UserListItem) => {
        if (user.isSelf) {
          onDayAction({
            type: DayActionType.ModifyBooking,
            officeId,
            date,
          });
        }
      };
    }
  }, [booking, date, onDayAction, officeId]);

  const handleSeeOverflow = useCallback(
    (nbhId: string | undefined) => {
      onDayAction({
        type: DayActionType.SeeDetails,
        date,
        initialFilters: [officeId],
      });
    },
    [date, onDayAction]
  );

  const handleJoin = useCallback(() => {
    onDayAction({
      type: DayActionType.JoinDay,
      date,
      officeId,
    });
  }, [onDayAction, date, officeId]);

  const handleCancel = useCallback(() => {
    onDayAction({
      type: DayActionType.CancelDay,
      date,
    });
  }, [onDayAction, date]);

  const actions = useMemo<
    Array<
      DayAction & {
        label: string;
        condition: boolean;
      }
    >
  >(() => {
    return [
      {
        type: DayActionType.ModifyBooking,
        date,
        label: `Modify`,
        officeId,
        condition: !!booking && canModifyBooking,
      },
      {
        type: DayActionType.InviteCoworkers,
        officeId,
        date,
        nbhId: booking?.nbhId,
        label: `${emojis.heavy_plus_sign}   Invite Coworkers`,
        condition: !!booking && !isToday,
      },
    ];
  }, [booking, canModifyBooking, isToday, officeId]);

  const handleActionClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      const value = e.currentTarget.value;
      const action = actions.find((action) => action.type === value);
      if (action) {
        onDayAction(action);
      }
    },
    [actions, onDayAction]
  );

  const handleAnnouncementClick = useCallback(
    (id: string) => {
      onDayAction({
        type: DayActionType.ManageAnnouncements,
        date,
        officeId,
        id,
      });
    },
    [date, onDayAction, officeId]
  );

  const renderedActions = useMemo(() => {
    return actions
      .filter(({ condition }) => condition)
      .map((action) => (
        <Button
          key={`${date}-${action.type}`}
          value={action.type}
          size={btnSize}
          variant={'outline'}
          className={cn(ctaBtnClass, {
            'rounded-full': rounded,
          })}
          onClick={handleActionClick}>
          {action.label}
        </Button>
      ));
  }, [actions, date, handleActionClick]);

  const mainBtn = useMemo(() => {
    if (userAlreadyBooked) {
      return (
        <Button onClick={handleCancel} variant="destructive" size={btnSize} className={ctaBtnClass}>
          Cancel
        </Button>
      );
    } else if (canBook) {
      return (
        <Button onClick={handleJoin} variant="success" size={btnSize} className={ctaBtnClass}>
          Join
        </Button>
      );
    }
  }, [handleJoin, handleCancel, userAlreadyBooked, canBook]);

  return (
    <div className="first:mt-0 mt-10">
      <div className="flex justify-between items-center">
        {emphasied ? (
          <TypographyH4 className="">{getFiendlyDate(date)}</TypographyH4>
        ) : (
          // <TypographyH4>{getFiendlyDate(date)}</TypographyH4>
          <TypographyP className="font-bold ">{getFiendlyDate(date)}</TypographyP>
        )}
        {!!totalGoing && (
          <div className="mt-1 italic text-xs text-muted-foreground ml-auto mr-2">{`${totalGoing} going`}</div>
        )}
        {/* <Button variant={'ghost'} size={'icon'} className="-mb-2">
          <DotsHorizontalIcon />
        </Button> */}
        {/* {mainBtn} */}
      </div>
      <Separator className="mt-2" />

      <DayNotices
        announcements={announcements}
        className="mt-4"
        onAnnouncementClick={handleAnnouncementClick}
        isOfficeAdmin={isOfficeAdmin}
        altOfficeId={altOfficeId}
      />

      {/* <ButtonGroup className="mt-4">{renderedActions}</ButtonGroup> */}

      {/* <div className="flex justify-between items-center">
        <TypographyMuted>
          <i>No takers</i>
        </TypographyMuted>
      </div> */}
      {!!totalGoing && (
        <div className="mt-2">
          <DayOfficeOnlyNames
            date={date}
            bookings={bookings}
            bookedNbhs={bookedNbhs}
            bookedExtras={bookedExtras}
            onEditNote={handleEditNote}
            // onSelfClick={handleSelfClick}
            onSeeOverflow={handleSeeOverflow}
          />
        </div>
      )}

      <ButtonGroup className="mt-4">
        {mainBtn}
        {...renderedActions}
        {isOfficeAdmin && (
          <DayActionsSelect
            date={date}
            officeId={officeId}
            isAdmin={true}
            isBooked={userAlreadyBooked}
            announcementCount={announcements.length}
            exclude={[DayActionType.ModifyBooking]}
            onDayAction={onDayAction}
          />
        )}
        {/* <Button variant={'outline'} size={'icon'}>
          <DotsHorizontalIcon />
        </Button> */}
      </ButtonGroup>
    </div>
  );
};
import { z } from 'zod';

import {
  UserWorkStatusType,
  BookingMethod,
  TimeSlot,
  BillingPlan,
  InviteType,
  UserRelation,
  UserNotificationType,
  RecurringBookingSchedule,
  OfficeBroadcastType,
  OfficeBroadcastDisplayMode,
  CalendarProvider,
  Weekday,
  HRIS_STATUSES,
  OnboardingFlow,
  OfficeNotificationType,
  BillingDowngradeReason,
  OnboardingStep,
  MANAGED_NOTIFICATION_TYPES,
  Product,
  UserDayCheckpoint,
  OfficeDayCheckPoint,
} from './const';

// --------- CORE -----------

export const productSchema = z.nativeEnum(Product);

export const weekdaySchema = z.nativeEnum(Weekday);

export const yyyyMmDdSchema = z.string().regex(/^\d{4}-\d{2}-\d{2}$/);

// --------- USER WORK STATUS ----------

export const userWorkStatusTypeSchema = z.nativeEnum(UserWorkStatusType);

export const timeSlotSchema = z.nativeEnum(TimeSlot);

const userWorkStatusCommonFields = z.object({
  userId: z.string(),
  type: userWorkStatusTypeSchema,
  note: z.string().optional(),
  fromDate: z.string().optional(),
  toDate: z.string().optional(),
  isLocked: z.boolean().optional(),
});

export const userWorkStatusNonOfficeSchema = userWorkStatusCommonFields.extend({
  type: z.enum([
    // everything but OFFICE
    UserWorkStatusType.REMOTE,
    UserWorkStatusType.OFF_SICK,
    UserWorkStatusType.ANNUAL_LEAVE,
    UserWorkStatusType.NO_STATUS,
    UserWorkStatusType.NON_WORKING_DAY,
    UserWorkStatusType.WORK_TRAVEL,
    UserWorkStatusType.CLIENT_OFFICE,
    UserWorkStatusType.OTHER_ABSENCE,
  ]),
});

export const userWorkStatusOfficeSchema = z.intersection(
  userWorkStatusCommonFields,
  z.object({
    type: z.intersection(userWorkStatusTypeSchema, z.literal(UserWorkStatusType.OFFICE)),
    office: z.object({
      id: z.string(),
      name: z.string(),
      emoji: z.string().optional(),
      timezone: z.string(),
      waitlistPosition: z.number().optional(),
      neighbourhood: z
        .object({
          id: z.string(),
          name: z.string(),
          category: z.string().optional(),
        })
        .optional(),
      extras: z.array(
        z.object({
          id: z.string(),
          name: z.string(),
          emoji: z.string(),
        })
      ),
      guests: z.array(z.string()),
    }),
  })
);

export const userWorkStatusSchema = z.union([userWorkStatusNonOfficeSchema, userWorkStatusOfficeSchema]);

// ---------- USER WORK STATUS MINIMAL ----------

export const userWorkStatusMinimalOfficeSchema = userWorkStatusCommonFields.extend({
  type: z.enum([UserWorkStatusType.OFFICE]),
  officeId: z.string(),
  nbhId: z.string().optional(),
  extraIds: z.array(z.string()).optional(),
  guestEmails: z.array(z.string()).optional(),
  waitlistPosition: z.number().optional(),
});

export const userWorkStatusMinimalSchema = z.discriminatedUnion('type', [
  userWorkStatusNonOfficeSchema,
  userWorkStatusMinimalOfficeSchema,
]);

// ---------- BOOKING METHOD ----------

export const bookingMethodSchema = z.nativeEnum(BookingMethod);

// ------ AWAY DATES ---------

export const awayDatesTypeSchema = z.intersection(
  userWorkStatusTypeSchema,
  z.enum([
    UserWorkStatusType.ANNUAL_LEAVE,
    UserWorkStatusType.WORK_TRAVEL,
    UserWorkStatusType.OFF_SICK,
    UserWorkStatusType.OTHER_ABSENCE,
  ])
);

const awayDatesCommonFields = z.object({
  id: z.string(),
  userId: z.string(),
  accountId: z.string(),
  type: awayDatesTypeSchema,
  fromDate: z.string(),
  toDate: z.string(),
  note: z.string().nullable(),
  isLocked: z.boolean(),
});

export const awayDatesSchema = z.intersection(
  awayDatesCommonFields,
  z.object({
    type: z.intersection(
      awayDatesTypeSchema,
      z.enum([
        UserWorkStatusType.ANNUAL_LEAVE,
        UserWorkStatusType.OFF_SICK,
        UserWorkStatusType.OTHER_ABSENCE,
        UserWorkStatusType.WORK_TRAVEL,
      ])
    ),
  })
);

/**
 * --------- ROUTINES ----------
 */

export const routineFailedBookingSchema = z.object({
  localDateStr: z.string(),
  officeDayClosed: z.boolean().optional(),
  fullyBookedNbh: z
    .object({
      officeName: z.string(),
      preferredNbhNames: z.array(z.string()),
      bookedNbhName: z.string().nullable(),
      waitlistPosition: z.number().nullable(),
    })
    .strict()
    .optional(),
  fullyBookedExtras: z.array(z.string()).optional(),
});

// --------- GO TO MARKET MESSAGE --------

const gtmFilterEnum = z.enum(['admin']);

export const gtmModeSchema = z.enum(['self', 'officely', 'live']);
export const gtmFiltersSchema = z.array(gtmFilterEnum);
export const gtmMetadataSchema = z.object({
  campaign: z.string(),
  triggeredAt: z.date(),
  triggeredBy: z.string(),
});

export const gtmOptionsSchema = z.object({
  mode: gtmModeSchema.default('self'),
  filters: gtmFiltersSchema.optional(),
  campaign: z.string(),
  previewText: z.string().optional(),
  blocks: z.array(z.any()).optional(),
  overrideProps: z.record(z.any()).optional(),
});

/**
 * ---------- BILLING -----------
 */

export const billingPlanSchema = z.nativeEnum(BillingPlan);

export const billingDowngradeReasonSchema = z.nativeEnum(BillingDowngradeReason);

//  --------- ACCOUNT ------------

export const accountSchema = z.object({
  id: z.string(),
  active: z.boolean(),
  slackWorkspaceId: z.string().optional(),
  msTenantId: z.string().optional(),
  name: z.string(),
  avatar: z.string().optional(),
  products: z.array(z.nativeEnum(Product)),
  managers: z.array(z.string()),
  createdAt: z.date(),
  disabledStatuses: z.array(userWorkStatusTypeSchema),
  usingHris: z.boolean(),
  hrisAbsenceTypeMapping: z.record(
    z.object({
      absenceName: z.string(),
      status: z.enum(HRIS_STATUSES),
    })
  ),
  employeeCount: z.number(),
  installer: z
    .object({
      id: z.string(),
      name: z.string(),
      email: z.string(),
    })
    .optional(),
  billing: z.object({
    plan: billingPlanSchema,
    customerId: z.string().nullable(),
    subscriptionId: z.string().nullable(),
    subscriptionStatus: z.string().nullable(),
    customerUserId: z.string().nullable(),
    licencedUsers: z.array(z.string()),
  }),
});

export const guestSchema = z.object({
  accountId: z.string(),
  email: z.string(),
  name: z.string(),
});

// --------- ROUTINE ----------

const routineCommonFields = z.object({
  predicted: z.boolean(),
  status: z.intersection(
    userWorkStatusTypeSchema,
    z.enum([UserWorkStatusType.OFFICE, UserWorkStatusType.REMOTE, UserWorkStatusType.NON_WORKING_DAY])
  ),
  note: z.string().nullable(),
});

export const officeRoutineSchema = routineCommonFields.extend({
  status: z.intersection(userWorkStatusTypeSchema, z.literal(UserWorkStatusType.OFFICE)),
  officeId: z.string(),
  neighbourhoodIds: z.array(z.string()),
  extraIds: z.array(z.string()),
});

export const nonOfficeRoutineSchema = routineCommonFields.extend({
  status: z.intersection(
    userWorkStatusTypeSchema,
    z.enum([UserWorkStatusType.REMOTE, UserWorkStatusType.NON_WORKING_DAY])
  ),
});

export const routineSchema = z.union([officeRoutineSchema, nonOfficeRoutineSchema]);

// --------- USER ------------

export const userRelationSchema = z.nativeEnum(UserRelation);

export const userNotificationPreferenceSchema = z.object({
  off: z.boolean().optional(),
  unsubscribedRelations: z.array(userRelationSchema).optional(),
  whitelist: z.array(z.string()).optional(),
  blacklist: z.array(z.string()).optional(),
  muteUntil: z.date().optional(),
});

export const userSchema = z.object({
  id: z.string(),
  accountId: z.string(),
  hrisId: z.string().nullable(),
  slackUserId: z.string().optional(),
  slackWorkspaceId: z.string().optional(),
  msTenantId: z.string().optional(),
  msUserId: z.string().optional(),

  active: z.boolean(),
  archived: z.boolean(),
  createdAt: z.date(),
  currentTimezone: z.string(),
  onboardedAt: z.date().nullable(),
  firstInvitedAt: z.date().nullable(),
  officeIds: z.array(z.string()),
  parkingLocationIds: z.array(z.string()),
  lastBookedNbhIds: z.array(z.string()),
  lastBookedZoneIds: z.array(z.string()),

  profile: z.object({
    email: z.string(),
    name: z.string(),
    firstName: z.string().nullable(),
    lastName: z.string().nullable(),
    jobTitle: z.string().nullable(),
    avatarSmall: z.string().nullable(),
    avatarMedium: z.string().nullable(),
    avatarLarge: z.string().nullable(),
  }),

  managerId: z.string().nullable(),
  directReportIds: z.array(z.string()),
  favouriteCoworkerIds: z.array(z.string()),

  routines: z.record(weekdaySchema, routineSchema),

  lazyBookings: z.object({
    enabled: z.boolean(),
    neighbourhoodIds: z.array(z.string()),
  }),

  slackStatus: z.object({
    enabled: z.boolean(),
  }),

  notifications: z.record(z.string(), userNotificationPreferenceSchema),

  parkly: z
    .object({
      onboardedAt: z.date().nullable().optional(),
      lazyBookings: z
        .object({
          enabled: z.boolean(),
          zoneIds: z.array(z.string()),
        })
        .optional(),
    })
    .optional(),

  metadata: z.record(z.string(), z.any()),
});

export const userDayCheckpointSchema = z.nativeEnum(UserDayCheckpoint);

// --------- TEAM -----------

export const recurringBookingScheduleSchema = z.nativeEnum(RecurringBookingSchedule);

export const teamSchema = z.object({
  id: z.string(),
  accountId: z.string(),
  hrisId: z.string().nullable(),
  name: z.string(),
  officeId: z.string().nullable(),
  managerIds: z.array(z.string()),
  createdAt: z.date(),
  neighbourhoodIds: z.array(z.string()),
  memberIds: z.array(z.string()),
  recurringBookings: z
    .object({
      schedule: recurringBookingScheduleSchema,
      weekdays: z.array(z.number()),
      upcomingDates: z.array(z.string()),
    })
    .optional(),
});

// ---------- OFFICE ------------

export const officeExtraSchema = z.object({
  id: z.string(),
  enabled: z.boolean(),
  name: z.string(),
  emoji: z.string(),
  capacity: z.number().nullable(),
  weekdays: z.array(z.number()).nullable(),
});

export const officeNeighbourhoodSchema = z.object({
  id: z.string(),
  name: z.string(),
  category: z.string().nullable(),
  capacity: z.number().nullable(),
  usersAllowed: z.array(z.string()).nullable(),
});

export const officeBroadcastConfigSchema = z.object({
  enabled: z.boolean(),
  time: z.string(),
  type: z.nativeEnum(OfficeBroadcastType),
  display: z.nativeEnum(OfficeBroadcastDisplayMode),
  slackChannelId: z.string().nullable(),
  teamsChannelId: z.string().nullable(),
});

export const officeFindARoomConfigSchema = z.object({
  enabled: z.boolean(),
  linkedBuildingId: z.string().nullable(),
});

export const officeHealthSurveyConfigSchema = z.object({
  enabled: z.boolean(),
});

export const officeSchema = z.object({
  id: z.string(),
  accountId: z.string(),
  name: z.string(),
  emoji: z.string(),
  timezone: z.string(),
  bookingWindow: z.number(),
  weekdays: z.array(z.number()),
  allowGuests: z.boolean(),
  address: z.string(),
  country: z.string(),
  createdAt: z.date(),
  managerIds: z.array(z.string()),
  imageUrl: z.string().nullable(),
  routineDisallowed: z.boolean(),
  neighbourhoods: z.array(officeNeighbourhoodSchema),
  extras: z.array(officeExtraSchema),
  broadcast: officeBroadcastConfigSchema,
  findARoom: officeFindARoomConfigSchema,
  floorPlan: z.object({
    url: z.string().nullable(),
    isImage: z.boolean(),
  }),
  officeChat: z.object({
    slackChannelId: z.string().nullable(),
    ownerId: z.string().nullable(),
    autoStart: z.boolean(),
    autoStartHour: z.number().optional(),
    autoEndHour: z.number().optional(),
  }),
  checkIns: z.object({
    enabled: z.boolean(),
    customText: z.string().optional(),
    sendHour: z.number().optional(),
  }),
});

export const officeDayCheckpointSchema = z.nativeEnum(OfficeDayCheckPoint);

// ---------- OFFICE CLOSURES ----------

export const officeClosureSchema = z.object({
  id: z.string(),
  officeId: z.string(),
  fromDate: z.string(),
  toDate: z.string(),
  reason: z.string().nullable(),
  weekdays: z.array(z.number()).nullable(),
});

// ---------- OFFICE ANNOUNCEMENTS ----------

export const officeAnnouncementSchema = z.object({
  id: z.string(),
  officeIds: z.array(z.string()),
  text: z.string(),
  category: z.number(),
  createdAt: z.date(),
  createdBy: z.string(),
  channelIds: z.array(z.string()),
  fromDate: z.string(),
  toDate: z.string(),
  weekdays: z.array(z.number()).nullable(),
  excludedDates: z.array(z.string()).nullable(),
});

// ---------- OFFICE DAY ------------

export const officeDayBookingSchema = z.object({
  userId: z.string(),
  neighbourhoodId: z.string().nullable(),
  extraIds: z.array(z.string()),
  bookedByRoutine: z.boolean(),
  checkedIn: z.boolean().optional(),
  note: z.string().optional(),
});

export const officeDayGuestBookingSchema = z.object({
  email: z.string(),
  inviterIds: z.array(z.string()),
});

export const officeDaySchema = z.object({
  date: z.string(),
  officeId: z.string(),
  isClosed: z.boolean(),
  closureReason: z.string().nullable(),
  spacesLeft: z.number().nullable(),
  totalGoing: z.number(),
  officeChatChannelId: z.string().nullable(),
  waitlist: z.array(z.string()),
  announcements: z.array(
    officeAnnouncementSchema.pick({
      id: true,
      text: true,
      category: true,
    })
  ),
  floorPlanUrl: z.string().nullable(),
  guestBookings: z.array(officeDayGuestBookingSchema),
  bookings: z.array(officeDayBookingSchema),
});

// ---------- INVITES ------------

export const inviteTypeSchema = z.nativeEnum(InviteType);

const baseInvite = z.object({
  id: z.string(),
  type: inviteTypeSchema,
  userId: z.string(),
  accountId: z.string(),
  inviter: userSchema.pick({
    id: true,
    slackUserId: true,
    msUserId: true,
    profile: true,
  }),
  onboarded: z.boolean(),
  sentAt: z.date(),
  acceptedAt: z.date().optional(),
});

export const inviteOfficelySchema = baseInvite.extend({
  type: z.literal(InviteType.Officely),
  data: z.object({
    currentUserCount: z.number(),
    currentUserPreviewList: z
      .array(
        z.object({
          avatar: z.string().optional(),
          name: z.string(),
        })
      )
      .optional(),
  }),
});

export const inviteOfficeManagerSchema = baseInvite.extend({
  type: z.literal(InviteType.OfficeManager),
  data: z.object({
    officeId: z.string(),
    officeEmoji: z.string(),
    officeName: z.string(),
  }),
});

export const inviteOfficeDaySchema = baseInvite.extend({
  type: z.literal(InviteType.OfficeDay),
  data: z.object({
    date: z.string(),
    officeId: z.string(),
    officeEmoji: z.string(),
    officeName: z.string(),
    neighbourhoodId: z.string().optional(),
  }),
});

export const inviteAccountManagerSchema = baseInvite.extend({
  type: z.literal(InviteType.AccountManager),
  data: z.object({}),
});

export const inviteTeamSchema = baseInvite.extend({
  type: z.literal(InviteType.Team),
  data: z.object({
    teamId: z.string(),
    teamName: z.string(),
    memberCount: z.number(),
    isManager: z.boolean(),
    officeId: z.string().optional(),
  }),
});

export const inviteFavouriteCoworkerSchema = baseInvite.extend({
  type: z.literal(InviteType.FavouriteCoworker),
  data: z.object({}),
});

export const inviteSchema = z.discriminatedUnion('type', [
  inviteOfficelySchema,
  inviteOfficeManagerSchema,
  inviteOfficeDaySchema,
  inviteAccountManagerSchema,
  inviteTeamSchema,
  inviteFavouriteCoworkerSchema,
]);

// ------------- CALENDAR -------------

export const calendarProviderSchema = z.nativeEnum(CalendarProvider);

// ------------- ONBOARDING -------------

export const onboardingFlowSchema = z.nativeEnum(OnboardingFlow);

export const onboardingStepSchema = z.nativeEnum(OnboardingStep);

// ---------- USER NOTIFICATIONS -----------

export const userNotificationTypeSchema = z.nativeEnum(UserNotificationType);

const userNotificationBaseSchema = z.object({
  id: z.string(),
  accountId: z.string(),
  userId: z.string(),
  timestamp: z.date(),
  data: z.record(z.string(), z.any()).optional(),
  metadata: z.record(z.string(), z.any()).optional(),
});

export const userNotificationWelcomeSchema = userNotificationBaseSchema.extend({
  type: z.literal(UserNotificationType.Welcome),
  data: z.object({
    isInstaller: z.boolean().optional(),
  }),
});

export const userNotificationBillingPlanChangedSchema = userNotificationBaseSchema.extend({
  type: z.literal(UserNotificationType.BillingPlanChanged),
  data: z.object({
    oldPlan: billingPlanSchema,
    newPlan: billingPlanSchema,
  }),
});

export const userNotificationInviteSchema = userNotificationBaseSchema.extend({
  type: z.literal(UserNotificationType.Invite),
  data: inviteSchema,
});

export const userNotificationInviteAcceptedSchema = userNotificationBaseSchema.extend({
  type: z.literal(UserNotificationType.InviteAccepted),
  data: z.object({
    invite: inviteSchema,
    invitee: userSchema.pick({
      id: true,
      slackUserId: true,
      msUserId: true,
      profile: true,
    }),
  }),
});

export const userNotificationCalendarConnectedSchema = userNotificationBaseSchema.extend({
  type: z.literal(UserNotificationType.CalendarConnected),
  data: z.object({ provider: calendarProviderSchema }),
});

export const userNotificationCalendarDisconnectedSchema = userNotificationBaseSchema.extend({
  type: z.literal(UserNotificationType.CalendarDisconnected),
  data: z.object({ provider: calendarProviderSchema, authUrl: z.string() }),
});

export const userNotificationOnboardingCompleteSchema = userNotificationBaseSchema.extend({
  type: z.literal(UserNotificationType.OnboardingComplete),
});

export const userNotificationIntegrationSuggestionSchema = userNotificationBaseSchema.extend({
  type: z.literal(UserNotificationType.IntegrationSuggestion),
  data: z.object({
    integration: z.enum(['SLACK', 'CALENDAR']),
    localDate: z.string().optional(),
    userWorkStatusType: userWorkStatusTypeSchema.optional(),
  }),
});

export const userNotificationOfficelyHelpSchema = userNotificationBaseSchema.extend({
  type: z.literal(UserNotificationType.OfficelyHelp),
});

export const userNotificationYouveBeenFavouritedSchema = userNotificationBaseSchema.extend({
  type: z.literal(UserNotificationType.YouveBeenFavourited),
  data: z.object({ count: z.number(), alreadySetupFavourites: z.boolean(), variant: z.enum(['1', '2', '3']) }),
});

export const userNotificationManagerChangedYourStatusSchema = userNotificationBaseSchema.extend({
  type: z.literal(UserNotificationType.ManagerChangedYourStatus),
  data: z.object({
    manager: userSchema.pick({
      id: true,
      slackUserId: true,
      msUserId: true,
      profile: true,
    }),
    date: z.string(),
    oldStatus: userWorkStatusSchema,
    newStatus: userWorkStatusSchema,
  }),
});

export const userNotificationWaitlistSuccessSchema = userNotificationBaseSchema.extend({
  type: z.literal(UserNotificationType.WaitlistSuccess),
  data: z.object({ date: z.string(), userWorkStatus: userWorkStatusOfficeSchema }),
});

export const userNotificationCancellationAlertSchema = userNotificationBaseSchema.extend({
  type: z.literal(UserNotificationType.CancellationAlert),
  data: z.object({
    date: z.string(),
    office: officeSchema.pick({
      id: true,
      name: true,
      emoji: true,
    }),
    subjects: z.array(
      userSchema.pick({
        id: true,
        msUserId: true,
        slackUserId: true,
        profile: true,
      })
    ),
    reasons: z.record(z.string(), z.array(z.union([userRelationSchema, z.enum(['whitelisted'])]))),
  }),
});

export const userNotificationOfficeOpportunityAlertSchema = userNotificationBaseSchema.extend({
  type: z.literal(UserNotificationType.OfficeOpportunityAlert),
  data: z.object({
    date: z.string(),
    office: officeSchema.pick({
      id: true,
      name: true,
      emoji: true,
    }),
    subjects: z.array(
      userSchema.pick({
        id: true,
        msUserId: true,
        slackUserId: true,
        profile: true,
      })
    ),
    reasons: z.record(z.string(), z.array(z.union([userRelationSchema, z.enum(['whitelisted'])]))),
    neighbourhoodId: z.string().optional(),
    ignored: z.boolean(),
    accepted: z.boolean(),
  }),
});

export const userNotificationOfficeCompanionAlertSchema = userNotificationBaseSchema.extend({
  type: z.literal(UserNotificationType.OfficeCompanionAlert),
  data: z.object({
    date: z.string(),
    office: officeSchema.pick({
      id: true,
      name: true,
      emoji: true,
    }),
    subjects: z.array(
      userSchema.pick({
        id: true,
        msUserId: true,
        slackUserId: true,
        profile: true,
      })
    ),
    reasons: z.record(z.string(), z.array(z.union([userRelationSchema, z.enum(['whitelisted'])]))),
  }),
});

export const userNotificationWeeklyReminderSchema = userNotificationBaseSchema.extend({
  type: z.literal(UserNotificationType.WeeklyReminder),
  data: z.object({
    office: officeSchema
      .pick({
        id: true,
        name: true,
        emoji: true,
        timezone: true,
      })
      .partial({
        timezone: true, // TODO: make it a required field after next release
      }),
    bookableStatuses: z.array(userWorkStatusTypeSchema),
    fromDate: z.string(),
    toDate: z.string(),
    days: z.array(
      z.object({
        date: z.string(),
        userWorkStatus: userWorkStatusSchema,
        total: z.number(),
        spacesLeft: z.number().nullable(),
        preview: z.array(
          userSchema.pick({
            id: true,
            slackUserId: true,
            msUserId: true,
            profile: true,
          })
        ),
      })
    ),
  }),
});

export const userNotificationOfficeCheckInSchema = userNotificationBaseSchema.extend({
  type: z.literal(UserNotificationType.OfficeCheckIn),
  data: z.object({
    officeId: z.string(),
    officeName: z.string(),
    officeEmoji: z.string(),
    date: z.string(),
    customText: z.string().optional(),
    nudgedBy: userSchema
      .pick({
        id: true,
        slackUserId: true,
        msUserId: true,
        profile: true,
      })
      .optional(),
  }),
});

export const userNotificationStatusReminderSchema = userNotificationBaseSchema.extend({
  type: z.literal(UserNotificationType.StatusReminder),
  data: z.object({ date: z.string() }),
});

export const userNotificationSchema = z.discriminatedUnion('type', [
  userNotificationWelcomeSchema,
  userNotificationBillingPlanChangedSchema,
  userNotificationInviteSchema,
  userNotificationInviteAcceptedSchema,
  userNotificationCalendarConnectedSchema,
  userNotificationCalendarDisconnectedSchema,
  userNotificationOnboardingCompleteSchema,
  userNotificationIntegrationSuggestionSchema,
  userNotificationOfficelyHelpSchema,
  userNotificationYouveBeenFavouritedSchema,
  userNotificationManagerChangedYourStatusSchema,
  userNotificationWaitlistSuccessSchema,
  userNotificationCancellationAlertSchema,
  userNotificationOfficeOpportunityAlertSchema,
  userNotificationOfficeCompanionAlertSchema,
  userNotificationWeeklyReminderSchema,
  userNotificationOfficeCheckInSchema,
  userNotificationStatusReminderSchema,
]);

export const managedNotificationTypeSchema = z.enum(MANAGED_NOTIFICATION_TYPES);

// ---------- OFFICE NOTIFICATIONS -----------

export const officeNotificationTypeSchema = z.nativeEnum(OfficeNotificationType);

const officeNotificationBaseSchema = z.object({
  id: z.string(),
  accountId: z.string(),
  officeId: z.string(),
  timestamp: z.date(),
  data: z.record(z.string(), z.any()).optional(),
  metadata: z.record(z.string(), z.any()).optional(),
});

export const officeNotificationBroadcastSchema = officeNotificationBaseSchema.extend({
  type: z.literal(OfficeNotificationType.Broadcast),
  data: z.object({
    compressed: z.boolean(),
    office: officeSchema.pick({
      id: true,
      name: true,
      emoji: true,
      timezone: true,
      broadcast: true,
    }),
    officeDay: officeDaySchema
      .pick({
        date: true,
        isClosed: true,
        spacesLeft: true,
        totalGoing: true,
        announcements: true,
      })
      .extend({
        waitlistCount: z.number(),
        bookedNbhs: z.array(
          officeNeighbourhoodSchema
            .pick({
              id: true,
              name: true,
              category: true,
              capacity: true,
            })
            .extend({
              isPrivate: z.boolean().optional(),
            })
        ),
        bookings: z.array(
          z.object({
            nbhId: z.string().optional(),
            user: userSchema.pick({
              id: true,
              slackUserId: true,
              msUserId: true,
              profile: true,
            }),
          })
        ),
      }),
  }),
});

export const officeNotificationSchema = officeNotificationBroadcastSchema;

// ----------- PARKING LOCATIONS -----------

export const parkingZoneSchema = z.object({
  id: z.string(),
  name: z.string(),
  category: z.string().nullable(),
  capacity: z.number().nullable(),
  usersAllowed: z.array(z.string()).nullable(),
});

export const parkingLocationSchema = z.object({
  id: z.string(),
  accountId: z.string(),
  name: z.string(),
  emoji: z.string(),
  timezone: z.string(),
  weekdays: z.array(z.number()),
  allowGuests: z.boolean(),
  address: z.string(),
  country: z.string(),
  createdAt: z.date(),
  managerIds: z.array(z.string()),
  floorPlan: z.object({
    url: z.string().nullable(),
    isImage: z.boolean(),
  }),
  zones: z.array(parkingZoneSchema),
});

// ---------- PARKING LOCATIONS CLOSURES ----------

export const parkingLocationClosureSchema = z.object({
  id: z.string(),
  parkingLocationId: z.string(),
  fromDate: z.string(),
  toDate: z.string(),
  reason: z.string().nullable(),
  weekdays: z.array(z.number()).nullable(),
});

// ---------- PARKING DAYS ----------

export const parkingDayBookingSchema = z.object({
  userId: z.string(),
  zoneId: z.string().nullable(),
});

export const parkingDaySchema = z.object({
  date: z.string(),
  parkingLocationId: z.string(),
  isClosed: z.boolean(),
  closureReason: z.string().nullable(),
  spacesLeft: z.number().nullable(),
  totalGoing: z.number(),
  waitlist: z.array(z.string()),
  floorPlanUrl: z.string().nullable(),
  guestBookings: z.array(officeDayGuestBookingSchema),
  bookings: z.array(parkingDayBookingSchema),
});

export const parkingBookingSchema = z.object({
  zoneId: z.string().optional(),
});

import { Emoji } from '@client/components/ui/custom/emoji';
import { MultiSelectPeople } from '@client/components/shared/MultiSelectPeople';
import { TypographyP } from '@client/components/ui/custom/typography-p';
import { Button } from '@client/components/ui/button';
import { emojis } from '@client/lib/emojis';
import { StepComponentProps } from '@client/features/onboarding/types';

const CtaBtns = (props: StepComponentProps) => {
  const { onNext, onBack } = props;

  return (
    <>
      <div className="mt-6">
        <Button variant="outline" onClick={onBack}>
          Back
        </Button>
        <Button variant="default" className="ml-2" onClick={onNext}>
          Finish
        </Button>
      </div>
    </>
  );
};

export const OthersTesting = (props: StepComponentProps) => {
  const { state, updateState } = props;

  const handleChange = (ids: string[]) => {
    updateState({ othersTestingOfficely: ids });
  };

  return (
    <div>
      <TypographyP className="mt-2">
        <Emoji className="mr-2">{emojis.wand}</Emoji>
        We'll help them get started by sending them a magic link to join your office.
      </TypographyP>
      <MultiSelectPeople className="mt-4" value={state.othersTestingOfficely ?? []} onChange={handleChange} />
      <CtaBtns {...props} />
    </div>
  );
};

/* eslint-disable sonarjs/no-small-switch */
import { Person } from '@client/components/ui/custom/person';
import { Cross2Icon } from '@radix-ui/react-icons';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { MultiSelectPrimitive } from './primitive';
import { MultiSelectProps, MultiSelectOption } from './types';
import { Button } from '@client/components/ui/button';
import { cn } from '@client/lib/utils';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@client/components/ui/tooltip';
import { ButtonGroup } from '@client/components/ui/custom/button-group';
import _ from 'lodash';

// -------- VARIANTS: DEFAULT --------

const OptionDefault = (props: MultiSelectOption<'default'>) => {
  return (
    <span>
      <span>{props.label ?? props.value}</span>
      {props.description && <span className="text-muted-foreground text-xs ml-4">{props.description}</span>}
    </span>
  );
};

const OptionDefaultButton = (
  props: MultiSelectOption<'default'> & {
    selected: boolean;
    customProps: MultiSelectProps['optionsAsBtnsProps'];
    onClick: (value: string) => void;
  }
) => {
  const { selected = false, customProps = {}, value, label, onClick, description, ...rest } = props;

  const { size = 'sm', badge = true, className, variant = 'outline', selectedVariant = 'secondary' } = customProps;

  const handleClick = useCallback(() => {
    onClick(value);
  }, [value, onClick]);

  const ref = useRef<HTMLButtonElement>(null);
  // we use this to disable automatic focus on the button in forms
  // this causes the tooltip to open and worsen the UX
  useEffect(() => {
    if (ref.current) {
      ref.current.blur();
    }
  }, [ref.current]);

  const btnRendered = useMemo(() => {
    return (
      <Button
        ref={ref}
        key={value}
        type="button"
        className={cn(className, {
          'rounded-full': badge,
        })}
        size={size}
        variant={selected ? selectedVariant : variant}
        onClick={handleClick}>
        <span className="max-w-40 truncate">{label ?? value}</span>
      </Button>
    );
  }, [value, label, selected, customProps, handleClick]);

  if (description) {
    return (
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>{btnRendered}</TooltipTrigger>
          <TooltipContent>{description}</TooltipContent>
        </Tooltip>
      </TooltipProvider>
    );
  }

  return btnRendered;
};

// const OptionDefault = (props: MultiSelectOption<'default'>) => <span>{props.label ?? props.value}</span>;

const OptionSelectedDefault = (props: MultiSelectOption<'default'> & { onRemove: (value: string) => void }) => {
  const onHandleRemove = useCallback(
    (e: any) => {
      e.stopPropagation();
      props.onRemove(props.value);
    },
    [props.value, props.onRemove]
  );
  return (
    <div className="inline-block bg-accent text-sm font-medium text-accent-foreground p-1 leading-3 pl-2 rounded-sm flex items-center	text-nowrap max-w-full">
      <span className="truncate flex-shrink leading-4">{props.label ?? props.value}</span>
      <div className="flex items-center h-auto ml-2 p-[2px] rounded-sm hover:bg-tint/10" onClick={onHandleRemove}>
        <Cross2Icon />
      </div>
    </div>
  );
};

// -------- VARIANTS: PEOPLE --------

const OptionPeople = (props: MultiSelectOption<'people'>) => {
  const { name } = props;
  return (
    <span>
      <Person name={name} variant="text" />
    </span>
  );
};

const OptionSelectedPeople = (props: MultiSelectOption<'people'> & { onRemove: (value: string) => void }) => {
  const { name } = props;
  const onHandleRemove = useCallback(
    (e: any) => {
      e.stopPropagation();
      props.onRemove(props.value);
    },
    [props.value, props.onRemove]
  );
  return (
    <div className="inline-block bg-accent text-sm font-medium text-accent-foreground p-1 leading-3 pl-2 rounded-sm flex items-center	text-nowrap max-w-full">
      <span className="truncate flex-shrink leading-4">
        <Person name={name} variant="text" />
      </span>
      <div className="flex items-center h-auto ml-2 p-[2px] rounded-sm hover:bg-tint/10" onClick={onHandleRemove}>
        <Cross2Icon />
      </div>
    </div>
  );
};

// -------- COMPONENT --------

const MultiSelectDefaultBtns = (props: Omit<MultiSelectProps<'default'>, 'variant'>) => {
  const { options, selected, onChange, optionsAsBtnsProps } = props;

  const handleSelect = useCallback(
    (value: string) => {
      const isSelected = selected?.find((x) => x.value === value);
      if (isSelected) {
        onChange(selected?.map((x) => x.value).filter((x) => x !== value) ?? []);
      } else {
        onChange(_.uniq([...(selected?.map((opt) => opt.value) ?? []), value]));
      }
    },
    [selected, onChange]
  );

  return (
    <ButtonGroup className="mt-0">
      {options.map((opt) => {
        const isSelected = !!selected?.find((x) => x.value === opt.value);
        return (
          <OptionDefaultButton
            key={opt.value}
            onClick={() => handleSelect(opt.value)}
            selected={isSelected}
            customProps={optionsAsBtnsProps}
            {...opt}
          />
        );
      })}
    </ButtonGroup>
  );
};

const MultiSelectDefault = (
  props: Omit<MultiSelectProps<'default'>, 'variant'> & {
    onRemove: (value: string) => void;
  }
) => {
  const { options, selected, onRemove, optionsAsBtns = false, optionsAsBtnsProps, ...propsForPrimitive } = props;
  const shouldRenderAsBtns = useMemo(() => {
    const optIn = optionsAsBtns === true || (typeof optionsAsBtns === 'number' && options.length <= optionsAsBtns);
    const optOut = props.loading !== undefined || !!props.onSearchChange;
    return optIn && !optOut;
  }, [props.optionsAsBtns, props.options, props.loading, props.onSearchChange]);

  if (shouldRenderAsBtns) {
    return <MultiSelectDefaultBtns optionsAsBtnsProps={optionsAsBtnsProps} {...props} />;
  }

  return (
    <MultiSelectPrimitive
      {...propsForPrimitive}
      selected={selected.map((opt) => {
        const option = opt as MultiSelectOption<'default'>;
        return {
          value: option.value,
          elem: <OptionSelectedDefault {...option} onRemove={onRemove} />,
        };
      })}
      options={options.map((opt) => {
        const option = opt as MultiSelectOption<'default'>;
        return {
          value: option.value,
          elem: <OptionDefault {...option} />,
        };
      })}
    />
  );
};

const MultiSelectPeople = (
  props: Omit<MultiSelectProps<'people'>, 'variant'> & {
    onRemove: (value: string) => void;
  }
) => {
  const { options, selected, onRemove, ...propsForPrimitive } = props;

  return (
    <MultiSelectPrimitive
      {...propsForPrimitive}
      selected={selected.map((opt) => {
        const option = opt as MultiSelectOption<'people'>;
        return {
          value: option.value,
          elem: <OptionSelectedPeople {...option} onRemove={onRemove} />,
        };
      })}
      options={options.map((opt) => {
        const option = opt as MultiSelectOption<'people'>;
        return {
          value: option.value,
          elem: <OptionPeople {...option} />,
        };
      })}
    />
  );
};

export const MultiSelect = (props: MultiSelectProps) => {
  const { variant = 'default', ...rest } = props;

  const handleRemove = useCallback(
    (value: string) => {
      props.onChange(props.selected.filter((opt) => opt.value !== value).map((opt) => opt.value));
    },
    [props.selected, props.onChange]
  );

  switch (variant) {
    case 'default':
      return <MultiSelectDefault {...(rest as Omit<MultiSelectProps<'default'>, 'variant'>)} onRemove={handleRemove} />;
    case 'people':
      return <MultiSelectPeople {...(rest as Omit<MultiSelectProps<'people'>, 'variant'>)} onRemove={handleRemove} />;
    default:
      throw new Error('MultiSelect - Invalid variant: ' + variant);
  }
};

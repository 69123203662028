import moment from 'moment';
import { Weekday } from '../const';
import { BaseHelper } from './_base';

export class WeekdayHelper extends BaseHelper<Weekday> {
  get isoWeekday(): number {
    const d = moment().isoWeekday(this.data);
    return d.isoWeekday();
  }

  static fromIsoWeekday(isoWeekday: number): Weekday {
    const weekdayName = moment().isoWeekday(isoWeekday).format('dddd');
    return (weekdayName.charAt(0).toUpperCase() + weekdayName.slice(1)) as Weekday;
  }
}

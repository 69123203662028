import { defineForm } from '@client/lib/form/helpers';
import { useMemo } from 'react';
import { BillingDowngradeReason, BILLING_DOWNGRADE_REASON_PROFILES } from '@officely/models';
import { FormValues } from '@client/lib/form/types';
import { SimpleForm, SimpleFormProps } from '@client/components/shared/simple-form';

const FORM_ID = 'billing-downgrade-form' as const;

const getDefinition = () =>
  defineForm({
    id: FORM_ID,
    fields: {
      reasons: {
        type: 'multi-select',
        required: true,
        options: Object.values(BillingDowngradeReason).map((reason) => ({
          value: reason,
          label: BILLING_DOWNGRADE_REASON_PROFILES[reason].name,
        })),
        label: 'Reason',
        description: 'Please tell us why you are downgrading',
      },
      comment: {
        type: 'textarea',
        label: 'So we can improve and do better next time, could you please share why you’re choosing to leave? 🙏',
      },
    },
    validate(values, errors) {
      if (values.reasons.length === 1 && values.reasons.includes(BillingDowngradeReason.OTHER) && !values.comment) {
        errors.comment = 'Required';
      }
      return errors;
    },
    conditions(values) {
      return {
        hidden: {
          comment: !values.reasons?.length,
        },
      };
    },
  });

type Props = Omit<SimpleFormProps<ReturnType<typeof getDefinition>>, 'definition'>;
export type BillingDowngradeFormValues = FormValues<ReturnType<typeof getDefinition>>;



const BillingDowngradeForm = (props: Props) => {
  const definition = useMemo(() => getDefinition(), []);
  return <SimpleForm {...props} definition={definition} />;
};

BillingDowngradeForm.id = FORM_ID;

export { BillingDowngradeForm };

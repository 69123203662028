const emojiMapping = {
  office: '🏢',
  help: '❓',
  note: '🖊️',
  information: 'ℹ️',
  office_usage: '💡',
  insights: '📈',
  wave: '👋',
  great: '👏',
  floor: '🗺',
  info_text: '💬',
  office_chat: '💬',
  setting: '⚙️',
  whats_new: '🎁',
  team: '🎳',
  billing: '💳',
  weekend: '🎉',
  welcome: '🎉',
  tada: '🎉',
  desk: '🔸',
  allday: '🔆',
  morning: '🕘',
  afternoon: '🕒',
  away_date: '🕓',
  time: '🕓',
  enable: '⚡️',
  fast: '⚡️',
  is_going: '✅',
  square_bullet: '▫️',
  approved: '✅',
  done: '✅',
  success: '✓',
  fail: '✗ ',
  booked: '✔️',
  checked: '✓',
  fully_booked: '💯',
  popular: '🔥',
  available: '👍',
  add: '➕',
  remove: '✖ ',
  cancel: '✗ ',
  danger: '❌',
  denied: '❌',
  close: '⛔️',
  disable: '🚫',
  search: '🔎',
  contact_tracing: '🔎',
  meetingroom: '🔎',
  no_data: '🙁',
  edit: '✏️',
  attachment: '📎',
  inbox: '📥',
  export: '📥',
  suggest_feature: '💎',
  neighbourhood: '📬',
  one_click: '☝️',
  recurring: '🔄',
  notification: '💬',
  send_at: '✉️',
  slack: ':slack:',
  calendar: '📅',
  selected: '👉',
  tips: '✨',
  health: '🤒',
  deadline: '⏱',
  access: '🗝',
  bookable: '👁',
  customise: '🎨',
  vaccine: '💉',
  test: '🧪',
  preview: '🧐',
  starter: '🌱',
  preference: '💚',
  back: '←',
  next: '→',
  up: '↑',
  bug: '🐞',
  thanks: '😉',
  love: '💛',
  no_love: '😰',
  warning: '⚠️',
  location: '📍',
  snooze: '⏰',
  mute: '🤫',
  see_below: '👇',
  join_day: '🙋',
  launch_officely: '🤩',
  book_consultation: '📞',
  checklist: '📋',
  summary: '📋',
  launch: '🚀',
  discovery: '👀',
  excited: '😃',
  idea: '💭',
  attendee: '🤝',
  pinned: '📌',
  announcement: '📣',
  announcement_gray: '📢',
  drinks: '🥂',
  favorite_coworker: '⭐️',
  switch: '🔁',
  office_broadcast: '🎙️',
  remote: '🏡',
  annual_leave: '🏖️',
  other_absence: '✖️',
  off_sick: '🤒',
  not_working: '✖️',
  no_status: '❓',
  peace: '✌️',
  magic: '🪄',
  finish: '🏁',
  routines: `♾️`,
  invite_teammates: `👋`,
  office_members: `👥`,
  intergation: `🔌`,
  work_travel: `✈️`,
  manage_bookings: '📋',
  praise: '🙌',
  zap: '⚡️',
  cool: '😎',
  dog: '🐶',
  lunch: '🍜',
  parking: '🅿️',
  client_office: '💼',
  // loading: '⏳',
  loading: '🔄',
  system_managed: '✨',
  cry: '😢',
  zone: '🚗',
} as const;

export const emojis = Object.fromEntries(Object.entries(emojiMapping)) as Record<keyof typeof emojiMapping, string>;

export const EMOJI_SELECTOR_HINT_TEXT = `Tip: Emoji selector, press Ctrl + ⌘ + Space on Mac or Windows + Period (.) on PC`;

export const EMOJI_REGEX = /\p{Emoji}/u;
export const EMOJI_SLACK_REGEX = /^:([a-z0-9_]+):$/g;

import { Select } from '@client/components/ui/custom/select';
import { useCallback, useMemo } from 'react';

export const NoteSuggestions = (props: {
  suggestions: string[] | undefined;
  onSuggestion?: (suggestion: string) => void;
}) => {
  const { suggestions, onSuggestion } = props;

  const suggestionOpts = useMemo(() => suggestions?.map((suggestion) => ({ value: suggestion })) ?? [], [suggestions]);

  const handleSuggestion = useCallback(
    (suggestion: string | undefined) => {
      suggestion && onSuggestion?.(suggestion);
    },
    [onSuggestion]
  );

  return !suggestions?.length ? null : (
    <div className="mt-2">
      <Select optionsAsBtns options={suggestionOpts} onChange={handleSuggestion} />
    </div>
  );
};

import { Emoji } from '@client/components/ui/custom/emoji';
import { splitTextAndEmojis } from '@client/lib/emojis';
import { cn } from '@client/lib/utils';

type EmojiProps = Parameters<typeof Emoji>[0];

type Props = React.HTMLAttributes<HTMLDivElement> & {
  children: string;
  italic?: boolean;
  textClassName?: string;
  convertEmojis?: boolean;
  emojiProps?: EmojiProps;
};

export const TextAndEmojis = ({
  children,
  className,
  italic,
  textClassName,
  emojiProps,
  convertEmojis = false,
  ...rest
}: Props) => {
  const _parts = splitTextAndEmojis(children);
  type Val = ReturnType<typeof splitTextAndEmojis>[number];
  const parts = _parts.reduce(
    (acc, x) => {
      if (typeof x === 'object' && x.isModifier && !!acc[acc.length - 1]) {
        const lastItem = acc[acc.length - 1];
        const lastItemInItem = lastItem?.[lastItem.length - 1];
        if (lastItem && typeof lastItemInItem === 'object') {
          lastItem.push(x);
        }
      } else {
        acc.push([x]);
      }
      return acc;
    },
    [] as Array<Array<Val>>
  );

  return (
    <div className={cn('flex items-center gap-x-1', className)} {...rest}>
      {parts
        .filter((x) => x.length > 0)
        .filter((x) => x.length > 1 || typeof x[0] === 'object' || x[0].trim().length > 0)
        .map((part, i) =>
          part.length === 1 && typeof part[0] === 'string' ? (
            <span className={cn(italic ? 'italic' : 'not-italic', textClassName)} key={i}>
              {part[0]}
            </span>
          ) : convertEmojis ? (
            <Emoji key={i} {...emojiProps}>
              {part.map((x) => (typeof x === 'object' ? x.emoji : x)).join('')}
            </Emoji>
          ) : (
            <span key={i}>{part.map((x) => (typeof x === 'object' ? x.emoji : x)).join('')}</span>
          )
        )}
      {/* {parts.map((part, i) => (typeof part === 'object' ? <span key={i}>{part.emoji}</span> : <i key={i}>{part}</i>))} */}
    </div>
  );
};

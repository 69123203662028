import moment from 'moment';
import { UserWorkStatusType } from '../const';
import { UserWorkStatus, UserWorkStatusMinimal } from '../types';
import { UserWorkStatusTypeHelper } from './userWorkStatusType';

export class UserWorkStatusMinimalHelper<S extends UserWorkStatusMinimal | UserWorkStatus> {
  protected typeHelper: UserWorkStatusTypeHelper<S['type']>;

  constructor(public data: S) {
    this.typeHelper = new UserWorkStatusTypeHelper(data.type);
  }

  get officeId(): string | undefined {
    if ('officeId' in this.data) {
      return this.data.officeId;
    }
    if ('office' in this.data && this.data.office) {
      return this.data.office.id;
    }
  }

  get waitlistPosition(): number | undefined {
    if ('waitlistPosition' in this.data) {
      return this.data.waitlistPosition;
    }
    if ('office' in this.data && 'waitlistPosition' in this.data.office) {
      return this.data.office.waitlistPosition;
    }
  }

  get neighbourhoodId(): string | undefined {
    if ('nbhId' in this.data) {
      return this.data.nbhId;
    }
    if ('office' in this.data && 'neighbourhood' in this.data.office) {
      return this.data.office?.neighbourhood?.id;
    }
  }

  get extraIds(): string[] | undefined {
    if ('extraIds' in this.data) {
      return this.data.extraIds;
    }
    if ('office' in this.data && 'extras' in this.data.office) {
      return this.data.office.extras.map((extra) => extra.id);
    }
  }

  get guestEmails(): string[] | undefined {
    if ('guestEmails' in this.data) {
      return this.data.guestEmails;
    }
    if ('office' in this.data && 'guests' in this.data.office) {
      return this.data.office.guests;
    }
  }
  get fromDate(): string | undefined {
    if ('fromDate' in this.data) {
      return this.data.fromDate;
    }
  }

  get toDate(): string | undefined {
    if ('toDate' in this.data) {
      return this.data.toDate;
    }
  }

  get hasStatus(): boolean {
    return this.data.type !== UserWorkStatusType.NO_STATUS;
  }

  get morningOnly(): boolean {
    return !!this.data.note?.toLowerCase()?.includes(`morning only`);
  }
  get afternoonOnly(): boolean {
    return !!this.data.note?.toLowerCase()?.includes(`afternoon only`);
  }
  get allDay(): boolean {
    return !this.morningOnly && !this.afternoonOnly;
  }
  get profile(): UserWorkStatusTypeHelper<S['type']>['profile'] {
    return this.typeHelper.profile;
  }

  get isAwayDate(): boolean {
    return this.typeHelper.isAwayDate;
  }
  get isOffice(): boolean {
    return this.typeHelper.isOffice;
  }

  public getDescription(currentDate: string): string {
    return getDescription({ currentDate, ...this.data });
  }

  static getDescription(args: Parameters<typeof getDescription>[0]): string {
    return getDescription(args);
  }
}

// -------- Helper functions --------

function getDescription(arg: { currentDate: string; note?: string; fromDate?: string; toDate?: string }): string {
  let description = '';
  const { note, fromDate, toDate, currentDate } = arg;
  const daysCount = fromDate && toDate ? getDayCount(fromDate, toDate, currentDate) : { total: 1, current: 1 };
  if (daysCount.total > 1) {
    const dayCountText = `Day ${daysCount.current}/${daysCount.total}`;
    // const returningText = `Back on ${moment(toDate).format('D MMM')}`;
    // description = description ? `${description}  ${returningText}` : returningText;
    description = description ? `${description}  ${dayCountText}` : dayCountText;
  }
  if (note) {
    const separator = description.includes('Day') ? '  -  ' : '  ';
    const noteText = `${note}`;
    description = description ? `${description}${separator}${noteText}` : noteText;
  }
  return description;
}

// get day count not including weekends
const getDayCount = (fromDate: string, toDate: string, currentDate: string) => {
  const startM = moment(fromDate);
  const endM = moment(toDate);
  const currentM = moment(currentDate);
  let total = 0;
  let current = 0;
  while (startM <= endM) {
    const isoWeekday = startM.isoWeekday();
    if (![6, 7].includes(isoWeekday)) {
      total++;
      if (startM <= currentM) {
        current++;
      }
    }
    startM.add(1, 'days');
  }
  return {
    total,
    current,
  };
};

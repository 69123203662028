/* eslint-disable sonarjs/no-small-switch */

import { TypographyP } from '@client/components/ui/custom/typography-p';
import { Avatar, AvatarFallback, AvatarImage } from '@client/components/ui/avatar';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@client/components/ui/tooltip';
import { cn, numBetween } from '@client/lib/utils';
import { EnvelopeClosedIcon } from '@radix-ui/react-icons';
import { useMemo } from 'react';
import { Skeleton } from '@client/components/ui/skeleton';
import { getInitials } from '@client/features/app/helpers';

type Props = {
  name?: string; // undefined when loading
  email?: string;
  avatarUrl?: string;
  isSelf?: boolean;
  variant?: 'text' | 'tag' | 'tag-compressed';
  className?: string;
  onClick?: () => void;
};

const PersonText = (props: Props) => {
  const { name, className } = props;
  if (!name) {
    const width = numBetween(90, 110);
    return <Skeleton style={{ width }} className={cn(`h-2`, className)} />;
  }
  return <span className={cn('inline-flex items-center', className)}>{name}</span>;
};

const PersonTag = (props: Props) => {
  const { variant, name, className, avatarUrl, email, isSelf, onClick } = props;

  const triggerElem = useMemo(() => {
    if (!name) {
      const width = numBetween(70, 90);
      return <Skeleton style={{ width }} className={cn(`h-4 rounded-[3px]`, className)} />;
    }
    return (
      <span
        className={cn(
          'inline-flex items-center px-1 h-7  rounded-[3px] bg-person/10 text-person-foreground',
          className,
          {
            'bg-person-self/20': isSelf,
            'text-person-self-foreground': isSelf,
            'h-6': variant === 'tag-compressed',
            'cursor-pointer': !!onClick,
            'cursor-default': !onClick,
            'hover:bg-person/20': !!onClick && !isSelf,
            'hover:bg-person-self/25': !!onClick && isSelf,
          }
        )}>
        {name}
      </span>
    );
  }, [name, className, onClick]);

  const contentElem = useMemo(() => {
    return (
      <div className="flex items-center gap-4">
        <Avatar>
          <AvatarImage src={avatarUrl}></AvatarImage>
          <AvatarFallback>{getInitials(name)}</AvatarFallback>
        </Avatar>
        <div>
          <TypographyP className="font-bold text-base">{name}</TypographyP>
          {email && (
            <div className="flex items-center mt-2 text-muted-foreground">
              <EnvelopeClosedIcon className="w-4 h-4 mr-2" />
              <TypographyP className="text-xs text-link">
                <a href={`mailto:${email}`}>{email}</a>
              </TypographyP>
            </div>
          )}
        </div>
      </div>
    );
  }, [name, avatarUrl, email]);

  // if (isMobile) {
  //   return (
  //     <Popover>
  //       <PopoverTrigger>{triggerElem}</PopoverTrigger>
  //       <PopoverContent className="bg-card border p-4 rounded-md shadow-md text-inherit" align="start" sideOffset={8}>
  //         {contentElem}
  //       </PopoverContent>
  //     </Popover>
  //   );
  // }

  return (
    <TooltipProvider>
      <Tooltip delayDuration={500}>
        <TooltipTrigger onClick={onClick}>{triggerElem}</TooltipTrigger>
        <TooltipContent
          className={cn('bg-card border p-4 rounded-md shadow-md text-inherit')}
          align="start"
          sideOffset={8}>
          {contentElem}
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
};

export const Person = (props: Props) => {
  const { variant = 'text' } = props;

  switch (variant) {
    case 'text':
      return <PersonText {...props} />;
    case 'tag':
    case 'tag-compressed':
      return <PersonTag {...props} />;
    default:
      return null;
  }
};
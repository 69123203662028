/* eslint-disable sonarjs/cognitive-complexity */
import moment from 'moment';
import { ANNOUNCEMENT_CATEGORY_PROFILES, AnnouncementCategory, YYYY_MM_DD } from '../const';
import { OfficeAnnouncement } from '../types';
import { BaseHelper } from './_base';
import _ from 'lodash';

export class OfficeAnnouncementHelper extends BaseHelper<OfficeAnnouncement> {
  public get categoryProfile() {
    return ANNOUNCEMENT_CATEGORY_PROFILES[this.data.category as AnnouncementCategory];
  }

  public appliesOnWeekday(weekday: number) {
    return !this.data.weekdays || this.data.weekdays.length === 0 || this.data.weekdays.includes(weekday);
  }

  public excludedFromDate(date: string) {
    return this.data.excludedDates && this.data.excludedDates.includes(date);
  }

  public appliesOnDate(date: string) {
    const isoWeekday = moment(date, YYYY_MM_DD, true).isoWeekday();
    return (
      this.data.fromDate <= date &&
      this.data.toDate >= date &&
      this.appliesOnWeekday(isoWeekday) &&
      !this.excludedFromDate(date)
    );
  }

  public getRelativeDatesText(on?: Date): string {
    return getRelativeDatesString({ dates: this.data, on });
  }
}

const DAYS_IN_WEEK = 7;
const MILLSECONDS_IN_ONE_WEEK = 604800000;
const workingWeekdays = [1, 2, 3, 4, 5];
type Dates = Pick<OfficeAnnouncement, 'fromDate' | 'toDate' | 'excludedDates' | 'weekdays'>;

enum AnnouncementEventType {
  SINGLE_DAY = 'single_day',
  CONTINUOUS = 'continuous',
  RUNNING_RECURRING = 'running_recurring',
  UPCOMING_RECURRING = 'upcoming_recurring',
  RECURRING = 'recurring',
  BROKEN = 'broken',
}

// ------- helpers ---------

/**
 * get relative date string e.g. Today → Tomorrow by a given recurringDates
 * @param arg collection of arguments
 * @param arg.recurringDates date in RecurringDates format
 * @param arg.on the workspace's today
 * @returns relative date string e.g. Today → Tomorrow
 */
function getRelativeDatesString(arg: { dates: Dates; on?: Date }): string {
  const { dates, on = new Date() } = arg;
  const adjustedDates = getAdjustedDates(dates, on);
  const { fromDate, toDate, weekdays } = adjustedDates;
  const eventType = getEventType({ dates: adjustedDates, on });

  const isSameYear = moment(on).isSame(toDate, 'year');

  switch (eventType) {
    case AnnouncementEventType.SINGLE_DAY: {
      return getRelativeDateStringForAnnouncement({
        date: fromDate,
        on,
        defaultFormat: isSameYear ? 'dddd - DD MMM' : 'dddd - DD MMM YYYY',
      });
    }
    case AnnouncementEventType.CONTINUOUS:
      return getNiceStartandEndDates(fromDate, toDate, on);
    case AnnouncementEventType.RUNNING_RECURRING:
      const until = getRelativeDateStringForAnnouncement({
        date: toDate,
        on,
      });
      return `${weekdays ? `${getNiceWeekdays(weekdays)} - ` : ''}until ${until}`;
    case AnnouncementEventType.UPCOMING_RECURRING:
      return `${getNiceStartandEndDates(fromDate, toDate, on)}${weekdays ? ` - on ${getNiceWeekdays(weekdays)}` : ''}`;
    default:
      return getNiceTargetDates(dates, on);
  }
}

/**
 * get display format type
 * @param _ collection of arguments
 * @param _.recurringDates date selection
 * @param _.on the time when the display is relative to
 * @returns display format type
 */
function getEventType(args: { dates: Dates; on: Date }): AnnouncementEventType {
  const { dates, on } = args;
  const { fromDate, toDate, weekdays, excludedDates } = dates;

  const hasWeekdayRestriction = weekdays && weekdays.length > 0;
  const hasExcludedDates = excludedDates && excludedDates.length > 0;

  if (fromDate === toDate) {
    return AnnouncementEventType.SINGLE_DAY;
  } else if (
    !hasWeekdayRestriction ||
    _.isEqual(weekdays, workingWeekdays) ||
    (!hasExcludedDates &&
      _.isEqual(
        // check if the evaluated target dates are the same as normal working days
        listDatesBetween(dates),
        listDatesBetween({
          ...dates,
          weekdays: workingWeekdays,
        })
      ))
  ) {
    return AnnouncementEventType.CONTINUOUS;
  } else if (!hasExcludedDates && moment.utc(toDate).diff(moment.utc(fromDate), 'weeks') > 0) {
    return fromDate > on.toISOString()
      ? AnnouncementEventType.UPCOMING_RECURRING
      : AnnouncementEventType.RUNNING_RECURRING;
  }

  return AnnouncementEventType.BROKEN;
}

/**
 * get relative date string e.g. Next Monday by a given date
 * @param arg collection of arguments
 * @param arg.date date in YYYY-MM-DD
 * @param arg.on the workspace's today
 * @param arg.shortWeekday return from is short like 'Mon' not 'Monday'
 * @param arg.defaultFormat default date format if date can't be presented in a relative way e.g. 'DD MMM', 'DD MMM YYYY', 'ddd - DD MMM YYYY'
 * @returns relative date string e.g. Next Monday, Monday, Today, Tomorrow, 21 Jul, 21 Jul 2020, Tuesday - 21 Jul 2020
 */
function getRelativeDateStringForAnnouncement(arg: {
  date: string;
  on: Date;
  shortWeekday?: boolean;
  defaultFormat?: string;
}): string {
  const { date, on, shortWeekday = false, defaultFormat } = arg;
  const dateFormat = defaultFormat ?? (on.getFullYear() === new Date(date).getFullYear() ? 'DD MMM' : 'DD MMM YYYY');

  const humanDate: Partial<Record<string, string>> = {};

  const weekdayFormat = shortWeekday ? 'ddd' : 'dddd';

  for (let i = 1; i <= DAYS_IN_WEEK; i++) {
    const weekday = moment.utc(on).isoWeekday(i).format(weekdayFormat);
    const isPast = moment.utc(on) > moment.utc(on).weekday(i);
    const currentDate = moment.utc(on).isoWeekday(i).format(YYYY_MM_DD);
    humanDate[currentDate] = isPast ? `Past ${weekday}` : weekday;

    const nextWeekdate = moment
      .utc(on)
      .isoWeekday(DAYS_IN_WEEK + i)
      .format(YYYY_MM_DD);
    humanDate[nextWeekdate] = `Next ${weekday}`;
  }

  humanDate[moment.utc(on).format(YYYY_MM_DD)] = 'Today';
  humanDate[moment.utc(on).add(1, 'days').format(YYYY_MM_DD)] = 'Tomorrow';

  return humanDate[date] ?? moment.utc(date).format(dateFormat);
}

/**
 * get nice start and end dates string
 * @param start the first upcoming event day in YYYY-MM-DD format
 * @param end the first upcoming event days in YYYY-MM-DD format
 * @param on today
 * @returns nice start and end dates string e.g. Next Monday → Tuesday
 */
function getNiceStartandEndDates(fromDate: string, toDate: string, on: Date): string {
  const withYear = moment.utc(fromDate).format('YYYY') !== moment.utc(toDate).format('YYYY');
  const isAllInSameWeek = moment.utc(toDate).diff(moment.utc(fromDate), 'week') === 0;
  const defaultFormat = withYear ? 'DD MMM YYYY' : 'DD MMM';

  if (isAllInSameWeek && moment.utc(toDate).diff(moment.utc(on), 'week') === 1) {
    return `Next ${moment.utc(fromDate).format('dddd')} → ${moment.utc(toDate).format('dddd')}`;
  } else {
    const relativeStart = getRelativeDateStringForAnnouncement({
      date: fromDate,
      on,
      defaultFormat,
    });
    const relativeEnd = getRelativeDateStringForAnnouncement({
      date: toDate,
      on,
      defaultFormat,
    });

    return `${relativeStart} → ${relativeEnd}`;
  }
}

/**
 * get nice tagetDates string
 * @param validRecurringDates upcoming event days in userfriendly format
 * @param on today
 * @returns nice targetDate string e.g. Next Mon, Next Tue, Next Wed, 21 Dec, 29 Dec, 1 Jan 2020
 */
function getNiceTargetDates(dates: Dates, on: Date): string {
  const { fromDate, toDate, weekdays } = dates;

  const hasWeekdayRestriction = weekdays && weekdays.length > 0;

  const targetDates = listDatesBetween(dates).map((date) => getRelativeDateStringForAnnouncement({ date, on }));

  const weekDiff = (date: string): number =>
    moment.utc(date, YYYY_MM_DD).startOf('week').diff(moment.utc(on).startOf('week')) / MILLSECONDS_IN_ONE_WEEK;

  const isAllThisWeek = weekDiff(fromDate) === 0 && weekDiff(toDate) === 0;

  if (isAllThisWeek) {
    const upcomingDatesInDateFormat = listDatesBetween(dates);
    const upcomingDatesInHumanDateFormat = upcomingDatesInDateFormat.map((date) =>
      getRelativeDateStringForAnnouncement({
        date,
        on,
        shortWeekday: true,
      })
    );

    return upcomingDatesInHumanDateFormat.join(', ');
  }

  const isAllNextWeek = weekDiff(fromDate) === 1 && weekDiff(toDate) === 1;

  if (isAllNextWeek) {
    return hasWeekdayRestriction ? `Next Week - on ${getNiceWeekdays(weekdays)}` : 'Next Week';
  }

  const niceTargetDates = targetDates.map((date) => {
    if (date === 'Today' || date === 'Tomorrow' || date.split(' ').some((el) => typeof el === 'number')) {
      return date;
    } else {
      return date
        .split(' ')
        .map((el) => {
          if (el === 'Next') {
            return el;
          } else {
            return el.slice(0, 3);
          }
        })
        .join(' ');
    }
  });

  return niceTargetDates.join(', ');
}

/**
 * get nice weekdays string
 * @param weekdays collection of weekdays e.g. [1,2,3]
 * @returns nice weekdays string e.g. Mon, Tue
 */
function getNiceWeekdays(weekdays: number[]): string {
  return weekdays
    .sort()
    .map((weekday) => moment.utc().isoWeekday(weekday).format('ddd'))
    .join(', ');
}

/**
 * get all event dates in 'YYYY-MM-DD' format from RecurringDates format
 * @param recurringDates date in RecurringDates format
 * @returns an array with dates in 'YYYY-MM-DD' format
 */
function listDatesBetween(dates: Dates): string[] {
  const { fromDate, toDate, weekdays, excludedDates } = dates;
  const hasWeekdayRestriction = weekdays && weekdays.length > 0;

  const startDateM = moment.utc(fromDate);
  const endDateM = moment.utc(toDate);

  const result: string[] = [];

  while (startDateM.isSameOrBefore(endDateM)) {
    const date = startDateM.format(YYYY_MM_DD);
    const isWeekday = !hasWeekdayRestriction || weekdays.includes(startDateM.isoWeekday());
    const isExcluded = excludedDates && excludedDates.includes(date);
    if (isWeekday && !isExcluded) {
      result.push(date);
    }
    startDateM.add(1, 'day');
  }

  return result;
}

/**
 * adjust for on date
 */
function getAdjustedDates(dates: Dates, on: Date): Dates {
  const { toDate, fromDate, weekdays, excludedDates } = dates;

  const sortedWeekdays = weekdays && weekdays.sort();
  const hasWeekdayRestriction = !!sortedWeekdays && sortedWeekdays.length > 0;

  // resolve start date
  const fromDateM = moment.utc(fromDate);
  const onDateM = moment.utc(on);
  const newFromDateM = fromDateM.isAfter(onDateM) ? fromDateM : onDateM;
  do {
    const isoWeekday = newFromDateM.isoWeekday();
    const isValidWeekday = !hasWeekdayRestriction || sortedWeekdays.includes(isoWeekday);
    const isExcluded = excludedDates?.includes(newFromDateM.format(YYYY_MM_DD));
    if (isValidWeekday && !isExcluded) {
      break;
    }
    newFromDateM.add(1, 'day');
  } while (newFromDateM.isSameOrBefore(moment.utc(toDate)));

  // resolve end date
  const newToDateM = moment.utc(toDate);
  do {
    const isoWeekday = newToDateM.isoWeekday();
    const isValidWeekday = !hasWeekdayRestriction || sortedWeekdays.includes(isoWeekday);
    const isExcluded = excludedDates?.includes(newToDateM.format(YYYY_MM_DD));
    if (isValidWeekday && !isExcluded) {
      break;
    }
    newToDateM.subtract(1, 'day');
  } while (newToDateM.isSameOrAfter(newFromDateM));

  const newFromDate = newFromDateM.format(YYYY_MM_DD);
  const newToDate = newToDateM.format(YYYY_MM_DD);

  return {
    fromDate: newFromDate,
    toDate: newToDate,
    weekdays: sortedWeekdays,
    excludedDates: excludedDates && excludedDates.filter((date) => date < newToDate && date > newFromDate),
  };
}

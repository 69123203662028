/* eslint-disable sonarjs/no-small-switch */
import { cn, numBetween } from '@client/lib/utils';
import { Skeleton } from '@client/components/ui/skeleton';
import { Emoji } from '@client/components/ui/custom/emoji';

type Props = {
  name?: string; // undefined when loading
  emoji?: string;
  hideEmoji?: boolean;
  className?: string;
  onClick?: () => void;
};

export const Office = (props: Props) => {
  const { name, hideEmoji = false, emoji, className } = props;
  if (!name) {
    const width = numBetween(70, 90);
    return <Skeleton style={{ width }} className={cn(`h-2 mx-1`, className)} />;
  }
  return (
    <span className={cn('inline-flex items-center', className)}>
      {emoji && !hideEmoji && <Emoji className="mr-1">{emoji}</Emoji>}
      {name}
    </span>
  );
};

import { DefaultLayout } from '@client/components/layout/DefaultLayout';
import { Emoji } from '@client/components/ui/custom/emoji';
import { TypographyH3 } from '@client/components/ui/custom/typography-h3';
import { TypographyMuted } from '@client/components/ui/custom/typography-muted';
import { CloseIcon } from '@client/components/ui/custom/icons/index';
import { Spinner } from '@client/components/ui/custom/spinner';
import { emojis } from '@client/lib/emojis';
import React from 'react';
import { OnboardingStep, ONBOARDING_STEP_PROFILES } from '@officely/models';
import { Separator } from '@client/components/ui/separator';

type Props = React.PropsWithChildren<{
  steps: OnboardingStep[];
  currentStep: OnboardingStep;
  name: string;
  submitting?: boolean;
  exit?: () => void;
}>;

export const OnboardingLayout = (props: Props) => {
  const { steps, currentStep, name, submitting, exit } = props;
  const showStepCount = steps.length > 2;
  const stepIndex = steps.findIndex((step) => step === currentStep);
  const stepsText = `step ${stepIndex + 1} of ${steps.length}`;
  const stepProfile = ONBOARDING_STEP_PROFILES[currentStep];

  return (
    <DefaultLayout className="relative">
      <div className="flex items-center">
        <TypographyMuted>
          <Emoji>{emojis.wave}</Emoji>
          {`  `}
          <i>
            Welcome {name}!{' '}
            Let's get started.
          </i>
        </TypographyMuted>
        {exit && <CloseIcon asButton buttonVariant={'ghost'} tooltip="Exit Setup" onClick={exit} className="ml-auto" />}
      </div>

      <Separator className="my-3" />
      <div className="mt-6">
        <TypographyH3>
          {stepProfile.heading}
          {showStepCount ? `  (${stepsText})` : ''}
        </TypographyH3>
      </div>
      {props.children}
      {submitting && <Spinner includeOverlay fullScreen />}
    </DefaultLayout>
  );
};

import { useEffect, useRef } from 'react';

type AsyncFn = (...args: any[]) => Promise<any>;
type Fn = (...args: any[]) => any;

export function useEffectOnce(fn: Fn | AsyncFn) {
  const loaded = useRef(false);

  useEffect(() => {
    if (!loaded.current) {
      loaded.current = true;
      fn();
    }
  }, [fn]);
}

import { cn } from '@client/lib/utils';
import { PropsWithChildren } from 'react';

type Props = PropsWithChildren<{
  sm?: boolean;
  className?: string;
}>;

export const QuoteBlock = (props: Props) => {
  const { className } = props;

  return (
    <div className={cn('relative pl-4 ml-[2px]', className)}>
      <span
        className={cn(
          'absolute top-0 left-0 bottom-0 inline-block h-full rounded-lg bg-input',
          // eslint-disable-next-line sonarjs/no-all-duplicated-branches
          props.sm ? 'w-1' : 'w-1'
        )}></span>
      {props.children}
    </div>
  );
};

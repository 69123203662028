import { trpc } from '@client/lib/trpc';
import { UserWorkStatusType } from '@officely/models';
import { useCallback } from 'react';
import { toast } from 'sonner';

export const useAccountSettings = () => {
  const accountUpdateAdminsMutation = trpc.account.updateAdmins.useMutation();
  const accountUpdateDisabledStatusesMutation = trpc.account.updateDisabledStatuses.useMutation();

  const trpcUtils = trpc.useUtils();

  const invalidateTrpcQueries = useCallback(async () => {
    await Promise.all([trpcUtils.account.current.invalidate()]);
  }, [trpcUtils]);

  const updateAccountAdmins = useCallback(
    async (value: string[]) => {
      const promise = accountUpdateAdminsMutation.mutateAsync({
        peopleIds: value,
      });
      toast.promise(promise, {
        loading: 'Updating account admins...',
        success: 'Account admins updated successfully!',
        error: 'Failed to update account admins',
      });
      await promise;
      await invalidateTrpcQueries();
    },
    [accountUpdateAdminsMutation.mutateAsync, toast, invalidateTrpcQueries]
  );

  const updateDisabledStatuses = useCallback(
    async (value: UserWorkStatusType[]): Promise<boolean> => {
      const promise = accountUpdateDisabledStatusesMutation.mutateAsync({
        disabledStatuses: value,
      });
      toast.promise(promise, {
        loading: 'Updating statuses...',
        success: 'Succesfully updated statuses!',
        error: 'Failed to update statuses',
      });
      try {
        await promise;
        await invalidateTrpcQueries();
        return true;
      } catch (error) {
        return false;
      }
    },
    [accountUpdateDisabledStatusesMutation.mutateAsync, toast]
  );

  return {
    updateAccountAdmins,
    updateDisabledStatuses,
  };
};

import { ModalLayout } from '@client/components/ui/custom/modal-layout';
import { BroadcastForm, BroadcastFormValues } from '@client/features/settings/components/broadcast-form';

type Props = {
  data?: BroadcastFormValues;
  onClose: () => void;
  onSubmit: (values: BroadcastFormValues) => void;
};

export const BroadcastModal = ({ data, onClose, onSubmit }: Props) => {
  return (
    <ModalLayout
      title="Office Broadcast"
      closeText="Cancel"
      onClose={onClose}
      submitText="Save"
      submitFormId={BroadcastForm.id}>
      <BroadcastForm onSubmit={onSubmit} data={data} />
    </ModalLayout>
  );
};

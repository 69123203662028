import * as React from 'react';
import {
  CaretSortIcon,
  CheckCircledIcon,
  ChevronDownIcon,
  Cross2Icon,
  DotsHorizontalIcon,
  PlusIcon,
} from '@radix-ui/react-icons';
import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';

import { Button } from '@client/components/ui/button';
import { Checkbox } from '@client/components/ui/checkbox';
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@client/components/ui/dropdown-menu';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@client/components/ui/table';
import { Emoji } from '@client/components/ui/custom/emoji';
import { Badge } from '@client/components/ui/badge';
import { TypographyH3 } from '@client/components/ui/custom/typography-h3';
import { TypographyP } from '@client/components/ui/custom/typography-p';
import { WeekdayHelper } from '@officely/models';

type RowDef = {
  id: string;
  enabled: boolean;
  profile: {
    emoji: string;
    name: string;
  };
  capacity: number | undefined;
  daysOfWeek: string[] | number[] | undefined;
  onEdit: () => void;
  onDelete: () => void;
};

export const columns: ColumnDef<RowDef>[] = [
  {
    id: 'select',
    header: ({ table }) => (
      <Checkbox
        checked={table.getIsAllPageRowsSelected() || (table.getIsSomePageRowsSelected() && 'indeterminate')}
        onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
        aria-label="Select all"
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        checked={row.getIsSelected()}
        onCheckedChange={(value) => row.toggleSelected(!!value)}
        aria-label="Select row"
      />
    ),
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: 'profile',
    header: 'Profile',
    cell: ({ row }) => {
      const value = row.getValue('profile') as RowDef['profile'];
      const { emoji, name } = value;
      return (
        <div className="flex items-center">
          <Emoji className="mr-2">{emoji}</Emoji>
          <span className="capitalize italic">{name}</span>
        </div>
      );
    },
  },
  {
    accessorKey: 'enabled',
    header: 'Enabled',
    cell: ({ row }) => {
      const value = row.getValue('enabled') as RowDef['enabled'];
      return (
        <div className="text-center">
          {value ? <CheckCircledIcon className="text-green-500" /> : <Cross2Icon className="text-red-500" />}
        </div>
      );
    },
  },
  {
    accessorKey: 'daysOfWeek',
    header: 'Days of Week',
    cell: ({ row }) => {
      const value = row.getValue('daysOfWeek') as RowDef['daysOfWeek'];
      return (
        <div className="flex flex-wrap gap-1">
          {value?.map((day) => (
            <Badge variant={'secondary'} key={day}>
              {day}
            </Badge>
          ))}
        </div>
      );
    },
  },
  {
    accessorKey: 'capacity',
    header: ({ column }) => {
      return (
        <div className="text-right">
          <Button variant="ghost" onClick={() => column.toggleSorting(column.getIsSorted() === 'asc')}>
            Capacity
            <CaretSortIcon className="ml-2 h-4 w-4" />
          </Button>
        </div>
      );
    },
    cell: ({ row }) => {
      const value = row.getValue('capacity');
      const amount = typeof value === 'number' ? value : '-';
      return <div className="text-right font-medium pr-4">{amount}</div>;
    },
  },
  {
    id: 'actions',
    enableHiding: false,
    cell: ({ row }) => {
      const rowData = row.original;
      return (
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="ghost" className="h-8 w-8 p-0">
              <span className="sr-only">Open menu</span>
              <DotsHorizontalIcon className="h-4 w-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuLabel>Actions</DropdownMenuLabel>
            <DropdownMenuSeparator />
            <DropdownMenuItem onSelect={rowData.onEdit}>Edit</DropdownMenuItem>
            <DropdownMenuItem onSelect={rowData.onDelete}>Delete</DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      );
    },
  },
];

export function ExtraTable(props: {
  rows: Omit<RowDef, 'onEdit' | 'onDelete'>[];
  onAdd?: () => void;
  onEdit?: (id: string) => void;
  onDelete?: (ids: string[]) => Promise<void>;
}) {
  const { rows, onEdit, onDelete, onAdd } = props;

  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([]);
  const [columnVisibility, setColumnVisibility] = React.useState<VisibilityState>({});
  const [rowSelection, setRowSelection] = React.useState({});

  const data = React.useMemo(() => {
    return rows
      .sort((a, b) => {
        // sort by enabled first
        // then by name
        return a.enabled === b.enabled ? a.profile.name.localeCompare(b.profile.name) : a.enabled ? -1 : 1;
      })
      .map((row) => ({
        ...row,
        // convert any iso date numbers to strings
        daysOfWeek: row.daysOfWeek?.map((day) => (typeof day === 'number' ? WeekdayHelper.fromIsoWeekday(day) : day)),
        onEdit: () => onEdit?.(row.id),
        onDelete: () => onDelete?.([row.id]),
      }));
  }, [rows, onEdit, onDelete]);

  const table = useReactTable({
    data,
    columns,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      columnFilters,
      columnVisibility,
      rowSelection,
    },
  });

  const numSelected = table.getFilteredSelectedRowModel().rows.length;

  const handleDelete = React.useCallback(() => {
    const ids = table.getFilteredSelectedRowModel().rows.map((row) => row.original.id);
    void onDelete?.(ids)
      .then(() => {
        setRowSelection({});
      })
      .catch(() => {
        /** ignore */
      });
  }, [table, onDelete, setRowSelection]);

  return (
    <div className="w-full">
      <div className="flex items-end py-4 space-x-2">
        <div className="flex-1">
          <TypographyH3>Extras</TypographyH3>
          <TypographyP className="mt-2 hidden md:block">Create bookable addons for your office.</TypographyP>
        </div>
        {numSelected === 0 ? (
          <Button onClick={onAdd} variant="default">
            <PlusIcon className="h-4 w-4 mr-2" />
            Add New
          </Button>
        ) : (
          <Button onClick={handleDelete} variant="destructive">{`Delete ${numSelected} row(s)`}</Button>
        )}
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button variant="outline">
              Columns <ChevronDownIcon className="ml-2 h-4 w-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            {table
              .getAllColumns()
              .filter((column) => column.getCanHide())
              .map((column) => {
                return (
                  <DropdownMenuCheckboxItem
                    key={column.id}
                    className="capitalize"
                    checked={column.getIsVisible()}
                    onCheckedChange={(value) => column.toggleVisibility(!!value)}>
                    {column.id}
                  </DropdownMenuCheckboxItem>
                );
              })}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
      <div className="rounded-md border">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id}>
                      {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                    </TableHead>
                  );
                })}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow key={row.id} data-state={row.getIsSelected() && 'selected'}>
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length} className="h-24 text-center">
                  No results.
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
      <div className="flex items-center justify-end space-x-2 py-4">
        <div className="flex-1 text-sm text-muted-foreground">
          {table.getFilteredSelectedRowModel().rows.length} of {table.getFilteredRowModel().rows.length} row(s)
          selected.
        </div>
        <div className="space-x-2">
          <Button
            variant="outline"
            size="sm"
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}>
            Previous
          </Button>
          <Button variant="outline" size="sm" onClick={() => table.nextPage()} disabled={!table.getCanNextPage()}>
            Next
          </Button>
        </div>
      </div>
    </div>
  );
}

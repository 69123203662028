import { UserWorkStatusType, USER_WORK_STATUS_PROFILES, HRIS_STATUSES } from '../const';
import { BaseHelper } from './_base';

export class UserWorkStatusTypeHelper<T extends UserWorkStatusType> extends BaseHelper<T> {
  get isAwayDate(): boolean {
    return [
      UserWorkStatusType.ANNUAL_LEAVE,
      UserWorkStatusType.OFF_SICK,
      UserWorkStatusType.WORK_TRAVEL,
      UserWorkStatusType.OTHER_ABSENCE,
    ].includes(this.data);
  }

  get isNoStatus(): boolean {
    return this.data === UserWorkStatusType.NO_STATUS;
  }

  get isOffice(): boolean {
    return this.data === UserWorkStatusType.OFFICE;
  }

  get profile(): (typeof USER_WORK_STATUS_PROFILES)[T] {
    return USER_WORK_STATUS_PROFILES[this.data];
  }

  get summary(): string {
    switch (this.data) {
      case UserWorkStatusType.OFFICE:
        return `You're in the office`;
      case UserWorkStatusType.REMOTE:
        return `You're working from home`;
      case UserWorkStatusType.OFF_SICK:
        return `You're off sick`;
      case UserWorkStatusType.ANNUAL_LEAVE:
        return `You're on vacation`;
      case UserWorkStatusType.NON_WORKING_DAY:
        return `You're not working`;
      case UserWorkStatusType.WORK_TRAVEL:
        return `You're traveling for work`;
      case UserWorkStatusType.CLIENT_OFFICE:
        return `You're at a client office`;
      case UserWorkStatusType.OTHER_ABSENCE:
        return `You're away`;
      default:
        throw new Error('Unhandled user work status type');
    }
  }

  static isValidStatus(unknownStatus: string): boolean {
    return Object.values(UserWorkStatusType).includes(unknownStatus as UserWorkStatusType);
  }
}

import { Emoji } from '@client/components/ui/custom/emoji';
import { TypographyP } from '@client/components/ui/custom/typography-p';
import { Button } from '@client/components/ui/button';
import { emojis } from '@client/lib/emojis';
import { useCallback, useMemo, useState } from 'react';
import { Modal } from '@client/components/ui/custom/modal';
import { ModalLayout } from '@client/components/ui/custom/modal-layout';
import { OfficeBroadcastType } from '@officely/models';
import { StepComponentProps } from '@client/features/onboarding/types';
import { BroadcastForm, BroadcastFormValues } from '@client/features/settings/components/broadcast-form';

const CtaBtns = (props: StepComponentProps & { onConfigure: () => void; onUndo: () => void }) => {
  const { onNext, onBack, state, onConfigure, onUndo } = props;

  const hasSelection = state.broadcastChannelId !== undefined;

  return (
    <>
      <div className="mt-4">
        <Button variant="outline" onClick={onBack}>
          Back
        </Button>
        {hasSelection ? (
          <>
            <Button variant="outline" className="ml-2" onClick={onUndo}>
              Undo
            </Button>
            <Button variant="default" className="ml-2" onClick={onNext}>
              Next
            </Button>
          </>
        ) : (
          <Button variant="default" className="ml-2" onClick={onConfigure}>
            Configure
          </Button>
        )}
      </div>
      {!hasSelection && (
        <Button variant="link" className="mt-4 pl-0 text-muted-foreground" onClick={onNext}>
          Maybe Later
        </Button>
      )}
    </>
  );
};

export const OfficeBroadcast = (props: StepComponentProps) => {
  const { state, updateState } = props;
  const [modalOpen, setModalOpen] = useState(false);

  const handleChange = useCallback(
    (id: string | undefined) => {
      updateState({ broadcastChannelId: id });
    },
    [updateState]
  );

  const handleModalClose = useCallback(() => {
    setModalOpen(false);
  }, []);

  const handleSubmit = useCallback(
    (values: BroadcastFormValues) => {
      updateState({
        broadcastChannelId: values.channel,
        broadcastType: values.sendTime as OfficeBroadcastType,
      });
      setModalOpen(false);
    },
    [updateState]
  );

  const handleUndo = useCallback(() => {
    updateState({
      broadcastChannelId: undefined,
      broadcastType: undefined,
    });
  }, [updateState]);

  const data = useMemo<BroadcastFormValues>(() => {
    return {
      channel: state.broadcastChannelId,
      sendTime: state.broadcastType,
    };
  }, [state.broadcastChannelId, state.broadcastType]);

  return (
    <div>
      <TypographyP className="mt-2">
        Posts to a channel who's in the office each day along with any announcements. <i>(Highly recommended)</i>
      </TypographyP>
      <img
        className="mt-4 w-full max-w-[492px]"
        src="https://cdn.getofficely.com/assets/Office_Broadcast.png"
        alt="Office broadcast"
      />
      {/* <div className="mt-6">
        <SelectTeamChannel value={state.broadcastChannelId} onChange={handleChange} allowClear />
      </div> */}

      {state.broadcastChannelId && (
        <TypographyP className="mt-2 flex items-center mt-4 ml-2">
          <Emoji className="mr-2">{emojis.white_check_mark}</Emoji>
          <span className="italic">Enabled</span>
        </TypographyP>
      )}

      {/* <div className="max-w-96">
        <SelectTeamChannel value={undefined} onChange={() => {}} />
      </div> */}
      <CtaBtns {...props} onConfigure={() => setModalOpen(true)} onUndo={handleUndo} />
      {/* <Separator className="my-4" /> */}
      <Modal open={modalOpen} onClose={handleModalClose}>
        <ModalLayout
          title="Office Broadcast"
          closeText="Cancel"
          onClose={handleModalClose}
          submitText="Submit"
          submitFormId={BroadcastForm.id}>
          <BroadcastForm onSubmit={handleSubmit} data={data} />
        </ModalLayout>
      </Modal>
    </div>
  );
};

import { OfficeProfile, useDirectoryContext } from '@client/context/directory';
import { Office as OfficePrimitive } from '@client/components/ui/custom/office';
import { useMemo } from 'react';

type OfficePrimitiveProps = Parameters<typeof OfficePrimitive>[0];

type Props = Pick<OfficePrimitiveProps, 'className' | 'hideEmoji'> & {
  id: string;
  name?: string; // can pass in if already known
  emoji?: string; // can pass in if already known
  onClick?: (officeProfile: OfficeProfile) => void;
};

export const Office = (props: Props) => {
  const { id, onClick, ...propsForPrimitive } = props;
  const { offices } = useDirectoryContext();

  const officeProfile = useMemo(() => offices.data[id], [id, offices.data]);

  const handleClick = useMemo(() => {
    if (onClick) {
      return () => onClick(officeProfile);
    }
  }, [officeProfile, onClick]);

  return (
    <OfficePrimitive
      {...propsForPrimitive}
      name={officeProfile?.name}
      emoji={officeProfile?.emoji}
      onClick={handleClick}
    />
  );
};

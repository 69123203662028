export enum SettingsModalPage {
  Prefs = 'prefs',
  Offices = 'offices',
  Account = 'account',
  Notifications = 'notifications',
}

export const SETTINGS_MODAL_PAGE_PROFILES: Record<SettingsModalPage, { title: string }> = {
  [SettingsModalPage.Prefs]: {
    title: 'Your Preferences',
  },
  [SettingsModalPage.Offices]: {
    title: 'Offices',
  },
  [SettingsModalPage.Account]: {
    title: 'Account & Billing',
  },
  [SettingsModalPage.Notifications]: {
    title: 'Notifications',
  },
};

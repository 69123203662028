/* eslint-disable sonarjs/no-small-switch */
import { CommonPropsForModal } from '@client/lib/modal';
import { Emoji } from '@client/components/ui/custom/emoji';
import { TypographyMuted } from '@client/components/ui/custom/typography-muted';
import { TypographyP } from '@client/components/ui/custom/typography-p';
import { ModalLayout, ModalLayoutProps } from '@client/components/ui/custom/modal-layout';
import { QuoteBlock } from '@client/components/ui/custom/quote-block';
import { emojis } from '@client/lib/emojis';
import { trpc } from '@client/lib/trpc';
import { InviteType } from '@officely/models';
import { useCallback } from 'react';
import { OfficelyInviteForm } from '@client/features/app/components/officely-invite-form';
import { toast } from 'sonner';

const TITLE = 'Add Coworkers';
const DESCRIPTION = 'Send your coworkers an invite to Officely';

type Props = CommonPropsForModal;

export const OfficelyInviteModal = (props: Props) => {
  const { onDone, ...restForModal } = props;
  const inviteUsersMutation = trpc.general.inviteUsers.useMutation();
  const loading = inviteUsersMutation.isPending;

  const handleSubmit = useCallback(
    async ({ peopleIds }: { peopleIds: string[] }) => {
      const promise = inviteUsersMutation.mutateAsync({
        type: InviteType.Officely,
        peopleIds,
      });
      toast.promise(promise, {
        loading: 'Inviting users...',
        success: 'Users invited!',
        error: 'Failed to invite users',
      });
      await promise.then(onDone);
    },
    [inviteUsersMutation.mutateAsync, onDone]
  );

  const commonModalProps: ModalLayoutProps = {
    title: TITLE,
    description: DESCRIPTION,
    loading,
    submitFormId: OfficelyInviteForm.id,
    submitText: 'Invite',
    ...restForModal,
  };

  return (
    <ModalLayout {...commonModalProps}>
      <QuoteBlock>
        <TypographyP>
          Invite your coworkers to Officely, so that they can reserve a desk in the office and work alongside you
        </TypographyP>
      </QuoteBlock>
      <div className="mt-4">
        <OfficelyInviteForm onSubmit={handleSubmit} />
      </div>
      <TypographyMuted className="mt-2">
        <Emoji sm className="mr-2">
          {emojis.face_with_monocle}
        </Emoji>
        <span className="text-xs">Want a preview? Try sending yourself one first</span>
      </TypographyMuted>
    </ModalLayout>
  );
};

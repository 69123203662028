import { createFileRoute, useRouter } from '@tanstack/react-router';
import { CommonPropsForModal } from '@client/lib/modal';
import { useNavigate } from '@tanstack/react-router';
import { useCallback } from 'react';
import { z } from 'zod';
import { sleep } from '@client/lib/utils';
import { BillingModal } from '@client/features/billing/components/billing-modal';
import { SettingsBillingStep } from '@client/features/billing/const';

function HomeSettingsAccountBilling() {
  const navigate = useNavigate({ from: Route.fullPath });

  const router = useRouter();
  const { billingStep } = Route.useSearch();
  const loaderData = Route.useLoaderData();

  const handleClose = useCallback(() => {
    void navigate({
      to: '/home/settings/account',
      search: (prev) => ({
        ...prev,
        billingStep: undefined,
      }),
    });
  }, [navigate]);

  const handleChangeBillingStep = useCallback(
    (step: SettingsBillingStep | undefined) => {
      void navigate({
        to: '/home/settings/account/billing',
        search: (prev) => ({ ...prev, billingStep: step }),
      });
    },
    [navigate]
  );

  const handlePlanChanged = useCallback(() => {
    void sleep(1000).then(() => {
      void router.invalidate();
    });
  }, [router]);

  const propsForModal: CommonPropsForModal = {
    onClose: handleClose,
    nested: true,
  };

  return (
    <BillingModal
      {...propsForModal}
      {...loaderData}
      billingStep={billingStep}
      changeBillingStep={handleChangeBillingStep}
      onPlanChanged={handlePlanChanged}
    />
  );
}

export const Route = createFileRoute('/_authenticated/home/settings/account/billing')({
  component: HomeSettingsAccountBilling,
  validateSearch: z.object({
    billingStep: z.nativeEnum(SettingsBillingStep).optional().catch(undefined),
  }),
  loader: async ({ context }) => {
    const { user } = context.auth!;
    const account = await context.trpcUtils.account.current.fetch();
    const currentPlan = account.billing.plan;
    const isAccountAdmin = account.managers.includes(user.id);
    const customerId = account.billing.customerId ?? undefined;
    const userName = user.profile.name;
    return {
      currentPlan,
      isAccountAdmin,
      customerId,
      userName,
    };
  },
});

import { sleep } from '@client/lib/utils';

declare global {
  interface Window {
    Intercom: any;
  }
}

const HIDE_LAUNCH_PATHS = ['/dialog/', '/teams-dialog/', 'ngrok', 'localhost'];

export const intercomIdentify = (args: { name: string; email?: string }) => {
  const { name, email } = args;
  email &&
    window.Intercom?.('update', {
      user_id: email,
      name,
      email,
      platform: 'ms_teams',
    });
};

export const intercomLoad = async () => {
  // don't show the intercom launcher on certain paths
  if (HIDE_LAUNCH_PATHS.some((path) => window.location.href.includes(path))) {
    return;
  }

  await sleep(5000);

  // show the intercom launcher
  window.Intercom?.('update', {
    hide_default_launcher: false,
  });

  // adjust intercom z-index to 10
  // so that's it's not in front of toaster
  await sleep(1000);
  const intercom = window.document.querySelector<HTMLElement>('.intercom-lightweight-app');
  if (intercom) {
    intercom.style.zIndex = '10';
  }
};

export const intercomShow = () => {
  window.Intercom?.('show');
};

export const intercomHide = () => {
  window.Intercom?.('hide');
};

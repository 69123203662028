import { ExtraFormValues } from '@client/features/settings/components/extra-form';
import { confirm } from '@client/lib/confirm';
import { trpc } from '@client/lib/trpc';
import { Weekday, WeekdayHelper } from '@officely/models';
import pluralize from 'pluralize';
import { useCallback } from 'react';
import { toast } from 'sonner';

type Args = {
  officeId: string;
};

export const useExtraSettings = (args: Args) => {
  const { officeId } = args;

  const officeUpdateExtraMutation = trpc.office.updateExtra.useMutation();
  const officeDeleteExtrasMutation = trpc.office.deleteExtras.useMutation();
  const officeCreateExtraMutation = trpc.office.createExtra.useMutation();

  const createExtra = useCallback(
    async (values: ExtraFormValues) => {
      const promise = officeCreateExtraMutation.mutateAsync({
        officeId,
        data: {
          enabled: !!values.enabled,
          name: values.name,
          emoji: values.emoji,
          capacity: values.capacity,
          weekdays: values.daysOfWeek?.map((day) => new WeekdayHelper(day as Weekday).isoWeekday),
        },
      });
      toast.promise(promise, {
        loading: 'Creating extra...',
        success: 'Extra created',
        error: 'Failed to create extra',
      });
      await promise;
    },
    [officeId, officeCreateExtraMutation, toast]
  );

  const updateExtra = useCallback(
    async (id: string, values: ExtraFormValues) => {
      const promise = officeUpdateExtraMutation.mutateAsync({
        officeId,
        data: {
          id,
          name: values.name,
          emoji: values.emoji,
          capacity: values.capacity,
          weekdays: values.daysOfWeek?.map((day) => new WeekdayHelper(day as Weekday).isoWeekday),
          enabled: !!values.enabled,
        },
      });
      toast.promise(promise, {
        loading: 'Updating extra...',
        success: 'Extra updated',
        error: 'Failed to update extra',
      });
      await promise;
    },
    [officeId, officeUpdateExtraMutation, toast]
  );

  const deleteExtras = useCallback(
    async (ids: string[]) => {
      const confirmation = await confirm(`Are you sure you want to delete ${pluralize('extra', ids.length, true)}?`);
      if (!confirmation) {
        return Promise.reject('User cancelled');
      }
      const promise = officeDeleteExtrasMutation.mutateAsync({
        officeId,
        ids,
      });
      toast.promise(promise, {
        loading: 'Deleting extras...',
        success: 'Extras deleted',
        error: 'Failed to delete extras',
      });
      await promise;
    },
    [officeId, officeDeleteExtrasMutation, toast]
  );

  return {
    createExtra,
    updateExtra,
    deleteExtras,
  };
};

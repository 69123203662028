import { createFileRoute } from '@tanstack/react-router';
import { CommonPropsForModal } from '@client/lib/modal';
import { useTeams } from '@client/lib/useTeams';
import { Modal } from '@client/components/ui/custom/modal';
import { OfficelyInviteModal } from '@client/features/app/components/officely-invite-modal';

function DialogOfficelyInvite() {
  const { closeDialog } = useTeams();

  const props: CommonPropsForModal = {
    variant: 'msTeamsDialog',
    onClose: closeDialog,
    onDone: closeDialog,
  };

  return (
    <Modal open={true} onClose={closeDialog} isTeamsDialog={true}>
      <OfficelyInviteModal {...props} />
    </Modal>
  );
}

export const Route = createFileRoute('/_authenticated/dialog/officely-invite')({
  component: DialogOfficelyInvite,
});

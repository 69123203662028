import { Link } from '@client/components/ui/custom/link';

export const Mrkdwn = (props: { mrkdwn: string }) => {
  const { mrkdwn } = props;

  const tokens = mrkdwn.split(/(\*.*?\*|_.*?_|<.*?\|.*?>|>.*|\n)/g).filter(Boolean);
  // console.log(tokens, 'tokens');
  return (
    <>
      {tokens.map((token, i) => {
        if (token.startsWith('*')) {
          return (
            <span key={i} className="font-bold">
              {token.slice(1, -1)}
            </span>
          );
        }
        if (token.startsWith('_')) {
          return (
            <span key={i} className="italic">
              {token.slice(1, -1)}
            </span>
          );
        }
        if (token.startsWith('<')) {
          const [url, text] = token.slice(1, -1).split('|');
          return (
            <Link key={i} to={url} newTab>
              {text}
            </Link>
          );
        }
        if (token.startsWith('>')) {
          return (
            <blockquote
              key={i}
              className="pl-4 relative before:absolute before:left-0 before:top-0 before:bottom-0 before:w-[5px] before:bg-border before:rounded-md">
              {token.slice(1)}
            </blockquote>
          );
        }
        if (token === '\n') {
          return <br key={i} />;
        }

        return <span key={i}>{token}</span>;
      })}
    </>
  );
};

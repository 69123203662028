import { SimpleForm } from '@client/components/shared/simple-form';
import { ModalLayout, ModalLayoutProps } from '@client/components/ui/custom/modal-layout';
import { Select } from '@client/components/ui/custom/select';
import { NoteSuggestions } from '@client/features/booking/components/note-suggestions';
import { useBooking } from '@client/features/booking/hooks/use-booking';
import { useNoteSuggestions } from '@client/features/booking/hooks/use-note-suggestions';
import { emojis } from '@client/lib/emojis';
import { defineForm } from '@client/lib/form/helpers';
import { FormValues } from '@client/lib/form/types';
import { CommonPropsForModal } from '@client/lib/modal';
import { trpc } from '@client/lib/trpc';
import { BookingMethod, UserWorkStatusType } from '@officely/models';
import { useCallback, useMemo } from 'react';

type Props = CommonPropsForModal & {
  note?: string;
  date: string;
  type?: UserWorkStatusType;
  bookingMethod: BookingMethod;
};

const getNoteFormDef = (placeholder?: string) =>
  defineForm({
    id: 'notes-form',
    fields: {
      note: {
        type: 'text',
        label: `${emojis.lower_left_fountain_pen}   Note`,
        placeholder,
        showOptionalLabel: true,
      },
    },
  });

type NoteFormValues = FormValues<ReturnType<typeof getNoteFormDef>>;

export const NotesModal = (props: Props) => {
  const { date, onDone, bookingMethod, ...restForModal } = props;
  const workStatusGetQuery = trpc.workStatus.get.useQuery({ date });
  const { type, note } = workStatusGetQuery.data ?? {};
  const { updateNote } = useBooking();
  const { suggestions, notePlaceholder } = useNoteSuggestions(type);
  const noteFormDef = useMemo(() => getNoteFormDef(notePlaceholder), [notePlaceholder]);

  const data = useMemo(
    () => ({
      note: note ?? '',
    }),
    [note]
  );

  const handleSubmit = useCallback(
    async (values: NoteFormValues | string) => {
      const note = typeof values === 'string' ? values : values.note ?? '';
      await updateNote({
        date,
        note,
        bookingMethod,
      });
      onDone?.();
    },
    [onDone, updateNote]
  );

  const commonModalProps: ModalLayoutProps = {
    title: note ? 'Edit note' : 'Add a note',
    loading: workStatusGetQuery.isLoading,
    submitFormId: noteFormDef.id,
    submitText: 'Submit',
    closeText: props.nested ? 'Back' : 'Cancel',
    ...restForModal,
  };

  return (
    <ModalLayout {...commonModalProps}>
      <SimpleForm definition={noteFormDef} data={data} onSubmit={handleSubmit} />
      <NoteSuggestions suggestions={suggestions} onSuggestion={handleSubmit} />
    </ModalLayout>
  );
};

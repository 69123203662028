import { createFileRoute } from '@tanstack/react-router'
import { CommonPropsForModal } from '@client/lib/modal';
import { useNavigate } from '@tanstack/react-router';
import { useCallback } from 'react';
import { UserPrefsModal } from '@client/features/settings/components/user-prefs-modal';

function HomeSettingsPrefs() {
  const navigate = useNavigate({ from: Route.fullPath });
  const loaderData = Route.useLoaderData();

  const handleClose = useCallback(() => {
    void navigate({
      to: '/home/settings',
      search: true,
    });
  }, [navigate]);

  const propsForModal: CommonPropsForModal = {
    onClose: handleClose,
    nested: true,
  };

  return <UserPrefsModal {...propsForModal} {...loaderData} />;
}

export const Route = createFileRoute('/_authenticated/home/settings/prefs')({
  component: HomeSettingsPrefs,
  loader: async ({ context }) => {
    const user = await context.trpcUtils.user.current.fetch();
    const {
      officeIds: [officeId],
      favouriteCoworkerIds,
    } = user;

    return {
      officeId,
      favouriteCoworkerIds,
    };
  },
});

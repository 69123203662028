/* eslint-disable @typescript-eslint/naming-convention */
import { UserWorkStatusType } from '@officely/models';

export enum HomeView {
  Default = 'default',
  OfficeOnly = 'office-only',
  FavoritesOnly = 'favorites-only',
}

export enum SortMethod {
  NONE = 0,
  ALPHABETICAL = 1,
  RELEVANT_COWORKERS = 2,
}

export enum ScheduleViewType {
  Statuses = 'statuses',
  OfficeOnly = 'office-only',
}

export const HOME_VIEW_OPTIONS: Record<
  HomeView,
  {
    text: string;
    emoji: string;
  }
> = {
  [HomeView.Default]: { text: 'All statuses', emoji: '👥' },
  [HomeView.OfficeOnly]: { text: 'Office only', emoji: '🏢' },
  [HomeView.FavoritesOnly]: { text: 'Favorites only', emoji: '⭐️' },
};

export const SORTED_STATUS_TYPES = [
  UserWorkStatusType.NO_STATUS,
  UserWorkStatusType.OFFICE,
  UserWorkStatusType.REMOTE,
  UserWorkStatusType.WORK_TRAVEL,
  UserWorkStatusType.CLIENT_OFFICE,
  UserWorkStatusType.ANNUAL_LEAVE,
  UserWorkStatusType.OFF_SICK,
  UserWorkStatusType.NON_WORKING_DAY,
];

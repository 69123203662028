import { Emoji } from '@client/components/ui/custom/emoji';
import { ModalLayout, ModalLayoutProps } from '@client/components/ui/custom/modal-layout';
import { MultiSelect } from '@client/components/ui/custom/multi-select';
import { TypographyP } from '@client/components/ui/custom/typography-p';
import { Input } from '@client/components/ui/input';
import { Separator } from '@client/components/ui/separator';
import { AnnouncementCreateModal } from '@client/features/announcements/components/create-modal';
import { AnnouncementListModal } from '@client/features/announcements/components/list-modal';
import { NotesModal } from '@client/features/booking/components/notes-modal';
import { OfficeBookingModal } from '@client/features/booking/components/office-booking-modal';
import { DayActionsSelect } from '@client/features/home/components/day-actions-select';
import { DayNotices } from '@client/features/home/components/day-notices';
import { NbhHeading } from '@client/features/home/components/nbh-heading';
import { UserList } from '@client/features/home/components/users-list';
import { SortMethod } from '@client/features/home/const';
import { dayActionToHomeModal, getFiendlyDate, neighbourhoodSort, sortStatusType } from '@client/features/home/helpers';
import {
  DayActionType,
  DetailView,
  DetailViewStatus,
  DetailViewStatusNonOffice,
  DetailViewStatusOffice,
  HomeModalData,
  OnDayAction,
} from '@client/features/home/types';
import { CommonPropsForModal } from '@client/lib/modal';
import { trpc } from '@client/lib/trpc';
import { ANNOUNCEMENT_CATEGORY_PROFILES, BookingMethod } from '@officely/models';
import { DotsVerticalIcon } from '@radix-ui/react-icons';
import { SearchIcon } from 'lucide-react';
import pluralize from 'pluralize';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

const PERSON_TAG_VARIANT = 'tag-compressed';

type Props = CommonPropsForModal & {
  date: string;
  initialFilters?: string[];
  invalidateRouter?: () => void;
};

const OpenModal = (
  props: HomeModalData & {
    onClose: () => void;
    onDone: () => void;
  }
) => {
  const { type, onClose, onDone } = props;

  const commonModalProps = {
    nested: true,
    onClose,
    onDone,
  };

  if (type === 'announcements') {
    return <AnnouncementListModal {...commonModalProps} date={props.date} officeId={props.officeId} id={props.id} />;
  }
  if (type === 'create-announcement') {
    return <AnnouncementCreateModal {...commonModalProps} date={props.date} officeId={props.officeId} />;
  }
  if (type === 'notes') {
    return <NotesModal {...commonModalProps} date={props.date} bookingMethod={props.bookingMethod} />;
  }
  if (type === 'book') {
    return <OfficeBookingModal {...commonModalProps} date={props.date} officeId={props.officeId} />;
  }

  return null;
};

// ------- hooks -------

const useChildModal = (props: { invalidateRouter?: () => void }) => {
  const { invalidateRouter } = props;
  const [modalData, setModalData] = useState<HomeModalData | null>(null);

  const openModal = useCallback((args: HomeModalData) => {
    setModalData(args);
  }, []);

  const handleModalClose = useCallback(() => {
    setModalData(null);
  }, []);

  const handleModalDone = useCallback(async () => {
    handleModalClose();
    invalidateRouter?.();
  }, [handleModalClose, invalidateRouter]);

  return {
    modalData,
    openModal,
    onModalClose: handleModalClose,
    onModalDone: handleModalDone,
  };
};

const useAction = (props: { openModal: (args: HomeModalData) => void }) => {
  const { openModal } = props;

  const onDayAction = useCallback<OnDayAction>(
    async (payload) => {
      switch (payload.type) {
        case DayActionType.ChangeNote:
        case DayActionType.ModifyBooking:
        case DayActionType.ManageAnnouncements:
        case DayActionType.ChangeAwayDates:
        case DayActionType.CreateAnnouncement:
          const modalData = dayActionToHomeModal(payload);
          return openModal(modalData!);
      }
    },
    [openModal]
  );

  return onDayAction;
};

// ------- components -------

const Filters = (props: {
  search: string;
  filters: string[];
  onFilterChange: (filters: string[]) => void;
  onSearchChange: (search: string) => void;
  statuses: DetailView['statuses'];
}) => {
  const { statuses, filters, search, onFilterChange, onSearchChange } = props;

  const options = useMemo(() => {
    return Object.entries(statuses).map(([key, { isOffice, name, emoji, data }]) => ({
      value: key,
      label: `${emoji}  ${name}`,
      description: `${isOffice ? data.bookings.length : data.length}  ${pluralize('people', isOffice ? data.bookings.length : data.length, false)}`,
    }));
  }, [statuses]);

  const selected = useMemo(() => {
    return options.filter((option) => filters.includes(option.value));
  }, [filters, options]);

  const handleSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onSearchChange(e.target.value);
    },
    [onSearchChange]
  );

  return (
    <>
      {/* <div className="my-2 text-xs font-bold text-muted-foreground">Filters</div> */}
      <div className="flex flex-col gap-2">
        {/* <IconInput placeholder="Search" startIcon={SearchIcon} /> */}
        <Input
          value={search}
          className="focus-visible:ring-0"
          onChange={handleSearchChange}
          placeholder="Find..."
          startIcon={<SearchIcon size={16} />}
        />
        <MultiSelect
          optionsAsBtns
          optionsAsBtnsProps={{
            className: 'h-7',
          }}
          options={options}
          selected={selected}
          onChange={onFilterChange}
        />
      </div>
    </>
  );
};

const StatusRowHeading = (props: { emoji: string; name: string }) => {
  return (
    <div className="flex items-center gap-2">
      <Emoji lg>{props.emoji}</Emoji>
      <TypographyP className="font-bold text-lg">{props.name}</TypographyP>
    </div>
  );
};

const StatusRow = (
  props: Omit<DetailViewStatusNonOffice, 'isOffice'> & {
    date: string;
    onEditNote?: () => void;
  }
) => {
  const { emoji, name, data, onEditNote, date } = props;

  return (
    <div className="flex flex-col gap-2">
      <StatusRowHeading emoji={emoji} name={name} />
      <UserList
        date={date}
        list={data}
        minNumSingleItemRows={0}
        sort={SortMethod.ALPHABETICAL}
        total={data.length}
        onEditNote={onEditNote}
        personVariant={PERSON_TAG_VARIANT}
      />
    </div>
  );
};

const OfficeRow = (
  props: Omit<DetailViewStatusOffice, 'isOffice'> & {
    officeId: string;
    date: string;
    isOfficeAdmin: boolean;
    showNbhs?: boolean;
    onDayAction: OnDayAction;
    onEditNote?: () => void;
  }
) => {
  const { officeId, emoji, name, data, isOfficeAdmin, onDayAction, date, showNbhs, onEditNote } = props;
  const { bookings, announcements = [], bookedNbhs = [], bookedExtras = [] } = data;

  const handleAnnouncementClick = useCallback(
    (id: string) => {
      onDayAction({
        type: DayActionType.ManageAnnouncements,
        date,
        officeId,
        id,
      });
    },
    [date, onDayAction, officeId]
  );

  const booking = useMemo(() => {
    return bookings.find((booking) => booking.isSelf);
  }, [bookings]);

  const handleSelfClick = useMemo(() => {
    if (booking?.canModify) {
      return () =>
        onDayAction({
          type: DayActionType.ModifyBooking,
          date,
          officeId,
        });
    }
  }, [date, onDayAction, officeId, booking]);

  const renderedDayNotices = useMemo(() => {
    return announcements.length ? (
      <DayNotices
        className="pl-1"
        announcements={announcements}
        isOfficeAdmin={isOfficeAdmin}
        onAnnouncementClick={handleAnnouncementClick}
      />
    ) : null;
  }, [announcements]);

  const isBooked = useMemo(() => {
    return bookings.some((booking) => booking.isSelf);
  }, [bookings]);

  const sortedNbhs = useMemo(() => bookedNbhs.sort(neighbourhoodSort), [bookedNbhs]);
  const renderedRows = useMemo(() => {
    if (showNbhs) {
      return (
        <div className="flex flex-col gap-2">
          {sortedNbhs
            .map((nbh) => ({
              ...nbh,
              bookings: bookings.filter((booking) => booking.nbhId === nbh.id),
            }))
            .filter((row) => row.bookings.length)
            .map((row) => {
              return (
                <div key={row.id} className="col-span-1">
                  <NbhHeading {...row} className="mb-1" />
                  <UserList
                    list={row.bookings}
                    date={date}
                    minNumSingleItemRows={0}
                    onEditNote={onEditNote}
                    personVariant={PERSON_TAG_VARIANT}
                    // onSelfClick={handleSelfClick}
                  />
                </div>
              );
            })}
        </div>
      );
    } else {
      return (
        <UserList
          date={date}
          list={bookings}
          minNumSingleItemRows={0}
          sort={SortMethod.ALPHABETICAL}
          total={bookings.length}
          onEditNote={onEditNote}
          personVariant={PERSON_TAG_VARIANT}
          // onSelfClick={handleSelfClick}
        />
      );
    }
  }, [showNbhs, sortedNbhs, bookings, date, onEditNote]);

  return (
    <div className="flex flex-col gap-2">
      <div className="flex items-center justify-between">
        <StatusRowHeading emoji={emoji} name={name} />
        {(isBooked || isOfficeAdmin) && (
          <DayActionsSelect
            date={date}
            officeId={officeId}
            icon={DotsVerticalIcon}
            isAdmin={isOfficeAdmin}
            isBooked={isBooked}
            exclude={[DayActionType.SeeDetails]}
            announcementCount={announcements.length}
            onDayAction={onDayAction}
            selectProps={{
              popoverOpts: {
                align: 'end',
                side: 'bottom',
              },
            }}
          />
        )}
      </div>
      {renderedDayNotices}

      {renderedRows}
    </div>
  );
};

export const DetailsModal = (props: Props) => {
  const { date, onDone, invalidateRouter, ...restForModal } = props;

  const { modalData, openModal, onModalClose, onModalDone } = useChildModal({ invalidateRouter });
  const onDayAction = useAction({ openModal });

  const handleEditNote = useCallback(() => {
    onDayAction({
      type: DayActionType.ChangeNote,
      date,
    });
  }, [date, onDayAction]);

  const scheduleDetailQuery = trpc.schedule.detail.useQuery({ date });
  const { statuses = {}, officeAdminOf = [] } = scheduleDetailQuery.data ?? {};

  const [search, setSearch] = useState('');
  const [filters, setFilters] = useState(props.initialFilters ?? []);

  const filteredStatuses = useMemo(() => {
    return Object.entries(statuses)
      .map(([typeOrOfficeId, value]) => ({
        typeOrOfficeId,
        ...value,
      }))
      .sort((a, b) => sortStatusType(a.typeOrOfficeId, b.typeOrOfficeId))
      .filter(({ typeOrOfficeId }) => !filters.length || filters.includes(typeOrOfficeId))
      .map((row) => ({
        ...row,
        data: applySearchFilter(search, row.data),
      }))
      .filter(({ data }) => !isEmpty(data));
  }, [statuses, search, filters]);

  const showNbhs = useMemo(() => {
    // eslint-disable-next-line sonarjs/no-redundant-boolean
    return true || filteredStatuses.length === 1;
  }, [filteredStatuses]);

  const noResultsMsg = useMemo(() => {
    return filteredStatuses.length === 0 ? (
      <TypographyP className="text-center text-muted-foreground italic text-sm">No results</TypographyP>
    ) : null;
  }, [filteredStatuses]);

  const commonModalProps: ModalLayoutProps = {
    title: getFiendlyDate(date),
    loading: scheduleDetailQuery.isLoading,
    closeText: 'Close',
    ...restForModal,
  };

  if (modalData) {
    return <OpenModal {...modalData} onDone={onModalDone} onClose={onModalClose} />;
  }

  return (
    <ModalLayout {...commonModalProps}>
      <Filters
        search={search}
        statuses={statuses}
        filters={filters}
        onFilterChange={setFilters}
        onSearchChange={setSearch}
      />
      <div className="mt-6 flex flex-col gap-6 pb-6">
        {filteredStatuses.map((row, i) => {
          const { typeOrOfficeId, isOffice, data, ...rest } = row;
          return (
            <React.Fragment key={`${typeOrOfficeId}-${date}`}>
              {isOffice ? (
                <OfficeRow
                  officeId={typeOrOfficeId}
                  date={date}
                  showNbhs={showNbhs}
                  onDayAction={onDayAction}
                  onEditNote={handleEditNote}
                  isOfficeAdmin={officeAdminOf.includes(typeOrOfficeId)}
                  data={data as DetailViewStatusOffice['data']}
                  {...rest}
                />
              ) : (
                <StatusRow
                  date={date}
                  data={data as DetailViewStatusNonOffice['data']}
                  onEditNote={handleEditNote}
                  {...rest}
                />
              )}

              {i < filteredStatuses.length - 1 && <Separator />}
            </React.Fragment>
          );
        })}
        {noResultsMsg}
      </div>
    </ModalLayout>
  );
};

// ------- helpers -------

const applySearchFilter = (search: string, data: DetailViewStatus['data']): DetailViewStatus['data'] => {
  if (!search?.trim()) return data;
  if ('bookings' in data) {
    const searchLower = search.toLowerCase();
    return {
      ...data,
      bookings: data.bookings.filter((x) => x.name.toLowerCase().includes(searchLower)),
    };
  } else {
    return data.filter((x) => x.name.toLowerCase().includes(search.toLowerCase()));
  }
};

const isEmpty = (data: DetailViewStatus['data']) => {
  if ('bookings' in data) {
    return data.bookings.length === 0;
  } else {
    return data.length === 0;
  }
};
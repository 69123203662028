import { createFileRoute } from '@tanstack/react-router';
import { CommonPropsForModal } from '@client/lib/modal';
import { OnboardingFlow } from '@officely/models';
import { useNavigate } from '@tanstack/react-router';
import { useCallback } from 'react';
import { OfficesModal } from '@client/features/settings/components/offices-modal';

function HomeSettingsOffices() {
  const navigate = useNavigate({ from: Route.fullPath });

  const searchParams = Route.useSearch();
  const loaderData = Route.useLoaderData();

  const handleClose = useCallback(() => {
    void navigate({
      to: '/home/settings',
      search: true,
    });
  }, [navigate]);

  const handleOnEdit = useCallback(
    (id: string) => {
      void navigate({
        to: '/home/settings/offices/$id',
        params: { id },
        search: true,
      });
    },
    [navigate]
  );

  const handleOnCreate = useCallback(() => {
    void navigate({
      to: '/setup',
      search: {
        flow: OnboardingFlow.OfficeCreation,
        return: {
          path: '/home/settings/offices',
          search: searchParams,
        },
      },
    });
  }, [navigate, searchParams]);

  const propsForModal: CommonPropsForModal = {
    onClose: handleClose,
    nested: true,
  };

  return <OfficesModal {...propsForModal} {...loaderData} onEdit={handleOnEdit} onCreate={handleOnCreate} />;
}

export const Route = createFileRoute('/_authenticated/home/settings/offices')({
  component: HomeSettingsOffices,
  loader: async ({ context }) => {
    const { user } = context.auth!;
    const [account, offices] = await Promise.all([
      context.trpcUtils.account.current.fetch(),
      context.trpcUtils.office.listAll.fetch(),
    ]);
    const isAnyOfficeAdmin = offices.some((o) => o.managerIds.includes(user.id));
    const hasAccountAdmins = account.managers.length > 0;
    const isAccountAdmin = account.managers.some((m) => m === user.id);
    return {
      userId: user.id,
      isAccountAdmin,
      canCreate: isAnyOfficeAdmin || isAccountAdmin || !hasAccountAdmins,
      offices,
    };
  },
});

/* eslint-disable sonarjs/no-duplicate-string */
import { TypographyP } from '@client/components/ui/custom/typography-p';
import { Button } from '@client/components/ui/button';
import { useCallback, useMemo, useState } from 'react';
import pluralize from 'pluralize';

import _ from 'lodash';
import { Emoji } from '@client/components/ui/custom/emoji';

import { Badge } from '@client/components/ui/badge';

const HELP_ARTICLE_URL =
  'https://help.getofficely.com/en/articles/5213017-making-the-most-of-the-neighborhoods-and-desks';
const EMPTY_CATEGORY_NAME = 'Other';

import { Switch } from '@client/components/ui/switch';
import { sleep } from '@client/lib/utils';
import { Modal } from '@client/components/ui/custom/modal';
import { ExtraData, StepComponentProps } from '@client/features/onboarding/types';
import { SetupExtraModal } from '@client/features/onboarding/components/setup-extra-modal';
import { isoWeekdayToString } from '@client/lib/date';

const CtaBtns = (
  props: StepComponentProps & {
    onAddExtra: () => void;
  }
) => {
  const {
    state: { extras = [] },
    onAddExtra,
    onNext,
    onBack,
  } = props;

  const enabledExtrasCount = extras.filter((e) => e.enabled).length;

  if (enabledExtrasCount === 0) {
    return (
      <>
        <div className="mt-6">
          <Button variant="outline" onClick={onBack}>
            Back
          </Button>
          <Button variant="default" className="ml-2" onClick={onAddExtra}>
            Create My Own
          </Button>
        </div>
        <Button variant="link" className="mt-4 pl-0 text-muted-foreground" onClick={onNext}>
          Maybe Later
        </Button>
      </>
    );
  }

  return (
    <div className="mt-6">
      <Button variant="outline" onClick={onBack}>
        Back
      </Button>
      <Button variant="outline" className="ml-2" onClick={onAddExtra}>
        Create My Own
      </Button>
      <Button variant="default" className="ml-2" onClick={onNext}>
        Next
      </Button>
    </div>
  );
};

export const AddExtras = (props: StepComponentProps) => {
  const { state, updateState } = props;

  const [formConfig, setFormConfig] = useState<{
    open: boolean;
    index?: number;
  }>({
    open: false,
  });

  const handleFormClosed = useCallback(() => {
    setFormConfig({ ...formConfig, open: false });
  }, [formConfig]);

  const addNew = useCallback(() => {
    // pass in new ID for nbh
    setFormConfig({
      open: true,
    });
  }, []);

  const handleSubmit = useCallback(
    (data: ExtraData) => {
      if (typeof formConfig.index === 'number') {
        // update existing nbh in state
        updateState({
          extras: state.extras?.map((nbh, i) => (i === formConfig.index ? data : nbh)),
        });
      } else {
        // add new nbh to state
        updateState({
          extras: [...(state.extras ?? []), data],
        });
      }
      setFormConfig({ open: false });
    },
    [state.extras, updateState, formConfig.index]
  );

  const handleEdit = useCallback((index: number) => {
    setFormConfig({
      open: true,
      index,
    });
  }, []);

  const handleRemove = useCallback(
    (index: number) => {
      if (state.extras) {
        updateState({
          extras: state.extras.map((e, i) => ({
            ...e,
            enabled: i == index ? false : e.enabled,
          })),
        });
      }
    },
    [state.extras, updateState]
  );

  const handleToggle = useCallback(
    (index: number) => {
      if (state.extras) {
        updateState({
          extras: state.extras.map((e, i) => ({
            ...e,
            enabled: i == index ? !e.enabled : e.enabled,
          })),
        });
        // if enabled, fire onEdit
        if (!state.extras[index].enabled) {
          sleep(250).then(() => handleEdit(index));
        }
      }
    },
    [state.extras, updateState]
  );

  const editing = useMemo(() => {
    if (typeof formConfig.index === 'number') {
      return state.extras?.[formConfig.index];
    }
  }, [formConfig.index, state.extras]);

  return (
    <div className="mt-2">
      <TypographyP>
        Make coming to the office fun. Below are some of our favorite bookable extras. You can enable them now or later.
      </TypographyP>
      {!state.extras?.length ? null : (
        <ExtraList {...props} onEdit={handleEdit} onRemove={handleRemove} onToggle={handleToggle} />
      )}

      <CtaBtns {...props} onAddExtra={addNew} />

      <Modal open={formConfig.open} onClose={handleFormClosed}>
        <SetupExtraModal onSubmit={handleSubmit} existing={editing} onClose={handleFormClosed} />
      </Modal>

      {/* <ExtraForm
        title={editing?.defaultExtraId ? 'Enable This Extra' : undefined}
        onClose={handleFormClosed}
        open={formConfig.open}
        onSubmit={handleSubmit}
        data={editing}
      /> */}
    </div>
  );
};

const ExtraList = (
  props: StepComponentProps & {
    onToggle: (index: number) => void;
    onEdit: (index: number) => void;
    onRemove: (index: number) => void;
  }
) => {
  const { state, onEdit, onRemove, onToggle } = props;
  const { extras = [] } = state;

  const extrasWithIndex = useMemo(() => {
    return extras.map((x, i) => ({ ...x, index: i }));
  }, [extras]);

  return (
    <>
      {extrasWithIndex.map((extra) => (
        <div key={extra.index} className="my-2 flex border p-4 rounded-lg items-center">
          <div className="flex items-center flex-1">
            <div>
              <div className="flex items-center flex-1">
                <Emoji lg>{extra.emoji}</Emoji>
                <TypographyP>
                  {`  `}
                  <i>{extra.name}</i>
                </TypographyP>
              </div>
              <div className="flex items-center mt-2">
                {typeof extra.capacity === 'number' && (
                  <Badge variant={'outline'} className="self-center">
                    {pluralize('space', extra.capacity, true)}
                  </Badge>
                )}
                {!!extra.weekdays?.length && (
                  <Badge variant={'outline'} className="ml-2 self-center">
                    {extra.weekdays?.map((d) => isoWeekdayToString(d, 'Mon')).join(', ')}
                  </Badge>
                )}
              </div>
            </div>
          </div>
          <Switch className="" checked={extra.enabled} onCheckedChange={() => onToggle(extra.index)} />

          {/* <EditIcon tooltip="Edit" onClick={() => onEdit(extra.index)} asButton className="ml-2" />
          {!extra.defaultExtraId && <TrashIcon tooltip="Remove" onClick={() => onRemove(extra.index)} asButton />} */}
        </div>
      ))}
    </>
  );
};

import * as React from "react"

import { cn } from '@client/lib/utils';

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
}

const TEXT_ICON_PADDING = 8;
const ICON_WRAPPER_CLASS = `absolute top-1/2 transform -translate-y-1/2 text-muted-foreground`;

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, startIcon, endIcon, ...props }, ref) => {
    return (
      <div className="w-full relative">
        {startIcon && <div className={`${ICON_WRAPPER_CLASS} left-2`}>{startIcon}</div>}
        <input
          type={type}
          className={cn(
            'flex h-9 w-full rounded-md border border-input bg-transparent px-3 py-1 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:cursor-not-allowed disabled:opacity-50',
            startIcon ? `pl-${TEXT_ICON_PADDING}` : '',
            endIcon ? `pr-${TEXT_ICON_PADDING}` : '',
            className
          )}
          ref={ref}
          {...props}
        />
        {endIcon && <div className={`${ICON_WRAPPER_CLASS} right-2`}>{endIcon}</div>}
      </div>
    );
  }
);
Input.displayName = "Input"

export { Input }

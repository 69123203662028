import { createFileRoute, useRouteContext } from '@tanstack/react-router';
import { pages } from '@microsoft/teams-js';
import { useEffectOnce } from '@client/lib/useEffectOnce';
import { StarStruck } from '@client/components/ui/custom/svg/star-struck';
import { FullScreenCentered } from '@client/components/layout/FullScreenCentered';
import { TypographyH1 } from '@client/components/ui/custom/typography-h1';

function ConfigureComponent() {
  const { auth } = useRouteContext({
    from: '/configure',
  });

  useEffectOnce(async () => {
    pages.config.registerOnSaveHandler(async (saveEvent) => {
      const baseUrl = `https://${window.location.hostname}:${window.location.port}`;
      await pages.config.setConfig({ contentUrl: baseUrl });
      saveEvent.notifySuccess();
    });

    pages.config.setValidityState(true);
  });

  const name = auth?.user.profile.name;

  return (
    <FullScreenCentered>
      <StarStruck size={120} />
      <TypographyH1 className="mt-4">You're All Set</TypographyH1>
      <span className="text-lg font-bold mt-4">Hey {name ?? 'there'}, thanks for installing Officely!</span>
    </FullScreenCentered>
  );
}

export const Route = createFileRoute('/configure')({
  component: ConfigureComponent,
});

export function getInitials(name?: string): string {
  try {
    return (name ?? '')
      .trim()
      .replace(/\s+/g, ' ') // Replace multiple spaces with a single space
      .split(' ')
      .filter((part) => part) // Remove empty parts that might result from spaces
      .map((part) => part[0].toUpperCase()) // Take the first character of each part and make it uppercase
      .join('')
      .substring(0, 2); // Limit to two characters
  } catch (err) {
    return '';
  }
}

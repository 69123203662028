/* eslint-disable sonarjs/no-small-switch */
import { CommonPropsForModal } from '@client/lib/modal';
import { TypographyP } from '@client/components/ui/custom/typography-p';
import { ModalLayout, ModalLayoutProps } from '@client/components/ui/custom/modal-layout';
import { QuoteBlock } from '@client/components/ui/custom/quote-block';
import { trpc } from '@client/lib/trpc';
import { useCallback } from 'react';
import { emojis } from '@client/lib/emojis';
import { TypographyMuted } from '@client/components/ui/custom/typography-muted';
import { Emoji } from '@client/components/ui/custom/emoji';
import { Separator } from '@client/components/ui/separator';
import { FeedbackForm } from '@client/features/app/components/feedback-form';
import { toast } from 'sonner';

type Props = CommonPropsForModal & {
  userName: string;
};

export const FeedbackModal = (props: Props) => {
  const { userName, onDone, ...restForModal } = props;

  const sendFeedbackMutation = trpc.user.sendFeedback.useMutation();

  const handleSubmit = useCallback(
    async ({ feedback }: { feedback?: string }) => {
      const promise = sendFeedbackMutation.mutateAsync({
        feedback: feedback ?? '',
      });
      toast.promise(promise, {
        loading: 'Sending feedback...',
        success: 'Feedback sent!',
        error: 'Failed to send feedback',
      });
      await promise.then(onDone);
    },
    [sendFeedbackMutation.mutateAsync, onDone]
  );

  const commonModalProps: ModalLayoutProps = {
    title: 'Send us feedback',
    description: `Hey ${userName}, what would you like to share?`,
    loading: sendFeedbackMutation.isPending,
    submitFormId: FeedbackForm.id,
    submitText: 'Send',
    closeText: 'Cancel',
    ...restForModal,
  };

  return (
    <ModalLayout {...commonModalProps}>
      <QuoteBlock className="mb-4">
        <TypographyP>Hey {userName}, what would you like to share?</TypographyP>
      </QuoteBlock>
      <FeedbackForm onSubmit={handleSubmit} />
      <div className="flex items-center gap-2 mt-1">
        {[
          [emojis.gem, 'New features'],
          [emojis.lady_bug, 'Bugs'],
          [emojis.yellow_heart, 'Love'],
          [emojis.cold_sweat, 'Not so much love'],
        ].map(([emoji, text]) => (
          <div key={text} className="flex items-center gap-1">
            <Emoji sm>{emoji}</Emoji>
            <TypographyMuted className="text-xs italic">{text}</TypographyMuted>
          </div>
        ))}
      </div>
      <Separator className="my-4" />
      <div className="text-sm italic text-muted-foreground">
        <TypographyP className="font-bold">A note from the Team:</TypographyP>
        <QuoteBlock sm className="mt-2">
          <span className="italic text-sm">
            However big or small, we'd love to hear it. Often it's the tiny details that make the difference between a
            great product and a bad one. If there's something missing or bothering you, let us know and we'll jump right
            on it!
          </span>
        </QuoteBlock>
      </div>
    </ModalLayout>
  );
};

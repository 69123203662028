import { ErrorLayout } from '@client/components/layout/ErrorLayout';
import { createRootRouteWithContext, Outlet } from '@tanstack/react-router';
import { trpc } from '@client/lib/trpc';
import { FrontendTeamsAppContext } from '@client/lib/useTeams';
import { AuthStateAuthenticatedHealthy } from '@server/types';
import React from 'react';

export interface RouterContext {
  // The ReturnType of your useAuth hook or the value of your AuthContext
  trpcUtils: ReturnType<typeof trpc.useUtils>;
  auth: AuthStateAuthenticatedHealthy | undefined;
  appId: string | undefined;
  teamsContext: FrontendTeamsAppContext | undefined;
  authErrors?: Array<'missing_team_install' | 'unauthenticated' | 'missing_admin_consent'>;
}

// @ts-ignore
const TanStackRouterDevtools = import.meta.env.PROD
  ? () => null // Render nothing in production
  : React.lazy(() =>
      // Lazy load in development
      import('@tanstack/router-devtools').then((res) => ({
        default: res.TanStackRouterDevtools,
      }))
    );

export const Route = createRootRouteWithContext<RouterContext>()({
  errorComponent: ({ error }) => <ErrorLayout variant="unknown" error={error} />,
  component: () => {
    return (
      <>
        <Outlet />
        {/* <React.Suspense>
          <TanStackRouterDevtools />
        </React.Suspense> */}
      </>
    );
  },
});

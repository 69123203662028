/* eslint-disable sonarjs/no-small-switch */

import { CommonPropsForModal } from '@client/lib/modal';
import { ModalLayout, ModalLayoutProps } from '@client/components/ui/custom/modal-layout';
import { PropsWithChildren, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { TypographyP } from '@client/components/ui/custom/typography-p';
import { BillingPlan, BillingPlanHelper, UserWorkStatusType } from '@officely/models';
import { Emoji } from '@client/components/ui/custom/emoji';
import { emojis } from '@client/lib/emojis';
import { Button } from '@client/components/ui/button';
import { Separator } from '@client/components/ui/separator';
import { ButtonGroup } from '@client/components/ui/custom/button-group';
import { useAccountSettings } from '@client/features/settings/hooks/use-account-settings';
import { AccountForm, AccountFormValues } from '@client/features/settings/components/account-form';
import { useEagerLoadOfficelyUsers } from '@client/context/directory';
import { cn } from '@client/lib/utils';
import { TextAndEmojis } from '@client/components/ui/custom/text-and-emojis';
import {
  AccountStatusesForm,
  AccountStatusesFormValues,
  POSSIBLE_DISABLED_STATUSES,
} from '@client/features/settings/components/account-statuses-form';
import { confirm } from '@client/lib/confirm';

const TITLE = 'Account & Billing';
const DESCRIPTION = 'Manage your organization and billing settings.';

type Props = CommonPropsForModal & {
  isAccountAdmin: boolean;
  billingPlan: BillingPlan;
  adminIds: string[];
  statusesEnabled: boolean;
  disabledStatuses: UserWorkStatusType[];
  onViewPlans: () => void;
  invalidateRouter?: () => void;
};

const PaywalledFeature = (
  props: PropsWithChildren<{
    disabled: boolean;
    text: string;
  }>
) => {
  const { disabled, text } = props;
  const ref = useRef<HTMLDivElement>(null);

  //  this is to make sure that the inputs are not tabbable when the feature is disabled
  useEffect(() => {
    const allInputs = ref.current?.querySelectorAll('input, button');
    if (disabled) {
      // find all inputs and buttons within the container and set their tab index to -1
      if (allInputs) {
        allInputs.forEach((input) => {
          input.setAttribute('tabindex', '-1');
        });
      }
    } else {
      // find all inputs and buttons within the container and set their tab index to 0
      if (allInputs) {
        allInputs.forEach((input) => {
          input.setAttribute('tabindex', '0');
        });
      }
    }
  }, [disabled]);

  return (
    <div className={cn('relative')} ref={ref}>
      <div className={cn(disabled ? 'blur-sm pointer-events-none' : '')}>{props.children}</div>
      {disabled && (
        <div className="absolute top-0 right-0 h-full w-full text-sm text-muted-foreground flex items-center justify-center font-bold">
          <TextAndEmojis italic convertEmojis>
            {text}
          </TextAndEmojis>
        </div>
      )}
    </div>
  );
};

export const AccountModal = (props: Props) => {
  const {
    isAccountAdmin,
    billingPlan,
    onViewPlans,
    adminIds,
    invalidateRouter,
    disabledStatuses,
    statusesEnabled,
    ...restForModal
  } = props;

  const [accountFormDirty, setAccountFormDirty] = useState(false);
  const [accountStatusesFormDirty, setAccountStatusesFormDirty] = useState(false);
  const { peopleIds: accountAdminPeopleIds } = useEagerLoadOfficelyUsers(adminIds);
  const { updateAccountAdmins, updateDisabledStatuses } = useAccountSettings();

  const handleAccountFormSubmit = useCallback(
    (values: AccountFormValues) => {
      const { admins } = values;
      if (admins) {
        void updateAccountAdmins(admins);
      }
    },
    [updateAccountAdmins]
  );

  const handleAccountStatusesFormSubmit = useCallback(
    async (values: AccountStatusesFormValues) => {
      const { enabled } = values;
      if (!enabled) {
        const result = await confirm('Are you sure you want to hide all statuses? Only Office will remain.', {
          title: 'Hide All Statuses',
          ctaText: 'Yes',
          cancelText: 'No',
        });
        if (!result) {
          return;
        }
      }

      await setAccountStatusesFormDirty(false);

      const disabledStatuses = !enabled ? POSSIBLE_DISABLED_STATUSES : values.disabledStatuses ?? [];
      const success = await updateDisabledStatuses(disabledStatuses as UserWorkStatusType[]);

      if (success) {
        invalidateRouter?.();
      }
    },
    [invalidateRouter, updateDisabledStatuses]
  );

  const commonModalProps: ModalLayoutProps = {
    title: TITLE,
    description: DESCRIPTION,
    closeText: props.nested ? 'Back' : 'Close',
    ...restForModal,
  };

  const billingPlanHelper = new BillingPlanHelper(billingPlan);
  const canManagePlans = isAccountAdmin || billingPlanHelper.isFreePlan();

  const accountAdminsFormData = useMemo(() => {
    return {
      admins: accountAdminPeopleIds,
    };
  }, [accountAdminPeopleIds]);

  const disabledStatusesFormData = useMemo<AccountStatusesFormValues>(() => {
    return {
      enabled: statusesEnabled,
      disabledStatuses,
    };
  }, [disabledStatuses, statusesEnabled]);

  return (
    <ModalLayout {...commonModalProps}>
      <TypographyP className="font-bold">Your Plan</TypographyP>
      <TypographyP className="flex items-center flex-grow">
        <Emoji lg className="mr-2">
          {emojis.card}
        </Emoji>
        {billingPlanHelper.config.name}
        {billingPlanHelper.isAnnualPlan() && <span className="italic ml-2">(Annual plan)</span>}
      </TypographyP>
      {canManagePlans && (
        <Button variant="outline" className="mt-2" onClick={onViewPlans}>
          Change / View Plans
        </Button>
      )}

      <Separator className="my-4" />

      <div>
        <TypographyP className="font-bold">Statuses</TypographyP>
        <div className="mt-2">
          <AccountStatusesForm
            onSubmit={handleAccountStatusesFormSubmit}
            onDirty={setAccountStatusesFormDirty}
            data={disabledStatusesFormData}
          />
          {accountStatusesFormDirty && (
            <ButtonGroup>
              <Button type="submit" form={AccountStatusesForm.id}>
                Update
              </Button>
            </ButtonGroup>
          )}
        </div>
      </div>

      <Separator className="my-4" />

      <PaywalledFeature disabled={!isAccountAdmin} text={`${emojis.old_key} Upgrade to manage Account Admins`}>
        <>
          <AccountForm data={accountAdminsFormData} onSubmit={handleAccountFormSubmit} onDirty={setAccountFormDirty} />
          {accountFormDirty && (
            <ButtonGroup>
              <Button type="submit" form={AccountForm.id}>
                Update
              </Button>
            </ButtonGroup>
          )}
        </>
      </PaywalledFeature>
    </ModalLayout>
  );
};

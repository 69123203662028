import _ from 'lodash';
import { useEffectOnce } from '@client/lib/useEffectOnce';
import { app, authentication } from '@microsoft/teams-js';
import { useState } from 'react';
import { getTimezone } from '@client/lib/utils';
import { TrpcCustomHeader } from '@server/const';
import { TrpcCustomHeaders } from '@server/types';

export const getTrpcCustomHeaders = async (): Promise<TrpcCustomHeaders> => {
  try {
    // return {
    //   [TrpcCustomHeader.TestMode]: 'true',
    //   [TrpcCustomHeader.Timezone]: getTimezone(),
    // };
    await app.initialize(); // we have to include this to get it to throw when in test mode
    app.notifyAppLoaded();
    app.notifySuccess();
    const [ssoToken, context] = await Promise.all([authentication.getAuthToken(), app.getContext()]);
    const teamId = context.team?.groupId;
    return _.omitBy(
      {
        [TrpcCustomHeader.SsoToken]: ssoToken,
        [TrpcCustomHeader.TeamId]: teamId,
        [TrpcCustomHeader.Timezone]: getTimezone(),
      },
      _.isUndefined
    ) as TrpcCustomHeaders;
  } catch (_err) {
    const err = _err as Error;
    // alert(err.message);
    // we're probably accessing teams in a web browser.
    // Only throw if env is not development or we have demo mode enabled in query string
    const demoMode = resolveDemoMode();
    // @ts-ignore
    if (import.meta.env.PROD && !demoMode) {
      console.warn('You need to be in Microsoft Teams to access this app.');
      return {
        [TrpcCustomHeader.Timezone]: getTimezone(),
      };
    } else {
      return {
        [TrpcCustomHeader.TestMode]: 'true',
        [TrpcCustomHeader.Timezone]: getTimezone(),
      };
    }
  }
};

export const useLoadMsTeamsApp = () => {
  const [loading, setLoading] = useState(true);

  useEffectOnce(async () => {
    try {
      await app.initialize();
      app.notifyAppLoaded();
      app.notifySuccess();
    } catch (err) {
      if (process.env.NODE_ENV !== 'development') {
        throw new Error('Not in MS Teams');
      } else {
        console.log('In TEST MODE');
      }
    } finally {
      setLoading(false);
    }
  });

  return { loading };
};

export const isDemoMode = () => {
  return window.location.hostname.includes('localhost') || localStorage.getItem('demoMode') === 'true';
};

function resolveDemoMode(): boolean {
  let demoMode = false;
  const demoModeFromQuery = new URLSearchParams(window.location.search).get('demo');
  const demoModeFromLocalStorage = localStorage.getItem('demoMode');

  if (demoModeFromQuery === 'false') {
    // remove from local storage
    localStorage.removeItem('demoMode');
  } else {
    demoMode = demoModeFromQuery === 'true' || demoModeFromLocalStorage === 'true';
  }

  if (demoMode) {
    // write to local storage so that it doesn't matter if we loose the query string
    localStorage.setItem('demoMode', 'true');
  }

  return demoMode;
}
import * as React from 'react';

import { MapPin, Loader2 } from 'lucide-react';

import { Command, CommandEmpty, CommandInput, CommandItem } from '@client/components/ui/command';
import { CommandList } from 'cmdk';
import _ from 'lodash';

type Result = {
  googlePlaceId: string;
  address: string;
};

type Props = {
  searchTerm?: string;
  results?: Result[];
  isLoading?: boolean;
  onSearch?: (value: string) => void;
  onSelect?: (value: string) => void;
};

export function AddressSearch(props: Props) {
  const onChange = React.useMemo(() => _.debounce((e) => props.onSearch?.(e.target.value), 250), [props.onSearch]);
  return (
    <Command className="rounded-lg border shadow-md" onChange={onChange} shouldFilter={false}>
      <CommandInput placeholder="Search office address..." />
      <CommandList>
        <CommandEmpty>No results found.</CommandEmpty>
        {props.results?.map((result) => (
          <CommandItem key={result.googlePlaceId} onSelect={() => props.onSelect?.(result.googlePlaceId)}>
            <MapPin className="mr-2 h-4 w-4 text-muted-foreground" />
            <span>{_.truncate(result.address, { length: 50 })}</span>
            {props.isLoading && <Loader2 className="ml-2 h-4 w-4 text-muted-foreground" />}
          </CommandItem>
        ))}
      </CommandList>
    </Command>
  );
}

import { Person as PersonType, useDirectoryContext } from '@client/context/directory';
import { Person as PersonPrimitive } from '@client/components/ui/custom/person';
import { useMemo } from 'react';

type PersonPrimitiveProps = Parameters<typeof PersonPrimitive>[0];

type Props = Pick<PersonPrimitiveProps, 'className' | 'variant' | 'isSelf'> & {
  id?: string;
  userId?: string;
  name?: string;
  onClick?: (person: PersonType) => void;
};

export const Person = (props: Props) => {
  const { id, userId, onClick, ...propsForPrimitive } = props;
  const { people } = useDirectoryContext();

  if (!id && !userId) {
    throw new Error('User component requires either msUserId or userId');
  }

  const personId = useMemo(() => id ?? people.byUserId[userId!], [id, userId, people.byUserId]);
  const person = useMemo(() => people.data[personId], [personId, people.data]);
  const userName = useMemo(() => person?.name ?? props.name, [person, props.name]);

  const handleClick = useMemo(() => {
    if (onClick) {
      return () => onClick(person);
    }
  }, [person, onClick]);

  return (
    <PersonPrimitive
      {...propsForPrimitive}
      name={userName}
      email={person?.email}
      avatarUrl={person?.avatar}
      isSelf={person?.id === people.currentPerson.id}
      onClick={handleClick}
    />
  );
};

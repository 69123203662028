import { cn } from '@client/lib/utils';

export const Locked = (props: { size?: number; className?: string }) => {
  const { size = 32 } = props;
  return (
    <svg
      className={cn(`min-w-[${size}px]`, props.className)}
      width={size.toFixed(0)}
      height={size.toFixed(0)}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.57812 8.57812C9.57812 5.04868 12.4393 2.1875 15.9688 2.1875C19.4982 2.1875 22.3594 5.04868 22.3594 8.57812V14.1406H19.6094V8.57812C19.6094 6.56746 17.9794 4.9375 15.9688 4.9375C13.9581 4.9375 12.3281 6.56746 12.3281 8.57812V14.1406H9.57812V8.57812Z"
        fill="#C2C2C6"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.57812 8.57812C9.57812 5.04868 12.4393 2.1875 15.9688 2.1875C19.4982 2.1875 22.3594 5.04868 22.3594 8.57812V14.1406H19.6094V8.57812C19.6094 6.56746 17.9794 4.9375 15.9688 4.9375C13.9581 4.9375 12.3281 6.56746 12.3281 8.57812V14.1406H9.57812V8.57812Z"
        fill="url(#paint0_linear_730_66522)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.57812 8.57812C9.57812 5.04868 12.4393 2.1875 15.9688 2.1875C19.4982 2.1875 22.3594 5.04868 22.3594 8.57812V14.1406H19.6094V8.57812C19.6094 6.56746 17.9794 4.9375 15.9688 4.9375C13.9581 4.9375 12.3281 6.56746 12.3281 8.57812V14.1406H9.57812V8.57812Z"
        fill="url(#paint1_linear_730_66522)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.57812 8.57812C9.57812 5.04868 12.4393 2.1875 15.9688 2.1875C19.4982 2.1875 22.3594 5.04868 22.3594 8.57812V14.1406H19.6094V8.57812C19.6094 6.56746 17.9794 4.9375 15.9688 4.9375C13.9581 4.9375 12.3281 6.56746 12.3281 8.57812V14.1406H9.57812V8.57812Z"
        fill="url(#paint2_linear_730_66522)"
        fillOpacity="0.39"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.57812 8.57812C9.57812 5.04868 12.4393 2.1875 15.9688 2.1875C19.4982 2.1875 22.3594 5.04868 22.3594 8.57812V14.1406H19.6094V8.57812C19.6094 6.56746 17.9794 4.9375 15.9688 4.9375C13.9581 4.9375 12.3281 6.56746 12.3281 8.57812V14.1406H9.57812V8.57812Z"
        fill="url(#paint3_radial_730_66522)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.57812 8.57812C9.57812 5.04868 12.4393 2.1875 15.9688 2.1875C19.4982 2.1875 22.3594 5.04868 22.3594 8.57812V14.1406H19.6094V8.57812C19.6094 6.56746 17.9794 4.9375 15.9688 4.9375C13.9581 4.9375 12.3281 6.56746 12.3281 8.57812V14.1406H9.57812V8.57812Z"
        fill="url(#paint4_radial_730_66522)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.57812 8.57812C9.57812 5.04868 12.4393 2.1875 15.9688 2.1875C19.4982 2.1875 22.3594 5.04868 22.3594 8.57812V14.1406H19.6094V8.57812C19.6094 6.56746 17.9794 4.9375 15.9688 4.9375C13.9581 4.9375 12.3281 6.56746 12.3281 8.57812V14.1406H9.57812V8.57812Z"
        fill="url(#paint5_radial_730_66522)"
        fillOpacity="0.3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.57812 8.57812C9.57812 5.04868 12.4393 2.1875 15.9688 2.1875C19.4982 2.1875 22.3594 5.04868 22.3594 8.57812V14.1406H19.6094V8.57812C19.6094 6.56746 17.9794 4.9375 15.9688 4.9375C13.9581 4.9375 12.3281 6.56746 12.3281 8.57812V14.1406H9.57812V8.57812Z"
        fill="url(#paint6_radial_730_66522)"
        fillOpacity="0.3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.57812 8.57812C9.57812 5.04868 12.4393 2.1875 15.9688 2.1875C19.4982 2.1875 22.3594 5.04868 22.3594 8.57812V14.1406H19.6094V8.57812C19.6094 6.56746 17.9794 4.9375 15.9688 4.9375C13.9581 4.9375 12.3281 6.56746 12.3281 8.57812V14.1406H9.57812V8.57812Z"
        fill="url(#paint7_radial_730_66522)"
        fillOpacity="0.3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.57812 8.57812C9.57812 5.04868 12.4393 2.1875 15.9688 2.1875C19.4982 2.1875 22.3594 5.04868 22.3594 8.57812V14.1406H19.6094V8.57812C19.6094 6.56746 17.9794 4.9375 15.9688 4.9375C13.9581 4.9375 12.3281 6.56746 12.3281 8.57812V14.1406H9.57812V8.57812Z"
        fill="url(#paint8_radial_730_66522)"
        fillOpacity="0.3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.57812 8.57812C9.57812 5.04868 12.4393 2.1875 15.9688 2.1875C19.4982 2.1875 22.3594 5.04868 22.3594 8.57812V14.1406H19.6094V8.57812C19.6094 6.56746 17.9794 4.9375 15.9688 4.9375C13.9581 4.9375 12.3281 6.56746 12.3281 8.57812V14.1406H9.57812V8.57812Z"
        fill="url(#paint9_radial_730_66522)"
        fillOpacity="0.3"
      />
      <rect x="5.03125" y="11.125" width="21.9375" height="18.8438" rx="3" fill="url(#paint10_linear_730_66522)" />
      <rect x="5.03125" y="11.125" width="21.9375" height="18.8438" rx="3" fill="url(#paint11_radial_730_66522)" />
      <rect x="5.03125" y="11.125" width="21.9375" height="18.8438" rx="3" fill="url(#paint12_linear_730_66522)" />
      <rect x="5.03125" y="11.125" width="21.9375" height="18.8438" rx="3" fill="url(#paint13_linear_730_66522)" />
      <rect x="5.03125" y="11.125" width="21.9375" height="18.8438" rx="3" fill="url(#paint14_linear_730_66522)" />
      <rect x="5.03125" y="11.125" width="21.9375" height="18.8438" rx="3" fill="url(#paint15_linear_730_66522)" />
      <rect x="5.03125" y="11.125" width="21.9375" height="18.8438" rx="3" fill="url(#paint16_linear_730_66522)" />
      <rect x="5.03125" y="11.125" width="21.9375" height="18.8438" rx="3" fill="url(#paint17_linear_730_66522)" />
      <rect x="5.03125" y="11.125" width="21.9375" height="18.8438" rx="3" fill="url(#paint18_linear_730_66522)" />
      <rect x="5.03125" y="11.125" width="21.9375" height="18.8438" rx="3" fill="url(#paint19_radial_730_66522)" />
      <rect x="5.03125" y="11.125" width="21.9375" height="18.8438" rx="3" fill="url(#paint20_linear_730_66522)" />
      <rect x="5.03125" y="11.125" width="21.9375" height="18.8438" rx="3" fill="url(#paint21_linear_730_66522)" />
      <rect x="5.03125" y="11.125" width="21.9375" height="18.8438" rx="3" fill="url(#paint22_linear_730_66522)" />
      <rect x="5.03125" y="11.125" width="21.9375" height="18.8438" rx="3" fill="url(#paint23_linear_730_66522)" />
      <rect x="5.03125" y="11.125" width="21.9375" height="18.8438" rx="3" fill="url(#paint24_linear_730_66522)" />
      <rect x="5.03125" y="11.125" width="21.9375" height="18.8438" rx="3" fill="url(#paint25_linear_730_66522)" />
      <rect x="5.03125" y="11.125" width="21.9375" height="18.8438" rx="3" fill="url(#paint26_linear_730_66522)" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.9863 17.6621C15.6508 17.6621 15.3789 17.9341 15.3789 18.2695C15.3789 18.4843 15.4889 18.6748 15.6652 18.7856L15.9908 18.99L16.3138 18.7815C16.4865 18.67 16.5938 18.4817 16.5938 18.2695C16.5938 17.9341 16.3218 17.6621 15.9863 17.6621ZM11.3789 18.2695C11.3789 15.7249 13.4417 13.6621 15.9863 13.6621C18.531 13.6621 20.5938 15.7249 20.5938 18.2695C20.5938 19.4624 20.1396 20.5486 19.3984 21.3653V23.7735C19.3984 25.6504 17.8769 27.1719 16 27.1719C14.1231 27.1719 12.6016 25.6504 12.6016 23.7735V21.3952C11.8442 20.5751 11.3789 19.4771 11.3789 18.2695Z"
        fill="url(#paint27_radial_730_66522)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.9863 17.6621C15.6508 17.6621 15.3789 17.9341 15.3789 18.2695C15.3789 18.4843 15.4889 18.6748 15.6652 18.7856L15.9908 18.99L16.3138 18.7815C16.4865 18.67 16.5938 18.4817 16.5938 18.2695C16.5938 17.9341 16.3218 17.6621 15.9863 17.6621ZM11.3789 18.2695C11.3789 15.7249 13.4417 13.6621 15.9863 13.6621C18.531 13.6621 20.5938 15.7249 20.5938 18.2695C20.5938 19.4624 20.1396 20.5486 19.3984 21.3653V23.7735C19.3984 25.6504 17.8769 27.1719 16 27.1719C14.1231 27.1719 12.6016 25.6504 12.6016 23.7735V21.3952C11.8442 20.5751 11.3789 19.4771 11.3789 18.2695Z"
        fill="url(#paint28_radial_730_66522)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.9863 17.6621C15.6508 17.6621 15.3789 17.9341 15.3789 18.2695C15.3789 18.4843 15.4889 18.6748 15.6652 18.7856L15.9908 18.99L16.3138 18.7815C16.4865 18.67 16.5938 18.4817 16.5938 18.2695C16.5938 17.9341 16.3218 17.6621 15.9863 17.6621ZM11.3789 18.2695C11.3789 15.7249 13.4417 13.6621 15.9863 13.6621C18.531 13.6621 20.5938 15.7249 20.5938 18.2695C20.5938 19.4624 20.1396 20.5486 19.3984 21.3653V23.7735C19.3984 25.6504 17.8769 27.1719 16 27.1719C14.1231 27.1719 12.6016 25.6504 12.6016 23.7735V21.3952C11.8442 20.5751 11.3789 19.4771 11.3789 18.2695Z"
        fill="url(#paint29_radial_730_66522)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.9863 17.6621C15.6508 17.6621 15.3789 17.9341 15.3789 18.2695C15.3789 18.4843 15.4889 18.6748 15.6652 18.7856L15.9908 18.99L16.3138 18.7815C16.4865 18.67 16.5938 18.4817 16.5938 18.2695C16.5938 17.9341 16.3218 17.6621 15.9863 17.6621ZM11.3789 18.2695C11.3789 15.7249 13.4417 13.6621 15.9863 13.6621C18.531 13.6621 20.5938 15.7249 20.5938 18.2695C20.5938 19.4624 20.1396 20.5486 19.3984 21.3653V23.7735C19.3984 25.6504 17.8769 27.1719 16 27.1719C14.1231 27.1719 12.6016 25.6504 12.6016 23.7735V21.3952C11.8442 20.5751 11.3789 19.4771 11.3789 18.2695Z"
        fill="url(#paint30_radial_730_66522)"
      />
      <path
        d="M17.3984 20.4619C18.1176 19.9977 18.5938 19.1892 18.5938 18.2695C18.5938 16.8295 17.4264 15.6621 15.9863 15.6621C14.5463 15.6621 13.3789 16.8295 13.3789 18.2695C13.3789 19.2008 13.8671 20.018 14.6016 20.4793V23.7735C14.6016 24.5458 15.2277 25.1719 16 25.1719C16.7723 25.1719 17.3984 24.5458 17.3984 23.7735V20.4619Z"
        fill="url(#paint31_radial_730_66522)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_730_66522"
          x1="8.60814"
          y1="8.85156"
          x2="11.7646"
          y2="8.85156"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#8F8E93" />
          <stop offset="1" stopColor="#8F8E93" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_730_66522"
          x1="12.2261"
          y1="2.01793"
          x2="13.867"
          y2="3.5625"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#8F8E93" />
          <stop offset="1" stopColor="#8F8E93" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_730_66522"
          x1="16.9437"
          y1="1.91537"
          x2="17.1489"
          y2="3.5625"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#8F8E93" />
          <stop offset="1" stopColor="#8F8E93" stopOpacity="0" />
        </linearGradient>
        <radialGradient
          id="paint3_radial_730_66522"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(17.3239 9.74267) rotate(82.9768) scale(4.4312)">
          <stop offset="0.598958" stopColor="#8F8E93" />
          <stop offset="1" stopColor="#8F8E93" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint4_radial_730_66522"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(15.9687 7.65625) rotate(-90) scale(4.84375 5.28858)">
          <stop offset="0.530612" stopColor="#AB83C0" />
          <stop offset="1" stopColor="#CBBBDC" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint5_radial_730_66522"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(11.2234 9.22265) rotate(180) scale(1.42417 4.99122)">
          <stop stopColor="#EDECF3" />
          <stop offset="0.677083" stopColor="#EDECF3" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint6_radial_730_66522"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(20.2181 5.38407) rotate(-35.8528) scale(1.04496 3.8099)">
          <stop stopColor="#EDECF3" />
          <stop offset="0.677083" stopColor="#EDECF3" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint7_radial_730_66522"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(19.0186 4.33582) rotate(-53.0623) scale(0.984216 2.43243)">
          <stop stopColor="#EDECF3" />
          <stop offset="0.677083" stopColor="#EDECF3" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint8_radial_730_66522"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(17.4145 3.63008) rotate(-61.8969) scale(0.970091 2.43402)">
          <stop stopColor="#EDECF3" />
          <stop offset="0.677083" stopColor="#EDECF3" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint9_radial_730_66522"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(21.065 7.58771) rotate(-13.351) scale(0.92796 2.14657)">
          <stop stopColor="#EDECF3" />
          <stop offset="0.677083" stopColor="#EDECF3" stopOpacity="0" />
        </radialGradient>
        <linearGradient
          id="paint10_linear_730_66522"
          x1="10.1562"
          y1="14.1875"
          x2="17.0938"
          y2="29.9688"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFC74E" />
          <stop offset="1" stopColor="#F7864E" />
        </linearGradient>
        <radialGradient
          id="paint11_radial_730_66522"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(26.4063 29.9687) rotate(-132.274) scale(3.25195 7.57598)">
          <stop stopColor="#FF8650" />
          <stop offset="1" stopColor="#FF8650" stopOpacity="0" />
        </radialGradient>
        <linearGradient
          id="paint12_linear_730_66522"
          x1="16"
          y1="10.8125"
          x2="16"
          y2="12.3125"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#F3D04E" />
          <stop offset="1" stopColor="#F3D04E" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_730_66522"
          x1="16"
          y1="11.125"
          x2="16"
          y2="12.0207"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFD744" />
          <stop offset="1" stopColor="#FFD744" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_730_66522"
          x1="6.4167"
          y1="11.125"
          x2="7.74776"
          y2="12.5542"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFD744" />
          <stop offset="1" stopColor="#FFD744" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint15_linear_730_66522"
          x1="26.3629"
          y1="11.125"
          x2="25.3075"
          y2="12.4475"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFD744" />
          <stop offset="1" stopColor="#FFD744" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint16_linear_730_66522"
          x1="5.03125"
          y1="20.0011"
          x2="6.49534"
          y2="20.0011"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#BA7A35" />
          <stop offset="1" stopColor="#BA7A35" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint17_linear_730_66522"
          x1="5.55014"
          y1="11.125"
          x2="7.06246"
          y2="12.1559"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#BA7A35" />
          <stop offset="1" stopColor="#BA7A35" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint18_linear_730_66522"
          x1="5.03125"
          y1="29.264"
          x2="6.34803"
          y2="27.7426"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#BA7A35" />
          <stop offset="1" stopColor="#BA7A35" stopOpacity="0" />
        </linearGradient>
        <radialGradient
          id="paint19_radial_730_66522"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(26.6562 11.125) rotate(126.254) scale(2.32513 5.25171)">
          <stop stopColor="#FFF760" />
          <stop offset="1" stopColor="#FFF760" stopOpacity="0" />
        </radialGradient>
        <linearGradient
          id="paint20_linear_730_66522"
          x1="16"
          y1="30.3415"
          x2="16"
          y2="28.5457"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#E55D6C" />
          <stop offset="1" stopColor="#E55D6C" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint21_linear_730_66522"
          x1="5.03125"
          y1="29.9688"
          x2="7.03344"
          y2="28.4186"
          gradientUnits="userSpaceOnUse">
          <stop offset="0.260417" stopColor="#E55E5D" />
          <stop offset="1" stopColor="#E55D6C" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint22_linear_730_66522"
          x1="4.44356"
          y1="20.2299"
          x2="5.80746"
          y2="20.2299"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#E55E5D" />
          <stop offset="1" stopColor="#E55D6C" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint23_linear_730_66522"
          x1="28.3163"
          y1="20.2299"
          x2="26.2303"
          y2="20.2299"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#E55D72" />
          <stop offset="1" stopColor="#E55D6C" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint24_linear_730_66522"
          x1="27.142"
          y1="20.5469"
          x2="25.4279"
          y2="20.5469"
          gradientUnits="userSpaceOnUse">
          <stop offset="0.291667" stopColor="#FFC55D" />
          <stop offset="1" stopColor="#FFC55D" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint25_linear_730_66522"
          x1="26.9687"
          y1="29.4192"
          x2="25.6322"
          y2="28.1701"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFB95D" />
          <stop offset="1" stopColor="#FFC55D" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint26_linear_730_66522"
          x1="26.5621"
          y1="12.2317"
          x2="25.4839"
          y2="12.936"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FFB95D" />
          <stop offset="1" stopColor="#FFC55D" stopOpacity="0" />
        </linearGradient>
        <radialGradient
          id="paint27_radial_730_66522"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(16.3141 18.3695) rotate(95.6522) scale(3.3278 3.09996)">
          <stop offset="0.765625" stopColor="#D98A40" />
          <stop offset="1" stopColor="#D98A40" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint28_radial_730_66522"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(16.6394 22.4248) rotate(-87.6141) scale(3.09125 1.61082)">
          <stop offset="0.765625" stopColor="#D98A40" />
          <stop offset="1" stopColor="#D98A40" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint29_radial_730_66522"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(15.0575 17.8583) rotate(43.1524) scale(2.84574)">
          <stop offset="0.677083" stopColor="#FFCD63" />
          <stop offset="1" stopColor="#FFCD63" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint30_radial_730_66522"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(15.2443 22.0889) rotate(-90.507) scale(3.58138 1.56807)">
          <stop stopColor="#FFCD63" />
          <stop offset="0.0260417" stopColor="#FFCD63" />
          <stop offset="1" stopColor="#FFCD63" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint31_radial_730_66522"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(13.3789 18.6875) rotate(34.0609) scale(5.69121 7.82719)">
          <stop offset="0.21669" stopColor="#564F80" />
          <stop offset="1" stopColor="#473154" />
        </radialGradient>
      </defs>
    </svg>
  );
};

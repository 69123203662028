import {
  BillingPlan,
  BILLING_PLAN_CONFIGS,
  BillingRestrictedFeature,
  UserWorkStatusType,
  BillingPeriod,
} from '../const';
import { BaseHelper } from './_base';
import _ from 'lodash';

export class BillingPlanHelper extends BaseHelper<BillingPlan> {
  public constructor(data: BillingPlan) {
    super(data);
    if (!Object.keys(BILLING_PLAN_CONFIGS).includes(data)) {
      throw new Error(`Invalid BillingPlan: ${data}`);
    }
  }

  get config() {
    return BILLING_PLAN_CONFIGS[this.data];
  }

  public isBetterThan(plan: BillingPlan): boolean {
    return this.config.rank > BILLING_PLAN_CONFIGS[plan].rank;
  }

  public isWorseThan(plan: BillingPlan): boolean {
    return this.config.rank < BILLING_PLAN_CONFIGS[plan].rank;
  }

  public isEquivalentTo(plan: BillingPlan): boolean {
    return this.config.rank === BILLING_PLAN_CONFIGS[plan].rank;
  }

  public isAnnualPlan(): boolean {
    return this.config.billingPeriod === BillingPeriod.ANNUAL;
  }

  public isFreePlan(): boolean {
    return this.data === BillingPlan.FREE;
  }

  public isSameBillingPeriod(period: BillingPeriod): boolean {
    return this.config.billingPeriod === period;
  }

  public isLegacyPlan(): boolean {
    return Boolean(this.config.discontinuedFrom && this.config.discontinuedFrom < new Date());
  }

  public isAvailable(currentPlan: BillingPlan): boolean {
    const currentPlanConfig = BILLING_PLAN_CONFIGS[currentPlan]!;
    const currentPlanIsLegacy = Boolean(
      currentPlanConfig.discontinuedFrom && currentPlanConfig.discontinuedFrom < new Date()
    );

    return (
      (!this.config.availableFrom || new Date() >= this.config.availableFrom) &&
      (this.data === currentPlan ||
        // Legacy monthly plan customer can see new Simple plans, but not Flex plans
        (currentPlanIsLegacy &&
          this.config.rank !== currentPlanConfig.rank &&
          currentPlanIsLegacy !== this.isLegacyPlan()) ||
        // Legacy monthly plan customer can see Legacy Annual plan
        (currentPlanIsLegacy === this.isLegacyPlan() &&
          this.config.billingPeriod !== currentPlanConfig.billingPeriod) ||
        // Non-legacy plan customer can see Simple or Flex plans only
        (!currentPlanIsLegacy && !this.isLegacyPlan()))
    );
  }

  public canUse(feature: BillingRestrictedFeature): boolean {
    return !this.config.restrictions.includes(feature);
  }

  get permittedStatuses(): UserWorkStatusType[] {
    return this.canUse(BillingRestrictedFeature.STATUSES)
      ? Object.values(UserWorkStatusType)
      : [UserWorkStatusType.OFFICE, UserWorkStatusType.NO_STATUS];
  }
}

import { createFileRoute } from '@tanstack/react-router';
import { CommonPropsForModal } from '@client/lib/modal';
import { useTeams } from '@client/lib/useTeams';
import { Modal } from '@client/components/ui/custom/modal';
import { FeedbackModal } from '@client/features/app/components/feedback-modal';

function DialogFeedback() {
  const { closeDialog } = useTeams();
  const loaderData = Route.useLoaderData();

  const props: CommonPropsForModal = {
    variant: 'msTeamsDialog',
    onClose: closeDialog,
    onDone: closeDialog,
  };

  return (
    <Modal open={true} onClose={closeDialog} isTeamsDialog={true}>
      <FeedbackModal {...props} {...loaderData} />
    </Modal>
  );
}

export const Route = createFileRoute('/_authenticated/dialog/feedback')({
  component: DialogFeedback,
  loader: async ({ context }) => {
    const user = context.auth!.user;
    const userName = user.profile.firstName ?? user.profile.name;
    return {
      userName,
    };
  },
});

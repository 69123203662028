import { cn } from '@client/lib/utils';

export const StarStruck = (props: { size?: number; className?: string }) => {
  const { size = 48 } = props;
  return (
    <svg
      className={cn(`min-w-[${size}px]`, props.className)}
      width={size.toFixed(0)}
      height={size.toFixed(0)}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.9989 29.9978C25.3333 29.9978 29.9978 23.7303 29.9978 15.9989C29.9978 8.26751 25.3333 2 15.9989 2C6.66443 2 2 8.26751 2 15.9989C2 23.7303 6.66443 29.9978 15.9989 29.9978Z"
        fill="url(#paint0_radial_730_29762)"
      />
      <path
        d="M15.9989 29.9978C25.3333 29.9978 29.9978 23.7303 29.9978 15.9989C29.9978 8.26751 25.3333 2 15.9989 2C6.66443 2 2 8.26751 2 15.9989C2 23.7303 6.66443 29.9978 15.9989 29.9978Z"
        fill="url(#paint1_radial_730_29762)"
      />
      <path
        d="M15.9989 29.9978C25.3333 29.9978 29.9978 23.7303 29.9978 15.9989C29.9978 8.26751 25.3333 2 15.9989 2C6.66443 2 2 8.26751 2 15.9989C2 23.7303 6.66443 29.9978 15.9989 29.9978Z"
        fill="url(#paint2_radial_730_29762)"
      />
      <path
        d="M15.9989 29.9978C25.3333 29.9978 29.9978 23.7303 29.9978 15.9989C29.9978 8.26751 25.3333 2 15.9989 2C6.66443 2 2 8.26751 2 15.9989C2 23.7303 6.66443 29.9978 15.9989 29.9978Z"
        fill="url(#paint3_radial_730_29762)"
        fillOpacity="0.6"
      />
      <path
        d="M15.9989 29.9978C25.3333 29.9978 29.9978 23.7303 29.9978 15.9989C29.9978 8.26751 25.3333 2 15.9989 2C6.66443 2 2 8.26751 2 15.9989C2 23.7303 6.66443 29.9978 15.9989 29.9978Z"
        fill="url(#paint4_radial_730_29762)"
      />
      <path
        d="M15.9989 29.9978C25.3333 29.9978 29.9978 23.7303 29.9978 15.9989C29.9978 8.26751 25.3333 2 15.9989 2C6.66443 2 2 8.26751 2 15.9989C2 23.7303 6.66443 29.9978 15.9989 29.9978Z"
        fill="url(#paint5_radial_730_29762)"
      />
      <path
        d="M15.9989 29.9978C25.3333 29.9978 29.9978 23.7303 29.9978 15.9989C29.9978 8.26751 25.3333 2 15.9989 2C6.66443 2 2 8.26751 2 15.9989C2 23.7303 6.66443 29.9978 15.9989 29.9978Z"
        fill="url(#paint6_radial_730_29762)"
      />
      <path
        d="M15.9989 29.9978C25.3333 29.9978 29.9978 23.7303 29.9978 15.9989C29.9978 8.26751 25.3333 2 15.9989 2C6.66443 2 2 8.26751 2 15.9989C2 23.7303 6.66443 29.9978 15.9989 29.9978Z"
        fill="url(#paint7_radial_730_29762)"
      />
      <path
        d="M15.9989 29.9978C25.3333 29.9978 29.9978 23.7303 29.9978 15.9989C29.9978 8.26751 25.3333 2 15.9989 2C6.66443 2 2 8.26751 2 15.9989C2 23.7303 6.66443 29.9978 15.9989 29.9978Z"
        fill="url(#paint8_radial_730_29762)"
      />
      <path
        d="M15.9989 29.9978C25.3333 29.9978 29.9978 23.7303 29.9978 15.9989C29.9978 8.26751 25.3333 2 15.9989 2C6.66443 2 2 8.26751 2 15.9989C2 23.7303 6.66443 29.9978 15.9989 29.9978Z"
        fill="url(#paint9_radial_730_29762)"
      />
      <path
        d="M15.9989 29.9978C25.3333 29.9978 29.9978 23.7303 29.9978 15.9989C29.9978 8.26751 25.3333 2 15.9989 2C6.66443 2 2 8.26751 2 15.9989C2 23.7303 6.66443 29.9978 15.9989 29.9978Z"
        fill="url(#paint10_radial_730_29762)"
      />
      <path
        d="M15.9989 29.9978C25.3333 29.9978 29.9978 23.7303 29.9978 15.9989C29.9978 8.26751 25.3333 2 15.9989 2C6.66443 2 2 8.26751 2 15.9989C2 23.7303 6.66443 29.9978 15.9989 29.9978Z"
        fill="url(#paint11_radial_730_29762)"
      />
      <path
        d="M15.9989 29.9978C25.3333 29.9978 29.9978 23.7303 29.9978 15.9989C29.9978 8.26751 25.3333 2 15.9989 2C6.66443 2 2 8.26751 2 15.9989C2 23.7303 6.66443 29.9978 15.9989 29.9978Z"
        fill="url(#paint12_radial_730_29762)"
      />
      <path
        d="M15.9989 29.9978C25.3333 29.9978 29.9978 23.7303 29.9978 15.9989C29.9978 8.26751 25.3333 2 15.9989 2C6.66443 2 2 8.26751 2 15.9989C2 23.7303 6.66443 29.9978 15.9989 29.9978Z"
        fill="url(#paint13_radial_730_29762)"
      />
      <path
        d="M8.77746 13.062L5.72736 15.4343C5.45116 15.6491 5.05175 15.4308 5.08343 15.0823L5.3821 11.7969C5.45377 11.0085 5.05364 10.252 4.3616 9.86755L2.37899 8.7661C1.82667 8.45926 1.87804 7.64879 2.46468 7.41413L4.99409 6.40236C5.62162 6.15135 6.07992 5.60038 6.21247 4.93764L6.68419 2.57907C6.80624 1.96879 7.59279 1.79038 7.9662 2.28827L9.33038 4.10717C9.74659 4.66211 10.4206 4.96176 11.1115 4.89896L14.6039 4.58146C14.9491 4.55009 15.1681 4.94257 14.9601 5.21982L13.1744 7.60081C12.7556 8.1592 12.6582 8.89553 12.9174 9.54359L14.3286 13.0715C14.4023 13.2558 14.2421 13.4484 14.0475 13.4095L10.3976 12.6795C9.82762 12.5655 9.23627 12.7051 8.77746 13.062Z"
        fill="url(#paint14_linear_730_29762)"
      />
      <path
        d="M8.77746 13.062L5.72736 15.4343C5.45116 15.6491 5.05175 15.4308 5.08343 15.0823L5.3821 11.7969C5.45377 11.0085 5.05364 10.252 4.3616 9.86755L2.37899 8.7661C1.82667 8.45926 1.87804 7.64879 2.46468 7.41413L4.99409 6.40236C5.62162 6.15135 6.07992 5.60038 6.21247 4.93764L6.68419 2.57907C6.80624 1.96879 7.59279 1.79038 7.9662 2.28827L9.33038 4.10717C9.74659 4.66211 10.4206 4.96176 11.1115 4.89896L14.6039 4.58146C14.9491 4.55009 15.1681 4.94257 14.9601 5.21982L13.1744 7.60081C12.7556 8.1592 12.6582 8.89553 12.9174 9.54359L14.3286 13.0715C14.4023 13.2558 14.2421 13.4484 14.0475 13.4095L10.3976 12.6795C9.82762 12.5655 9.23627 12.7051 8.77746 13.062Z"
        fill="url(#paint15_radial_730_29762)"
      />
      <path
        d="M8.77746 13.062L5.72736 15.4343C5.45116 15.6491 5.05175 15.4308 5.08343 15.0823L5.3821 11.7969C5.45377 11.0085 5.05364 10.252 4.3616 9.86755L2.37899 8.7661C1.82667 8.45926 1.87804 7.64879 2.46468 7.41413L4.99409 6.40236C5.62162 6.15135 6.07992 5.60038 6.21247 4.93764L6.68419 2.57907C6.80624 1.96879 7.59279 1.79038 7.9662 2.28827L9.33038 4.10717C9.74659 4.66211 10.4206 4.96176 11.1115 4.89896L14.6039 4.58146C14.9491 4.55009 15.1681 4.94257 14.9601 5.21982L13.1744 7.60081C12.7556 8.1592 12.6582 8.89553 12.9174 9.54359L14.3286 13.0715C14.4023 13.2558 14.2421 13.4484 14.0475 13.4095L10.3976 12.6795C9.82762 12.5655 9.23627 12.7051 8.77746 13.062Z"
        fill="url(#paint16_radial_730_29762)"
      />
      <path
        d="M23.2225 13.062L26.2726 15.4343C26.5488 15.6491 26.9483 15.4308 26.9166 15.0823L26.6179 11.7969C26.5462 11.0085 26.9464 10.252 27.6384 9.86755L29.621 8.7661C30.1733 8.45926 30.122 7.64879 29.5353 7.41413L27.0059 6.40236C26.3784 6.15135 25.9201 5.60038 25.7875 4.93764L25.3158 2.57907C25.1938 1.96879 24.4072 1.79038 24.0338 2.28827L22.6696 4.10717C22.2534 4.66211 21.5794 4.96176 20.8885 4.89896L17.3961 4.58146C17.0509 4.55009 16.8319 4.94257 17.0399 5.21982L18.8256 7.60081C19.2444 8.1592 19.3418 8.89553 19.0826 9.54359L17.6714 13.0715C17.5977 13.2558 17.7579 13.4484 17.9525 13.4095L21.6024 12.6795C22.1724 12.5655 22.7637 12.7051 23.2225 13.062Z"
        fill="url(#paint17_linear_730_29762)"
      />
      <path
        d="M23.2225 13.062L26.2726 15.4343C26.5488 15.6491 26.9483 15.4308 26.9166 15.0823L26.6179 11.7969C26.5462 11.0085 26.9464 10.252 27.6384 9.86755L29.621 8.7661C30.1733 8.45926 30.122 7.64879 29.5353 7.41413L27.0059 6.40236C26.3784 6.15135 25.9201 5.60038 25.7875 4.93764L25.3158 2.57907C25.1938 1.96879 24.4072 1.79038 24.0338 2.28827L22.6696 4.10717C22.2534 4.66211 21.5794 4.96176 20.8885 4.89896L17.3961 4.58146C17.0509 4.55009 16.8319 4.94257 17.0399 5.21982L18.8256 7.60081C19.2444 8.1592 19.3418 8.89553 19.0826 9.54359L17.6714 13.0715C17.5977 13.2558 17.7579 13.4484 17.9525 13.4095L21.6024 12.6795C22.1724 12.5655 22.7637 12.7051 23.2225 13.062Z"
        fill="url(#paint18_radial_730_29762)"
      />
      <path
        d="M23.2225 13.062L26.2726 15.4343C26.5488 15.6491 26.9483 15.4308 26.9166 15.0823L26.6179 11.7969C26.5462 11.0085 26.9464 10.252 27.6384 9.86755L29.621 8.7661C30.1733 8.45926 30.122 7.64879 29.5353 7.41413L27.0059 6.40236C26.3784 6.15135 25.9201 5.60038 25.7875 4.93764L25.3158 2.57907C25.1938 1.96879 24.4072 1.79038 24.0338 2.28827L22.6696 4.10717C22.2534 4.66211 21.5794 4.96176 20.8885 4.89896L17.3961 4.58146C17.0509 4.55009 16.8319 4.94257 17.0399 5.21982L18.8256 7.60081C19.2444 8.1592 19.3418 8.89553 19.0826 9.54359L17.6714 13.0715C17.5977 13.2558 17.7579 13.4484 17.9525 13.4095L21.6024 12.6795C22.1724 12.5655 22.7637 12.7051 23.2225 13.062Z"
        fill="url(#paint19_radial_730_29762)"
      />
      <path
        d="M23.2225 13.062L26.2726 15.4343C26.5488 15.6491 26.9483 15.4308 26.9166 15.0823L26.6179 11.7969C26.5462 11.0085 26.9464 10.252 27.6384 9.86755L29.621 8.7661C30.1733 8.45926 30.122 7.64879 29.5353 7.41413L27.0059 6.40236C26.3784 6.15135 25.9201 5.60038 25.7875 4.93764L25.3158 2.57907C25.1938 1.96879 24.4072 1.79038 24.0338 2.28827L22.6696 4.10717C22.2534 4.66211 21.5794 4.96176 20.8885 4.89896L17.3961 4.58146C17.0509 4.55009 16.8319 4.94257 17.0399 5.21982L18.8256 7.60081C19.2444 8.1592 19.3418 8.89553 19.0826 9.54359L17.6714 13.0715C17.5977 13.2558 17.7579 13.4484 17.9525 13.4095L21.6024 12.6795C22.1724 12.5655 22.7637 12.7051 23.2225 13.062Z"
        fill="url(#paint20_radial_730_29762)"
      />
      <path
        d="M23.2225 13.062L26.2726 15.4343C26.5488 15.6491 26.9483 15.4308 26.9166 15.0823L26.6179 11.7969C26.5462 11.0085 26.9464 10.252 27.6384 9.86755L29.621 8.7661C30.1733 8.45926 30.122 7.64879 29.5353 7.41413L27.0059 6.40236C26.3784 6.15135 25.9201 5.60038 25.7875 4.93764L25.3158 2.57907C25.1938 1.96879 24.4072 1.79038 24.0338 2.28827L22.6696 4.10717C22.2534 4.66211 21.5794 4.96176 20.8885 4.89896L17.3961 4.58146C17.0509 4.55009 16.8319 4.94257 17.0399 5.21982L18.8256 7.60081C19.2444 8.1592 19.3418 8.89553 19.0826 9.54359L17.6714 13.0715C17.5977 13.2558 17.7579 13.4484 17.9525 13.4095L21.6024 12.6795C22.1724 12.5655 22.7637 12.7051 23.2225 13.062Z"
        fill="url(#paint21_radial_730_29762)"
      />
      <path
        d="M23.2225 13.062L26.2726 15.4343C26.5488 15.6491 26.9483 15.4308 26.9166 15.0823L26.6179 11.7969C26.5462 11.0085 26.9464 10.252 27.6384 9.86755L29.621 8.7661C30.1733 8.45926 30.122 7.64879 29.5353 7.41413L27.0059 6.40236C26.3784 6.15135 25.9201 5.60038 25.7875 4.93764L25.3158 2.57907C25.1938 1.96879 24.4072 1.79038 24.0338 2.28827L22.6696 4.10717C22.2534 4.66211 21.5794 4.96176 20.8885 4.89896L17.3961 4.58146C17.0509 4.55009 16.8319 4.94257 17.0399 5.21982L18.8256 7.60081C19.2444 8.1592 19.3418 8.89553 19.0826 9.54359L17.6714 13.0715C17.5977 13.2558 17.7579 13.4484 17.9525 13.4095L21.6024 12.6795C22.1724 12.5655 22.7637 12.7051 23.2225 13.062Z"
        fill="url(#paint22_radial_730_29762)"
      />
      <path
        d="M23.2225 13.062L26.2726 15.4343C26.5488 15.6491 26.9483 15.4308 26.9166 15.0823L26.6179 11.7969C26.5462 11.0085 26.9464 10.252 27.6384 9.86755L29.621 8.7661C30.1733 8.45926 30.122 7.64879 29.5353 7.41413L27.0059 6.40236C26.3784 6.15135 25.9201 5.60038 25.7875 4.93764L25.3158 2.57907C25.1938 1.96879 24.4072 1.79038 24.0338 2.28827L22.6696 4.10717C22.2534 4.66211 21.5794 4.96176 20.8885 4.89896L17.3961 4.58146C17.0509 4.55009 16.8319 4.94257 17.0399 5.21982L18.8256 7.60081C19.2444 8.1592 19.3418 8.89553 19.0826 9.54359L17.6714 13.0715C17.5977 13.2558 17.7579 13.4484 17.9525 13.4095L21.6024 12.6795C22.1724 12.5655 22.7637 12.7051 23.2225 13.062Z"
        fill="url(#paint23_radial_730_29762)"
      />
      <path d="M16 26C7 26 7 17 7 17H25C25 17 25 26 16 26Z" fill="url(#paint24_radial_730_29762)" />
      <path
        d="M8 17.5V17H24V17.5C24 18.0523 23.5523 18.5 23 18.5H9C8.44772 18.5 8 18.0523 8 17.5Z"
        fill="url(#paint25_linear_730_29762)"
      />
      <defs>
        <radialGradient
          id="paint0_radial_730_29762"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(25.5 9) rotate(132.839) scale(37.5033)">
          <stop stopColor="#FFF478" />
          <stop offset="0.474827" stopColor="#FFB02E" />
          <stop offset="1" stopColor="#F70A8D" />
        </radialGradient>
        <radialGradient
          id="paint1_radial_730_29762"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(25.5 9) rotate(131.878) scale(38.9487)">
          <stop stopColor="#FFF478" />
          <stop offset="0.474827" stopColor="#FFB02E" />
          <stop offset="1" stopColor="#F70A8D" />
        </radialGradient>
        <radialGradient
          id="paint2_radial_730_29762"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(16 12.5) rotate(101.31) scale(17.8466 22.8581)">
          <stop offset="0.787821" stopColor="#F59639" stopOpacity="0" />
          <stop offset="0.972509" stopColor="#FF7DCE" />
        </radialGradient>
        <radialGradient
          id="paint3_radial_730_29762"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(18 14) rotate(135) scale(41.0122)">
          <stop offset="0.314853" stopOpacity="0" />
          <stop offset="1" />
        </radialGradient>
        <radialGradient
          id="paint4_radial_730_29762"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(16 17) rotate(77.692) scale(28.1469)">
          <stop offset="0.507903" stopColor="#7D6133" stopOpacity="0" />
          <stop offset="1" stopColor="#715B32" />
        </radialGradient>
        <radialGradient
          id="paint5_radial_730_29762"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(16.5 16.5) rotate(55.7131) scale(13.3135 9.65032)">
          <stop stopColor="#FFB849" />
          <stop offset="1" stopColor="#FFB847" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint6_radial_730_29762"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(20.5 18) rotate(9.86581) scale(11.6726)">
          <stop stopColor="#FFA64B" />
          <stop offset="0.900412" stopColor="#FFAE46" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint7_radial_730_29762"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(17.5 15) rotate(43.9708) scale(59.0529)">
          <stop offset="0.185425" stopOpacity="0" />
          <stop offset="1" stopOpacity="0.4" />
        </radialGradient>
        <radialGradient
          id="paint8_radial_730_29762"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(23.172 13.0832) rotate(136.169) scale(1.24264 3.98455)">
          <stop stopColor="#EC812A" stopOpacity="0.76" />
          <stop offset="0.572289" stopColor="#EC812A" stopOpacity="0.260417" />
          <stop offset="0.794087" stopColor="#EC812A" stopOpacity="0.111011" />
          <stop offset="1" stopColor="#EC812A" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint9_radial_730_29762"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(21.6997 12.2578) rotate(103.824) scale(1.66994 2.79166)">
          <stop stopColor="#EC812A" />
          <stop offset="0.359734" stopColor="#EC812A" stopOpacity="0.52" />
          <stop offset="0.792681" stopColor="#EC812A" stopOpacity="0.125447" />
          <stop offset="1" stopColor="#EC812A" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint10_radial_730_29762"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(18.9855 10.3285) rotate(-162.668) scale(1.90831 3.9894)">
          <stop stopColor="#EC812A" stopOpacity="0.78" />
          <stop offset="0.451488" stopColor="#EC812A" stopOpacity="0.49" />
          <stop offset="0.747195" stopColor="#EC812A" stopOpacity="0.12266" />
          <stop offset="1" stopColor="#EC812A" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint11_radial_730_29762"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(18.8224 8.08812) rotate(134.735) scale(1.3726 3.1153)">
          <stop stopColor="#EC812A" stopOpacity="0.72" />
          <stop offset="0.65285" stopColor="#EC812A" stopOpacity="0.192708" />
          <stop offset="1" stopColor="#EC812A" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint12_radial_730_29762"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(10.8705 12.7347) rotate(99.0872) scale(1.49157 4.38736)">
          <stop stopColor="#C85F27" stopOpacity="0.65" />
          <stop offset="0.482815" stopColor="#C85F27" stopOpacity="0.260417" />
          <stop offset="0.806099" stopColor="#C85F27" stopOpacity="0.07" />
          <stop offset="1" stopColor="#C85F27" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint13_radial_730_29762"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(9.0356 12.652) rotate(62.5603) scale(1.28368 3.72584)">
          <stop stopColor="#C85F27" stopOpacity="0.65" />
          <stop offset="0.285517" stopColor="#C85F27" stopOpacity="0.260417" />
          <stop offset="0.727885" stopColor="#C85F27" stopOpacity="0.07" />
          <stop offset="1" stopColor="#C85F27" stopOpacity="0" />
        </radialGradient>
        <linearGradient id="paint14_linear_730_29762" x1="7.5" y1="1" x2="10" y2="15" gradientUnits="userSpaceOnUse">
          <stop stopColor="#7912B2" />
          <stop offset="1" stopColor="#CF1DA1" />
        </linearGradient>
        <radialGradient
          id="paint15_radial_730_29762"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(9.20619 3.85915) rotate(135) scale(0.679562 2.48129)">
          <stop stopColor="#A82D9C" />
          <stop offset="1" stopColor="#A82D9C" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint16_radial_730_29762"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(12.2565 4.82019) rotate(87.6141) scale(0.50185 3.09894)">
          <stop stopColor="#A82D9C" />
          <stop offset="1" stopColor="#A82D9C" stopOpacity="0" />
        </radialGradient>
        <linearGradient
          id="paint17_linear_730_29762"
          x1="22"
          y1="2.5"
          x2="22.5"
          y2="14.5"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#7912B2" />
          <stop offset="1" stopColor="#CF1DA1" />
        </linearGradient>
        <radialGradient
          id="paint18_radial_730_29762"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(28.9734 7.19478) rotate(116.565) scale(0.31087 2.20718)">
          <stop stopColor="#B51AA6" />
          <stop offset="0.432292" stopColor="#B51AA6" stopOpacity="0.21875" />
          <stop offset="1" stopColor="#B51AA6" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint19_radial_730_29762"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(27.6141 6.66958) rotate(116.456) scale(0.512027 2.14062)">
          <stop stopColor="#B51AA6" />
          <stop offset="0.600176" stopColor="#B51AA6" stopOpacity="0.21875" />
          <stop offset="1" stopColor="#B51AA6" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint20_radial_730_29762"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(29.5174 7.29171) rotate(112.521) scale(0.554675 2.31891)">
          <stop offset="0.145752" stopColor="#B51AA6" />
          <stop offset="0.761264" stopColor="#B51AA6" stopOpacity="0.21875" />
          <stop offset="1" stopColor="#B51AA6" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint21_radial_730_29762"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(26.5034 6.0539) rotate(127.504) scale(0.543309 1.27284)">
          <stop stopColor="#B51AA6" />
          <stop offset="0.565981" stopColor="#B51AA6" stopOpacity="0.21875" />
          <stop offset="0.841141" stopColor="#B51AA6" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint22_radial_730_29762"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(25.9813 5.50805) rotate(152.269) scale(0.416143 1.29012)">
          <stop stopColor="#B51AA6" />
          <stop offset="0.546247" stopColor="#B51AA6" stopOpacity="0.21875" />
          <stop offset="1" stopColor="#B51AA6" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint23_radial_730_29762"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(25.6433 4.12997) rotate(168.178) scale(0.549015 2.98829)">
          <stop offset="0.00764343" stopColor="#B51AA6" />
          <stop offset="0.704661" stopColor="#B51AA6" stopOpacity="0.21875" />
          <stop offset="1" stopColor="#B51AA6" stopOpacity="0" />
        </radialGradient>
        <radialGradient
          id="paint24_radial_730_29762"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(16 28) rotate(-90) scale(11 22)">
          <stop stopColor="#F70A8D" />
          <stop offset="1" stopColor="#89029C" />
        </radialGradient>
        <linearGradient id="paint25_linear_730_29762" x1="16" y1="17" x2="16" y2="18.5" gradientUnits="userSpaceOnUse">
          <stop stopColor="#D5A8FE" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
      </defs>
    </svg>
  );
};

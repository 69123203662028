import superjson from 'superjson';

function isObject(x: unknown): boolean {
  return x instanceof Object && x !== null && !Array.isArray(x) && !(x instanceof Map) && !(x instanceof Set);
}

function recurisivelyRemoveUndefinedKeys(x: any): any {
  return isObject(x)
    ? Object.fromEntries(
        Object.entries(x)
          .filter(([_, v]) => v !== undefined)
          .map(([k, v]) => [k, recurisivelyRemoveUndefinedKeys(v)])
      )
    : x;
}

const serialize = (x: any) => {
  const withoutUndefined = recurisivelyRemoveUndefinedKeys(x);
  return superjson.serialize(withoutUndefined);
};

const deserialize = (x: any) => {
  return superjson.deserialize(x);
};

export default {
  serialize,
  deserialize,
};

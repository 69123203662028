import { defineForm } from '@client/lib/form/helpers';
import { FormValues } from '@client/lib/form/types';
import { SimpleFormProps } from '@client/components/shared/simple-form';
import { AWAY_DATES_DESCRIPTION_MAPPING, UserWorkStatusType } from '@officely/models';
import { useCallback, useEffect, useMemo } from 'react';
import { useForm } from '@client/lib/form/useForm';
import { Form } from '@client/components/ui/form';
import { FormField } from '@client/components/shared/FormField';
import { useNoteSuggestions } from '@client/features/booking/hooks/use-note-suggestions';
import { NoteSuggestions } from '@client/features/booking/components/note-suggestions';

const FORM_ID = 'away-dates-form' as const;

const getDefinition = (notePlaceholder: string) =>
  defineForm({
    id: FORM_ID,
    fields: {
      dates: {
        type: 'select-date-range',
        label: 'Dates (inclusive)',
        allowSameDayRange: true,
        rangeRequired: true,
      },
      note: {
        type: 'text',
        label: 'Add a note?',
        placeholder: notePlaceholder,
      },
    },
  });

export type AwayDatesFormValues = FormValues<ReturnType<typeof getDefinition>>;

type Props = FormValues<ReturnType<typeof getDefinition>> & {
  type: UserWorkStatusType;
  data?: AwayDatesFormValues;
  onSubmit: (values: AwayDatesFormValues) => void;
};

const AwayDatesForm = (props: Props) => {
  const { type, data, onSubmit } = props;

  const { notePlaceholder, suggestions } = useNoteSuggestions(type);
  const definition = useMemo(() => getDefinition(notePlaceholder), [notePlaceholder]);
  const { form, setValues, conditions } = useForm(definition);

  useEffect(() => {
    // whenever data changes reset the form and set the values
    form.reset();
    if (data) {
      setValues(data);
    }
  }, [form.reset, data]);

  const handleSubmit = useCallback(
    form.handleSubmit((values) => {
      onSubmit?.(values);
      form.reset(values);
    }),
    [form, onSubmit]
  );

  const handleNoteSuggestion = useCallback(
    (suggestion: string) => {
      form.setValue('note', suggestion);
    },
    [form.setValue]
  );

  return (
    <Form {...form}>
      <form onSubmit={handleSubmit} className="flex flex-col gap-y-6" id={definition.id}>
        {Object.keys(definition.fields)
          .map((key) => key as keyof typeof definition.fields)
          .map((name) => {
            return (
              <FormField
                {...definition.fields[name]}
                key={name}
                name={name}
                control={form.control}
                disabled={conditions?.disabled?.[name]}
                hidden={conditions?.hidden?.[name]}
              />
            );
          })}
      </form>
      <NoteSuggestions suggestions={suggestions} onSuggestion={handleNoteSuggestion} />
    </Form>
  );
};

AwayDatesForm.id = FORM_ID;

export { AwayDatesForm };

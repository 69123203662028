import { defineForm } from '@client/lib/form/helpers';
import { useForm } from '@client/lib/form/useForm';
import { useCallback, useEffect, useMemo } from 'react';
import { Form } from '@client/components/ui/form';
import { FormField } from '@client/components/shared/FormField';
import { CommonPropsForForm, FormValues } from '@client/lib/form/types';
import { ManagedNotificationType, MANAGED_NOTIFICATION_PROFILES } from '@officely/models';

const FORM_ID = 'user-notif-edit-form' as const;

const getDefinition = (type: ManagedNotificationType) =>
  defineForm({
    id: FORM_ID,
    fields: {
      enabled: {
        type: 'switch',
        label: 'Enabled',
        text: ' ',
      },
      subscribedGroups: {
        type: 'checkboxes',
        label: 'Subscribed Groups',
        options: [
          {
            label: 'Your Favorite Coworkers',
            value: 'favoriteCoworkers',
          },
        ],
        description: 'Send alerts regarding these groups.',
      },
      whitelist: {
        type: 'multi-select-people',
        label: 'Allowlist',
        description: 'Always send alerts regarding these people.',
      },
      blacklist: {
        type: 'multi-select-people',
        label: 'Blocklist',
        description: 'Block alerts regarding these people.',
      },
    },
    conditions(values) {
      const enabled = values.enabled;
      return {
        hidden: {
          subscribedGroups: !enabled || !MANAGED_NOTIFICATION_PROFILES[type].controls.includes('relations'),
          whitelist: !enabled || !MANAGED_NOTIFICATION_PROFILES[type].controls.includes('whitelist'),
          blacklist: !enabled || !MANAGED_NOTIFICATION_PROFILES[type].controls.includes('blacklist'),
        },
      };
    },
  });

type FormDefintion = ReturnType<typeof getDefinition>;
export type UserNotifEditFormValues = FormValues<FormDefintion>;

type Props = CommonPropsForForm<UserNotifEditFormValues> & {
  type: ManagedNotificationType;
};

const UserNotifEditForm = (props: Props) => {
  const { type, data, onSubmit } = props;

  const definition = useMemo(() => getDefinition(type), [type]);
  const { form, setValues, conditions } = useForm(definition);

  useEffect(() => {
    // whenever data changes reset the form and set the values
    form.reset();
    if (data) {
      setValues(data);
    }
  }, [form.reset, data]);

  const handleSubmit = useCallback(
    form.handleSubmit((values) => {
      onSubmit?.(values);
      form.reset(values);
    }),
    [form, onSubmit]
  );

  return (
    <Form {...form}>
      <form onSubmit={handleSubmit} className="flex flex-col gap-y-6" id={FORM_ID}>
        {Object.keys(definition.fields)
          .map((key) => key as keyof typeof definition.fields)
          .map((name) => {
            return (
              <FormField
                {...definition.fields[name]}
                key={name}
                name={name}
                control={form.control}
                disabled={conditions?.disabled?.[name]}
                hidden={conditions?.hidden?.[name]}
              />
            );
          })}
      </form>
    </Form>
  );
};

UserNotifEditForm.id = FORM_ID;

export { UserNotifEditForm };

/* eslint-disable sonarjs/no-duplicate-string */
import { TypographyP } from '@client/components/ui/custom/typography-p';
import { Button } from '@client/components/ui/button';
import { useCallback, useMemo, useState } from 'react';
import pluralize from 'pluralize';

import _ from 'lodash';
import { TypographySmall } from '@client/components/ui/custom/typography-small';
import { emojis } from '@client/lib/emojis';
import { Emoji } from '@client/components/ui/custom/emoji';

import { Badge } from '@client/components/ui/badge';

const HELP_ARTICLE_URL =
  'https://help.getofficely.com/en/articles/5213017-making-the-most-of-the-neighborhoods-and-desks';
const NBH_GRAPHIC_URL = 'https://cdn.getofficely.com/assets/neighborhoods.png';
const EMPTY_CATEGORY_NAME = 'Other';

import { EditIcon, TrashIcon } from '@client/components/ui/custom/icons/index';
import { Modal } from '@client/components/ui/custom/modal';
import { NbhData, StepComponentProps } from '@client/features/onboarding/types';
import { SetupNbhModal } from '@client/features/onboarding/components/setup-nbh-modal';
import { Separator } from '@client/components/ui/separator';

const CtaBtns = (
  props: StepComponentProps & {
    onAddNbh: () => void;
  }
) => {
  const {
    state: { neighbourhoods = [] },
    onAddNbh,
    onNext,
    onBack,
  } = props;

  if (neighbourhoods.length === 0) {
    return (
      <>
        <div className="mt-6">
          <Button variant="outline" onClick={onBack}>
            Back
          </Button>
          <Button variant="default" className="ml-2" onClick={onAddNbh}>
            Add a Neighborhood
          </Button>
        </div>
        <Button variant="link" className="mt-4 pl-0 text-muted-foreground" onClick={onNext}>
          Maybe Later
        </Button>
      </>
    );
  }

  return (
    <div className="mt-6">
      <Button variant="outline" onClick={onBack}>
        Back
      </Button>
      <Button variant="outline" className="ml-2" onClick={onAddNbh}>
        Add Another Neighborhood
      </Button>
      <Button variant="default" className="ml-2" onClick={onNext}>
        Next
      </Button>
    </div>
  );
};

export const AddNeighbourhoods = (props: StepComponentProps) => {
  const { state, updateState } = props;

  const [nbhForm, setNbhForm] = useState<{
    open: boolean;
    index?: number;
  }>({
    open: false,
  });

  const handleNbhFormClosed = useCallback(() => {
    setNbhForm({ ...nbhForm, open: false });
  }, [nbhForm]);

  const handleHelpClick = useMemo(
    () => () => {
      window.open(
        HELP_ARTICLE_URL,
        '_blank' // new tab
      );
    },
    []
  );

  const addNewNbh = useCallback(() => {
    // pass in new ID for nbh
    setNbhForm({
      open: true,
    });
  }, []);

  const handleNbhSubmit = useCallback(
    (data: NbhData) => {
      if (typeof nbhForm.index === 'number') {
        // update existing nbh in state
        updateState({
          neighbourhoods: state.neighbourhoods?.map((nbh, i) => (i === nbhForm.index ? data : nbh)),
        });
      } else {
        // add new nbh to state
        updateState({
          neighbourhoods: [...(state.neighbourhoods ?? []), data],
        });
      }
      setNbhForm({ open: false });
    },
    [state.neighbourhoods, updateState, nbhForm.index]
  );

  const handleEditNbh = useCallback((index: number) => {
    setNbhForm({
      open: true,
      index,
    });
  }, []);

  const handleRemoveNbh = useCallback(
    (index: number) => {
      if (state.neighbourhoods) {
        updateState({
          neighbourhoods: state.neighbourhoods.filter((_, i) => i !== index),
        });
      }
    },
    [state.neighbourhoods, updateState]
  );

  const editingNbh = useMemo(() => {
    if (typeof nbhForm.index === 'number') {
      return state.neighbourhoods?.[nbhForm.index];
    }
  }, [nbhForm.index, state.neighbourhoods]);

  const existingCategories = useMemo(() => {
    return _.uniq((state.neighbourhoods ?? []).map((nbh) => nbh.category).filter(Boolean) as string[]);
  }, [state.neighbourhoods]);

  return (
    <div className="mt-2">
      <TypographyP>
        Recommended if you want desk booking or if you want people to book into specific areas of the office.{' '}
        <Button variant={'link'} className="pl-2" onClick={handleHelpClick}>
          Read more
        </Button>
      </TypographyP>
      {!state.neighbourhoods?.length ? null : ( // <img src={NBH_GRAPHIC_URL} className="mt-2" />
        <NbhList {...props} onEdit={handleEditNbh} onRemove={handleRemoveNbh} />
      )}

      <CtaBtns {...props} onAddNbh={addNewNbh} />

      <Modal open={nbhForm.open} onClose={handleNbhFormClosed}>
        <SetupNbhModal
          categories={existingCategories}
          onSubmit={handleNbhSubmit}
          existing={editingNbh}
          onClose={handleNbhFormClosed}
        />
      </Modal>

      {/* <NbhForm
        onClose={handleNbhFormClosed}
        open={nbhForm.open}
        onSubmit={handleNbhSubmit}
        categories={existingCategories}
        data={editingNbh}
      /> */}
    </div>
  );
};

const NbhList = (
  props: StepComponentProps & {
    onEdit: (index: number) => void;
    onRemove: (index: number) => void;
  }
) => {
  const { state, onEdit, onRemove } = props;
  const { neighbourhoods = [] } = state;

  const neighbourhoodsWithIndex = useMemo(() => {
    return neighbourhoods.map((nbh, i) => ({ ...nbh, index: i }));
  }, [neighbourhoods]);

  const byCategory = useMemo(() => {
    const categories = _(neighbourhoodsWithIndex)
      .map((nbh) => nbh.category ?? EMPTY_CATEGORY_NAME)
      .uniq()
      // sort alphabetically and send empty category to the end
      .sort((a, b) => (a === EMPTY_CATEGORY_NAME ? 1 : b === EMPTY_CATEGORY_NAME ? -1 : a.localeCompare(b)))
      .value() as string[];
    return Object.fromEntries(
      categories.map((category) => [
        category,
        _(neighbourhoodsWithIndex)
          .filter((nbh) => (nbh.category ?? EMPTY_CATEGORY_NAME) === category)
          .sort((a, b) => a.name.localeCompare(b.name))
          .value(),
      ])
    );
  }, [neighbourhoodsWithIndex]);

  const categories = Object.keys(byCategory);
  const hasMultipleCategories = categories.length > 1;
  const hasCategories = hasMultipleCategories || (categories[0] && categories[0] !== EMPTY_CATEGORY_NAME);

  return (
    <>
      {categories.map((category) => (
        <div key={category} className="mt-6">
          <>
            {hasCategories && (
              <>
                <TypographySmall className="font-semibold">{category}</TypographySmall>
                <Separator className="my-2" />
              </>
            )}
            {byCategory[category].map((nbh) => {
              let additionalTxt = '';
              if (typeof nbh.capacity === 'number') {
                additionalTxt = pluralize('space', nbh.capacity, true);
              }
              if (!!nbh.peopleIds?.length) {
                additionalTxt = additionalTxt ? additionalTxt + ', private' : 'Private';
              }
              return (
                <div key={nbh.index} className="my-2 inline-block flex justify-items items-center">
                  <Emoji>{emojis.neighbourhood}</Emoji>
                  <TypographyP>
                    {`  `}
                    {nbh.name}
                  </TypographyP>
                  {typeof nbh.capacity === 'number' && (
                    <Badge variant={'outline'} className="ml-2 self-center">
                      {pluralize('space', nbh.capacity, true)}
                    </Badge>
                  )}
                  {!!nbh.peopleIds?.length && (
                    <Badge variant={'outline'} className="ml-2 self-center">
                      Private
                    </Badge>
                  )}
                  <EditIcon tooltip="Edit" onClick={() => onEdit(nbh.index)} asButton className="ml-2" />
                  <TrashIcon tooltip="Remove" onClick={() => onRemove(nbh.index)} asButton />
                </div>
              );
            })}
          </>
        </div>
      ))}
      <Separator className="mt-4" />
    </>
  );
};

import { yyyyMmDdSchema } from '@officely/models';
import { createFileRoute } from '@tanstack/react-router';
import { z } from 'zod';
import { CommonPropsForModal } from '@client/lib/modal';
import { useTeams } from '@client/lib/useTeams';
import { Modal } from '@client/components/ui/custom/modal';
import { OfficeBookingModal } from '@client/features/booking/components/office-booking-modal';

function DialogOfficeBooking() {
  const { closeDialog } = useTeams();
  const { nbhId } = Route.useSearch();
  const { date, officeId } = Route.useLoaderData();

  // not sure what this was for?
  // useEffect(() => {
  //   if (nbhId && loaderData.nbhs.some((x) => x.id === nbhId)) {
  //     closeDialog();
  //   }
  // }, [nbhId]);

  const props: CommonPropsForModal = {
    variant: 'msTeamsDialog',
    onClose: closeDialog,
    onDone: closeDialog,
  };

  return (
    <Modal open={true} onClose={closeDialog} isTeamsDialog={true}>
      <OfficeBookingModal {...props} date={date} officeId={officeId} nbhId={nbhId} />
    </Modal>
  );
}

export const Route = createFileRoute('/_authenticated/dialog/office-booking')({
  component: DialogOfficeBooking,
  validateSearch: z.object({
    officeId: z.string(),
    date: yyyyMmDdSchema,
    nbhId: z.string().optional().catch(undefined),
  }),
  loaderDeps: ({ search }) => ({
    officeId: search.officeId,
    date: search.date,
    nbhId: search.nbhId,
  }),
  loader: async ({ deps }) => {
    return {
      date: deps.date,
      officeId: deps.officeId,
      nbhId: deps.nbhId,
    };
  },
});

import { createFileRoute } from '@tanstack/react-router';
import { SettingsLayout } from '@client/components/layout/SettingsLayout';
import { TypographyH2 } from '@client/components/ui/custom/typography-h2';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from '@client/components/ui/breadcrumb';
import { buttonVariants } from '@client/components/ui/button';
import { Separator } from '@client/components/ui/separator';
import { cn } from '@client/lib/utils';
import { Link, Outlet } from '@tanstack/react-router';
import { SparklesIcon, RadioTowerIcon, MonitorIcon, Settings2Icon } from 'lucide-react';
import { useScreenSize } from '@client/lib/useScreenSize';

const BreadcrumbNav = () => {
  const search = Route.useSearch();
  const { office } = Route.useLoaderData();

  return (
    <Breadcrumb>
      <BreadcrumbList>
        <BreadcrumbLink asChild>
          <Link to="/home" search={search}>
            Home
          </Link>
        </BreadcrumbLink>
        <BreadcrumbSeparator />
        <BreadcrumbLink asChild>
          <Link to="/home/settings" search={search}>
            Settings
          </Link>
        </BreadcrumbLink>
        <BreadcrumbSeparator />
        <BreadcrumbLink asChild>
          <Link to="/home/settings/offices" search={search}>
            Offices
          </Link>
        </BreadcrumbLink>
        <BreadcrumbSeparator />
        <BreadcrumbItem>
          <BreadcrumbPage>{office.name}</BreadcrumbPage>
        </BreadcrumbItem>
      </BreadcrumbList>
    </Breadcrumb>
  );
};

const LINKS = [
  {
    title: 'General',
    path: '/home/settings/offices/$id',
    icon: Settings2Icon,
  },
  {
    title: 'Neighborhoods',
    path: '/home/settings/offices/$id/nbhs',
    icon: MonitorIcon,
  },
  {
    title: 'Extras',
    path: '/home/settings/offices/$id/extras',
    icon: SparklesIcon,
  },
  {
    title: 'Broadcast',
    path: '/home/settings/offices/$id/broadcast',
    icon: RadioTowerIcon,
  },
];

function SettingsOfficesOffice() {
  const params = Route.useParams();
  const search = Route.useSearch();
  const { isMobile } = useScreenSize();

  return (
    <SettingsLayout>
      <BreadcrumbNav />
      <div className="mt-4">
        <TypographyH2>Office Settings</TypographyH2>
      </div>
      <Separator className="my-6" />
      <div className="flex flex-col space-y-8 md:flex-row md:space-x-12 md:space-y-0">
        <aside className="md:-mx-4 md:w-1/5 md:min-w-52">
          <nav className={cn('flex flex-wrap md:flex-col md:space-y-1')}>
            {LINKS.map(({ title, path, icon: Icon }) => (
              <Link
                key={path}
                to={path}
                search={search}
                params={params}
                className={cn(buttonVariants({ variant: 'ghost' }), 'justify-start')}
                inactiveProps={{
                  className: 'hover:bg-transparent hover:underline',
                }}
                activeOptions={{
                  exact: true,
                  includeSearch: false,
                }}
                activeProps={{
                  className: 'bg-muted hover:bg-muted hover:no-underline',
                }}>
                {Icon && !isMobile && <Icon className="w-4 h-4 mr-4" />}
                {title}
              </Link>
            ))}
          </nav>
        </aside>
        <div className="flex-1 lg:max-w-2xl">
          <Outlet />
        </div>
      </div>
    </SettingsLayout>
  );
}

export const Route = createFileRoute('/_authenticated/home/settings/offices/$id')({
  component: SettingsOfficesOffice,
  loader: async ({ context, params }) => {
    const office = await context.trpcUtils.office.getById.fetch(params.id);
    return { office };
  },
});

/* eslint-disable sonarjs/no-small-switch */
import { CommonPropsForModal } from '@client/lib/modal';
import { ModalLayout, ModalLayoutProps } from '@client/components/ui/custom/modal-layout';
import { UserFavsForm } from '@client/features/settings/components/user-favs-form';
import { trpc } from '@client/lib/trpc';
import { useCallback } from 'react';
import { useEagerLoadOfficelyUsers } from '@client/context/directory';

const TITLE = 'Favorite Coworkers';
const DESCRIPTION = 'Add your favorite coworkers to see them in Officely';

type Props = CommonPropsForModal & {
  favouriteCoworkerIds: string[];
};

export const UserFavsModal = (props: Props) => {
  const { favouriteCoworkerIds, onDone, ...restForModal } = props;

  const { peopleIds, loading: fetchingUsers } = useEagerLoadOfficelyUsers(favouriteCoworkerIds);
  const updateFavouriteCoworkersMutation = trpc.user.updateFavouriteCoworkers.useMutation();
  const updating = updateFavouriteCoworkersMutation.isPending;
  const loading = updating;

  const handleSubmit = useCallback(
    (value: string[]) => {
      updateFavouriteCoworkersMutation
        .mutateAsync({
          peopleIds: value,
          favouriteMeBack: true,
        })
        .then(() => {
          onDone?.();
        })
        .catch((error) => {
          console.error(error);
        });
    },
    [updateFavouriteCoworkersMutation.mutateAsync, onDone]
  );

  const commonModalProps: ModalLayoutProps = {
    title: TITLE,
    description: DESCRIPTION,
    loading,
    submitFormId: UserFavsForm.id,
    ...restForModal,
  };

  return (
    <ModalLayout {...commonModalProps}>
      <UserFavsForm onSubmit={handleSubmit} data={peopleIds} />
    </ModalLayout>
  );
};

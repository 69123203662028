/* eslint-disable sonarjs/no-small-switch */
import { CommonPropsForModal } from '@client/lib/modal';

import { ModalLayout, ModalLayoutProps } from '@client/components/ui/custom/modal-layout';
import { useDirectoryContext } from '@client/context/directory';
import { UserNotifEditForm, UserNotifEditFormValues } from '@client/features/settings/components/user-notif-edit-form';
import { trpc } from '@client/lib/trpc';
import {
  MANAGED_NOTIFICATION_PROFILES,
  ManagedNotificationType,
  UserNotificationPreference,
  UserRelation,
} from '@officely/models';
import _ from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';

type Props = CommonPropsForModal &
  UserNotificationPreference & {
    type: ManagedNotificationType;
  };

export const UserNotifEditModal = (props: Props) => {
  const {
    type,
    off = false,
    blacklist = [],
    whitelist = [],
    unsubscribedRelations = [],
    // whitelistedPeopleIds = [],
    // blacklistedPeopleIds = [],
    onDone,
    ...restForModal
  } = props;

  const { people, actions: directoryActions } = useDirectoryContext();
  const notificationProfile = MANAGED_NOTIFICATION_PROFILES[type];
  const userUpdateNotificationPrefsMutation = trpc.user.updateNotificationPrefs.useMutation();

  const allUserIds = useMemo(() => _.uniq([...whitelist, ...blacklist]), [whitelist, blacklist]);

  useEffect(() => {
    directoryActions.eagerLoadOfficelyUsers(allUserIds);
  }, [allUserIds]);

  const handleSubmit = useCallback(
    async (values: UserNotifEditFormValues) => {
      const whitelist = values.whitelist;
      const blacklist = values.blacklist;
      const unsubscribeFavourites = values.subscribedGroups && !values.subscribedGroups.includes('favoriteCoworkers');
      await userUpdateNotificationPrefsMutation.mutateAsync({
        type,
        pref: {
          off: values.enabled === false,
          whitelist,
          blacklist,
          unsubscribedRelations: unsubscribeFavourites ? [UserRelation.Favourite] : undefined,
        },
      });
      onDone?.();
    },
    [type, onDone, userUpdateNotificationPrefsMutation.mutateAsync]
  );

  // const loading = userUpdateNotificationPrefsMutation.isPending;
  const loading = useMemo(() => {
    return allUserIds.some((userId) => !people.byUserId[userId]);
  }, [allUserIds, people.byUserId]);

  const commonModalProps: ModalLayoutProps = {
    title: `${notificationProfile.emoji}  ${notificationProfile.name}`,
    nested: true,
    loading,
    closeText: 'Cancel',
    submitText: 'Save',
    submitFormId: UserNotifEditForm.id,
    ...restForModal,
  };

  const initialValues = useMemo(() => {
    return {
      enabled: !off,
      subscribedGroups: !unsubscribedRelations.includes(UserRelation.Favourite) ? ['favoriteCoworkers'] : undefined,
      whitelist: whitelist.map((userId) => people.byUserId[userId])?.filter(Boolean),
      blacklist: blacklist.map((userId) => people.byUserId[userId])?.filter(Boolean),
    };
  }, [whitelist, blacklist, unsubscribedRelations, loading]);

  return (
    <ModalLayout {...commonModalProps}>
      <UserNotifEditForm onSubmit={handleSubmit} data={initialValues} type={type} />
    </ModalLayout>
  );
};

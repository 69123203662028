import { defineForm } from '@client/lib/form/helpers';
import { CommonPropsForForm, FormValues } from '@client/lib/form/types';
import { SimpleForm, SimpleFormProps } from '@client/components/shared/simple-form';
import { useMemo } from 'react';

const FORM_ID = 'account-form' as const;

const getDefinition = (disabled?: boolean) =>
  defineForm({
    id: FORM_ID,
    fields: {
      admins: {
        type: 'multi-select-people',
        label: 'Account Admins',
        description: 'These people will be able to manage your organization including your billing information.',
        closeOnSelect: true,
      },
    },
    conditions: {
      disabled: {
        admins: disabled,
      },
    },
  });

type FormDefinition = ReturnType<typeof getDefinition>;
type Props = Omit<SimpleFormProps<FormDefinition>, 'definition'> & {
  disabled?: boolean;
};

export type AccountFormValues = FormValues<FormDefinition>;

const AccountForm = (props: Props) => {
  const { data, onSubmit, disabled, ...restForForm } = props;

  const definition = useMemo(() => getDefinition(disabled), [disabled]);

  return <SimpleForm definition={definition} onSubmit={onSubmit} data={data} {...restForForm} />;
};

AccountForm.id = FORM_ID;

export { AccountForm };

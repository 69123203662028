import { cn } from '@client/lib/utils';
import React from 'react';

type Props = React.PropsWithChildren<{
  className?: string;
  centered?: boolean;
}>;

export const DefaultLayout = (props: Props) => {
  const { className, centered = false } = props;
  // using tailwind css
  // we want the content to be centered on the page
  // but towards the top with a little margin
  return (
    <div
      className={cn(className, 'max-w-2xl md:ml-12 lg:ml-32 pt-6 mb-6 px-3', {
        // we want to center the content horizontally but not vertically
        'flex flex-col items-center': centered,
      })}>
      {props.children}
    </div>
  );
};

import { trpc } from '@client/lib/trpc';
import React from 'react';

type Result = {
  googlePlaceId: string;
  address: string;
};

type Options = {
  includeSuggestedLocations?: boolean;
};

const SUGGESTED_LOCATIONS: Result[] = [
  { googlePlaceId: 'ChIJdd4hrwug2EcRmSrV3Vo6llI', address: 'London, UK' },
  { googlePlaceId: 'ChIJOwg_06VPwokRYv534QaPC8g', address: 'New York, NY, USA' },
  { googlePlaceId: 'ChIJ90260rVG1moRkM2MIXVWBAQ', address: 'Melbourne VIC, Australia' },
];

export const useAddressSearch = (options?: Options) => {
  const [searchTerm, setSearchTerm] = React.useState('');
  const [results, setResults] = React.useState<Result[] | undefined>(
    options?.includeSuggestedLocations ? SUGGESTED_LOCATIONS : undefined
  );

  const placePredicationsQuery = trpc.googleMaps.getPlacePredictions.useQuery(
    {
      query: searchTerm,
    },
    {
      enabled: !!searchTerm,
    }
  );

  React.useEffect(() => {
    if (placePredicationsQuery.data) {
      setResults(placePredicationsQuery.data);
    }
  }, [placePredicationsQuery.data]);

  return {
    searchTerm,
    setSearchTerm,
    results,
    isLoading: placePredicationsQuery.isLoading,
  };
};

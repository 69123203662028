import { SortMethod } from '@client/features/home/const';
import { BookingMethod, UserWorkStatusType } from '@officely/models';
import pluralize from 'pluralize';
import { format, getISOWeek, parse } from 'date-fns';
import { yyyy_MM_dd } from '@client/lib/const';
import { DayAction, DayActionType, HomeModalData } from '@client/features/home/types';

type NeighbourhoodSortItem = {
  name: string;
  category?: string;
};

type NeighbourhoodSortBookingItem = {
  name: string;
  isSelf?: boolean;
};

type SortableUserListItem = {
  name: string;
  note?: string;
  extraEmojis?: string[];
  fromDate?: string;
  toDate?: string;
  isSelf?: boolean;
  isFav?: boolean;
  isManager?: boolean;
  isTeammate?: boolean;
  isWaitlisted?: boolean;
};

export const getFiendlyDate = (date: string) => {
  const isToday = format(new Date(), yyyy_MM_dd) === date;
  return isToday ? 'Today' : format(parse(date, yyyy_MM_dd, new Date()), 'EEEE - d LLLL');
};

export function generateExtraStrings(
  extras: Record<
    string,
    {
      name: string;
      emoji: string;
      capacity?: number | null;
      numBooked?: number;
    }
  >
) {
  return Object.entries(extras)
    .map(([id, extra]) => ({ id, ...extra }))
    .map((extra) => {
      const pluralizedName = pluralize(extra.name, extra.numBooked).toLowerCase();
      const descriptionText = `${extra.emoji}  ${extra.numBooked} ${pluralizedName}`;
      const endingText = extra.id === 'DOGS' ? 'coming to the office' : `booked`;
      return `${descriptionText} ${endingText}`;
    });
}

export const sortStatusType = (a: string, b: string): number => {
  const aIsOfficeId = !Object.values(UserWorkStatusType).includes(a as UserWorkStatusType);
  const bIsOfficeId = !Object.values(UserWorkStatusType).includes(b as UserWorkStatusType);
  if (aIsOfficeId && !bIsOfficeId) {
    return -1;
  }
  if (!aIsOfficeId && bIsOfficeId) {
    return 1;
  }
  if (aIsOfficeId && bIsOfficeId) {
    // for UI consistency
    return a.localeCompare(b);
  }
  const priorityMap = Object.fromEntries(
    [
      UserWorkStatusType.OFFICE,
      UserWorkStatusType.REMOTE,
      UserWorkStatusType.OFF_SICK,
      UserWorkStatusType.WORK_TRAVEL,
      UserWorkStatusType.CLIENT_OFFICE,
      UserWorkStatusType.ANNUAL_LEAVE,
      UserWorkStatusType.NON_WORKING_DAY,
      UserWorkStatusType.NO_STATUS,
    ].map((status, i) => [status, i])
  );
  const aPriority = priorityMap[a] ?? Infinity;
  const bPriority = priorityMap[b] ?? Infinity;
  return aPriority > bPriority ? 1 : -1;
};

export const neighbourhoodSort = (a: NeighbourhoodSortItem, b: NeighbourhoodSortItem): number => {
  // category
  if (a.category && b.category) {
    return a.category.localeCompare(b.category);
  }
  if (a.category) {
    return -1;
  }
  if (b.category) {
    return 1;
  }
  // name
  if (a.name && b.name) {
    return a.name.localeCompare(b.name);
  }
  if (a.name) {
    return -1;
  }
  if (b.name) {
    return 1;
  }
  return 0;
};

export const sortNeighbourhoodBookings = (a: NeighbourhoodSortBookingItem, b: NeighbourhoodSortBookingItem) => {
  if (a.isSelf) {
    return -1;
  }
  if (b.isSelf) {
    return 1;
  }
  return a.name.localeCompare(b.name);
};

export const hasOwnRow = (
  args: Pick<SortableUserListItem, 'isSelf' | 'note' | 'fromDate' | 'toDate' | 'extraEmojis' | 'isWaitlisted'>
): boolean => {
  const { isSelf, note, fromDate, toDate, extraEmojis = [], isWaitlisted } = args;
  const hasDaysCount = fromDate && toDate && fromDate !== toDate;
  // eslint-disable-next-line sonarjs/prefer-immediate-return
  const hasOwnRow = Boolean(isSelf || extraEmojis.length > 0 || note || hasDaysCount || isWaitlisted);
  // console.log({ hasOwnRow, isSelf, extraCount, note, hasDaysCount });
  return hasOwnRow;
};

export const getSortMethod =
  (sort?: SortMethod) =>
  (a: SortableUserListItem, b: SortableUserListItem): number => {
    if (sort === SortMethod.NONE) {
      return 0;
    }
    if (a.isSelf) {
      return -1;
    }
    if (b.isSelf) {
      return 1;
    }
    switch (sort) {
      case SortMethod.RELEVANT_COWORKERS:
        return sortRelevantCoworkers(a, b);
      case SortMethod.ALPHABETICAL:
      default:
        return a.name?.localeCompare(b.name ?? '') ?? 0;
    }
  };

export const breakDatesAtWeekend = (dates: string[]): [string[], string[]] => {
  let hasWeekend = false;
  const week1: string[] = [];
  const week2: string[] = [];
  for (let i = 0; i < dates.length; i++) {
    const dateStr = dates[i];
    const date = parse(dateStr, yyyy_MM_dd, new Date());
    const prevDate = parse(dates[i - 1], yyyy_MM_dd, new Date());
    const dateIsoWeek = getISOWeek(date);
    const prevDateIsoWeek = getISOWeek(prevDate);
    const isFollowingWeek = !!prevDateIsoWeek && prevDateIsoWeek !== dateIsoWeek;
    if (isFollowingWeek) {
      hasWeekend = true;
    }
    if (hasWeekend) {
      week2.push(dateStr);
    } else {
      week1.push(dateStr);
    }
  }
  return [week1, week2];
};

export const dayActionToHomeModal = (payload: DayAction): HomeModalData | undefined => {
  switch (payload.type) {
    case DayActionType.ChangeNote:
      return {
        type: 'notes',
        date: payload.date,
        bookingMethod: BookingMethod.APP_HOME,
      };
    case DayActionType.SeeDetails:
      return {
        type: 'details',
        date: payload.date,
        initialFilters: payload.initialFilters,
      };
    case DayActionType.ModifyBooking:
      return {
        type: 'book',
        date: payload.date,
        officeId: payload.officeId,
      };
    case DayActionType.ChangeAwayDates:
      return {
        type: 'away-dates',
        date: payload.date,
        awayDatesType: payload.awayDatesType,
        conflictWith: payload.conflictWith,
      };
    case DayActionType.ManageAnnouncements:
      return {
        type: 'announcements',
        date: payload.date,
        officeId: payload.officeId,
        id: payload.id,
      };
    case DayActionType.CreateAnnouncement:
      return {
        type: 'create-announcement',
        date: payload.date,
        officeId: payload.officeId,
      };
    case DayActionType.InviteCoworkers:
      return {
        type: 'office-day-invite',
        date: payload.date,
        officeId: payload.officeId,
        nbhId: payload.nbhId,
      };
    default:
      return undefined;
  }
};

const sortRelevantCoworkers = (a: SortableUserListItem, b: SortableUserListItem): number => {
  const aIsManager = a.isManager;
  const bIsManager = b.isManager;
  const aIsFav = a.isFav;
  const bIsFav = b.isFav;
  const aIsTeammate = a.isTeammate;
  const bIsTeammate = b.isTeammate;
  const aName = a.name ?? '';
  const bName = b.name ?? '';
  /**
   * Sort so that managers are at the top, then favorites, then teammates, then everyone else
   */

  if (aIsManager && !bIsManager) {
    return -1;
  }
  if (!aIsManager && bIsManager) {
    return 1;
  }
  if (aIsFav && !bIsFav) {
    return -1;
  }
  if (!aIsFav && bIsFav) {
    return 1;
  }
  if (aIsTeammate && !bIsTeammate) {
    return -1;
  }
  if (!aIsTeammate && bIsTeammate) {
    return 1;
  }
  return aName.localeCompare(bName);
};


/* eslint-disable @typescript-eslint/naming-convention */
import _ from 'lodash';
import { BaseHelper } from './_base';
import { Team, User } from '../types';
import moment, { Moment } from 'moment-timezone';
import {
  USER_DAY_CHECKPOINT_HOUR_MAPPING,
  UserDayCheckpoint,
  UserNotificationType,
  UserRelation,
  UserWorkStatusType,
} from '../const';
import { UserNotificationPreferenceHelper, WeekdayHelper } from '.';
import { getCheckpointHour } from '../_internal/helpers';

const RELATIONAL_NOTIFICATION_TYPES = [
  UserNotificationType.CancellationAlert,
  UserNotificationType.OfficeCompanionAlert,
  UserNotificationType.OfficeOpportunityAlert,
] as const;

type RelationalNotificationType = (typeof RELATIONAL_NOTIFICATION_TYPES)[number];

export class UserHelper extends BaseHelper<User> {
  get currentISOWeekDay(): number {
    const { currentTimezone } = this.data;
    return moment.tz(currentTimezone).isoWeekday();
  }

  public getPreferredNbhIds(date?: string): string[] {
    const { routines, lazyBookings, lastBookedNbhIds } = this.data;
    const weekday = date ? moment(date).format('dddd').toLowerCase() : undefined;
    const routineNbhIdsOnDate = !weekday
      ? []
      : Object.entries(routines)
          .filter(([day]) => day === weekday)
          .map(([_day, routine]) =>
            routine.status === UserWorkStatusType.OFFICE ? routine.neighbourhoodIds : []
          )[0] ?? [];
    const anyRoutineNbhIds =
      Object.values(routines)
        .filter((routine) => routine.status === UserWorkStatusType.OFFICE)
        .map((routine) => (routine.status === UserWorkStatusType.OFFICE ? routine.neighbourhoodIds : []))[0] ?? [];
    const lazyBookingNbhIds = lazyBookings?.neighbourhoodIds ?? [];
    return _.uniq([...routineNbhIdsOnDate, ...lazyBookingNbhIds, ...anyRoutineNbhIds, ...lastBookedNbhIds]);
  }

  public getPreferredExtraIds(date?: string): string[] {
    const { routines } = this.data;
    const weekday = WeekdayHelper.fromIsoWeekday(moment(date).isoWeekday());
    const routine = routines[weekday];
    const routineExtraIds = routine && routine.status === UserWorkStatusType.OFFICE ? routine.extraIds : [];
    return _.uniq([...routineExtraIds]);
  }

  // optionally pass in teams to get relevant coworker ids
  public getRelevantCoworkerIds(teams?: Team[]): string[] {
    // deconstruct data
    const { favouriteCoworkerIds, managerId, directReportIds, notifications } = this.data;
    const whitelistedUsers = _.uniq(
      Object.values(notifications ?? {})
        .map((x) => x.whitelist ?? [])
        .flat()
    );
    const teamMemberIds = teams ? _.uniq(teams.map((team) => team.memberIds).flat()) : [];

    // Combine all user IDs
    const relevantCoworkerIds = [
      ...favouriteCoworkerIds, // favor favourites first
      ...whitelistedUsers, // then whitelisted users
      managerId, // then manager
      ...directReportIds,
      ...teamMemberIds,
    ].filter(Boolean) as string[];

    // Get unique IDs in sorted order
    return _.uniq(relevantCoworkerIds);
  }

  public isSubscribedToNotification<T extends UserNotificationType>(
    type: T,
    opts?: T extends RelationalNotificationType
      ? {
          userId: string;
          relations: UserRelation[];
        }
      : undefined
  ): boolean {
    const { notifications = {} } = this.data;
    const pref = notifications[type];

    if (!pref) {
      return true;
    }

    const additionalArgs: {
      userId?: string;
      relations?: UserRelation[];
    } = {};

    if (opts && 'userId' in opts) {
      additionalArgs.userId = opts.userId;
    }
    if (opts && 'relations' in opts) {
      additionalArgs.relations = opts.relations;
    }

    const isUnsubscribed = new UserNotificationPreferenceHelper(pref).isUnsubscribed(additionalArgs);
    return !isUnsubscribed;
  }

  public getDailyRoutineForDate(date: string) {
    const weekdayString = moment(date).format('dddd').toLowerCase();
    return Object.entries(this.data.routines ?? {}).find(([key]) => key === weekdayString)?.[1];
  }

  public getCheckpoints() {
    const { currentTimezone: timezone, routines } = this.data;
    const weekdays = [1, 2, 3, 4, 5].filter(
      (day) => routines[WeekdayHelper.fromIsoWeekday(day)]?.status !== UserWorkStatusType.NON_WORKING_DAY
    );
    return UserHelper.getUserCheckpoints({
      timezone,
      weekdays,
    });
  }

  public static getUserCheckpoints(
    args: { timezone: string; weekdays: number[] },
    __referenceMForTestingOnly?: Moment
  ): {
    current: UserDayCheckpoint[];
    until: Date;
  } {
    const { timezone, weekdays } = args;
    const checkpointHours = Object.values(USER_DAY_CHECKPOINT_HOUR_MAPPING);
    const { checkpointHour, until } = getCheckpointHour({
      timezone,
      weekdays,
      checkpointHours,
      __referenceMForTestingOnly,
    });

    const current = Object.entries(USER_DAY_CHECKPOINT_HOUR_MAPPING)
      .filter(([_k, v]) => v === checkpointHour)
      .map(([k]) => k as UserDayCheckpoint);

    return {
      current,
      until,
    };
  }
}

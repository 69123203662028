/* eslint-disable sonarjs/no-small-switch */

import { Emoji } from '@client/components/ui/custom/emoji';
import { TypographyMuted } from '@client/components/ui/custom/typography-muted';
import { TypographyP } from '@client/components/ui/custom/typography-p';
import { Button } from '@client/components/ui/button';
import { ButtonGroup } from '@client/components/ui/custom/button-group';
import { Link } from '@client/components/ui/custom/link';
import { Select } from '@client/components/ui/custom/select';
import { trpc } from '@client/lib/trpc';
import { BillingDowngradeReason, BillingPeriod, BillingPlan, BillingPlanHelper } from '@officely/models';
import { useCallback, useEffect, useState } from 'react';
import { SectionBlock } from '@client/components/ui/custom/blocks/section';
import { ContextBlock } from '@client/components/ui/custom/blocks/context';
import { useRouter } from '@tanstack/react-router';
import { CommonPropsForModal } from '@client/lib/modal';
import { ModalLayout, ModalLayoutProps } from '@client/components/ui/custom/modal-layout';
import {
  BillingDowngradeForm,
  BillingDowngradeFormValues,
} from '@client/features/billing/components/billing-downgrade-form';
import { HELP_CENTER_URL } from '@client/features/app/const';
import { SettingsBillingStep } from '@client/features/billing/const';
import { toast } from 'sonner';

type Props = CommonPropsForModal & {
  currentPlan: BillingPlan;
  customerId?: string | undefined;
  userName: string;
  billingStep?: SettingsBillingStep | undefined;
  changeBillingStep: (step?: SettingsBillingStep) => void;
  onPlanChanged?: (plan: BillingPlan) => void;
};

type Step = 'summary' | 'plans' | 'cancel';

type ModalStepProps<T = object> = T & Props;

const OFFICELY_WEBSITE = 'https://getofficely.com/pricing';
const STEP_CONFIG: Record<Step, { title: string; description?: string }> = {
  summary: {
    title: 'Your Plan',
  },
  plans: {
    title: 'Available Plans',
    // description: 'Choose a new billing plan',
  },
  cancel: {
    title: 'Cancel Your Plan',
    // description: 'Choose a new billing plan',
  },
};

const BillingModalSummary = (props: ModalStepProps) => {
  const { currentPlan, customerId, userName, changeBillingStep, ...restForModal } = props;

  const accountBillingPortalUrlQuery = trpc.account.getPortalUrl.useQuery(undefined, {
    enabled: !!customerId,
  });
  const accountHasPaymentMethodQuery = trpc.account.hasPaymentMethod.useQuery(undefined, {
    enabled: !!customerId,
  });
  const accountBillingCheckoutUrlQuery = trpc.account.getCheckoutUrl.useQuery({});

  const hasPaymentMethod = !!accountHasPaymentMethodQuery.isFetching ? true : accountHasPaymentMethodQuery.data;
  // console.log(hasPaymentMethod, accountHasPaymentMethodQuery.data, accountBillingPortalUrlQuery.data, customerId);

  const billingHelper = new BillingPlanHelper(currentPlan);
  const planConfig = billingHelper.config;
  const planIsFree = billingHelper.isFreePlan();
  const planIsAnnual = billingHelper.isAnnualPlan();
  const billPeriodText = planIsAnnual ? '  (Annual Plan)' : '';

  const loading = false;
  // const loading = Boolean(
  //   accountHasPaymentMethodQuery.isFetching ||
  //     accountBillingCheckoutUrlQuery.isFetching ||
  //     accountHasPaymentMethodQuery.isFetching
  // );

  const propsForModal: ModalLayoutProps = {
    title: STEP_CONFIG.summary.title,
    description: STEP_CONFIG.summary.description,
    loading,
    closeText: props.nested ? 'Back' : 'Close',
    ...restForModal,
  };

  return (
    <ModalLayout {...propsForModal}>
      <TypographyP className="flex items-center">
        <Emoji className="mr-2">💳</Emoji>
        <span className="font-medium">
          {planConfig.name}
          {billPeriodText}
        </span>
      </TypographyP>
      <TypographyP>{planConfig.description}</TypographyP>

      <ButtonGroup className="mt-4">
        {!planIsFree && !hasPaymentMethod && (
          <Button variant={'default'} asChild>
            {/* Add Payment Card */}
            {/* <a href={stripeCheckoutUrl} target={'_blank'}>
              Add Payment Card
            </a> */}
            <Link to={accountBillingCheckoutUrlQuery.data!} newTab>
              Add Payment Card
            </Link>
          </Button>
        )}
        {!planIsAnnual && (planIsFree || hasPaymentMethod) && (
          <Button onClick={() => changeBillingStep(SettingsBillingStep.Plans)}>Change Plan</Button>
        )}
        {hasPaymentMethod && (
          <Button variant={'outline'} asChild>
            {/* <a href={stripePortalUrl}>Manage Billing</a> */}
            <Link to={accountBillingPortalUrlQuery.data!} newTab>
              Manage Billing
            </Link>
          </Button>
        )}
      </ButtonGroup>

      {planIsAnnual && (
        <TypographyMuted className="mt-2">
          For queries and changes to your annual plan, please reach out to our support team at hello@getofficely.com
        </TypographyMuted>
      )}
    </ModalLayout>
  );
};

const BillingModalPlans = (props: ModalStepProps) => {
  const { currentPlan, customerId, changeBillingStep, onPlanChanged, userName, ...restForModal } = props;

  const router = useRouter();
  const [isQuotingAnnualPlan, setIsQuotingAnnualPlan] = useState(false);

  // Default selected plan to current plan
  const [selectedPlan, setSelectedPlan] = useState<BillingPlan>(currentPlan);
  const currentPlanHelper = new BillingPlanHelper(currentPlan);
  const selectedPlanHelper = new BillingPlanHelper(selectedPlan);
  const billingPeriodGroupMap = {
    [BillingPeriod.MONTHLY]: 'Monthly',
    [BillingPeriod.ANNUAL]: 'Annual',
    [BillingPeriod.NONE]: 'Default',
  };

  useEffect(() => {
    setSelectedPlan(currentPlan);
  }, [currentPlan]);

  // ------ TRPC Queries ------
  const accountBillingPortalUrlQuery = trpc.account.getPortalUrl.useQuery(undefined, {
    enabled: !!customerId,
  });
  const accountBillingCheckoutUrlQuery = trpc.account.getCheckoutUrl.useQuery(
    {
      desiredPlan: selectedPlan,
    },
    {
      enabled: selectedPlan !== currentPlan,
    }
  );
  const accountHasPaymentMethodQuery = trpc.account.hasPaymentMethod.useQuery(undefined, {
    enabled: !!customerId,
  });

  const accountChangePlanMutation = trpc.account.changePlan.useMutation();

  const accountAnnualPlanQuoteQuery = trpc.account.getAnnualPlanQuote.useQuery(
    {
      plan: selectedPlan,
    },
    {
      enabled: isQuotingAnnualPlan,
    }
  );

  // --------------------------

  const availablePlans = Object.values(BillingPlan).filter((plan) =>
    new BillingPlanHelper(plan).isAvailable(currentPlan)
  );

  const options = availablePlans
    .map((plan) => new BillingPlanHelper(plan))
    .filter((planHelper) => planHelper.isAvailable(currentPlan))
    .map((planHelper) => ({
      value: planHelper.data,
      label: `${planHelper.config.name}${planHelper.data === currentPlan ? '  - current' : ''}`,
      group: billingPeriodGroupMap[planHelper.config.billingPeriod],
    }));

  const hasDiff = selectedPlan !== currentPlan;
  const isDowngrade = currentPlanHelper.isBetterThan(selectedPlan);
  const isUpgrade = currentPlanHelper.isWorseThan(selectedPlan);
  const isFromMonthlyToAnnual = !currentPlanHelper.isAnnualPlan() && selectedPlanHelper.isAnnualPlan();
  const isCancellation = hasDiff && selectedPlanHelper.isFreePlan();
  const hasPaymentMethod = accountHasPaymentMethodQuery.data ?? false;

  const handleSubmit = useCallback(async () => {
    try {
      if (isCancellation) {
        changeBillingStep(SettingsBillingStep.Cancel);
      } else if (isFromMonthlyToAnnual && !isQuotingAnnualPlan) {
        setIsQuotingAnnualPlan(true);
      } else {
        await accountChangePlanMutation.mutateAsync({
          newPlan: selectedPlan,
        });
        changeBillingStep(undefined);
        onPlanChanged?.(selectedPlan);
      }
    } catch (_err) {
      const err = _err as Error;
      toast.error(`An error occurred while changing your plan: "${err.message}"`);
    }
  }, [
    isCancellation,
    selectedPlan,
    changeBillingStep,
    isQuotingAnnualPlan,
    accountChangePlanMutation.mutateAsync,
    onPlanChanged,
  ]);

  const handleBack = useCallback(() => {
    if (isQuotingAnnualPlan) {
      setIsQuotingAnnualPlan(false);
    } else {
      changeBillingStep(undefined);
    }
  }, [isQuotingAnnualPlan, setIsQuotingAnnualPlan, changeBillingStep]);

  const loading = Boolean(
    accountBillingPortalUrlQuery.isFetching ||
      accountBillingCheckoutUrlQuery.isFetching ||
      accountHasPaymentMethodQuery.isFetching ||
      accountChangePlanMutation.isPending ||
      accountAnnualPlanQuoteQuery.isFetching
  );

  const isOnAnnualQuoteStep = isQuotingAnnualPlan && accountAnnualPlanQuoteQuery.data;
  const needsToCheckout = !hasPaymentMethod && !selectedPlanHelper.isFreePlan();
  const isOnSamePlan = selectedPlan === currentPlan;

  const submitText =
    needsToCheckout || isOnSamePlan
      ? undefined
      : isFromMonthlyToAnnual
        ? isQuotingAnnualPlan
          ? 'Purchase'
          : 'Continue'
        : isUpgrade
          ? 'Upgrade'
          : isDowngrade
            ? 'Downgrade'
            : 'Change Plan';

  const onSubmit = needsToCheckout || isOnSamePlan ? undefined : handleSubmit;

  const checkoutBtn =
    !hasPaymentMethod && !selectedPlanHelper.isFreePlan() ? (
      <Button variant={'default'} asChild>
        <a href={accountBillingCheckoutUrlQuery.data} target={'_blank'}>
          Go to Checkout  →
        </a>
      </Button>
    ) : undefined;

  const propsForModal: ModalLayoutProps = {
    ...restForModal,
    title: isOnAnnualQuoteStep ? `${selectedPlanHelper.config.name} (annual)` : STEP_CONFIG.plans.title,
    description: STEP_CONFIG.plans.description,
    loading,
    closeText: 'Back',
    submitText,
    onSubmit,
    onClose: handleBack,
  };

  if (isOnAnnualQuoteStep) {
    const { discountPercentage, totalAnnualPrice } = accountAnnualPlanQuoteQuery.data;
    const selectedPlanHelper = new BillingPlanHelper(selectedPlan);
    const discountPercentageText =
      discountPercentage && discountPercentage > 0 && !selectedPlanHelper.isWorseThan(currentPlan)
        ? `The annual plan gives you a ${discountPercentage.toFixed(1)}% discount off your current billing and will be charged in-full upfront.`
        : `The annual plan will be charged in-full upfront.`;

    const explanationText = `${discountPercentageText} If you add more users over the course of the billing period, we’ll amend your subscription to reflect this and charge a pro-rata amount. If you have any questions, you can <${HELP_CENTER_URL}|speak with support here>.\n\n*Total (12 months): $${totalAnnualPrice!.toFixed(2)}*
`;
    return (
      <ModalLayout {...propsForModal}>
        <SectionBlock text={explanationText} />
        <ContextBlock elements={['Calculated based on your current usage']} />
        <ButtonGroup className="mt-4">{checkoutBtn}</ButtonGroup>
      </ModalLayout>
    );
  }

  return (
    <ModalLayout {...propsForModal}>
      <TypographyP>
        For details of each plan, please see our{' '}
        <Link to={OFFICELY_WEBSITE} newTab>
          pricing page
        </Link>
        .
      </TypographyP>
      <div className="mt-4">
        <Select
          options={options}
          onChange={(value) => setSelectedPlan(value as BillingPlan)}
          selected={options.find((option) => option.value === selectedPlan)}
        />
      </div>

      {selectedPlanHelper && (
        <TypographyMuted className="mt-2">{selectedPlanHelper.config.description}</TypographyMuted>
      )}

      {checkoutBtn && <ButtonGroup className="mt-4">{checkoutBtn}</ButtonGroup>}
    </ModalLayout>
  );
};

const BillingModalCancel = (props: ModalStepProps) => {
  const { userName, changeBillingStep, onPlanChanged, currentPlan, customerId, ...restForModal } = props;
  const router = useRouter();

  const accountChangePlanMutation = trpc.account.changePlan.useMutation();

  const handleSubmit = useCallback(
    async (values: BillingDowngradeFormValues) => {
      try {
        await accountChangePlanMutation.mutateAsync({
          newPlan: BillingPlan.FREE,
          downgradeComment: values.comment,
          downgradeReasons: values.reasons as BillingDowngradeReason[] | undefined,
        });
        changeBillingStep(undefined);
        onPlanChanged?.(BillingPlan.FREE);
      } catch (_err) {
        const err = _err as Error;
        toast.error(`An error occurred while changing your plan: "${err.message}"`);
      }
    },
    [changeBillingStep, router.invalidate, accountChangePlanMutation.mutateAsync, onPlanChanged]
  );

  const loading = accountChangePlanMutation.isPending;

  const propsForModal: ModalLayoutProps = {
    title: STEP_CONFIG.cancel.title,
    description: STEP_CONFIG.cancel.description,
    loading,
    closeText: 'Cancel',
    submitText: 'Cancel Plan',
    submitFormId: BillingDowngradeForm.id,
    ...restForModal,
  };

  return (
    <ModalLayout {...propsForModal}>
      <TypographyP>
        {`Hey ${userName}, so sorry to hear things didn’t work out  😔  Before you go, could you please tell us why you’re choosing to do so?`}
      </TypographyP>

      <div className="mt-4">
        <BillingDowngradeForm onSubmit={handleSubmit} />
      </div>
    </ModalLayout>
  );
};

export const BillingModal = (props: Props) => {
  switch (props.billingStep) {
    case 'plans':
      return <BillingModalPlans {...props} />;
    case 'cancel':
      return <BillingModalCancel {...props} />;
    default:
      return <BillingModalSummary {...props} />;
  }
};

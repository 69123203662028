import { createFileRoute } from '@tanstack/react-router';
import { ManagedNotificationType, managedNotificationTypeSchema } from '@officely/models';
import { useNavigate } from '@tanstack/react-router';
import { useCallback } from 'react';
import { z } from 'zod';
import { UserNotifsModal } from '@client/features/settings/components/user-notifs-modal';

function HomeSettingsNotifications() {
  const navigate = useNavigate({ from: Route.fullPath });
  const { userName, notifications } = Route.useLoaderData();
  const { t: notificationType } = Route.useSearch();

  const handleClose = useCallback(() => {
    void navigate({
      to: '/home/settings',
      search: (prev) => ({
        ...prev,
        t: undefined,
      }),
    });
  }, [navigate]);

  const handleTypeChange = useCallback(
    (type?: ManagedNotificationType) => {
      void navigate({
        to: '/home/settings/notifications',
        search: (prev) => ({
          ...prev,
          t: type,
        }),
      });
    },
    [navigate]
  );

  const propsForModal = {
    nested: true,
    onClose: handleClose,
  };
  return (
    <div>
      <UserNotifsModal
        {...propsForModal}
        userName={userName}
        notifications={notifications}
        type={notificationType}
        onTypeChange={handleTypeChange}
      />
    </div>
  );
}

export const Route = createFileRoute('/_authenticated/home/settings/notifications')({
  component: HomeSettingsNotifications,
  validateSearch: z.object({
    t: managedNotificationTypeSchema.optional().catch(undefined),
  }),
  loader: async ({ context }) => {
    const user = await context.trpcUtils.user.current.fetch();
    const { notifications } = user;
    return {
      userName: user.profile.firstName ?? user.profile.name,
      notifications,
    };
  },
});

import { useDirectoryContext } from '@client/context/directory';
import { trpc } from '@client/lib/trpc';
import { Weekday, WeekdayHelper } from '@officely/models';
import { useCallback } from 'react';
import { toast } from 'sonner';

type Args = {
  officeId: string;
};

type Values = {
  name?: string;
  emoji?: string;
  admins?: string[];
  workingDays?: string[];
  floorPlanUrl?: string;
};

export const useOfficeSettings = ({ officeId }: Args) => {
  const officeUpdateProfileMutation = trpc.office.updateProfile.useMutation();
  const {
    actions: { invalidateOffices },
  } = useDirectoryContext();

  const updateOffice = useCallback(
    async (values: Values) => {
      const promise = officeUpdateProfileMutation
        .mutateAsync({
          id: officeId,
          name: values.name,
          emoji: values.emoji,
          admins: values.admins,
          weekdays: values.workingDays?.map((x) => new WeekdayHelper(x as Weekday).isoWeekday),
          floorPlanUrl: values.floorPlanUrl,
        })
        .then(invalidateOffices);
      toast.promise(promise, {
        loading: 'Updating office...',
        success: 'Office updated',
        error: 'Failed to update office',
      });
      await promise;
    },
    [officeId, officeUpdateProfileMutation, toast, invalidateOffices]
  );

  return {
    updateOffice,
  };
};

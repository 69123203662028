import { NOTE_SUGGESTIONS, NOTE_PLACEHOLDERS } from '@officely/models';
import { useMemo } from 'react';

export const useNoteSuggestions = (type: string | undefined) => {
  const _suggestions = useMemo(() => (type ? NOTE_SUGGESTIONS[type] : undefined), [type]);
  const _notePlaceholder = useMemo(() => (type ? NOTE_PLACEHOLDERS[type] : undefined), [type]);

  const suggestions = _suggestions ?? [];
  const notePlaceholder = _notePlaceholder ?? 'Add a note?';

  return { suggestions, notePlaceholder };
};

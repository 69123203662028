import { defineForm } from '@client/lib/form/helpers';
import { useForm } from '@client/lib/form/useForm';
import { useCallback, useEffect, useMemo } from 'react';
import { Form } from '@client/components/ui/form';
import { FormField } from '@client/components/shared/FormField';
import { CommonPropsForForm, FormValues } from '@client/lib/form/types';

const FORM_ID = 'broadcast-form' as const;

const getDefinition = () =>
  defineForm({
    id: FORM_ID,
    fields: {
      channel: {
        type: 'select-team-channel',
        label: 'Broadcast Channel',
        // required: true,
        allowClear: true,
      },
      sendTime: {
        type: 'radio',
        label: 'Send Time',
        options: [
          { value: 'today', label: "In the morning (showing who's in the office today)" },
          { value: 'tomorrow', label: "In the afternoon (showing who's in the office tomorrow)" },
        ],
        description: 'Sending the day before can help people plan their day better.',
      },
    },
    defaultValues: {
      sendTime: 'tomorrow',
    },
    validate(values, errors) {
      if (!values.channel) {
        errors.channel = 'Channel is required';
      }
      return errors;
    },
  });

export type BroadcastFormValues = FormValues<ReturnType<typeof getDefinition>>;
type Props = CommonPropsForForm<BroadcastFormValues>;

const BroadcastForm = (props: Props) => {
  const { data, onSubmit } = props;

  const definition = useMemo(getDefinition, []);

  const { form, setValues, conditions } = useForm(definition);

  useEffect(() => {
    // whenever data changes reset the form and set the values
    form.reset();
    if (data) {
      setValues(data);
    }
  }, [form.reset, data]);

  const handleSubmit = useCallback(
    form.handleSubmit((values) => {
      onSubmit?.(values);
      form.reset(values);
    }),
    [form, onSubmit]
  );

  return (
    <Form {...form}>
      <form onSubmit={handleSubmit} className="flex flex-col gap-y-6" id={FORM_ID}>
        {Object.keys(definition.fields)
          .map((key) => key as keyof typeof definition.fields)
          .map((name) => {
            return (
              <FormField
                {...definition.fields[name]}
                key={name}
                name={name}
                control={form.control}
                disabled={conditions?.disabled?.[name]}
                hidden={conditions?.hidden?.[name]}
              />
            );
          })}
      </form>
    </Form>
  );
};

BroadcastForm.id = FORM_ID;

export { BroadcastForm };

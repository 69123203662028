import { createFileRoute } from '@tanstack/react-router';
import { useCallback, useMemo, useState } from 'react';
import { useRouter } from '@tanstack/react-router';
import { Modal } from '@client/components/ui/custom/modal';
import { DefaultExtraId, WeekdayHelper } from '@officely/models';
import { ExtraTable } from '@client/features/settings/components/extra-table';
import { useExtraSettings } from '@client/features/settings/hooks/use-extra-settings';
import { ExtraModal } from '@client/features/settings/components/extra-modal';
import { ExtraFormValues } from '@client/features/settings/components/extra-form';

function SettingsOfficesOfficeExtras() {
  const { office } = Route.useLoaderData();
  const { extras: _extras } = office;
  const params = Route.useParams();

  const extras = useMemo(() => {
    return _extras.map((e) => ({
      ...e,
      capacity: e.capacity ?? undefined,
      weekdays: e.weekdays?.map((day) => WeekdayHelper.fromIsoWeekday(day).toLowerCase()) ?? undefined,
    }));
  }, [_extras]);

  const { createExtra, updateExtra, deleteExtras } = useExtraSettings({ officeId: params.id });

  const [formConfig, setFormConfig] = useState<{
    open: boolean;
    id?: string;
  }>({
    open: false,
  });

  const router = useRouter();

  const editIsDefault = useMemo<boolean>(() => {
    if (!formConfig.id) return false;
    return Object.values(DefaultExtraId).includes(formConfig.id as any);
  }, [formConfig.id]);

  const editData = useMemo<ExtraFormValues | undefined>(() => {
    if (!formConfig.id) return undefined;
    const extra = extras.find((x) => x.id === formConfig.id);
    return (
      extra && {
        name: extra.name,
        emoji: extra.emoji,
        capacity: extra.capacity,
        limit: typeof extra.capacity === 'number' ? 'true' : 'false',
        daysOfWeek: extra.weekdays,
        enabled: extra.enabled,
      }
    );
  }, [formConfig.id, extras]);

  const handleSubmit = useCallback(
    async (values: ExtraFormValues) => {
      if (formConfig.id) {
        await updateExtra(formConfig.id, values);
      } else {
        await createExtra(values);
      }
      setFormConfig({ open: false });
      await router.invalidate();
    },
    [formConfig.id, updateExtra, createExtra, setFormConfig, router]
  );

  const handleEdit = useCallback(
    (id: string) => {
      setFormConfig({
        open: true,
        id,
      });
    },
    [setFormConfig]
  );

  const handleAdd = useCallback(() => {
    setFormConfig({
      open: true,
    });
  }, [setFormConfig]);

  const handleDelete = useCallback(
    async (ids: string[]) => {
      await deleteExtras(ids);
      await router.invalidate();
    },
    [deleteExtras, router]
  );

  const handleModalClose = useCallback(() => {
    setFormConfig({
      open: false,
    });
  }, [setFormConfig]);

  const rows = useMemo(() => {
    return extras.map((e) => ({
      ...e,
      daysOfWeek: e.weekdays,
      profile: {
        name: e.name,
        emoji: e.emoji,
      },
    }));
  }, [extras]);

  return (
    <div>
      <ExtraTable rows={rows} onEdit={handleEdit} onDelete={handleDelete} onAdd={handleAdd} />
      <Modal open={formConfig.open} onClose={handleModalClose}>
        <ExtraModal data={editData} isDefault={editIsDefault} onClose={handleModalClose} onSubmit={handleSubmit} />
      </Modal>
    </div>
  );
}

export const Route = createFileRoute('/_authenticated/home/settings/offices/$id/extras')({
  component: SettingsOfficesOfficeExtras,
  loader: async ({ context, params }) => {
    const office = await context.trpcUtils.office.getById.fetch(params.id);
    return { office };
  },
});

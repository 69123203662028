/* eslint-disable sonarjs/prefer-immediate-return */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { FormValues, Conditions, LooseDefinition } from './types';
import { useForm as useFormPrimitive } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useCallback, useEffect, useMemo } from 'react';

export const useForm = <T extends LooseDefinition>(definition: T) => {
  const form = useFormPrimitive<FormValues<T>>({
    mode: 'onSubmit',
    resolver: zodResolver(definition.schema),
    defaultValues: definition.defaultValues as any,
  });

  const setValues = useCallback(
    (update: Partial<FormValues<T>>) => {
      Object.keys(update).forEach((prop) => {
        form.setValue(prop as any, update[prop] as any);
      });
    },
    [form]
  );

  const shouldWatchValues = typeof definition.conditions === 'function';
  const valuesWatch = shouldWatchValues ? form.watch() : undefined;

  const conditions = (
    valuesWatch
      ? useMemo(() => {
          return (
            (definition.conditions as any)?.(valuesWatch) ?? {
              disabled: {},
              hidden: {},
            }
          );
        }, [valuesWatch])
      : definition.conditions
  ) as Conditions<T['fields']>;

  // useEffect(() => {
  //   // console.log('conditions', conditions);
  // }, [conditions]);

  return {
    form,
    setValues,
    conditions,
  };
};

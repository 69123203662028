/* eslint-disable sonarjs/no-small-switch */

import { CommonPropsForModal } from '@client/lib/modal';
import { Emoji } from '@client/components/ui/custom/emoji';
import { TypographyMuted } from '@client/components/ui/custom/typography-muted';
import { TypographyP } from '@client/components/ui/custom/typography-p';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@client/components/ui/accordion';

import { Button } from '@client/components/ui/button';
import { ButtonGroup } from '@client/components/ui/custom/button-group';
import { ModalLayout, ModalLayoutProps } from '@client/components/ui/custom/modal-layout';
import { MANAGED_NOTIFICATION_PROFILES, UserNotificationType, ManagedNotificationType, User } from '@officely/models';
import { useCallback, useState } from 'react';
import { UserNotifEditModal } from '@client/features/settings/components/user-notif-edit-modal';

const TITLE = 'Notification Preferences';
const DESCRIPTION = 'Choose how you want to receive notifications from Officely.';
const HEXAGON_SIZE = 2;

const SUPPORTED_TYPES = [
  UserNotificationType.CancellationAlert,
  UserNotificationType.OfficeOpportunityAlert,
  UserNotificationType.OfficeCompanionAlert,
  UserNotificationType.StatusReminder,
  UserNotificationType.WeeklyReminder,
];

type Profile = (typeof MANAGED_NOTIFICATION_PROFILES)[ManagedNotificationType];

type Props = CommonPropsForModal & {
  userName: string;
  notifications: User['notifications'];
  type: ManagedNotificationType | undefined;
  onTypeChange: (type: ManagedNotificationType | undefined) => void;
};

const ExampleMessage = (props: { profile: Profile; userName: string }) => {
  const { profile, userName } = props;
  return (
    <div className="flex items-start mt-10 mb-6">
      <div
        style={{
          appearance: 'none',
          position: 'relative',
          display: 'inline-block',
          minWidth: `${HEXAGON_SIZE}rem`, // Adjusted due to rotation, was using $hex-height
          height: `calc(${HEXAGON_SIZE}rem * 1.7321 / 2)`, // Adjusted due to rotation, was using $hex-min-width calculation
          // margin: 'auto',
          padding: `calc(${HEXAGON_SIZE}rem / (2 * 1.7321) * 1.5) 0.5rem`, // Adjusted due to rotation, was using $hex-distance calculation
          textAlign: 'center',
          fontSize: '1rem',
          lineHeight: '1rem',
          verticalAlign: 'middle',
          color: '#ffce00', // Using $border-color
          cursor: 'pointer',
          border: 'none',
          backgroundColor: 'rgba(0,0,0, .25)',
          clipPath: 'polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%)', // Rotated 90 degrees
        }}>
        <img
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            position: 'absolute',
            top: '0',
            left: '0',
          }}
          src="https://cdn.getofficely.com/logo/icon-color.png"
          alt="Profile image"
        />
      </div>
      <div className="ml-3 relative">
        <TypographyMuted className="absolute left-0 -top-5 text-xs">Officely</TypographyMuted>
        <div className="px-4 py-2 max-w-sm bg-gray-100 rounded-lg dark:bg-gray-700">
          {/* <p className="text-sm font-medium text-gray-900 dark:text-white">Officely</p> */}
          <TypographyP>{profile.example.replace('{{name}}', userName)}</TypographyP>
          {/* <p className="text-sm text-gray-500 dark:text-gray-300">
    {profile.example.replace('{{name}}', userName)}
  </p> */}
        </div>
      </div>
    </div>
  );
};

export const UserNotifsModal = (props: Props) => {
  const { userName, type, notifications, onTypeChange, ...restForModal } = props;
  const [expanded, setExpanded] = useState<string | undefined>();

  const handleEditModalClose = useCallback(() => {
    onTypeChange(undefined);
  }, [onTypeChange]);

  if (type) {
    const pref = notifications[type] ?? {};
    return (
      <UserNotifEditModal
        type={type}
        {...pref}
        variant={props.variant}
        nested
        onClose={handleEditModalClose}
        onDone={handleEditModalClose}
      />
    );
  }

  const commonModalProps: ModalLayoutProps = {
    title: TITLE,
    description: DESCRIPTION,
    closeText: props.nested ? 'Back' : 'Close',
    ...restForModal,
  };

  return (
    <ModalLayout {...commonModalProps}>
      <Accordion type="single" collapsible className="w-full" value={expanded} onValueChange={setExpanded}>
        {Object.entries(MANAGED_NOTIFICATION_PROFILES)
          .filter(([value]) => SUPPORTED_TYPES.includes(value as UserNotificationType))
          .map(([value, profile]) => (
            <AccordionItem value={value} key={value}>
              <AccordionTrigger className="hover:no-underline">
                <div className="flex justify-center items-center">
                  <Emoji className="mr-2">{profile.emoji}</Emoji>
                  {profile.name}
                </div>
              </AccordionTrigger>
              <AccordionContent>
                <TypographyP>{profile.description}</TypographyP>
                <ExampleMessage profile={profile} userName={userName} />
                <ButtonGroup>
                  <Button onClick={() => onTypeChange(value as ManagedNotificationType)}>Manage</Button>
                </ButtonGroup>
              </AccordionContent>
            </AccordionItem>
          ))}
      </Accordion>
    </ModalLayout>
  );
};

import {
  BILLING_PLAN_CONFIGS,
  BillingPlan,
  BillingRestrictedFeature,
  HRIS_STATUSES,
  UserWorkStatusType,
} from '../const';
import { Account } from '../types';
import { BillingPlanHelper } from './billingPlan';
import { BaseHelper } from './_base';
import _ from 'lodash';

const ALWAYS_ENABLED_STATUSES = [UserWorkStatusType.OFFICE, UserWorkStatusType.NO_STATUS];

export class AccountHelper extends BaseHelper<Account> {
  public isOrgManager(userId: string): boolean {
    return this.data.managers.length === 0 || this.data.managers.includes(userId);
  }

  public get enabledUserWorkStatuses(): {
    readable: UserWorkStatusType[];
    bookable: UserWorkStatusType[];
    hrisRestricted: UserWorkStatusType[];
  } {
    const billingPlanHelper = new BillingPlanHelper(this.data.billing.plan as BillingPlan);
    const canUseStatuses = billingPlanHelper.canUse(BillingRestrictedFeature.STATUSES);
    const readable: UserWorkStatusType[] = [];
    const bookable: UserWorkStatusType[] = [];
    const hrisRestricted: UserWorkStatusType[] = this.data.usingHris
      ? _(this.data.hrisAbsenceTypeMapping)
          .values()
          .map((m) => m.status)
          // Same as uniq(), but take 2nd parameter as array.
          // Always block OTHER_ABSENCE
          .union([UserWorkStatusType.OTHER_ABSENCE])
          .value()
      : [UserWorkStatusType.OTHER_ABSENCE];

    Object.values(UserWorkStatusType).forEach((t) => {
      const disabledWorkStatuses = this.data.disabledStatuses;
      const isDisabled = disabledWorkStatuses.includes(t);
      const alwaysEnabled = ALWAYS_ENABLED_STATUSES.includes(t);

      if (!canUseStatuses && !alwaysEnabled) {
        return;
      }

      if (!isDisabled) {
        readable.push(t);
        if (!hrisRestricted.includes(t as any)) {
          bookable.push(t);
        }
      }
    });
    return {
      readable,
      bookable,
      hrisRestricted,
    };
  }

  public get isSlackAccount(): boolean {
    return Boolean(this.data.slackWorkspaceId);
  }

  public unlicencedUsers(userIds: string[]) {
    const planHelper = new BillingPlanHelper(this.data.billing.plan);
    return planHelper.config.userLimit === Infinity ? [] : _.difference(userIds, this.data.billing.licencedUsers);
  }

  public canLicenceUsers(userIds: string[]) {
    const planHelper = new BillingPlanHelper(this.data.billing.plan);
    if (planHelper.config.userLimit === Infinity) {
      return false;
    }

    const unlicencedUsers = this.unlicencedUsers(userIds);
    const spaceLeft = planHelper.config.userLimit - this.data.billing.licencedUsers.length;
    return unlicencedUsers.length <= spaceLeft;
  }

  public userIsLicenced(userId: string): boolean {
    return !this.unlicencedUsers([userId]).length;
  }

  public statusesAreEnabled(): boolean {
    return this.enabledUserWorkStatuses.bookable.length > ALWAYS_ENABLED_STATUSES.length;
  }
}

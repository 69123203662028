import { UserWorkStatusType } from '../const';
import { UserWorkStatus, UserWorkStatusOffice } from '../types';
import { UserWorkStatusMinimalHelper } from './userWorkStatusMinimal';

export class UserWorkStatusHelper<S extends UserWorkStatus> extends UserWorkStatusMinimalHelper<S> {
  constructor(public data: S) {
    super(data);
  }

  get office(): UserWorkStatusOffice['office'] | undefined {
    return this.data.type === UserWorkStatusType.OFFICE ? this.data.office : undefined;
  }
}

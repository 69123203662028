import { TypographyP } from '@client/components/ui/custom/typography-p';
import { cn } from '@client/lib/utils';
import pluralize from 'pluralize';

export const NbhHeading = (props: { name: string; category?: string; spacesLeft?: number; className?: string }) => {
  const { name, category, spacesLeft, className } = props;
  const additionalTexts = [];
  if (category) {
    additionalTexts.push(category);
  }
  if (typeof spacesLeft === 'number') {
    additionalTexts.push(spacesLeft === 0 ? `Fully booked` : `${pluralize('space', spacesLeft, true)} left`);
  }
  return (
    <TypographyP className={cn('flex items-center', className)}>
      <span className="font-bold">{name}</span>
      {additionalTexts.length > 0 ? (
        <span className="italic ml-2 text-xs text-muted-foreground">{additionalTexts.join('  |  ')}</span>
      ) : null}
    </TypographyP>
  );
};

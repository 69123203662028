/* eslint-disable sonarjs/no-small-switch */

import { CommonPropsForModal } from '@client/lib/modal';
import { ModalLayout, ModalLayoutProps } from '@client/components/ui/custom/modal-layout';
import { useCallback, useMemo } from 'react';
import { trpc } from '@client/lib/trpc';
import { UserPrefsForm, UserPrefsFormValues } from '@client/features/settings/components/user-prefs-form';
import { useDirectoryContext, useEagerLoadOfficelyUsers } from '@client/context/directory';
import { toast } from 'sonner';

const TITLE = 'Your Preferences';

type Props = CommonPropsForModal & {
  officeId?: string;
  favouriteCoworkerIds: string[];
};

export const UserPrefsModal = (props: Props) => {
  const { officeId, favouriteCoworkerIds, ...restForModal } = props;

  const userUpdateMutation = trpc.user.update.useMutation();
  const { offices } = useDirectoryContext();
  const { peopleIds: favouriteCoworkersPeopleIds } = useEagerLoadOfficelyUsers(favouriteCoworkerIds);

  const updating = userUpdateMutation.isPending;
  const loading = updating || offices.loading;

  const data = useMemo(() => {
    return {
      office: officeId,
      favouriteCoworkers: favouriteCoworkersPeopleIds,
    };
  }, [officeId, favouriteCoworkersPeopleIds]);

  const handleSubmit = useCallback((value: UserPrefsFormValues) => {
    const promise = userUpdateMutation
      .mutateAsync({
        office: value.office,
        favourites: value.favouriteCoworkers && {
          peopleIds: value.favouriteCoworkers,
          favouriteMeBack: !!value.followMeBack?.length,
        },
      })
      .then(() => {
        props.onClose?.();
      });
    toast.promise(promise, {
      loading: 'Updating your preferences...',
      success: 'Preferences updated successfully!',
      error: 'Failed to update your preferences',
    });
  }, []);

  const commonModalProps: ModalLayoutProps = {
    title: TITLE,
    // description: DESCRIPTION,
    closeText: props.nested ? 'Back' : 'Close',
    submitText: 'Save',
    submitFormId: UserPrefsForm.id,
    loading,
    ...restForModal,
  };

  return (
    <ModalLayout {...commonModalProps}>
      <UserPrefsForm offices={Object.values(offices.data)} data={data} onSubmit={handleSubmit} />
    </ModalLayout>
  );
};

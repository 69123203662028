import { Select, SelectProps } from '@client/components/ui/custom/select';
import { OfficeProfile, useDirectoryContext } from '@client/context/directory';
import { useMemo } from 'react';

type SelectOfficeProps = Pick<
  SelectProps,
  'placeholder' | 'placeholderIcon' | 'searchPlaceholder' | 'noResultsMessage' | 'inForm' | 'asBtn' | 'asBtnProps'
> & {
  value: string | undefined;
  onChange: (value: string | undefined) => void;
};

export const SelectOffice = (props: SelectOfficeProps) => {
  const { value, onChange, ...propsForPrimitive } = props;

  // ------- STATE --------

  // ------- DATA FETCHING --------

  const {
    offices: { data, loading },
  } = useDirectoryContext();

  // ------- PEOPLE --------

  const offices = useMemo(() => {
    return Object.values(data ?? {});
  }, [data]);

  const selectedOffice = useMemo(() => {
    return offices.find((office) => value === office.id);
  }, [value, offices]);

  // ------- OPTIONS --------

  const options = useMemo(() => {
    return offices.sort(byCountryAndName).map(officeToOption);
  }, [offices]);

  const selected = useMemo(() => {
    return selectedOffice && officeToOption(selectedOffice);
  }, [selectedOffice]);

  // ------- RENDER --------

  return (
    <Select
      {...propsForPrimitive}
      options={options}
      selected={selected}
      onChange={onChange}
      noResultsMessage={props.noResultsMessage ?? 'No results found.'}
      placeholder={props.placeholder ?? 'Select office...'}
      searchPlaceholder={props.searchPlaceholder ?? 'Search...'}
      loading={loading}
    />
  );
};

// ---------- HELPERS ----------------
const officeToOption = (office: OfficeProfile): SelectProps['options'][number] => ({
  value: office.id,
  label: `${office.emoji}   ${office.name}`,
  group: office.country,
});

const byCountryAndName = (a: OfficeProfile, b: OfficeProfile) => {
  if (a.country < b.country) {
    return -1;
  }
  if (a.country > b.country) {
    return 1;
  }
  if (a.name < b.name) {
    return -1;
  }
  if (a.name > b.name) {
    return 1;
  }
  return 0;
};

import { BroadcastFormValues } from '@client/features/settings/components/broadcast-form';
import { confirm } from '@client/lib/confirm';
import { trpc } from '@client/lib/trpc';
import { useCallback } from 'react';
import { toast } from 'sonner';

type Args = {
  officeId: string;
};

export const useBroadcastSettings = (args: Args) => {
  const { officeId } = args;
  const officeUpdateBroadcastMutation = trpc.office.updateBroadcast.useMutation();
  const officeSendBroadcastNowMutation = trpc.office.sendBroadcastNow.useMutation();

  const updateBroadcast = useCallback(
    async (values: BroadcastFormValues) => {
      const promise = officeUpdateBroadcastMutation.mutateAsync({
        officeId,
        data: {
          enabled: true,
          channel: values.channel,
          sendTime: values.sendTime,
        },
      });
      toast.promise(promise, {
        loading: 'Updating Office Broadcast...',
        success: 'Office Broadcast has been updated',
        error: 'Failed to update Office Broadcast',
      });
      await promise;
    },
    [officeId, officeUpdateBroadcastMutation, toast]
  );

  const enableBroadcast = useCallback(async () => {
    const promise = officeUpdateBroadcastMutation.mutateAsync({
      officeId,
      data: {
        enabled: true,
      },
    });
    toast.promise(promise, {
      loading: 'Enabling Office Broadcast...',
      success: 'Office Broadcast has been enabled',
      error: 'Failed to enable Office Broadcast',
    });
    await promise;
  }, [officeId, officeUpdateBroadcastMutation, toast]);

  const disableBroadcast = useCallback(async () => {
    const promise = officeUpdateBroadcastMutation.mutateAsync({
      officeId,
      data: {
        enabled: false,
      },
    });
    toast.promise(promise, {
      loading: 'Disabling Office Broadcast...',
      success: 'Office Broadcast has been disabled',
      error: 'Failed to disable Office Broadcast',
    });
    await promise;
  }, [officeId, officeUpdateBroadcastMutation, toast]);

  const sendTestBroadcast = useCallback(async () => {
    const confirmResult = await confirm(
      'Are you sure you want to send a test broadcast? This will send a message to your broadcast channel.'
    );
    if (confirmResult) {
      const promise = officeSendBroadcastNowMutation.mutateAsync({
        officeId,
      });
      toast.promise(promise, {
        loading: 'Sending test broadcast...',
        success: 'Your broadcast has been sent',
        error: 'Failed to send test broadcast',
      });
      await promise;
    }
  }, [officeId, officeSendBroadcastNowMutation, toast]);

  return {
    updateBroadcast,
    enableBroadcast,
    disableBroadcast,
    sendTestBroadcast,
  };
};

import { trpc } from '@client/lib/trpc';
import { OfficeAnnouncement } from '@officely/models';
import { useCallback } from 'react';
import { toast } from 'sonner';

type CreateAnnouncementData = Omit<OfficeAnnouncement, 'id' | 'createdAt' | 'createdBy'>;
type UpdateAnnouncementData = Pick<
  OfficeAnnouncement,
  'category' | 'channelIds' | 'text' | 'fromDate' | 'toDate' | 'weekdays' | 'excludedDates'
>;

export const useAnnouncements = () => {

  const officeCreateAnnouncementMutation = trpc.office.createAnnouncement.useMutation();
  const officeUpdateAnnouncementMutation = trpc.office.updateAnnouncement.useMutation();
  const officeDeleteAnnouncementMutation = trpc.office.deleteAnnouncement.useMutation();

  const trpcUtils = trpc.useUtils();

  const invalidateQueries = useCallback(async () => {
    await Promise.all([
      trpcUtils.office.listAnnouncements.invalidate(),
      trpcUtils.schedule.detail.invalidate(),
      trpcUtils.schedule.office.invalidate(),
      trpcUtils.schedule.statuses.invalidate(),
    ]);
  }, [trpcUtils]);

  const createAnnouncement = useCallback(
    async (data: CreateAnnouncementData) => {
      const promise = officeCreateAnnouncementMutation.mutateAsync(data);
      toast.promise(promise, {
        loading: 'Creating announcement...',
        success: 'Announcement created',
        error: 'Failed to create announcement',
      });
      await promise.then(invalidateQueries);
    },
    [officeCreateAnnouncementMutation, invalidateQueries]
  );

  const updateAnnouncement = useCallback(
    async (id: string, data: UpdateAnnouncementData) => {
      const promise = officeUpdateAnnouncementMutation.mutateAsync({ id, data });
      toast.promise(promise, {
        loading: 'Updating announcement...',
        success: 'Announcement updated',
        error: 'Failed to update announcement',
      });
      await promise.then(invalidateQueries);
    },
    [officeUpdateAnnouncementMutation, invalidateQueries]
  );

  const deleteAnnouncement = useCallback(
    async (id: string) => {
      const promise = officeDeleteAnnouncementMutation.mutateAsync(id);
      toast.promise(promise, {
        loading: 'Deleting announcement...',
        success: 'Announcement deleted',
        error: 'Failed to delete announcement',
      });
      await promise.then(invalidateQueries);
    },
    [officeDeleteAnnouncementMutation, invalidateQueries]
  );

  return { createAnnouncement, updateAnnouncement, deleteAnnouncement };
};

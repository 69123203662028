import { createFileRoute, useRouter } from '@tanstack/react-router';
import { CommonPropsForModal } from '@client/lib/modal';
import { useNavigate } from '@tanstack/react-router';
import { useCallback } from 'react';
import { isInChildRouteOf } from '@client/lib/routeHelpers';
import { Outlet } from '@tanstack/react-router';
import { AccountModal } from '@client/features/settings/components/account-modal';
import { AccountHelper } from '@officely/models';

function HomeSettingsAccount() {
  const navigate = useNavigate({ from: Route.fullPath });
  const router = useRouter();
  const loaderData = Route.useLoaderData();
  const isInChildRoute = isInChildRouteOf(Route.fullPath);

  const handleClose = useCallback(() => {
    void navigate({
      to: '/home/settings',
      search: true,
    });
  }, [navigate]);

  const handleOnViewPlans = useCallback(() => {
    void navigate({
      to: '/home/settings/account/billing',
      search: true,
    });
  }, [navigate]);

  const propsForModal: CommonPropsForModal = {
    onClose: handleClose,
    nested: true,
  };

  if (isInChildRoute) {
    return <Outlet />;
  }

  return (
    <AccountModal
      {...propsForModal}
      {...loaderData}
      onViewPlans={handleOnViewPlans}
      invalidateRouter={router.invalidate}
    />
  );
}

export const Route = createFileRoute('/_authenticated/home/settings/account')({
  component: HomeSettingsAccount,
  loader: async ({ context }) => {
    const { user } = context.auth!;
    const account = await context.trpcUtils.account.current.fetch();
    const accountHelper = new AccountHelper(account);
    const statusesEnabled = accountHelper.statusesAreEnabled();
    return {
      billingPlan: account.billing.plan,
      isAccountAdmin: account.managers.includes(user.id),
      adminIds: account.managers,
      customerId: account.billing.customerId ?? undefined,
      userName: user.profile.firstName ?? user.profile.name,
      disabledStatuses: statusesEnabled ? account.disabledStatuses : [],
      statusesEnabled,
    };
  },
});

import { DefaultLayout } from '@client/components/layout/DefaultLayout';
import { CommonPropsForModal } from '@client/lib/modal';
import { Button } from '@client/components/ui/button';
import { Modal } from '@client/components/ui/custom/modal';
import { Locked } from '@client/components/ui/custom/svg/locked';
import { TypographyH1 } from '@client/components/ui/custom/typography-h1';
import { TypographyP } from '@client/components/ui/custom/typography-p';
import { BillingModal } from '@client/features/billing/components/billing-modal';
import { trpc } from '@client/lib/trpc';
import { sleep } from '@client/lib/utils';
import { createFileRoute, redirect, useNavigate, useRouter } from '@tanstack/react-router';
import { useCallback } from 'react';
import { z } from 'zod';
import { SettingsBillingStep } from '@client/features/billing/const';
import { AccountHelper } from '@officely/models';
import { toast } from 'sonner';

export const Route = createFileRoute('/_authenticated/upgrade')({
  component: UpgradeComponent,
  validateSearch: z.object({
    step: z.nativeEnum(SettingsBillingStep).optional().catch(undefined),
  }),
  beforeLoad: async ({ context }) => {
    const account = await context.trpcUtils.account.current.fetch();
    const licenced = new AccountHelper(account).userIsLicenced(context.auth!.user.id);
    if (licenced) {
      console.log('user is already licenced, redirecting to /home');
      redirect({
        to: '/home',
        throw: true,
      });
    }
    const selfLicenceSuccess = await context.trpcUtils.client.account.selfLicence.mutate();
    if (selfLicenceSuccess) {
      console.log('self licenced user, redirecting to /home');
      redirect({
        to: '/home',
        throw: true,
      });
    }
    return {
      ...context,
      account,
    };
  },
  loader: async ({ context }) => {
    const { account, auth } = context;
    const { user } = auth!;
    const currentPlan = account.billing.plan;
    const isAccountAdmin = account.managers.includes(user.id);
    const customerId = account.billing.customerId ?? undefined;
    const userName = user.profile.name;
    return {
      currentPlan,
      isAccountAdmin,
      customerId,
      userName,
    };
  },
});

function UpgradeComponent() {
  const { isAccountAdmin, userName } = Route.useLoaderData();
  const navigate = useNavigate({ from: Route.fullPath });

  const accountUpgradeNudgeAdminMutation = trpc.account.upgradeNudgeAdmin.useMutation();
  const { step } = Route.useSearch();

  const onViewPlans = useCallback(() => {
    void navigate({
      search: {
        step: SettingsBillingStep.Summary,
      },
      replace: true,
    });
  }, []);

  const handleGiveAdminNudge = useCallback(async () => {
    const promise = accountUpgradeNudgeAdminMutation.mutateAsync();
    toast.promise(promise, {
      loading: 'Sending message to your admin...',
      success: 'Message sent',
      error: 'Failed to send message',
    });
  }, [accountUpgradeNudgeAdminMutation]);

  return (
    <DefaultLayout>
      <div className="flex flex-col md:flex-row mt-12 items-start">
        <Locked size={120} />
        <div className="ml-4 md:ml-6">
          <TypographyH1 className="mt-4">Upgrade your plan</TypographyH1>
          {isAccountAdmin ? (
            <>
              <TypographyP className="mt-4">
                Hey {userName}! Before you can use Officely, you need to upgrade your plan. Click below to get started.
              </TypographyP>
              <Button className="mt-4" onClick={onViewPlans}>
                Upgrade
              </Button>
            </>
          ) : (
            <>
              <TypographyP className="mt-4">
                Hey {userName}! Before you can use Officely, your admin will need to upgrade your company's plan. Click
                below to give them a nudge - we'll take care of the rest  😉
              </TypographyP>
              <Button
                className="mt-4"
                onClick={handleGiveAdminNudge}
                disabled={accountUpgradeNudgeAdminMutation.isPending}>
                Give your admin a nudge
              </Button>
            </>
          )}
        </div>
      </div>

      {step !== undefined && <UpgradeViewPlans />}
    </DefaultLayout>
  );
}

function UpgradeViewPlans() {
  const router = useRouter();
  const loaderData = Route.useLoaderData();
  const { step } = Route.useSearch();
  const navigate = useNavigate({ from: Route.fullPath });

  const handleChangeBillingStep = useCallback(
    (value: SettingsBillingStep | undefined) => {
      void navigate({
        search: (old) => ({ ...old, step: value }),
      });
    },
    [navigate]
  );

  const handleClose = useCallback(() => {
    void navigate({
      search: (old) => ({ ...old, step: undefined }),
    });
  }, [navigate]);

  const handlePlanChanged = useCallback(async () => {
    void sleep(1000).then(() => {
      void router.invalidate();
    });
  }, [router]);

  const props: CommonPropsForModal = {
    variant: 'default',
    onClose: handleClose,
    onDone: handleClose,
  };

  if (!loaderData.isAccountAdmin) {
    return null;
  }

  return (
    <Modal open={true} onClose={handleClose}>
      <BillingModal
        {...props}
        {...loaderData}
        billingStep={step}
        changeBillingStep={handleChangeBillingStep}
        onPlanChanged={handlePlanChanged}
      />
    </Modal>
  );
}

import { Emoji } from '@client/components/ui/custom/emoji';
import { MultiSelectPeople } from '@client/components/shared/MultiSelectPeople';
import { TypographyMuted } from '@client/components/ui/custom/typography-muted';
import { TypographyP } from '@client/components/ui/custom/typography-p';
import { Button } from '@client/components/ui/button';
import { StepComponentProps } from '@client/features/onboarding/types';

const CtaBtns = (props: StepComponentProps) => {
  const { onNext, onBack } = props;

  return (
    <>
      <div className="mt-6">
        <Button variant="outline" onClick={onBack}>
          Back
        </Button>
        <Button variant="default" className="ml-2" onClick={onNext}>
          Finish
        </Button>
      </div>
    </>
  );
};

export const FavouriteCoworkerSelection = (props: StepComponentProps) => {
  const { state, updateState } = props;

  const handleChange = (ids: string[]) => {
    updateState({ favouriteCoworkerIds: ids });
  };

  return (
    <div>
      <TypographyP className="mt-2">
        Officely shows you where they are working each day. You can ammend these at any time.
      </TypographyP>
      <MultiSelectPeople
        className="mt-4"
        value={state.favouriteCoworkerIds ?? []}
        onChange={handleChange}
        placeholder="Choose coworkers"
      />
      <TypographyMuted>
        If they're not already using Officely, we'll send them a magic link to get started
        <Emoji name="wand" className="ml-2" />
      </TypographyMuted>
      <CtaBtns {...props} />
    </div>
  );
};

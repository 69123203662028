import { defineForm } from '@client/lib/form/helpers';
import { useMemo } from 'react';
import { SimpleForm, SimpleFormProps } from '@client/components/shared/simple-form';

const FORM_ID = 'feedback-form' as const;

const getDefinition = () =>
  defineForm({
    id: FORM_ID,
    fields: {
      feedback: {
        type: 'textarea',
        label: '',
      },
    },
    defaultValues: {
      feedback: '',
    },
  });

type Props = Omit<SimpleFormProps<ReturnType<typeof getDefinition>>, 'definition'>;

const FeedbackForm = (props: Props) => {
  const definition = useMemo(() => getDefinition(), []);
  return <SimpleForm {...props} definition={definition} />;
};

FeedbackForm.id = FORM_ID;

export { FeedbackForm };

/* eslint-disable sonarjs/no-identical-functions */
import z from 'zod';
import { FormField, FormDefinition } from './types';

const fieldTextSchema = (field: FormField<'text'> | FormField<'textarea'>): z.ZodTypeAny => {
  const { maxLength, minLength, required } = field;
  let schema = z.string();
  if (maxLength) {
    schema = schema.max(maxLength);
  }
  if (minLength) {
    schema = schema.min(minLength);
  }
  if (required) {
    schema = schema.min(minLength ?? 1) as any;
  } else {
    schema = schema.optional() as any;
  }
  return schema;
};

const fieldEmojiSchema = (field: FormField<'emoji'>): z.ZodTypeAny => {
  const { required } = field;
  let schema = z.string().emoji();
  if (!required) {
    schema = schema.optional() as any;
  }
  return schema;
};

const fieldNumberSchema = (field: FormField<'number'>): z.ZodTypeAny => {
  const { min, max, required } = field;
  let schema = z.coerce.number();
  if (min) {
    schema = schema.min(min);
  }
  if (max) {
    schema = schema.max(max);
  }
  if (!required) {
    schema = schema.optional() as any;
  }
  return schema as any;
};

const fieldRadioSchema = (field: FormField<'radio'> | FormField<'checkboxes'>): z.ZodTypeAny => {
  const { required } = field;
  let schema = z.string();
  if (!required) {
    schema = schema.optional() as any;
  }
  return schema as any;
};

const fieldCheckboxesSchema = (field: FormField<'radio'> | FormField<'checkboxes'>): z.ZodTypeAny => {
  const { required } = field;
  let schema = z.array(z.string());
  if (required) {
    schema = schema.min(1) as any;
  } else {
    schema = schema.optional() as any;
  }
  return schema as any;
};

const fieldSwitchSchema = (field: FormField<'switch'>): z.ZodTypeAny => {
  const { required } = field;
  let schema = z.boolean();
  if (!required) {
    schema = schema.optional() as any;
  }
  return schema as any;
};

const fieldMultiSelectPeopleSchema = (field: FormField<'multi-select-people'>): z.ZodTypeAny => {
  const { required } = field;
  let schema = z.array(z.string());
  if (required) {
    schema = schema.nonempty() as any;
  } else {
    schema = schema.optional() as any;
  }
  return schema as any;
};

const fieldMultiSelectSchema = (field: FormField<'multi-select'>): z.ZodTypeAny => {
  const { required } = field;
  let schema = z.array(z.string());
  if (required) {
    schema = schema.nonempty() as any;
  } else {
    schema = schema.optional() as any;
  }
  return schema as any;
};

const fieldSelectSchema = (field: FormField<'select'> | FormField<'select-team-channel'>): z.ZodTypeAny => {
  const { required } = field;
  let schema = z.string();
  if (required) {
    schema = schema.min(1, { message: 'This field is required.' }) as any;
  } else {
    schema = schema.optional() as any;
  }
  return schema as any;
};

const fieldSelectDateSchema = (field: FormField<'select-date'>): z.ZodTypeAny => {
  const { required } = field;
  let schema = z.date();
  if (!required) {
    schema = schema.optional() as any;
  }

  return schema as any;
};

const fieldSelectDateRangeSchema = (field: FormField<'select-date-range'>): z.ZodTypeAny => {
  const { required, rangeRequired } = field;
  let fromDateSchema = z.date();
  let toDateSchema = z.date();
  if (!required) {
    // neither are required
    fromDateSchema = fromDateSchema.optional() as any;
    toDateSchema = toDateSchema.optional() as any;
  } else if (!rangeRequired) {
    // only require from date
    toDateSchema = toDateSchema.optional() as any;
  } else {
    // both are required by default
  }
  let schema = z.object({
    from: fromDateSchema,
    to: toDateSchema,
  });
  if (!required) {
    schema = schema.optional() as any;
  }
  return schema as any;
};

const getFieldSchema = (field: FormField) => {
  switch (field.type) {
    case 'text':
    case 'textarea':
      return fieldTextSchema(field);
    case 'number':
      return fieldNumberSchema(field);
    case 'radio':
      return fieldRadioSchema(field);
    case 'checkboxes':
      return fieldCheckboxesSchema(field);
    case 'select':
    case 'select-team-channel':
      return fieldSelectSchema(field);
    case 'multi-select-people':
      return fieldMultiSelectPeopleSchema(field);
    case 'emoji':
      return fieldEmojiSchema(field);
    case 'switch':
      return fieldSwitchSchema(field);
    case 'multi-select':
      return fieldMultiSelectSchema(field);
    case 'select-date':
      return fieldSelectDateSchema(field);
    case 'select-date-range':
      return fieldSelectDateRangeSchema(field);
  }
};

export const defineForm = <F extends FormDefinition['fields']>(
  args: Omit<FormDefinition<F>, 'schema'>
): FormDefinition<F> => {
  // eslint-disable-next-line prefer-const
  let schema = z.object(
    Object.fromEntries(
      Object.entries(args.fields).map(([name, field]) => {
        const fieldSchema = getFieldSchema(field);
        return [name, fieldSchema];
      })
    )
  ) as unknown as FormDefinition<F>['schema'];
  if (args.validate) {
    schema = schema.superRefine((data, ctx) => {
      const errors = {} as Record<keyof F, string>;
      args.validate!(data, errors);
      Object.entries(errors ?? {}).forEach(([field, errorMessage]) => {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: [field],
          message: errorMessage,
        });
      });
    }) as unknown as FormDefinition<F>['schema'];
  }
  return {
    ...args,
    schema,
    validate: args.validate as FormDefinition['validate'],
    conditions: args.conditions as FormDefinition['conditions'],
  };
};

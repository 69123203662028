import { defineForm } from '@client/lib/form/helpers';
import { useForm } from '@client/lib/form/useForm';
import { useCallback, useEffect, useMemo } from 'react';
import { Form } from '@client/components/ui/form';
import { FormField } from '@client/components/shared/FormField';
import { Office as _Office } from '@officely/models';
import { CommonPropsForForm, FormValues } from '@client/lib/form/types';

type Office = Pick<_Office, 'id' | 'name' | 'country' | 'address'>;

const FORM_ID = 'user-prefs-form' as const;

const getDefinition = (args: { offices: Office[]; existingFavouriteCoworkers: string[] }) =>
  defineForm({
    id: FORM_ID,
    fields: {
      office: {
        type: 'select',
        optionsAsBtns: 3,
        options: args.offices.map((office) => ({
          label: office.name,
          value: office.id,
          group: office.country,
          description: office.address,
        })),
        label: 'Your Office',
        description: 'This is typically the office you attend most often.',
      },
      favouriteCoworkers: {
        type: 'multi-select-people',
        label: 'Your Favourite Coworkers',
        description: 'These are the people you work with most often and will pushed to the top of your Officely view.',
      },
      followMeBack: {
        type: 'checkboxes',
        options: [
          {
            label: 'Invite to follow me back',
            value: 'yes',
          },
        ],
      },
    },
    defaultValues: {
      followMeBack: ['yes'],
    },
    conditions(values) {
      return {
        hidden: {
          followMeBack:
            !values.favouriteCoworkers ||
            !values.favouriteCoworkers.length ||
            values.favouriteCoworkers?.every((coworker) => args.existingFavouriteCoworkers.includes(coworker)),
        },
      };
    },
  });

export type UserPrefsFormValues = FormValues<ReturnType<typeof getDefinition>>;

type Props = CommonPropsForForm<UserPrefsFormValues> & {
  offices: Office[];
};

const UserPrefsForm = (props: Props) => {
  const { data, onSubmit } = props;

  const definition = useMemo(
    () =>
      getDefinition({
        offices: props.offices,
        existingFavouriteCoworkers: data?.favouriteCoworkers || [],
      }),
    [props.offices, data?.favouriteCoworkers]
  );

  const { form, setValues, conditions } = useForm(definition);

  // useEffect(() => {
  //   console.log('data', JSON.stringify(data, null, 2));
  // }, [data]);

  // useEffect(() => {
  //   console.log('conditions', JSON.stringify(conditions, null, 2));
  // }, [conditions]);

  useEffect(() => {
    // whenever data changes reset the form and set the values
    form.reset();
    if (data) {
      setValues(data);
    }
  }, [form.reset, data]);

  const handleSubmit = useCallback(
    form.handleSubmit((values) => {
      onSubmit?.(values);
      form.reset(values);
    }),
    [form, onSubmit]
  );

  return (
    <Form {...form}>
      <form onSubmit={handleSubmit} className="flex flex-col gap-y-6" id={FORM_ID}>
        {Object.keys(definition.fields)
          .map((key) => key as keyof typeof definition.fields)
          .map((name) => {
            return (
              <FormField
                {...definition.fields[name]}
                key={name}
                name={name}
                control={form.control}
                disabled={conditions?.disabled?.[name]}
                hidden={conditions?.hidden?.[name]}
              />
            );
          })}
      </form>
    </Form>
  );
};

UserPrefsForm.id = FORM_ID;

export { UserPrefsForm };

import { TypographyH4 } from '@client/components/ui/custom/typography-h4';
import { TypographyP } from '@client/components/ui/custom/typography-p';
import { Button } from '@client/components/ui/button';
import { ButtonGroup } from '@client/components/ui/custom/button-group';
import { useCallback, useMemo } from 'react';
import { UserList } from '@client/features/home/components/users-list';
import { getFiendlyDate, sortStatusType } from '@client/features/home/helpers';
import { DayActionType, DayStatusesData, OnDayAction, UserListItem } from '@client/features/home/types';
import { Select } from '@client/components/ui/custom/select';
import { SORTED_STATUS_TYPES, SortMethod } from '@client/features/home/const';
import { DotsVerticalIcon } from '@radix-ui/react-icons';
import { Emoji } from '@client/components/ui/custom/emoji';
import { UserWorkStatusType, UserWorkStatusTypeHelper } from '@officely/models';
import { Separator } from '@client/components/ui/separator';
import { DayActionsSelect } from '@client/features/home/components/day-actions-select';
import { DayNotices } from '@client/features/home/components/day-notices';

// const DayOfficeOnlyNames = (props: {
//   bookings: NonNullable<OfficeDayData['bookings']>;
//   bookedNbhs: NonNullable<OfficeDayData['bookedNbhs']>;
//   bookedExtras: NonNullable<OfficeDayData['bookedExtras']>;
//   onEditNote: () => void;
// }) => {
//   const { bookings, bookedExtras, bookedNbhs, onEditNote } = props;
//   const { isMobile } = useScreenSize();

//   const sortedNbhs = useMemo(() => bookedNbhs.sort(neighbourhoodSort), [bookedNbhs]);
//   const extraMap = useMemo(() => Object.fromEntries(bookedExtras.map((extra) => [extra.id, extra])), [bookedExtras]);

//   const rows = useMemo(
//     () =>
//       sortedNbhs.map((nbh) => ({
//         ...nbh,
//         bookings: bookings
//           .filter((booking) => booking.nbhId === nbh.id)
//           .map((booking) => ({
//             ...booking,
//             extraEmojis: booking.extraIds?.map((extraId) => extraMap[extraId]?.emoji).filter(Boolean),
//           })),
//       })),
//     [sortedNbhs, bookings, extraMap]
//   );

//   return (
//     <div
//       className={cn('grid grid-cols-2 gap-4', {
//         'grid-cols-1': isMobile || rows.length === 1,
//       })}>
//       {rows.map((row) => {
//         return (
//           <div key={row.id} className="col-span-1">
//             <NbhHeading {...row} />
//             <UserList list={row.bookings} minNumSingleItemRows={0} onEditNote={onEditNote} />
//           </div>
//         );
//       })}
//     </div>
//   );
// };

const StatusRowHeading = (props: { emoji: string; name: string }) => {
  return (
    <div className="flex items-center gap-2">
      <Emoji>{props.emoji}</Emoji>
      <TypographyP className="font-bold">{props.name}</TypographyP>
    </div>
  );
};

export const StatusRow = (
  props: DayStatusesData['statuses'][string] & {
    date: string;
    altOfficeId: string | undefined;
    statusOrOfficeId: string;
    isOfficeAdmin: boolean;
    onDayAction: OnDayAction;
  }
) => {
  const {
    isOffice,
    statusOrOfficeId,
    altOfficeId,
    emoji,
    name,
    preview,
    total,
    isOfficeAdmin,
    announcements = [],
    onDayAction,
    date,
  } = props;

  const handleEditNote = useCallback(() => {
    onDayAction({
      type: DayActionType.ChangeNote,
      date,
    });
  }, [onDayAction, date]);

  const selfRow = useMemo(() => preview.find((user) => user.isSelf), [preview]);

  const handleSelfClick = useMemo(() => {
    if (selfRow?.canModify && isOffice) {
      return () => {
        onDayAction({
          type: DayActionType.ModifyBooking,
          officeId: statusOrOfficeId,
          date,
        });
      };
    }
  }, [selfRow, isOffice, onDayAction, statusOrOfficeId, date]);

  const handleSeeOverflow = useCallback(() => {
    onDayAction({
      type: DayActionType.SeeDetails,
      date,
      initialFilters: [statusOrOfficeId],
    });
  }, [onDayAction, date, statusOrOfficeId]);

  const handleAnnouncementClick = useCallback(
    (id: string) => {
      onDayAction({
        type: DayActionType.ManageAnnouncements,
        date,
        officeId: statusOrOfficeId,
        id,
      });
    },
    [onDayAction, date, statusOrOfficeId]
  );

  return (
    <div className="flex flex-col gap-2">
      <div className="flex items-center justify-between">
        <StatusRowHeading emoji={emoji} name={name} />
        {isOfficeAdmin && (
          <DayActionsSelect
            date={date}
            officeId={statusOrOfficeId}
            announcementCount={announcements.length}
            onDayAction={onDayAction}
            isBooked={!!selfRow}
            isAdmin={isOfficeAdmin}
            icon={DotsVerticalIcon}
          />
        )}
      </div>
      {isOffice && (
        <DayNotices
          announcements={announcements}
          className="mb-1"
          isOfficeAdmin={isOfficeAdmin}
          altOfficeId={altOfficeId}
          onAnnouncementClick={handleAnnouncementClick}
        />
      )}
      <UserList
        date={date}
        list={preview}
        minNumSingleItemRows={0}
        sort={SortMethod.NONE}
        total={total}
        onSeeOverflow={handleSeeOverflow}
        onEditNote={handleEditNote}
        // onSelfClick={handleSelfClick}
      />
    </div>
  );
};

export const DayStatuses = (
  props: DayStatusesData & {
    officeAdminOf: string[];
    bookableStatuses: UserWorkStatusType[];
    emphasied?: boolean;
    onDayAction: OnDayAction;
  }
) => {
  const { date, altOfficeId, statuses, officeAdminOf, bookableStatuses, emphasied, onDayAction } = props;

  const userStatusOrOfficeId = useMemo(() => {
    return (
      Object.entries(statuses).find(([_typeOrOfficeId, status]) => status.preview.some((user) => user.isSelf))?.[0] ??
      UserWorkStatusType.NO_STATUS
    );
  }, [statuses]);

  const currentStatusIsAway = useMemo(
    () => new UserWorkStatusTypeHelper(userStatusOrOfficeId as any).isAwayDate,
    [userStatusOrOfficeId]
  );

  const statusOptions = useMemo(() => {
    return SORTED_STATUS_TYPES.filter(
      // don't show the "clear status" option if the user is already clear
      (type) => userStatusOrOfficeId !== UserWorkStatusType.NO_STATUS || type !== UserWorkStatusType.NO_STATUS
    )
      .filter((type) => bookableStatuses.includes(type))
      .map((type) => new UserWorkStatusTypeHelper(type))
      .map((helper) => ({
        value: helper.data,
        group: helper.profile.category,
        label:
          helper.data === UserWorkStatusType.NO_STATUS
            ? '--  Clear my status  --'
            : `${helper.profile.emoji}   ${helper.profile.name}`,
      }));
  }, [userStatusOrOfficeId, bookableStatuses]);

  const selectedStatus = useMemo(() => {
    return statusOptions.find((option) => option.value === userStatusOrOfficeId);
  }, [statusOptions, userStatusOrOfficeId]);

  const renderedStatuses = useMemo(() => {
    return Object.keys(statuses)
      .sort(sortStatusType)
      .map((typeOrOfficeId) => {
        const isOfficeAdmin = officeAdminOf.includes(typeOrOfficeId);
        return (
          <StatusRow
            key={`${typeOrOfficeId}-${date}`}
            date={date}
            isOfficeAdmin={isOfficeAdmin}
            {...statuses[typeOrOfficeId]}
            statusOrOfficeId={typeOrOfficeId}
            altOfficeId={altOfficeId}
            onDayAction={onDayAction}
          />
        );
      });
  }, [statuses, date, onDayAction, officeAdminOf]);

  const handleChangeStatus = useCallback(
    (value: string | undefined) => {
      onDayAction({
        type: DayActionType.ChangeStatus,
        date,
        statusOrOfficeId: value ?? UserWorkStatusType.NO_STATUS,
      });
    },
    [onDayAction, date]
  );

  const handleChangeAwayDates = useCallback(() => {
    onDayAction({
      type: DayActionType.ChangeAwayDates,
      date,
      awayDatesType: userStatusOrOfficeId as UserWorkStatusType,
    });
  }, [onDayAction, date, userStatusOrOfficeId]);

  return (
    <div className="first:mt-0 mt-10">
      <div className="flex justify-between items-center">
        {emphasied ? (
          <TypographyH4 className="">{getFiendlyDate(date)}</TypographyH4>
        ) : (
          <TypographyP className="font-bold ">{getFiendlyDate(date)}</TypographyP>
        )}
      </div>
      <Separator className="my-2" />
      <div className="flex flex-col gap-4">{renderedStatuses}</div>
      <ButtonGroup className="mt-4">
        {currentStatusIsAway ? (
          <Button onClick={handleChangeAwayDates} variant="outline">
            Change Status
          </Button>
        ) : (
          <Select
            options={statusOptions}
            selected={selectedStatus}
            onChange={handleChangeStatus}
            asBtn
            disableSearch
            asBtnProps={{ variant: 'outline' }}
            // drawerOpts={{ fullHeight: true }}
            placeholder={userStatusOrOfficeId === UserWorkStatusType.NO_STATUS ? 'Set Your Status' : 'Change Status'}
          />
        )}
      </ButtonGroup>
    </div>
  );
};

import { ManagedNotificationType, managedNotificationTypeSchema } from '@officely/models';
import { createFileRoute } from '@tanstack/react-router';
import { z } from 'zod';
import { CommonPropsForModal } from '@client/lib/modal';
import { useTeams } from '@client/lib/useTeams';
import { useCallback } from 'react';
import { Modal } from '@client/components/ui/custom/modal';
import { useNavigate } from '@tanstack/react-router';
import { UserNotifsModal } from '@client/features/settings/components/user-notifs-modal';

function DialogNotifications() {
  const { closeDialog } = useTeams();
  const loaderData = Route.useLoaderData();
  const { notificationType } = Route.useSearch();
  const navigate = useNavigate({ from: Route.fullPath });

  const handleTypeChange = useCallback(
    (type?: ManagedNotificationType) => {
      void navigate({
        to: '/dialog/notifications',
        search: (old) => ({
          ...old,
          notificationType: type,
        }),
      });
    },
    [navigate]
  );

  const props: CommonPropsForModal = {
    variant: 'msTeamsDialog',
    onClose: closeDialog,
    onDone: closeDialog,
  };

  return (
    <Modal open={true} onClose={closeDialog} isTeamsDialog={true}>
      <UserNotifsModal {...props} {...loaderData} type={notificationType} onTypeChange={handleTypeChange} />
    </Modal>
  );
}

export const Route = createFileRoute('/_authenticated/dialog/notifications')({
  component: DialogNotifications,
  validateSearch: z.object({
    notificationType: managedNotificationTypeSchema.optional().catch(undefined),
  }),
  loader: async ({ context }) => {
    const user = await context.trpcUtils.user.current.fetch();
    const { notifications } = user;
    return {
      userName: user.profile.firstName ?? user.profile.name,
      notifications,
    };
  },
});

import { NbhFormValues } from '@client/features/settings/components/nbh-form';
import { confirm } from '@client/lib/confirm';
import { trpc } from '@client/lib/trpc';
import pluralize from 'pluralize';
import { useCallback } from 'react';
import { toast } from 'sonner';

type Args = {
  officeId: string;
};

export const useNbhSettings = (args: Args) => {
  const { officeId } = args;

  const officeUpdateNbhMutation = trpc.office.updateNbh.useMutation();
  const officeDeleteNbhsMutation = trpc.office.deleteNbhs.useMutation();
  const officeCreateNbhMutation = trpc.office.createNbh.useMutation();

  const createNbh = useCallback(
    async (values: NbhFormValues) => {
      const promise = officeCreateNbhMutation.mutateAsync({
        officeId,
        data: {
          name: values.name,
          capacity: values.capacity,
          category: values.category ?? values.newCategory,
          peopleIds: values.peopleIds,
        },
      });
      toast.promise(promise, {
        loading: 'Creating neighborhood...',
        success: 'Neighborhood created',
        error: 'Failed to create neighborhood',
      });
      await promise;
    },
    [officeId, officeCreateNbhMutation, toast]
  );

  const updateNbh = useCallback(
    async (id: string, values: NbhFormValues) => {
      const promise = officeUpdateNbhMutation.mutateAsync({
        officeId,
        data: {
          id,
          name: values.name,
          capacity: values.capacity,
          category: values.category ?? values.newCategory,
          peopleIds: values.peopleIds,
        },
      });
      toast.promise(promise, {
        loading: 'Updating neighborhood...',
        success: 'Neighborhood updated',
        error: 'Failed to update neighborhood',
      });
      await promise;
    },
    [officeId, officeUpdateNbhMutation, toast]
  );

  const deleteNbhs = useCallback(
    async (ids: string[]) => {
      const confirmation = await confirm(
        `Are you sure you want to delete ${pluralize('neighborhood', ids.length, true)}?`
      );
      if (!confirmation) {
        return Promise.reject('User cancelled');
      }
      const promise = officeDeleteNbhsMutation.mutateAsync({
        officeId,
        ids,
      });
      toast.promise(promise, {
        loading: 'Deleting neighborhoods...',
        success: 'Neighborhoods deleted',
        error: 'Failed to delete neighborhoods',
      });
      await promise;
    },
    [officeId, officeDeleteNbhsMutation, toast]
  );

  return {
    createNbh,
    updateNbh,
    deleteNbhs,
  };
};

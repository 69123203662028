import { defineForm } from '@client/lib/form/helpers';
import { useForm } from '@client/lib/form/useForm';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Form } from '@client/components/ui/form';
import { FormField } from '@client/components/shared/FormField';
import { CommonPropsForForm, FormValues } from '@client/lib/form/types';

const FORM_ID = 'nbh-form' as const;

const getDefinition = (args: { categories: string[]; addNewCategory: boolean; onAddNewCategory: () => void }) =>
  defineForm({
    id: FORM_ID,
    fields: {
      name: {
        type: 'text',
        label: 'Name',
        required: true,
      },
      capacity: {
        type: 'number',
        label: 'Limit Capacity?',
        description: 'Leave for unlimited capacity',
        showOptionalLabel: true,
      },
      category: {
        type: 'select',
        label: 'Category',
        optionsAsBtns: 3,
        onAddNew: args.onAddNewCategory,
        options: args.categories.map((cat) => ({
          value: cat,
          group: 'Existing',
        })),
      },
      newCategory: {
        type: 'text',
        label: args.categories.length > 0 ? 'New Category' : 'Category',
        placeholder: 'Example: Floor 1',
      },
      visibility: {
        label: 'Who can book this space?',
        type: 'select',
        required: true,
        optionsAsBtns: true,
        options: [
          { value: 'public', label: 'Anyone', description: 'Anyone can book this space' },
          {
            value: 'private',
            label: 'Specific people',
            description: 'Only people with access to this space can book it',
          },
        ],
      },
      peopleIds: {
        type: 'multi-select-people',
      },
    },
    defaultValues: {
      visibility: 'public',
    },
    validate: (values, errors) => {
      if (values.capacity && values.capacity < 0) {
        errors.capacity = 'Must be a positive number';
      }
      if (values.visibility === 'private' && !values.peopleIds?.length) {
        errors.peopleIds = 'Required';
      }
    },
    conditions: (values) => ({
      hidden: {
        category: args.categories.length === 0 || args.addNewCategory,
        newCategory: args.categories.length > 0 && !args.addNewCategory,
        peopleIds: values.visibility !== 'private',
      },
    }),
  });

export type NbhFormValues = FormValues<ReturnType<typeof getDefinition>>;
type Props = CommonPropsForForm<NbhFormValues> & {
  categories: string[];
};

const NbhForm = (props: Props) => {
  const { data, categories, onSubmit } = props;

  const [addNewCategory, setAddNewCategory] = useState(false);

  const definition = useMemo(() => {
    return getDefinition({
      categories,
      addNewCategory,
      onAddNewCategory: () => setAddNewCategory(true),
    });
  }, [categories, addNewCategory, setAddNewCategory]);

  const { form, setValues, conditions } = useForm(definition);

  useEffect(() => {
    // whenever data changes reset the form and set the values
    form.reset();
    if (data) {
      setValues(data);
    }
  }, [form.reset, data]);

  const handleSubmit = useCallback(
    form.handleSubmit((values) => {
      onSubmit?.(values);
      form.reset(values);
    }),
    [form, onSubmit]
  );

  return (
    <Form {...form}>
      <form onSubmit={handleSubmit} className="flex flex-col gap-y-6" id={FORM_ID}>
        {Object.keys(definition.fields)
          .map((key) => key as keyof typeof definition.fields)
          .map((name) => {
            return (
              <FormField
                {...definition.fields[name]}
                key={name}
                name={name}
                control={form.control}
                disabled={conditions?.disabled?.[name]}
                hidden={conditions?.hidden?.[name]}
              />
            );
          })}
      </form>
    </Form>
  );
};

NbhForm.id = FORM_ID;

export { NbhForm };

import { type ClassValue, clsx } from "clsx"
import { twMerge } from "tailwind-merge"
 
export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function parseDateStr<T>(dateStr: T): T extends string ? Date : T {
  if (typeof dateStr !== 'string') {
    return dateStr as any;
  }
  return new Date(dateStr) as any;
}

export function sleep(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function getTimezone(): string {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

export function numBetween(a: number, b: number) {
  return Math.round(Math.random() * (b - a)) + a;
}

const SMALL_WORDS =
  /\b(?:an?d?|a[st]|because|but|by|en|for|i[fn]|neither|nor|o[fnr]|only|over|per|so|some|tha[tn]|the|to|up|upon|vs?\.?|versus|via|when|with|without|yet)\b/i;
const TOKENS = /[^\s:–—-]+|./g;
const WHITESPACE = /\s/;
const IS_MANUAL_CASE = /.(?=[\p{Lu}]|\..)/u;
const ALPHANUMERIC_PATTERN = /[\p{Lu}\p{Ll}\d]/u;
export const titleCase = (input: string, locale?: string[] | string): string => {
  let result = '';
  let m;
  // tslint:disable-next-line
  while ((m = TOKENS.exec(input)) !== null) {
    const { 0: token, index } = m;
    if (
      // Ignore already capitalized words.
      !IS_MANUAL_CASE.test(token) &&
      // Ignore small words except at beginning or end.
      (!SMALL_WORDS.test(token) || index === 0 || index + token.length === input.length) &&
      // Ignore URLs.
      (input.charAt(index + token.length) !== ':' || WHITESPACE.test(input.charAt(index + token.length + 1)))
    ) {
      // Find and uppercase first word character, skips over *modifiers*.
      result += token.replace(ALPHANUMERIC_PATTERN, (m) => m.toLocaleUpperCase(locale));
      continue;
    }
    result += token;
  }
  return result;
};
export enum DialogId {
  FavouriteCoworkers = 'favourites',
  OfficelyInvite = 'officely-invite',
  Billing = 'billing',
  NotificationPrefs = 'notifications',
  CalendarSync = 'calendar-sync',
  OfficeDayInvite = 'office-day-invite',
  Feedback = 'feedback',
  OfficeWaitlist = 'office-waitlist',
  OfficeBooking = 'office-booking',
  AwayDates = 'away-dates',
}

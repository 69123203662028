import { defineForm } from '@client/lib/form/helpers';
import { useMemo } from 'react';
import { emojis } from '@client/lib/emojis';
import { FormValues } from '@client/lib/form/types';
import { OfficeBookingAvailabilityExtra, OfficeBookingAvailabilityNbh } from '@client/features/booking/types';
import { SimpleForm, SimpleFormProps } from '@client/components/shared/simple-form';

const FORM_ID = 'office-booking-form' as const;

const getDefinition = (args: {
  extras: OfficeBookingAvailabilityExtra[];
  nbhs: OfficeBookingAvailabilityNbh[];
  officeNbhCount: number;
  isModifying: boolean;
}) =>
  defineForm({
    id: FORM_ID,
    fields: {
      nbh: {
        type: 'select',
        label: 'Neighborhood',
        optionsAsBtns: 3,
        options: args.nbhs.map((nbh) => ({
          value: nbh.id,
          label: `${emojis.neighbourhood}   ${nbh.name}`,
          group: nbh.category ?? undefined,
          description:
            typeof nbh.spacesLeft === 'number'
              ? isFinite(nbh.spacesLeft) && nbh.spacesLeft > 0
                ? `${nbh.spacesLeft} spaces left`
                : 'Fully booked'
              : undefined,
        })),
      },
      extras: {
        type: 'multi-select',
        label: 'Add Extras?',
        optionsAsBtns: 3,
        options: args.extras.map((e) => ({
          value: e.id,
          label: `${e.emoji}   ${e.name}`,
          description: e.spacesLeft && isFinite(e.spacesLeft) ? `${e.spacesLeft} spaces left` : undefined,
        })),
        closeOnSelect: true,
      },
      invitedCoworkers: {
        label: 'Do you want to invite anyone?',
        type: 'multi-select-people',
        closeOnSelect: true,
      },
    },
    defaultValues: {
      invitedCoworkers: [],
    },
    conditions: {
      hidden: {
        nbh: args.nbhs.length === 0 || args.officeNbhCount <= 1,
        extras: args.extras.length === 0,
        invitedCoworkers: args.isModifying,
      },
    },
  });

type Props = Omit<SimpleFormProps<ReturnType<typeof getDefinition>>, 'definition'> & {
  nbhs?: OfficeBookingAvailabilityNbh[];
  extras?: OfficeBookingAvailabilityExtra[];
  officeNbhCount: number;
  isModifying: boolean;
};

export type OfficeBookingFormValues = FormValues<ReturnType<typeof getDefinition>>;

const OfficeBookingForm = (props: Props) => {
  const { extras = [], nbhs = [], officeNbhCount, isModifying } = props;

  const definition = useMemo(
    () =>
      getDefinition({
        extras,
        nbhs,
        officeNbhCount,
        isModifying,
      }),
    [nbhs, extras, officeNbhCount]
  );

  return <SimpleForm {...props} definition={definition} />;
};

OfficeBookingForm.id = FORM_ID;

export { OfficeBookingForm };

import { defineForm } from '@client/lib/form/helpers';
import { useForm } from '@client/lib/form/useForm';
import { useCallback, useEffect } from 'react';
import { Form } from '@client/components/ui/form';
import { FormField } from '@client/components/shared/FormField';
import { Weekday } from '@officely/models';
import { CommonPropsForForm, FormValues } from '@client/lib/form/types';
import { z } from 'zod';

const FORM_ID = 'office-profile-form' as const;

const getDefinition = () =>
  defineForm({
    id: FORM_ID,
    fields: {
      name: {
        type: 'text',
        label: 'Name',
        maxLength: 64,
        minLength: 2,
        required: true,
      },
      emoji: {
        type: 'emoji',
        label: 'Emoji',
        maxLength: 2,
        required: true,
      },
      admins: {
        type: 'multi-select-people',
        label: 'Office Admins',
        description: 'People who can manage this office.',
        required: true,
        closeOnSelect: true,
      },
      workingDays: {
        type: 'multi-select',
        optionsAsBtns: true,
        options: Object.values(Weekday).map((x) => ({
          value: x,
          label: x.charAt(0).toUpperCase() + x.slice(1),
        })),
        required: true,
        label: 'Working Days',
        description: 'The days this office is open.',
      },
      floorPlanUrl: {
        type: 'text',
        label: 'Floor Plan URL',
        placeholder: 'https://sharepoint.com/sites/yourcompany/documents/office-floorplan.pdf',
        description: 'You can add the URL of your floor plan to make visible within Officely.',
      },
    },
    validate(values, errors) {
      const isValidUrl = z.string().url().safeParse(values.floorPlanUrl).success;
      if (values.floorPlanUrl && !isValidUrl) {
        errors.floorPlanUrl = 'Invalid URL';
      }
    },
  });

export type OfficeProfileFormValues = FormValues<ReturnType<typeof getDefinition>>;
type Props = CommonPropsForForm<OfficeProfileFormValues>;

const OfficeProfileForm = (props: Props) => {
  const { data, onSubmit, onDirty } = props;

  const definition = getDefinition();

  const { form, setValues, conditions } = useForm(definition);

  useEffect(() => {
    // whenever data changes reset the form and set the values
    form.reset();
    if (data) {
      setValues(data);
    }
  }, [form.reset, data]);

  const handleSubmit = useCallback(
    form.handleSubmit((values) => {
      onSubmit?.(values);
      form.reset(values);
    }),
    [form, onSubmit]
  );

  useEffect(() => {
    // console.log('isDirty', form.formState.isDirty);
    onDirty?.(form.formState.isDirty);
  }, [form.formState.isDirty]);

  return (
    <Form {...form}>
      <form onSubmit={handleSubmit} className="flex flex-col gap-y-6" id={FORM_ID}>
        {Object.keys(definition.fields)
          .map((key) => key as keyof typeof definition.fields)
          .map((name) => {
            return (
              <FormField
                {...definition.fields[name]}
                key={name}
                name={name}
                control={form.control}
                disabled={conditions?.disabled?.[name]}
                hidden={conditions?.hidden?.[name]}
              />
            );
          })}
      </form>
    </Form>
  );
};

OfficeProfileForm.id = FORM_ID;

export { OfficeProfileForm };

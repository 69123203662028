import { SchedulePeriod } from '@client/features/home/types';
import { PropsWithChildren, useCallback } from 'react';
import { ButtonGroup } from '@client/components/ui/custom/button-group';
import { Button } from '@client/components/ui/button';
import { yyyy_MM_dd } from '@client/lib/const';
import { format, parse } from 'date-fns';
import { Separator } from '@client/components/ui/separator';

const getPeriodBtnText = (period: SchedulePeriod) => {
  return `Show ${format(parse(period.start, yyyy_MM_dd, new Date()), 'd MMM')} - ${format(parse(period.end, yyyy_MM_dd, new Date()), 'do MMM')}`;
};

export const SchedulePeriodControls = (
  props: PropsWithChildren<{
    previous: SchedulePeriod | undefined;
    next: SchedulePeriod | undefined;
    onChange: (period: SchedulePeriod) => void;
  }>
) => {
  const { previous, next, onChange } = props;

  const prevPeriodBtnText = previous && getPeriodBtnText(previous);
  const nextPeriodBtnText = next && getPeriodBtnText(next);

  const handleNextPeriod = useCallback(() => {
    if (!next) return;
    onChange(next);
  }, [next]);

  const handlePrevPeriod = useCallback(() => {
    if (!previous) return;
    onChange(previous);
  }, [previous]);

  return (
    <div className="mt-6">
      {prevPeriodBtnText && (
        <>
          <ButtonGroup className="mt-6 mb-6">
            <Button variant={'outline'} onClick={handlePrevPeriod}>
              {prevPeriodBtnText}
            </Button>
          </ButtonGroup>
        </>
      )}
      {props.children}
      {nextPeriodBtnText && (
        <>
          <Separator className="mt-6" />
          <ButtonGroup className="mt-6">
            <Button variant={'outline'} onClick={handleNextPeriod}>
              {nextPeriodBtnText}
            </Button>
          </ButtonGroup>
        </>
      )}
    </div>
  );
};

import { useScreenSize } from '@client/lib/useScreenSize';
import { cn } from '@client/lib/utils';
import { TypographyH2 } from '@client/components/ui/custom/typography-h2';
import { Button } from '@client/components/ui/button';
import { useMemo } from 'react';
import { DialogScreenReaderInfo } from '@client/components/ui/dialog';
import { useTimeout } from '@client/lib/use-timeout';

type Props = React.PropsWithChildren<{
  variant?: 'default' | 'msTeamsDialog';
  title: string;
  description?: string;
  loading?: boolean;
  nested?: boolean;
  submitFormId?: string;
  onSubmit?: () => void;
  onClose?: () => void;
  submitText?: string;
  closeText?: string;
}>;

export type ModalLayoutProps = Props;

const ModalHeaderIcon = () => {
  return (
    <img
      className="mr-5 flex-shrink-0 w-9 h-9 rounded-sm"
      src="https://cdn.getofficely.com/logo/icon-color.png"
      alt="Officely Logo"
    />
  );
};

const ModalHeader = (props: { isMobile?: boolean; title: string; description?: string }) => {
  if (props.isMobile) {
    const spacer = <div className="w-16 h-4 flex-shrink-0" />;
    return (
      <div
        className={`fixed top-0 left-0 w-full h-14 z-10 flex-shrink-0 justify-center items-center flex border-b border-b-2 border-b-border/50 bg-background rounded-t-[10px]`}>
        {spacer}
        <TypographyH2 className="text-lg font-bold truncate">{props.title}</TypographyH2>
        {spacer}
      </div>
    );
  }
  return (
    <div className="bg-clip-padding rounded-tl-lg rounded-tr-lg flex-shrink-0 justify-between items-center p-4 px-6 relative flex">
      <h1 className="w-full min-w-0 m-0 text-2xl font-extrabold leading-[1.36364] block overflow-hidden whitespace-nowrap text-ellipsis">
        <div className="justify-between items-center w-full flex">
          <ModalHeaderIcon />
          <div className="text-left text-ellipsis whitespace-nowrap w-full block overflow-hidden break-words">
            {props.title}
          </div>
        </div>
      </h1>
    </div>
  );
};

const ModalBody = (props: { isMobile?: boolean; children: React.ReactNode }) => {
  if (props.isMobile) {
    return <div className={`pt-16 px-2 pb-4`}>{props.children}</div>;
  }
  return (
    <div className="pt-2 pr-6 pb-4 pl-6 flex-shrink-1 overflow-y-auto select-text max-h-[480px]">{props.children}</div>
  );
};

const ModalFooterBtn = (props: {
  isMobile?: boolean;
  text: string;
  isSubmit?: boolean;
  onClick?: () => void;
  formId?: string;
  className?: string;
}) => {
  const commonProps = {
    form: props.formId,
    onClick: props.onClick,
    children: props.text,
    type: (props.formId ? 'submit' : 'button') as 'submit' | 'button',
  };

  if (props.isMobile) {
    return (
      <Button
        {...commonProps}
        variant={'ghost'}
        className={cn('text-md font-normal hover:bg-transparent hover:text-link text-link py-4 px-2', props.className)}
      />
    );
  }
  return (
    <Button
      {...commonProps}
      variant={props.isSubmit ? 'default' : 'outline'}
      className={cn('px-5 text-md', props.className, {
        'ml-3': props.isSubmit,
        'hover:bg-primary/5': !props.isSubmit,
      })}
    />
  );
};

const ModalFooter = (props: {
  isMobile: boolean;
  submitFormId: string | undefined;
  onSubmit: (() => void) | undefined;
  onClose: (() => void) | undefined;
  submitText: string | undefined;
  closeText: string | undefined;
}) => {
  const { isMobile, submitFormId, onSubmit, onClose, submitText, closeText } = props;

  const btns = useMemo(
    () => (
      <>
        <ModalFooterBtn
          isMobile={isMobile}
          text={closeText ?? 'Close'}
          onClick={onClose}
          className={isMobile ? 'pl-4' : undefined}
        />
        {(onSubmit || submitFormId) && (
          <ModalFooterBtn
            isMobile={isMobile}
            text={submitText ?? 'Submit'}
            isSubmit
            onClick={onSubmit}
            formId={submitFormId}
            className={isMobile ? 'pr-4' : undefined}
          />
        )}
      </>
    ),
    [closeText, submitText, onClose, onSubmit, submitFormId, isMobile]
  );

  if (isMobile) {
    return <div className="fixed w-full left-0 top-0 flex justify-between mt-2 z-30">{btns}</div>;
  }
  return (
    <div className="shadow-none bg-clip-padding rounded-b-[8px] flex-shrink-0 items-center justify-end px-6 py-4 flex relative border-t-[1px] border-t]">
      {btns}
    </div>
  );
};

const ModalDefault = (props: Props) => {
  const { isMobile } = useScreenSize();
  const commonProps = { isMobile };
  const loadingDelayed = useTimeout(200);

  if (isMobile) {
    return (
      <div className="min-h-[97dvh] rounded-t-[10px]">
        {props.loading && loadingDelayed && <LoadingSpinner />}

        <DialogScreenReaderInfo title={props.title} description={props.description} />

        <ModalHeader {...commonProps} title={props.title} description={props.description} />

        <ModalBody {...commonProps}>{props.children}</ModalBody>

        <ModalFooter
          {...commonProps}
          onSubmit={props.onSubmit}
          submitFormId={props.submitFormId}
          submitText={props.submitText}
          closeText={props.closeText}
          onClose={props.onClose}
        />
      </div>
    );
  }

  return (
    <>
      <DialogScreenReaderInfo title={props.title} description={props.description} />
      {props.loading && loadingDelayed && <LoadingSpinner />}
      <ModalHeader {...commonProps} title={props.title} description={props.description} />
      <ModalBody {...commonProps}>{props.children}</ModalBody>
      <ModalFooter
        {...commonProps}
        onSubmit={props.onSubmit}
        submitFormId={props.submitFormId}
        submitText={props.submitText}
        closeText={props.closeText}
        onClose={props.onClose}
      />
    </>
  );
};

const LoadingSpinner = () => {
  return (
    <div className={`absolute inset-0 flex justify-center items-center bg-tint-inverted/50 z-50`}>
      <svg
        className={`animate-spin -ml-1 mr-3 h-10 w-10 text-primary}`}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24">
        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
        <path
          fill="currentColor"
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
      </svg>
    </div>
  );
};

const ModalMsTeamsDialog = (props: Props) => {
  const { width } = useScreenSize();
  const isSmallScreen = width < 500;
  const submitBtn =
    props.submitFormId ?? props.onSubmit ? (
      <Button
        form={props.submitFormId}
        type="submit"
        onClick={props.onSubmit}
        variant="default"
        className="px-5 text-md ml-3">
        {props.submitText ?? 'Submit'}
      </Button>
    ) : null;

  return (
    <div className={`h-screen flex flex-col relative`}>
      {props.loading && <LoadingSpinner />}
      <div
        className={cn(`px-6 py-4`, {
          'pointer-events-none': props.loading,
          'flex-1': !isSmallScreen,
          'px-4 py-2': isSmallScreen,
        })}>
        {/* <TypographyH2 className="tracking-tight">{props.title}</TypographyH2> */}
        <TypographyH2 className="text-2xl font-black truncate">{props.title}</TypographyH2>
        {props.description && <div className="mt-1 text-sm text-muted-foreground">{props.description}</div>}
        <div className="mt-6">{props.children}</div>
      </div>
      {submitBtn && (
        <div
          className={cn(`px-8 py-4 pb-6 mt-2 text-right`, {
            'pointer-events-none': props.loading,
            'border-t': !isSmallScreen,
            'mt-4': !isSmallScreen,
          })}>
          {submitBtn}
        </div>
      )}
    </div>
  );
};

export const ModalLayout = (props: Props) => {
  const { variant } = props;
  if (variant === 'msTeamsDialog') {
    return <ModalMsTeamsDialog {...props} />;
  }
  return <ModalDefault {...props} />;
};

import { createFileRoute, useNavigate, useRouter } from '@tanstack/react-router';
import { Button } from '@client/components/ui/button';
import { ButtonGroup } from '@client/components/ui/custom/button-group';
import { useCallback, useMemo, useState } from 'react';
import { useOfficeSettings } from '@client/features/settings/hooks/use-office-settings';
import { OfficeProfileForm, OfficeProfileFormValues } from '@client/features/settings/components/office-profile-form';
import { useEagerLoadOfficelyUsers } from '@client/context/directory';
import { cn, sleep } from '@client/lib/utils';
import { useBlocker } from '@tanstack/react-router';
import { Separator } from '@client/components/ui/separator';
import { TypographyH3 } from '@client/components/ui/custom/typography-h3';
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '@client/components/ui/dialog';
import { defineForm } from '@client/lib/form/helpers';
import { SimpleForm } from '@client/components/shared/simple-form';
import { trpc } from '@client/lib/trpc';
import { InferFormState } from '@client/lib/form/types';
import { toast } from 'sonner';
import { confirm } from '@client/lib/confirm';
import { isoWeekdayToString } from '@client/lib/date';

const getOfficeDeleteForm = (officeName: string) =>
  defineForm({
    id: 'office-delete-form',
    fields: {
      officeName: {
        type: 'text',
        placeholder: officeName,
      },
    },
    validate(values, errors) {
      if (values.officeName?.toLowerCase() !== officeName.toLowerCase()) {
        errors.officeName = 'Name does not match';
      }
    },
  });

function SettingsOfficesOfficeIndex() {
  const router = useRouter();
  const navigate = useNavigate({ from: Route.fullPath });
  const { id: officeId } = Route.useParams();
  const { office } = Route.useLoaderData();
  const { peopleIds: officeAdminPeopleIds } = useEagerLoadOfficelyUsers(office.managerIds);
  const [profileDirty, setProfileDirty] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [deleteFormState, setDeleteFormState] = useState<InferFormState<typeof officeDeleteFormDef>>();
  const officeDeleteMutation = trpc.office.deleteOffice.useMutation();

  const { updateOffice } = useOfficeSettings({ officeId });
  const officeDeleteFormDef = useMemo(() => getOfficeDeleteForm(office.name), [office.name]);

  const handleUpdateOffice = useCallback(
    async (values: OfficeProfileFormValues) => {
      await updateOffice(values);
      await router.invalidate();
    },
    [updateOffice, router]
  );

  const data: OfficeProfileFormValues = useMemo(
    () => ({
      name: office.name,
      emoji: office.emoji,
      admins: officeAdminPeopleIds,
      workingDays: office.weekdays.map((x) => isoWeekdayToString(x).toLowerCase()),
      floorPlanUrl: office.floorPlan.url ?? undefined,
    }),
    [office, officeAdminPeopleIds]
  );

  // Block the user from leaving the page if the form is dirty
  useBlocker({
    blockerFn: () => confirm('You have unsaved changes, are you sure you want to leave?'),
    condition: profileDirty,
  });

  const handleDeleteConfirmation = useCallback(() => {
    const promise = officeDeleteMutation.mutateAsync(officeId).then(() => {
      return sleep(2000);
    });
    toast.promise(promise, {
      loading: 'Deleting office...',
      success: 'Office deleted successfully!',
      error: 'Failed to delete office',
    });
    void sleep(1000).then(() => {
      // force page refresh and navigate to route
      void promise.then(() => {
        window.location.href = '/';
      });
    });
  }, [officeDeleteMutation, navigate, officeId, router]);

  return (
    <div>
      <OfficeProfileForm data={data} onSubmit={handleUpdateOffice} onDirty={setProfileDirty} />
      <ButtonGroup>
        <Button
          type="submit"
          form={OfficeProfileForm.id}
          className={cn({
            hidden: !profileDirty,
          })}>
          Update
        </Button>
      </ButtonGroup>

      <Separator className="my-6" />

      <TypographyH3>Danger Zone</TypographyH3>
      <div className="border rounded-md p-4 mt-4">
        <div className="flex items-center justify-between gap-4">
          <div>
            <strong>Delete this office</strong>
            <p>This cannot be undone. Your bookings for this office will also be deleted.</p>
          </div>
          <Button variant="destructive" onClick={() => setDeleteOpen(true)}>
            Delete Office
          </Button>
        </div>
      </div>

      <Dialog open={deleteOpen} onOpenChange={setDeleteOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Enter office name to confirm</DialogTitle>
          </DialogHeader>
          <SimpleForm
            definition={officeDeleteFormDef}
            onSubmit={handleDeleteConfirmation}
            onStateChange={setDeleteFormState}
          />
          <DialogFooter>
            <Button variant={'destructive'} disabled={!deleteFormState?.isValid} form={officeDeleteFormDef.id}>
              Delete Office
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export const Route = createFileRoute('/_authenticated/home/settings/offices/$id/')({
  component: SettingsOfficesOfficeIndex,
  loader: async ({ context, params }) => {
    const office = await context.trpcUtils.office.getById.fetch(params.id);
    return { office };
  },
});

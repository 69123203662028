import { AnnouncementCategory, BookingMethod, UserWorkStatusType } from '@officely/models';
import { ScheduleViewType } from '@client/features/home/const';

// ---------- GENERAL TYPES ----------

export type SchedulePeriod = {
  start: string;
  end: string;
};

export type BookedNbh = {
  id: string;
  name: string;
  category: string | undefined;
  spacesLeft?: number | undefined;
};

export type BookedExtra = {
  id: string;
  name: string;
  emoji: string;
  numBooked?: number;
  spacesLeft?: number | undefined;
};

export type Announcement = {
  id: string;
  text: string;
  category: AnnouncementCategory;
};

export type UserListItem = {
  userId: string;
  name: string;
  note?: string;
  nbhId?: string;
  extraIds?: string[];
  extraEmojis?: string[];
  fromDate?: string;
  toDate?: string;
  isSelf?: boolean;
  isFav?: boolean;
  isManager?: boolean;
  isTeammate?: boolean;
  isWaitlisted?: boolean;
  canModify?: boolean;
  canBook?: boolean;
};

export type OfficeDayData = {
  date: string;
  officeId: string;
  totalGoing: number;
  bookings: UserListItem[];
  isToday?: boolean;
  spacesLeft?: number;
  isClosed?: boolean;
  closureReason?: string;
  waitlist?: string[];
  canBook?: boolean;
  canModify?: boolean;
  announcements?: Announcement[];
  bookedExtras?: BookedExtra[];
  bookedNbhs?: BookedNbh[];
  altOfficeId?: string;
};

export type DayStatusesData = {
  date: string;
  statuses: {
    [statusOrOfficeId: string]: {
      isOffice: boolean | undefined;
      emoji: string;
      name: string;
      total: number;
      announcements: Announcement[] | undefined;
      preview: UserListItem[];
    };
  };
  altOfficeId?: string;
};

export type OnDayAction = (action: DayAction) => void;

export enum DayActionType {
  ChangeNote = 'change-note',
  SeeDetails = 'see-details',
  ModifyBooking = 'modify-booking',
  InviteCoworkers = 'invite-coworkers',
  ManageAnnouncements = 'manage-announcements',
  CreateAnnouncement = 'create-announcement',
  ChangeStatus = 'change-status',
  ChangeAwayDates = 'change-away-dates',
  JoinDay = 'join-day',
  CancelDay = 'cancel-day',
}

export type DayAction =
  | {
      type: DayActionType.ChangeNote;
      date: string;
    }
  | {
      type: DayActionType.SeeDetails;
      date: string;
      initialFilters?: string[];
    }
  | {
      type: DayActionType.ModifyBooking;
      date: string;
      officeId: string;
    }
  | {
      type: DayActionType.InviteCoworkers;
      date: string;
      officeId: string;
      nbhId?: string;
    }
  | {
      type: DayActionType.ManageAnnouncements;
      date: string;
      officeId: string;
      id?: string;
    }
  | {
      type: DayActionType.CreateAnnouncement;
      date: string;
      officeId: string;
    }
  | {
      type: DayActionType.ChangeStatus;
      date: string;
      statusOrOfficeId: string;
    }
  | {
      type: DayActionType.ChangeAwayDates;
      date: string;
      awayDatesType?: UserWorkStatusType;
      conflictWith?: UserWorkStatusType;
    }
  | {
      type: DayActionType.JoinDay;
      date: string;
      officeId: string;
    }
  | {
      type: DayActionType.CancelDay;
      date: string;
    };

export type DayActionTyped<T extends DayActionType> = Extract<DayAction, { type: T }>;

export type HomeModalData =
  | {
      type: 'notes';
      date: string;
      bookingMethod: BookingMethod;
    }
  | {
      type: 'details';
      date: string;
      initialFilters?: string[];
    }
  | {
      type: 'book';
      date: string;
      officeId: string;
    }
  | {
      type: 'away-dates';
      date: string;
      awayDatesType?: UserWorkStatusType;
      conflictWith?: UserWorkStatusType;
    }
  | {
      type: 'announcements';
      date: string;
      officeId: string;
      id?: string;
    }
  | {
      type: 'create-announcement';
      date: string;
      officeId: string;
    }
  | {
      type: 'office-day-invite';
      date: string;
      officeId: string;
      nbhId?: string;
    }
  | {
      type: 'officely-invite';
    }
  | {
      type: 'feedback';
      userName: string;
    };

// ---------- SCHEDULE VIEW TYPES ----------

export type ScheduleViewStatuses = {
  type: ScheduleViewType.Statuses;
  period: SchedulePeriod;
  officeAdminOf: string[];
  bookableStatuses: UserWorkStatusType[];
  periodNext?: SchedulePeriod;
  periodPrev?: SchedulePeriod;
  days: DayStatusesData[];
};

export type ScheduleViewOfficeOnly = {
  type: ScheduleViewType.OfficeOnly;
  period: SchedulePeriod;
  periodNext?: SchedulePeriod;
  periodPrev?: SchedulePeriod;
  officeId: string;
  isOfficeAdmin: boolean;
  days: OfficeDayData[];
};

export type ScheduleView = ScheduleViewStatuses | ScheduleViewOfficeOnly;

// ---------- DETAIL VIEW TYPES ----------

export type DetailViewStatusOffice = {
  isOffice: true;
  emoji: string;
  name: string;
  data: OfficeDayData;
};

export type DetailViewStatusNonOffice = {
  isOffice: false;
  emoji: string;
  name: string;
  data: UserListItem[];
};

export type DetailViewStatus = DetailViewStatusOffice | DetailViewStatusNonOffice;

export type DetailView = {
  date: string;
  officeAdminOf: string[];
  statuses: {
    [statusOrOfficeId: string]: DetailViewStatus;
  };
};

import { defineForm } from '@client/lib/form/helpers';
import { useForm } from '@client/lib/form/useForm';
import { useCallback, useEffect, useMemo } from 'react';
import { Form } from '@client/components/ui/form';
import { FormField } from '@client/components/shared/FormField';
import { CommonPropsForForm, FormValues } from '@client/lib/form/types';
import { Weekday } from '@officely/models';

const FORM_ID = 'extra-form' as const;

const getDefinition = (args: { isDefault: boolean }) =>
  defineForm({
    id: FORM_ID,
    fields: {
      enabled: {
        type: 'switch',
        text: 'Enabled',
      },
      name: {
        type: 'text',
        label: 'Name',
        required: true,
      },
      emoji: {
        type: 'emoji',
        label: 'Emoji',
        required: true,
      },
      limit: {
        type: 'radio',
        label: 'Limit availability?',
        options: [
          { value: 'false', label: 'No limit' },
          { value: 'true', label: 'Limit availability' },
        ],
      },
      capacity: {
        type: 'number',
        label: 'Number available?',
        placeholder: 'Leave blank for unlimited',
      },
      daysOfWeek: {
        type: 'multi-select',
        label: 'Days of the week',
        optionsAsBtns: true,
        options: Object.entries(Weekday).map(([label, value]) => ({
          value,
          label,
        })),
        placeholder: 'Select days',
        description: 'Use this to only allow booking on specific days of the week',
      },
    },
    defaultValues: {
      enabled: true,
      limit: 'false',
    },
    conditions: (values) => ({
      hidden: {
        name: args.isDefault,
        emoji: args.isDefault,
        capacity: values.limit !== 'true',
        daysOfWeek: values.limit !== 'true',
      },
    }),
    validate(values, errors) {
      if (typeof values.capacity === 'number' && values.capacity < 0) {
        errors.capacity = 'Capacity cannot be a negative number';
      }
      return errors;
    },
  });

export type ExtraFormValues = FormValues<ReturnType<typeof getDefinition>>;
type Props = CommonPropsForForm<ExtraFormValues> & {
  isDefault: boolean;
};

const ExtraForm = (props: Props) => {
  const { data, onSubmit, isDefault } = props;

  const definition = useMemo(() => {
    return getDefinition({ isDefault });
  }, [isDefault]);

  const { form, setValues, conditions } = useForm(definition);

  useEffect(() => {
    // whenever data changes reset the form and set the values
    form.reset();
    if (data) {
      setValues(data);
    }
  }, [form.reset, data]);

  const handleSubmit = useCallback(
    form.handleSubmit((values) => {
      onSubmit?.(values);
      form.reset(values);
    }),
    [form, onSubmit]
  );

  return (
    <Form {...form}>
      <form onSubmit={handleSubmit} className="flex flex-col gap-y-6" id={FORM_ID}>
        {Object.keys(definition.fields)
          .map((key) => key as keyof typeof definition.fields)
          .map((name) => {
            return (
              <FormField
                {...definition.fields[name]}
                key={name}
                name={name}
                control={form.control}
                disabled={conditions?.disabled?.[name]}
                hidden={conditions?.hidden?.[name]}
              />
            );
          })}
      </form>
    </Form>
  );
};

ExtraForm.id = FORM_ID;

export { ExtraForm };

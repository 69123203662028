import { cn } from '@client/lib/utils';
import { Button } from '@client/components/ui/button';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@client/components/ui/tooltip';
import { PropsWithChildren } from 'react';
import type { CommonIconProps } from '@client/components/ui/custom/icons/types';

type Props = CommonIconProps<{
  Icon: React.FC<{
    className?: string;
    onClick?: (...args: any[]) => void;
  }>;
}>;

const WithToolTip = (
  props: PropsWithChildren<{
    text: string;
  }>
) => (
  <TooltipProvider>
    <Tooltip>
      <TooltipTrigger asChild>{props.children}</TooltipTrigger>
      <TooltipContent>
        <p>{props.text}</p>
      </TooltipContent>
    </Tooltip>
  </TooltipProvider>
);

export const IconWrapper = (props: Props) => {
  const { asButton, tooltip, className, buttonVariant = 'ghost', Icon, onClick } = props;

  // if wrapped by button, pass className to btn
  const content = asButton ? (
    <Button className={cn(className)} variant={buttonVariant} size={'icon'} onClick={onClick}>
      <Icon />
    </Button>
  ) : (
    <Icon className={cn(className)} onClick={onClick} />
  );

  return tooltip ? <WithToolTip text={tooltip}>{content}</WithToolTip> : content;
};

import { createRouter, RouterProvider } from '@tanstack/react-router';
// import { router } from './router';
import { trpc } from '@client/lib/trpc';
import { QueryClientProvider } from '@tanstack/react-query';
import { loggerLink, unstable_httpBatchStreamLink } from '@trpc/client';
import { getTrpcCustomHeaders } from '@client/lib/auth';
import { useTeams } from '@client/lib/useTeams';

import { QueryClient } from '@tanstack/react-query';

import { routeTree } from './routeTree.gen';
import { RouterContext } from '@client/routes/__root';
import { Toaster as SonnerToaster } from '@client/components/ui/sonner';
import { useTheme } from '@client/theme';
import { useEffectOnce } from '@client/lib/useEffectOnce';
import { Confirm } from '@client/lib/confirm';
import { intercomLoad } from '@client/lib/intercom';
import transformer from '@trpc-transformer';

export const queryClient = new QueryClient();

const trpcClient = trpc.createClient({
  links: [
    loggerLink({
      enabled: (opts) => {
        return true;
        // return process.env.NODE_ENV === 'development' || (opts.direction === 'down' && opts.result instanceof Error)
      },
    }),
    unstable_httpBatchStreamLink({
      url: '/trpc',
      headers: getTrpcCustomHeaders,
      transformer,
    }),
  ],
});

// Create a new router instance
const router = createRouter({ routeTree, context: {} as RouterContext });

// Register the router instance for type safety
declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }
}

function AppWithRoutes() {
  const authQuery = trpc.auth.getAuth.useQuery(undefined, {
    staleTime: 1000 * 60 * 5,
  });
  const { context: teamsContext } = useTeams();
  const trpcUtils = trpc.useUtils();

  if (authQuery.isLoading) {
    return null;
  }

  return (
    <RouterProvider
      router={router}
      context={{
        trpcUtils,
        auth: authQuery.data?.healthy ? authQuery.data : undefined,
        appId: (authQuery.data as any)?.appId,
        authErrors: !authQuery.data?.authenticated
          ? ['unauthenticated']
          : authQuery.data?.authenticated && !authQuery.data?.healthy
            ? authQuery.data.issues
            : undefined,
        teamsContext,
      }}
    />
  );
}

function App() {
  const { theme } = useTheme();

  // load intercom
  useEffectOnce(intercomLoad);

  return (
    <trpc.Provider queryClient={queryClient} client={trpcClient}>
      <QueryClientProvider client={queryClient}>
        {/* <div vaul-drawer-wrapper="" className="bg-background min-h-[100vh]"> */}
        <AppWithRoutes />
        <SonnerToaster theme={theme} />
        <Confirm />
        {/* </div> */}
      </QueryClientProvider>
    </trpc.Provider>
  );
}

declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }
}

export default App;

import { SimpleForm, SimpleFormProps } from '@client/components/shared/simple-form';
import { MaskValue } from '@client/components/ui/custom/select-date/types';
import { EMOJI_SELECTOR_HINT_TEXT } from '@client/lib/const';
import { getWeekdaysBetweenDates, isoWeekdayToString } from '@client/lib/date';
import { defineForm } from '@client/lib/form/helpers';
import { FormValues } from '@client/lib/form/types';
import { ANNOUNCEMENT_CATEGORY_PROFILES, AnnouncementCategory } from '@officely/models';

const formDefinition = defineForm({
  id: 'announcement-form',
  fields: {
    dates: {
      type: 'select-date-range',
      label: 'Date',
      required: true,
      mask: {
        min: MaskValue.Today,
      },
    },
    specificWeekdays: {
      type: 'switch',
      text: 'Specific Weekdays Only',
    },
    weekdays: {
      type: 'multi-select',
      optionsAsBtns: true,
      optionsAsBtnsProps: {
        size: 'icon',
        badge: true,
        className: 'h-8 w-8 text-xs',
        variant: 'outline',
        selectedVariant: 'default',
      },
      label: 'Weekdays',
      options: [7, 1, 2, 3, 4, 5, 6].map((x) => ({
        value: x.toString(),
        label: isoWeekdayToString(x, 'M'),
        description: isoWeekdayToString(x, 'Monday'),
      })),
    },
    category: {
      type: 'select',
      label: 'Category',
      required: true,
      options: Object.entries(ANNOUNCEMENT_CATEGORY_PROFILES).map(([cat, { emoji, nickname, group }]) => ({
        value: cat,
        group,
        label: `${emoji}   ${nickname}`,
      })),
    },
    text: {
      type: 'textarea',
      required: true,
      label: 'What do you want to say?',
      description: EMOJI_SELECTOR_HINT_TEXT,
    },
  },
  defaultValues: {
    category: AnnouncementCategory.GENERAL.toString(),
    specificWeekdays: false,
  },
  conditions: (values) => ({
    hidden: {
      specificWeekdays: !values.dates?.from || !values.dates?.to,
      weekdays: !values.specificWeekdays,
    },
  }),
  validate(values, errors) {
    if (values.specificWeekdays && !values.weekdays?.length) {
      errors.weekdays = 'Please select at least one weekday';
    }
    if (values.dates?.from && values.dates?.to && values.specificWeekdays && values.weekdays?.length) {
      const acceptableWeekdays = getWeekdaysBetweenDates(values.dates.from, values.dates.to);
      const hasInvalidWeekdays = values.weekdays?.some((day) => !acceptableWeekdays.includes(parseInt(day)));
      if (hasInvalidWeekdays) {
        errors.weekdays = 'Please select valid weekdays';
      }
    }
  },
});

type Props = Omit<SimpleFormProps<typeof formDefinition>, 'definition'>;

export type AnnouncementFormValues = FormValues<typeof formDefinition>;

const AnnouncementForm = (props: Props) => {
  return <SimpleForm {...props} definition={formDefinition} />;
};

AnnouncementForm.id = formDefinition.id;

export { AnnouncementForm };

import { appInstallDialog } from '@microsoft/teams-js';
import { useCallback } from 'react';

export const useDeepLink = (args: { appId: string }) => {
  const { appId } = args;
  const openAppInstallDialog = useCallback(() => {
    appInstallDialog
      .openAppInstallDialog({
        appId,
      })
      .catch((err) => {
        console.error('Error opening app install dialog', err);
      });
  }, []);

  return {
    openAppInstallDialog,
    getTabDeepLink: (tab: 'chat' | 'home') => {
      return `https://teams.microsoft.com/l/entity/${appId}/${tab}`;
    },
  };
};

import * as React from 'react';

import { Progress } from '@client/components/ui/progress';

export function LoadingBar() {
  const [progress, setProgress] = React.useState(13);

  React.useEffect(() => {
    const timer = setTimeout(() => setProgress(66), 500);
    return () => clearTimeout(timer);
  }, []);

  React.useEffect(() => {
    const timer = setTimeout(() => setProgress(90), 1000);
    return () => clearTimeout(timer);
  });

  return <Progress value={progress} className="w-[60%]" />;
}

import { createFileRoute } from '@tanstack/react-router';
import { useCallback, useMemo, useState } from 'react';
import { useRouter } from '@tanstack/react-router';
import { Modal } from '@client/components/ui/custom/modal';
import { NbhTable } from '@client/features/settings/components/nbh-table';
import { useNbhSettings } from '@client/features/settings/hooks/use-nbh-settings';
import { NbhFormValues } from '@client/features/settings/components/nbh-form';
import { NbhModal } from '@client/features/settings/components/nbh-modal';
import { useDirectoryContext, useEagerLoadOfficelyUsers } from '@client/context/directory';
import _ from 'lodash';

function SettingsOfficesOfficeNbhs() {
  const { office } = Route.useLoaderData();
  const { neighbourhoods: _nbhs } = office;
  const params = Route.useParams();
  const { people } = useDirectoryContext();

  const nbhs = useMemo(() => {
    return _nbhs.map((nbh) => ({
      ...nbh,
      category: nbh.category ?? undefined,
      capacity: nbh.capacity ?? undefined,
      usersAllowed: nbh.usersAllowed ?? undefined,
    }));
  }, [_nbhs]);

  const categories = useMemo(() => {
    return _.uniq(nbhs.map((nbh) => nbh.category).filter(Boolean) as string[]);
  }, [nbhs]);

  const allUserIds = useMemo<string[]>(() => {
    return _.uniq(nbhs.map((nbh) => nbh?.usersAllowed ?? []).flat());
  }, [nbhs]);

  const { loading: nbhPeopleLoading } = useEagerLoadOfficelyUsers(allUserIds);

  const { createNbh, updateNbh, deleteNbhs } = useNbhSettings({
    officeId: params.id,
  });

  const [formConfig, setFormConfig] = useState<{
    open: boolean;
    id?: string;
  }>({
    open: false,
  });

  const router = useRouter();

  const editData = useMemo<NbhFormValues | undefined>(() => {
    if (!formConfig.id) return undefined;
    const nbh = nbhs.find((x) => x.id === formConfig.id);
    return (
      nbh && {
        name: nbh.name,
        capacity: nbh.capacity,
        category: nbh.category,
        peopleIds: nbh.usersAllowed?.map((x) => people.byUserId[x]).filter(Boolean),
        visibility: !nbh.usersAllowed?.length ? 'public' : 'private',
      }
    );
  }, [formConfig.id, nbhs, nbhPeopleLoading]);

  const handleSubmit = useCallback(
    async (values: NbhFormValues) => {
      if (formConfig.id) {
        await updateNbh(formConfig.id, values);
      } else {
        await createNbh(values);
      }
      setFormConfig({ open: false });
      await router.invalidate();
    },
    [formConfig.id, updateNbh, createNbh, router]
  );

  const handleEdit = useCallback(
    (id: string) => {
      setFormConfig({
        open: true,
        id,
      });
    },
    [setFormConfig]
  );

  const handleAdd = useCallback(() => {
    setFormConfig({
      open: true,
    });
  }, [setFormConfig]);

  const handleDelete = useCallback(
    async (ids: string[]) => {
      await deleteNbhs(ids);
      await router.invalidate();
    },
    [deleteNbhs, router]
  );

  const handleModalClose = useCallback(() => {
    setFormConfig({
      open: false,
    });
  }, [setFormConfig]);

  const rows = useMemo(() => {
    return nbhs.map((nbh) => ({
      ...nbh,
      private: !!nbh.usersAllowed?.length,
    }));
  }, [nbhs]);

  return (
    <div>
      <NbhTable rows={rows} onEdit={handleEdit} onDelete={handleDelete} onAdd={handleAdd} />
      <Modal open={formConfig.open} onClose={handleModalClose}>
        <NbhModal data={editData} categories={categories} onClose={handleModalClose} onSubmit={handleSubmit} />
      </Modal>
    </div>
  );
}

export const Route = createFileRoute('/_authenticated/home/settings/offices/$id/nbhs')({
  component: SettingsOfficesOfficeNbhs,
  loader: async ({ context, params }) => {
    const office = await context.trpcUtils.office.getById.fetch(params.id);
    return { office };
  },
});

import { Emoji } from '@client/components/ui/custom/emoji';
import { TypographyP } from '@client/components/ui/custom/typography-p';
import { Office } from '@client/features/app/components/office';
import { cn } from '@client/lib/utils';
import { ANNOUNCEMENT_CATEGORY_PROFILES, AnnouncementCategory } from '@officely/models';
import { PropsWithChildren, useMemo } from 'react';

type Props = {
  className?: string;
  announcements: {
    id: string;
    category: AnnouncementCategory;
    text: string;
  }[];
  isOfficeAdmin?: boolean;
  altOfficeId?: string;
  onAnnouncementClick?: (id: string) => void;
};

const NoticeText = (props: PropsWithChildren<{ emoji: string; onClick?: () => void }>) => {
  const { emoji, children, onClick } = props;
  const renderedText = useMemo(
    () => (
      <TypographyP className="text-sm flex items-center">
        <Emoji sm className="mr-2">
          {emoji}
        </Emoji>
        <span className={onClick ? 'cursor-pointer hover:underline' : ''}>{children}</span>
      </TypographyP>
    ),
    [emoji, onClick, children]
  );

  return onClick ? (
    <div>
      <button onClick={onClick}>{renderedText}</button>
    </div>
  ) : (
    <>{renderedText}</>
  );
};

const Announcement = (
  props: Pick<Props, 'isOfficeAdmin' | 'onAnnouncementClick'> & {
    id: string;
    category: AnnouncementCategory;
    text: string;
  }
) => {
  const { id, category, text, isOfficeAdmin, onAnnouncementClick } = props;
  const { emoji } = ANNOUNCEMENT_CATEGORY_PROFILES[category as AnnouncementCategory];

  const onClick = useMemo(() => {
    if (isOfficeAdmin) {
      return () => onAnnouncementClick?.(id);
    }
  }, [isOfficeAdmin, onAnnouncementClick, id]);

  return (
    <NoticeText emoji={emoji} onClick={onClick}>
      {text}
    </NoticeText>
  );
};

const AltOfficeNotice = (props: { officeId: string }) => {
  const { officeId } = props;
  return (
    <NoticeText emoji={'ℹ️'}>
      <span className="">
        You've booked <Office id={officeId} hideEmoji className="font-bold italic" />
      </span>
    </NoticeText>
  );
};

export const DayNotices = (props: Props) => {
  const { announcements, className, isOfficeAdmin, altOfficeId, onAnnouncementClick } = props;

  const hasNotices = useMemo(() => announcements.length > 0 || altOfficeId, [announcements, altOfficeId]);

  return !hasNotices ? null : (
    <div className={cn('flex flex-col gap-2 pl-2 text-foreground/80', className)}>
      {altOfficeId && <AltOfficeNotice officeId={altOfficeId} />}
      {announcements.map((a) => (
        <Announcement key={a.id} {...a} isOfficeAdmin={isOfficeAdmin} onAnnouncementClick={onAnnouncementClick} />
      ))}
    </div>
  );
};

/* eslint-disable sonarjs/no-small-switch */

import { CommonPropsForModal } from '@client/lib/modal';
import { ModalLayout, ModalLayoutProps } from '@client/components/ui/custom/modal-layout';
import { TypographyP } from '@client/components/ui/custom/typography-p';
import { Emoji } from '@client/components/ui/custom/emoji';
import { emojis } from '@client/lib/emojis';
import { Button } from '@client/components/ui/button';
import { Separator } from '@client/components/ui/separator';
import { Person } from '@client/features/app/components/person';
import { PlusCircledIcon } from '@radix-ui/react-icons';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@client/components/ui/dropdown-menu';
import { Office } from '@officely/models';
import { DashedBtn } from '@client/components/ui/custom/dashed-btn';

const TITLE = 'Offices';
const DESCRIPTION = 'Manage your offices and create new ones.';

type Props = CommonPropsForModal & {
  userId: string;
  canCreate: boolean;
  isAccountAdmin: boolean;
  offices: Pick<Office, 'id' | 'name' | 'address' | 'emoji' | 'managerIds'>[];
  onEdit: (officeId: string) => void;
  onCreate: () => void;
};

const ManageOfficeDropdown = () => {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="outline">Manage</Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56">
        <DropdownMenuItem>General Settings</DropdownMenuItem>
        <DropdownMenuItem>Neighborhoods & Desks</DropdownMenuItem>
        <DropdownMenuItem>Bookable Extras</DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export const OfficesModal = (props: Props) => {
  const { userId, canCreate, isAccountAdmin, offices, onEdit, onCreate, ...restForModal } = props;

  const commonModalProps: ModalLayoutProps = {
    title: TITLE,
    description: DESCRIPTION,
    closeText: props.nested ? 'Back' : 'Close',
    ...restForModal,
  };

  return (
    <ModalLayout {...commonModalProps}>
      {offices.length === 0 && <TypographyP>You don't have any offices yet. Create one below.</TypographyP>}
      {offices.map((office, i) => (
        <div key={office.id}>
          <div className="flex items-center justify-between">
            <div>
              <div className="flex items-center">
                <Emoji className="mr-2" lg>
                  {office.emoji}
                </Emoji>
                <TypographyP className="font-bold">{office.name}</TypographyP>
              </div>
              <TypographyP className="italic">{office.address}</TypographyP>
              <div className="text-sm mt-2">
                <div className="flex items-center gap-1">
                  <Emoji className="mr-1" sm>
                    {emojis.old_key}
                  </Emoji>
                  {office.managerIds.map((userId) => (
                    <Person key={userId} userId={userId} variant="tag" />
                  ))}
                </div>
              </div>
            </div>

            {/* Manage Button */}
            {(isAccountAdmin || office.managerIds.includes(userId)) && (
              <Button variant="outline" onClick={() => onEdit(office.id)}>
                Manage
              </Button>
            )}
          </div>
          {i < offices.length - 1 && <Separator className="my-4" />}
        </div>
      ))}

      {/* Office Create Btn */}
      {canCreate && (
        <DashedBtn onClick={onCreate}>
          <PlusCircledIcon className="w-6 h-6 mr-4 text-muted-foreground" />
          <TypographyP className="text-center text-muted-foreground text-sm italic">Add another office</TypographyP>
        </DashedBtn>
      )}
    </ModalLayout>
  );
};

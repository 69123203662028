import { cn } from '@client/lib/utils';
import React from 'react';

export const TypographyP = (props: React.HTMLAttributes<HTMLElement>) => {
  return (
    <p {...props} className={cn(`leading-7`, props.className)}>
      {props.children}
    </p>
  );
};

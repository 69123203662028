import { cn } from '@client/lib/utils';
import { PropsWithChildren } from 'react';

export const DashedBtn = (
  props: PropsWithChildren<{
    onClick?: () => void;
    className?: string;
  }>
) => {
  return (
    <button
      onClick={props.onClick}
      className={cn('w-full rounded-md mt-6 p-6 w-full border border-dashed flex items-center', props.className)}>
      {props.children}
    </button>
  );
};

import type { FormField as TypeFormField, FormFieldType } from '@client/lib/form/types';
import * as FormUI from '@client/components/ui/form';
import { cn } from '@client/lib/utils';
import { Input } from '@client/components/ui/input';
import { MultiSelectPeople } from '@client/components/shared/MultiSelectPeople';
import { RadioGroup, RadioGroupItem } from '@client/components/ui/radio-group';
import { useCallback } from 'react';
import { Select } from '@client/components/ui/custom/select';
import type { Control } from 'react-hook-form';
import { Switch } from '@client/components/ui/switch';
import { TypographySmall } from '@client/components/ui/custom/typography-small';
import { MultiSelect } from '@client/components/ui/custom/multi-select/multi-select';
import { Textarea } from '@client/components/ui/textarea';
import { Checkbox } from '@client/components/ui/checkbox';
import { SelectTeamChannel } from '@client/components/shared/SelectTeamChannel';
import { EMOJI_SELECTOR_HINT_TEXT } from '@client/lib/const';
import { SelectDate } from '@client/components/ui/custom/select-date';
import { SelectDateRange } from '@client/components/ui/custom/select-date-range';

type FormFieldRender = Parameters<Parameters<typeof FormUI.FormField>[0]['render']>['0'];
type FormFieldRenderField = FormFieldRender['field'];

type FieldProps<T extends FormFieldType = FormFieldType> = {
  type: T;
  name: string;
  hidden?: boolean;
  disabled?: boolean;
  control: Control<any>;
  className?: string;
  description?: string;
} & TypeFormField<T>;

type LowLevelFieldProps<T extends FormFieldType = FormFieldType> = Omit<
  FieldProps<T>,
  'type' | 'name' | 'control' | 'disabled'
> & {
  field: FormFieldRenderField;
};

const Label = (props: { children: string; required: boolean | undefined; showOptionalLabel: boolean | undefined }) => {
  return (
    <span>
      <span className="font-bold">{props.children}</span>
      {!props.required && props.showOptionalLabel && (
        <span className="text-muted-foreground italic ml-2"> (optional)</span>
      )}
    </span>
  );
};

const FieldWrapper = (props: React.PropsWithChildren<LowLevelFieldProps>) => {
  const { hidden, label, required, showOptionalLabel, children, className, description } = props;
  return (
    <FormUI.FormItem
      className={cn(className, {
        hidden,
      })}>
      {label && (
        <FormUI.FormLabel>
          <Label required={required} showOptionalLabel={showOptionalLabel}>
            {label}
          </Label>
        </FormUI.FormLabel>
      )}
      <FormUI.FormControl>{children}</FormUI.FormControl>
      {description && <FormUI.FormDescription>{description}</FormUI.FormDescription>}
      <FormUI.FormMessage />
    </FormUI.FormItem>
  );
};

const FieldTextInput = (props: LowLevelFieldProps<'text'>) => {
  const { placeholder, field } = props;
  return (
    <FieldWrapper {...props}>
      <Input placeholder={placeholder} {...field} value={field.value ?? ''} />
    </FieldWrapper>
  );
};

const FieldTextAreaInput = (props: LowLevelFieldProps<'textarea'>) => {
  const { placeholder, field } = props;
  return (
    <FieldWrapper {...props}>
      <Textarea rows={4} placeholder={placeholder} {...field} value={field.value ?? ''} />
    </FieldWrapper>
  );
};

const FieldInputEmoji = (props: LowLevelFieldProps<'emoji'>) => {
  const { placeholder, field } = props;
  return (
    <FieldWrapper {...props} description={EMOJI_SELECTOR_HINT_TEXT}>
      <Input placeholder={placeholder} {...field} value={field.value ?? ''} />
    </FieldWrapper>
  );
};

const FieldSwitch = (props: LowLevelFieldProps<'switch'>) => {
  const { text, field } = props;
  return (
    <FieldWrapper {...props}>
      <div className="flex items-center">
        <Switch checked={field.value} onCheckedChange={field.onChange} />
        <TypographySmall className="ml-3">{text}</TypographySmall>
      </div>
    </FieldWrapper>
  );
};

const FieldMultiSelect = (props: LowLevelFieldProps<'multi-select'>) => {
  const { field } = props;
  return (
    <FieldWrapper {...props}>
      <MultiSelect
        onChange={field.onChange}
        options={props.options}
        selected={props.options.filter((opt) => field.value?.includes(opt.value))}
        placeholder={props.placeholder}
        variant="default"
        disabled={field.disabled}
        closeOnSelect={props.closeOnSelect}
        optionsAsBtns={props.optionsAsBtns}
        optionsAsBtnsProps={props.optionsAsBtnsProps}
      />
    </FieldWrapper>
  );
};

const FieldInputNumber = (props: LowLevelFieldProps<'number'>) => {
  const { placeholder, field } = props;

  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const parsed = parseInt(e.target.value, 10);
      field.onChange(isNaN(parsed) ? undefined : parsed);
    },
    [field.onChange]
  );

  return (
    <FieldWrapper {...props}>
      <Input type="number" placeholder={placeholder} {...field} value={field.value ?? ''} onChange={handleChange} />
    </FieldWrapper>
  );
};

const FieldMultiSelectPeople = (props: LowLevelFieldProps<'multi-select-people'>) => {
  const { placeholder, field } = props;
  return (
    <FieldWrapper {...props}>
      <MultiSelectPeople
        placeholder={placeholder}
        value={field.value ?? []}
        onChange={field.onChange}
        disabled={field.disabled}
        closeOnSelect={props.closeOnSelect}
      />
    </FieldWrapper>
  );
};

const FieldSelectTeamChannel = (props: LowLevelFieldProps<'select-team-channel'>) => {
  const { placeholder, field } = props;
  return (
    <FieldWrapper {...props}>
      <SelectTeamChannel
        placeholder={placeholder}
        value={field.value}
        onChange={field.onChange}
        disabled={field.disabled}
        allowClear={props.allowClear}
        inForm
      />
    </FieldWrapper>
  );
};

const FieldRadio = (props: LowLevelFieldProps<'radio'>) => {
  const { hidden, label, options, required, showOptionalLabel, field, className } = props;
  return (
    <FormUI.FormItem
      className={cn('', className, {
        hidden,
      })}>
      {label && (
        <FormUI.FormLabel>
          <Label showOptionalLabel={showOptionalLabel} required={required}>
            {label}
          </Label>
        </FormUI.FormLabel>
      )}
      <FormUI.FormControl>
        <RadioGroup onValueChange={field.onChange} value={field.value} className={cn('flex flex-col space-y-1')}>
          {options.map((opt) => (
            <FormUI.FormItem key={opt.value} className="flex items-center space-x-3 space-y-0">
              <FormUI.FormControl>
                <RadioGroupItem value={opt.value} />
              </FormUI.FormControl>
              <FormUI.FormLabel className="font-normal">{opt.label ?? opt.value}</FormUI.FormLabel>
            </FormUI.FormItem>
          ))}
        </RadioGroup>
      </FormUI.FormControl>
      <FormUI.FormMessage />
    </FormUI.FormItem>
  );
};

const FieldCheckboxes = (props: LowLevelFieldProps<'radio'>) => {
  const { hidden, label, options, required, showOptionalLabel, field, className, description } = props;
  return (
    <FormUI.FormItem
      className={cn('space-y-2 py-2', className, {
        hidden,
      })}>
      {Boolean(label) && (
        <div className="mb-4">
          {label && (
            <FormUI.FormLabel>
              <Label showOptionalLabel={showOptionalLabel} required={required}>
                {label}
              </Label>
            </FormUI.FormLabel>
          )}
        </div>
      )}

      {options.map((opt) => (
        <FormUI.FormItem key={opt.value} className="flex flex-row items-start space-x-3 space-y-0">
          <FormUI.FormControl>
            <Checkbox
              checked={field.value?.includes(opt.value)}
              onCheckedChange={(checked) => {
                return checked
                  ? field.onChange([...field.value, opt.value])
                  : field.onChange(field.value?.filter((value: string) => value !== opt.value));
              }}
            />
          </FormUI.FormControl>
          <FormUI.FormLabel className="font-normal">{opt.label}</FormUI.FormLabel>
        </FormUI.FormItem>
      ))}

      {Boolean(description) && (
        <div className="mt-2">{description && <FormUI.FormDescription>{description}</FormUI.FormDescription>}</div>
      )}
      <FormUI.FormMessage />
    </FormUI.FormItem>
  );
};

const FieldSelect = (props: LowLevelFieldProps<'select'>) => {
  const { options, placeholder, onAddNew, optionsAsBtns, allowClear, field } = props;

  const selected = options.find((opt) => opt.value === field.value);

  return (
    <FieldWrapper {...props}>
      <Select
        optionsAsBtns={optionsAsBtns}
        onAddNew={onAddNew}
        placeholder={placeholder}
        selected={selected}
        options={options}
        onChange={field.onChange}
        allowClear={allowClear}
        inForm
      />
    </FieldWrapper>
  );
};

const FieldSelectDate = (props: LowLevelFieldProps<'select-date'>) => {
  const { field } = props;
  return (
    <FieldWrapper {...props}>
      <SelectDate
        inForm
        placeholder={props.placeholder}
        selected={field.value}
        onChange={field.onChange}
        allowClear={props.allowClear}
        asBtn={props.asBtn}
        asBtnProps={props.asBtnProps}
        closeOnChange={props.closeOnChange}
        disabled={field.disabled}
        placeholderIcon={props.placeholderIcon}
        placeholderIconPosition={props.placeholderIconPosition}
        mask={props.mask}
      />
    </FieldWrapper>
  );
};

const FieldSelectDateRange = (props: LowLevelFieldProps<'select-date-range'>) => {
  const { field } = props;
  return (
    <FieldWrapper {...props}>
      <SelectDateRange
        inForm
        placeholder={props.placeholder}
        selected={field.value}
        onChange={field.onChange}
        allowClear={props.allowClear}
        asBtn={props.asBtn}
        asBtnProps={props.asBtnProps}
        closeOnChange={props.closeOnChange}
        disabled={field.disabled}
        placeholderIcon={props.placeholderIcon}
        placeholderIconPosition={props.placeholderIconPosition}
        mask={props.mask}
      />
    </FieldWrapper>
  );
};

export const FormField = (props: FieldProps) => {
  const { type, name, control, disabled, ...rest } = props;
  return (
    <FormUI.FormField
      key={name}
      name={name}
      control={control}
      disabled={disabled}
      render={({ field }) => {
        const lowLevelFieldProps: LowLevelFieldProps = {
          ...rest,
          field,
        };
        switch (type) {
          case 'text':
            return <FieldTextInput {...(lowLevelFieldProps as any)} />;
          case 'textarea':
            return <FieldTextAreaInput {...(lowLevelFieldProps as any)} />;
          case 'emoji':
            return <FieldInputEmoji {...(lowLevelFieldProps as any)} />;
          case 'switch':
            return <FieldSwitch {...(lowLevelFieldProps as any)} />;
          case 'number':
            return <FieldInputNumber {...(lowLevelFieldProps as any)} />;
          case 'radio':
            return <FieldRadio {...(lowLevelFieldProps as any)} />;
          case 'checkboxes':
            return <FieldCheckboxes {...(lowLevelFieldProps as any)} />;
          case 'select':
            return <FieldSelect {...(lowLevelFieldProps as any)} />;
          case 'multi-select':
            return <FieldMultiSelect {...(lowLevelFieldProps as any)} />;
          case 'multi-select-people':
            return <FieldMultiSelectPeople {...(lowLevelFieldProps as any)} />;
          case 'select-team-channel':
            return <FieldSelectTeamChannel {...(lowLevelFieldProps as any)} />;
          case 'select-date':
            return <FieldSelectDate {...(lowLevelFieldProps as any)} />;
          case 'select-date-range':
            return <FieldSelectDateRange {...(lowLevelFieldProps as any)} />;
          default:
            throw new Error('Invalid field type');
        }
      }}
    />
  );
};

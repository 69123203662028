import { createFileRoute, useRouter } from '@tanstack/react-router';
import { z } from 'zod';
import { CommonPropsForModal } from '@client/lib/modal';
import { useTeams } from '@client/lib/useTeams';
import { useCallback } from 'react';
import { Modal } from '@client/components/ui/custom/modal';
import { useNavigate } from '@tanstack/react-router';
import { BillingModal } from '@client/features/billing/components/billing-modal';
import { sleep } from '@client/lib/utils';
import { SettingsBillingStep } from '@client/features/billing/const';

function DialogBilling() {
  const { closeDialog } = useTeams();
  const loaderData = Route.useLoaderData();
  const { billingStep } = Route.useSearch();
  const router = useRouter();
  const navigate = useNavigate({ from: Route.fullPath });

  const handleChangeBillingStep = useCallback(
    (step: SettingsBillingStep | undefined) => {
      void navigate({
        to: '/dialog/billing',
        search: (old) => ({ ...old, billingStep: step }),
      });
    },
    [navigate]
  );

  const handlePlanChanged = useCallback(async () => {
    void sleep(1000).then(() => {
      void router.invalidate();
    });
  }, [router]);

  const props: CommonPropsForModal = {
    variant: 'msTeamsDialog',
    onClose: closeDialog,
    onDone: closeDialog,
  };

  return (
    <Modal open={true} onClose={closeDialog} isTeamsDialog={true}>
      <BillingModal
        {...props}
        {...loaderData}
        billingStep={billingStep}
        changeBillingStep={handleChangeBillingStep}
        onPlanChanged={handlePlanChanged}
      />
    </Modal>
  );
}

export const Route = createFileRoute('/_authenticated/dialog/billing')({
  component: DialogBilling,
  validateSearch: z.object({
    billingStep: z.nativeEnum(SettingsBillingStep).optional().catch(undefined),
  }),
  loader: async ({ context }) => {
    const { user } = context.auth!;
    const account = await context.trpcUtils.account.current.fetch();
    const currentPlan = account.billing.plan;
    const isAccountAdmin = account.managers.includes(user.id);
    const customerId = account.billing.customerId ?? undefined;
    const userName = user.profile.name;
    return {
      currentPlan,
      isAccountAdmin,
      customerId,
      userName,
    };
  },
});

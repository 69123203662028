import { SelectDateRange } from '@client/components/ui/custom/select-date-range';
import { SelectDateRangeValue } from '@client/components/ui/custom/select-date-range/types';
import { SelectDateProps } from '@client/components/ui/custom/select-date/types';
import { useCallback } from 'react';

export const SelectDate = (props: SelectDateProps) => {
  const { selected, onChange, closeOnChange = true, ...rest } = props;

  const handleDateRangeChange = useCallback(
    (value: SelectDateRangeValue) => {
      onChange?.(value.from);
    },
    [onChange]
  );

  return (
    <SelectDateRange
      disableRange
      closeOnChange={closeOnChange}
      selected={{ from: selected }}
      onChange={handleDateRangeChange}
      {...rest}
    />
  );
};

import { ModalLayout } from '@client/components/ui/custom/modal-layout';
import { TypographyMuted } from '@client/components/ui/custom/typography-muted';
import { ExtraForm, ExtraFormValues } from '@client/features/settings/components/extra-form';

type Props = {
  data?: ExtraFormValues;
  isDefault: boolean;
  onClose: () => void;
  onSubmit: (values: ExtraFormValues) => void;
};

export const ExtraModal = ({ data, isDefault, onClose, onSubmit }: Props) => {
  const isUpdating = !!data;

  return (
    <ModalLayout
      title={isDefault && data ? `${data.emoji}  ${data.name}` : isUpdating ? 'Edit Extra' : 'Create Extra'}
      submitText={isUpdating ? 'Update' : 'Create'}
      submitFormId={ExtraForm.id}
      closeText="Cancel"
      onClose={onClose}>
      <ExtraForm isDefault={isDefault} data={data} onSubmit={onSubmit} />
      {isDefault && data && (
        <div className="mt-6">
          <TypographyMuted className="italic text-sm">
            This extra's profile cannot be changed as it is managed by Officely.
          </TypographyMuted>
        </div>
      )}
    </ModalLayout>
  );
};

import { MaskValue, SelectDateRangeProps } from '@client/components/ui/custom/select-date-range/types';
import { addDays, endOfISOWeek, isAfter, isBefore, startOfDay, startOfISOWeek, subDays, isSameDay } from 'date-fns';

export function resolveMaskValue(v: MaskValue): Date {
  const today = startOfDay(new Date());
  const tomorrow = addDays(today, 1);
  const yesterday = subDays(today, 1);
  const thisWeekStart = startOfISOWeek(today);
  const thisWeekEnd = endOfISOWeek(today);
  const nextWeekStart = addDays(thisWeekStart, 7);
  const nextWeekEnd = addDays(nextWeekStart, 7);
  const lastWeekStart = subDays(thisWeekStart, 7);
  const lastWeekEnd = subDays(thisWeekEnd, 7);
  switch (v) {
    case MaskValue.Today:
      return today;
    case MaskValue.Tomorrow:
      return tomorrow;
    case MaskValue.Yesterday:
      return yesterday;
    case MaskValue.WeekStart:
      return thisWeekStart;
    case MaskValue.WeekEnd:
      return thisWeekEnd;
    case MaskValue.NextWeekStart:
      return nextWeekStart;
    case MaskValue.NextWeekEnd:
      return nextWeekEnd;
    case MaskValue.LastWeekStart:
      return lastWeekStart;
    case MaskValue.LastWeekEnd:
      return lastWeekEnd;
    default:
      throw new Error(`Invalid mask value: ${v}`);
  }
}

export function resolveMask(d: Date, mask: NonNullable<SelectDateRangeProps['mask']>): boolean {
  if (mask.custom) {
    return mask.custom(d);
  }
  let { min, max } = mask;
  if (typeof min === 'string') {
    min = resolveMaskValue(min);
  }
  if (typeof max === 'string') {
    max = resolveMaskValue(max);
  }
  const minResult = !min || isSameDay(d, min) || isAfter(d, min);
  const maxResult = !max || isSameDay(d, max) || isBefore(d, max);
  return minResult && maxResult;
}

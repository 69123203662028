// @ts-nocheck

const HOSTNAME = window.location.hostname;
const isProd = HOSTNAME === 'teams.getofficely.com';
const isStaging = HOSTNAME === 'teams.development.getofficely.com';

type Config = {
  env: 'production' | 'staging' | 'local';
  clientId: string;
  posthogKey?: string;
  posthogHost?: string;
};

export const config: Config = isProd
  ? {
      env: 'production',
      clientId: 'eaa404d5-913c-4bf5-afe5-e7eadf3a031e',
      posthogKey: 'phc_JeeS4wBiLUYKBeAMUC2ujI9yV6yl1DbGWXvVXegvJUl',
      posthogHost: 'https://eu.posthog.com',
    }
  : isStaging
    ? {
        env: 'staging',
        clientId: 'f577700d-201c-46c7-b62d-93115cb78e4e',
        posthogKey: 'phc_mGZnSC7VIiLVGJIp71AmEHGCUwHfP4hyLIK4qYQfRIy',
        posthogHost: 'https://eu.posthog.com',
      }
    : {
        env: 'local',
        clientId: import.meta.env.VITE_LOCAL_CLIENT_ID,
        posthogKey: import.meta.env.VITE_LOCAL_PUBLIC_POSTHOG_KEY,
        posthogHost: import.meta.env.VITE_LOCAL_PUBLIC_POSTHOG_HOST,
      };

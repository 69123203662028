import z from 'zod';
import { AddressSearch } from '@client/components/shared/AddressSearch';
import { TypographyP } from '@client/components/ui/custom/typography-p';
import { Button } from '@client/components/ui/button';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@client/components/ui/form';
import { trpc } from '@client/lib/trpc';
import { useAddressSearch } from '@client/lib/useAddressSearch';
import { Cross2Icon } from '@radix-ui/react-icons';
import { MapPin } from 'lucide-react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Input } from '@client/components/ui/input';
import { useCallback, useEffect, useState } from 'react';
import { OFFICE_ADMIN_ROLE_CAPABILITIES_DESCRIPTION } from '@officely/models';
import { MultiSelectPeople } from '@client/components/shared/MultiSelectPeople';
import { StepComponentProps } from '@client/features/onboarding/types';
import { EMOJI_SELECTOR_HINT_TEXT } from '@client/lib/const';

const formSchema = z.object({
  name: z.string().min(3),
  emoji: z.string(),
  admins: z.array(z.string()),
});

type Props = StepComponentProps & {
  msUserId: string;
};

const OfficeForm = (
  props: Props & {
    suggestedName?: string;
    onSubmit: (data: z.infer<typeof formSchema>) => void;
  }
) => {
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      emoji: props.state.officeEmoji ?? '🏢',
      name: props.state.officeName ?? props.suggestedName,
      admins: props.state.officeManagerIds ?? [props.msUserId],
    },
  });

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(props.onSubmit)}>
        <FormField
          name="name"
          control={form.control}
          render={({ field }) => (
            <FormItem className="py-2">
              <FormLabel>Name</FormLabel>
              <FormControl>
                <Input
                  placeholder={`Enter a name for your office or leave blank for "${props.suggestedName}"`}
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          name="emoji"
          control={form.control}
          render={({ field }) => (
            <FormItem className="py-2">
              <FormLabel>Emoji</FormLabel>
              <FormControl>
                <Input placeholder="Paste emoji or leave blank for default" {...field} />
              </FormControl>
              <FormDescription className="italic">{EMOJI_SELECTOR_HINT_TEXT}</FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          name="admins"
          control={form.control}
          render={({ field }) => (
            <FormItem className="py-2">
              <FormLabel>Office Admins</FormLabel>
              <FormControl>
                <MultiSelectPeople value={field.value} onChange={field.onChange} />
              </FormControl>
              <FormDescription className="italic">{`These people will be able to ${OFFICE_ADMIN_ROLE_CAPABILITIES_DESCRIPTION}.`}</FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className="py-4 space-x-4">
          <Button type="submit" variant="default">
            Next
          </Button>
        </div>
      </form>
    </Form>
  );
};

const AddressLookup = (props: Props) => {
  const { state, updateState } = props;
  const addressSearch = useAddressSearch({
    includeSuggestedLocations: true,
  });
  return (
    <>
      <TypographyP className="mt-2">To kick things off, start by entering your office address below.</TypographyP>
      <div className="mt-6">
        <AddressSearch
          searchTerm={addressSearch.searchTerm}
          results={addressSearch.results}
          onSearch={addressSearch.setSearchTerm}
          onSelect={(val) =>
            updateState({
              ...state,
              googlePlaceId: val,
            })
          }
        />
      </div>
    </>
  );
};

export const CreateOffice = (props: Props) => {
  const { state, updateState, onNext } = props;
  const [submitted, setSubmitted] = useState(false);

  const clearAddress = useCallback(() => updateState({ googlePlaceId: undefined }), [updateState]);
  const handleFormSubmit = useCallback(
    (values: z.infer<typeof formSchema>) => {
      updateState({
        officeName: values.name,
        officeEmoji: values.emoji || '🏢',
        officeManagerIds: values.admins,
      });
      setSubmitted(true);
    },
    [updateState]
  );

  useEffect(() => {
    if (submitted) {
      onNext();
    }
  }, [submitted]);

  const googlePlaceInfoQuery = trpc.googleMaps.getPlaceInfo.useQuery(
    {
      googlePlaceId: state.googlePlaceId ?? '',
    },
    {
      enabled: !!state.googlePlaceId,
    }
  );

  if (!state.googlePlaceId || !googlePlaceInfoQuery.data) {
    return (
      <>
        <AddressLookup {...props} />
        {/* {googlePlaceInfoQuery.isFetching && <LoadingSpinner subtle className="mt-6" />} */}
      </>
    );
  }

  return (
    <div className="mt-6">
      <div className="flex items-center">
        <MapPin className="mr-2 h-4 w-4 text-muted-foreground" />
        <TypographyP className="italic">{googlePlaceInfoQuery.data.address}</TypographyP>
        <Button variant="ghost" size="icon" className="ml-2" onClick={clearAddress}>
          <Cross2Icon className="h-4 w-4" />
        </Button>
      </div>
      <OfficeForm {...props} suggestedName={googlePlaceInfoQuery.data.name} onSubmit={handleFormSubmit} />
    </div>
  );
};

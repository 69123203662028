import { breakDatesAtWeekend } from '@client/features/home/helpers';
import { OnDayAction, ScheduleViewOfficeOnly, ScheduleViewStatuses } from '@client/features/home/types';
import { SchedulePeriod } from '@client/features/home/types';
import { DayOfficeOnly } from '@client/features/home/components/day-office-only';
import { Emoji } from '@client/components/ui/custom/emoji';
import { emojis } from '@client/lib/emojis';
import { TypographyMuted } from '@client/components/ui/custom/typography-muted';
import { ScheduleViewType } from '@client/features/home/const';
import { SchedulePeriodControls } from '@client/features/home/components/date-period-controls';
import { DayStatuses } from '@client/features/home/components/day-statuses';

const Weekend = () => {
  return (
    <div className="flex justify-start items-center my-12">
      <Emoji className="mr-2" sm>
        {emojis.tada}
      </Emoji>
      <TypographyMuted>
        <i>Weekend!</i>
      </TypographyMuted>
    </div>
  );
};

type PropsOfficeOnly = ScheduleViewOfficeOnly & {
  onChangePeriod: (period: SchedulePeriod) => void;
  onDayAction: OnDayAction;
};

type PropsStatuses = ScheduleViewStatuses & {
  onChangePeriod: (period: SchedulePeriod) => void;
  onDayAction: OnDayAction;
};

type Props = PropsOfficeOnly | PropsStatuses;

const OfficeOnly = (props: PropsOfficeOnly) => {
  const { days, periodPrev, periodNext, isOfficeAdmin, onChangePeriod, onDayAction } = props;
  const isFirstPeriod = !periodPrev;
  const [week1, week2] = breakDatesAtWeekend(days.map((x) => x.date));
  const daysWeek1 = week1.map((date) => days.find((x) => x.date === date)!);
  const daysWeek2 = week2.map((date) => days.find((x) => x.date === date)!);

  return (
    <SchedulePeriodControls previous={periodPrev} next={periodNext} onChange={onChangePeriod}>
      {daysWeek1.map((day, i) => (
        <DayOfficeOnly
          key={day.date}
          data={day}
          emphasied={i === 0 && isFirstPeriod}
          isOfficeAdmin={isOfficeAdmin}
          onDayAction={onDayAction}
          canModifyBooking={day.canModify ?? false}
        />
      ))}

      {daysWeek2.length > 0 && <Weekend />}

      {daysWeek2.map((day) => (
        <DayOfficeOnly
          key={day.date}
          data={day}
          emphasied={false}
          isOfficeAdmin={isOfficeAdmin}
          onDayAction={onDayAction}
          canModifyBooking={day.canModify ?? false}
        />
      ))}
    </SchedulePeriodControls>
  );
};

const Statuses = (props: PropsStatuses) => {
  const { days, periodPrev, periodNext, officeAdminOf, bookableStatuses, onChangePeriod, onDayAction } = props;
  const isFirstPeriod = !periodPrev;
  const [week1, week2] = breakDatesAtWeekend(days.map((x) => x.date));
  const daysWeek1 = week1.map((date) => days.find((x) => x.date === date)!);
  const daysWeek2 = week2.map((date) => days.find((x) => x.date === date)!);

  return (
    <SchedulePeriodControls previous={periodPrev} next={periodNext} onChange={onChangePeriod}>
      {daysWeek1.map((day, i) => (
        <DayStatuses
          key={day.date}
          {...day}
          officeAdminOf={officeAdminOf}
          bookableStatuses={bookableStatuses}
          emphasied={i === 0 && isFirstPeriod}
          onDayAction={onDayAction}
        />
      ))}

      {daysWeek2.length > 0 && <Weekend />}

      {daysWeek2.map((day) => (
        <DayStatuses
          key={day.date}
          {...day}
          officeAdminOf={officeAdminOf}
          bookableStatuses={bookableStatuses}
          emphasied={false}
          onDayAction={onDayAction}
        />
      ))}
    </SchedulePeriodControls>
  );
};

export const ScheduleView = (props: Props) => {
  switch (props.type) {
    case ScheduleViewType.Statuses:
      return <Statuses {...props} />;
    case ScheduleViewType.OfficeOnly:
      return <OfficeOnly {...props} />;
  }
};

import { createFileRoute } from '@tanstack/react-router';
import { FullScreenCentered } from '@client/components/layout/FullScreenCentered';
import { FaceWithMonocle } from '@client/components/ui/custom/svg/face-with-monocle';
import { PartyingFace } from '@client/components/ui/custom/svg/partying-face';
import { TypographyH1 } from '@client/components/ui/custom/typography-h1';
import { TypographyP } from '@client/components/ui/custom/typography-p';
import { trpc } from '@client/lib/trpc';
import { useEffect, useMemo } from 'react';

// EXAMPLE QUERY PARAMS:
// ?admin_consent=True&tenant=xxxxxx&scope=https%3a%2f%2fgraph.microsoft.com%2fUser.Read+https%3a%2f%2fgraph.microsoft.com%2f.default#

const AdminConsentCallback = () => {
  const authHandleAdminConsentGrantedMutation = trpc.auth.handleAdminConsentGranted.useMutation();

  // wrap in useMemo to avoid re-computing on every render
  const result = useMemo(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const msTenantId = searchParams.get('tenant');
    const scopes =
      searchParams
        .get('scope')
        ?.split(' ')
        ?.map((scope) => scope.trim()) ?? [];
    const success = searchParams.get('admin_consent')?.toLowerCase() === 'true';
    return { success, msTenantId, scopes };
  }, []);

  useEffect(() => {
    console.log('handleAdminConsentGranted', result);
    if (result.success && result.msTenantId) {
      authHandleAdminConsentGrantedMutation.mutate({
        msTenantId: result.msTenantId,
        scopes: result.scopes,
      });
    }
  }, [result]);

  if (!result.success) {
    return (
      <FullScreenCentered>
        <div className="flex mt-12">
          <FaceWithMonocle size={120} />
          <div className="ml-6">
            <TypographyH1 className="">Something went wrong</TypographyH1>
            <TypographyP className="mt-2">Please go back to Officely and try again.</TypographyP>
          </div>
        </div>
      </FullScreenCentered>
    );
  }

  return (
    <FullScreenCentered>
      <div className="flex mt-12">
        <PartyingFace size={120} />
        <div className="ml-6">
          <TypographyH1 className="">You're all set!</TypographyH1>
          <TypographyP className="mt-2">You can now close this window and go back to Officely.</TypographyP>
        </div>
      </div>
    </FullScreenCentered>
  );
};

export const Route = createFileRoute('/admin-consent-callback')({
  component: AdminConsentCallback,
});

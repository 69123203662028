import { redirect } from '@tanstack/react-router';
import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/')({
  beforeLoad: ({ context }) => {
    console.log('index redirecting user to /home');
    redirect({
      to: '/home',
      // to: '/configure',
      throw: true,
    });
  },
});

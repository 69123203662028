import { createFileRoute } from '@tanstack/react-router';
import { Modal, useModalCloseDelay } from '@client/components/ui/custom/modal';
import { isInChildRouteOf } from '@client/lib/routeHelpers';
import { Outlet } from '@tanstack/react-router';
import { useNavigate } from '@tanstack/react-router';
import { useCallback } from 'react';
import { SettingsModal } from '@client/features/settings/components/settings-modal';
import { SettingsModalPage } from '@client/features/settings/const';

function HomeSettings() {
  const navigate = useNavigate({ from: Route.fullPath });
  const isChildRoute = isInChildRouteOf(Route.fullPath);

  const handleClose = useCallback(async () => {
    await navigate({
      to: '/home',
      replace: true,
      search: (prev) => ({
        office: prev.office,
        period: prev.period,
        view: prev.view,
      }),
    });
  }, [navigate]);

  const { modalOpen, closeModal } = useModalCloseDelay(true, handleClose);

  const handlePageChange = useCallback(
    (page: SettingsModalPage) => {
      void navigate({
        to: `${Route.fullPath}/${page}`,
        search: true,
        replace: true,
      });
    },
    [navigate]
  );

  return (
    <Modal open={modalOpen} onClose={closeModal}>
      {!isChildRoute && <SettingsModal onClose={handleClose} onChangePage={handlePageChange} />}
      <Outlet />
    </Modal>
  );
}

export const Route = createFileRoute('/_authenticated/home/settings')({
  component: HomeSettings,
});

import { ModalLayout, ModalLayoutProps } from '@client/components/ui/custom/modal-layout';
import { AnnouncementForm, AnnouncementFormValues } from '@client/features/announcements/components/form';
import { useAnnouncements } from '@client/features/announcements/hook';
import { formatDate, parseDate } from '@client/lib/date';
import { CommonPropsForModal } from '@client/lib/modal';
import { OfficeAnnouncement } from '@officely/models';
import { useCallback, useMemo } from 'react';

type Props = CommonPropsForModal & {
  data: Pick<OfficeAnnouncement, 'id' | 'category' | 'text' | 'fromDate' | 'toDate' | 'weekdays' | 'excludedDates'>;
};

export const AnnouncementEditModal = (props: Props) => {
  const { onDone, data: _data, ...restForModal } = props;
  const { id, fromDate, toDate, weekdays, category, text } = _data;
  const { updateAnnouncement } = useAnnouncements();

  const handleSubmit = useCallback(
    async (values: AnnouncementFormValues) => {
      await updateAnnouncement(id, {
        category: parseInt(values.category),
        text: values.text,
        channelIds: [],
        excludedDates: [],
        fromDate: formatDate(values.dates.from!),
        toDate: values.dates.to ? formatDate(values.dates.to) : formatDate(values.dates.from!),
        weekdays:
          values.specificWeekdays && values.weekdays?.length ? values.weekdays.map((day) => parseInt(day)) : null,
      });
      onDone?.();
    },
    [updateAnnouncement, onDone, id]
  );

  const commonModalProps: ModalLayoutProps = {
    title: 'Edit Announcement',
    loading: false,
    submitFormId: AnnouncementForm.id,
    submitText: 'Update',
    closeText: props.nested ? 'Back' : 'Cancel',
    ...restForModal,
  };

  const data = useMemo<AnnouncementFormValues>(() => {
    return {
      text,
      category: category.toString(),
      specificWeekdays: !!weekdays?.length,
      weekdays: weekdays?.map((day) => day.toString()),
      dates: {
        from: parseDate(fromDate),
        to: fromDate === toDate ? undefined : parseDate(toDate),
      },
    };
  }, [text, category]);

  return (
    <ModalLayout {...commonModalProps}>
      <AnnouncementForm onSubmit={handleSubmit} data={data} />
    </ModalLayout>
  );
};

import { createFileRoute } from '@tanstack/react-router';
import { CommonPropsForModal } from '@client/lib/modal';
import { useTeams } from '@client/lib/useTeams';
import { Modal } from '@client/components/ui/custom/modal';
import { UserFavsModal } from '@client/features/settings/components/user-favs-modal';

function DialogFavourites() {
  const { closeDialog } = useTeams();
  const loaderData = Route.useLoaderData();

  const props: CommonPropsForModal = {
    variant: 'msTeamsDialog',
    onClose: closeDialog,
    onDone: closeDialog,
  };

  return (
    <Modal open={true} onClose={closeDialog} isTeamsDialog={true}>
      <UserFavsModal {...props} {...loaderData} />
    </Modal>
  );
}

export const Route = createFileRoute('/_authenticated/dialog/favourites')({
  component: DialogFavourites,
  loader: async ({ context }) => {
    const user = await context.trpcUtils.user.current.fetch();
    const { favouriteCoworkerIds } = user;
    return {
      favouriteCoworkerIds,
    };
  },
});

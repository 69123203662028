import { Select, SelectProps } from '@client/components/ui/custom/select';
import { Channel, useDirectoryContext } from '@client/context/directory';
import { useCallback, useMemo } from 'react';

type SelectTeamChannelProps = Pick<
  SelectProps,
  'placeholder' | 'searchPlaceholder' | 'noResultsMessage' | 'inForm' | 'allowClear' | 'disabled'
> & {
  value: string | undefined;
  onChange: (value: string | undefined) => void;
};

export const SelectTeamChannel = (props: SelectTeamChannelProps) => {
  const { value, onChange, ...propsForPrimitive } = props;

  const {
    channels: { data: channelsData },
  } = useDirectoryContext();

  // ------- PEOPLE --------

  const teamChannels = useMemo(() => {
    return Object.values(channelsData).sort((a, b) => {
      return a.teamName.localeCompare(b.teamName) || a.name.localeCompare(b.name);
    });
  }, [channelsData]);

  const selectedTeamChannel = useMemo(() => {
    return teamChannels.find((d) => value === d.id);
  }, [value, teamChannels]);

  // ------- OPTIONS --------

  const options = teamChannels.map(dataToOption);
  const selected = selectedTeamChannel && dataToOption(selectedTeamChannel);

  // ------- RENDER --------

  const handleChange = useCallback(
    (value: string | undefined) => {
      onChange(value);
    },
    [onChange]
  );

  return (
    <Select
      {...propsForPrimitive}
      options={options}
      selected={selected}
      onChange={handleChange}
      noResultsMessage="No results found."
      placeholder="Select channel..."
      searchPlaceholder="Search..."
    />
  );
};

// ---------- HELPERS ----------------
const dataToOption = (data: Channel): SelectProps['options'][number] => ({
  value: data.id,
  label: data.name,
  group: data.teamName,
});

import { defineForm } from '@client/lib/form/helpers';
import { useForm } from '@client/lib/form/useForm';
import { useCallback, useEffect, useMemo } from 'react';
import { Form } from '@client/components/ui/form';
import { FormField } from '@client/components/shared/FormField';
import { CommonPropsForForm } from '@client/lib/form/types';

const FORM_ID = 'user-favs-form' as const;

const getDefinition = () =>
  defineForm({
    id: FORM_ID,
    fields: {
      peopleIds: {
        type: 'multi-select-people',
      },
    },
    defaultValues: {
      peopleIds: [],
    },
  });

type Props = CommonPropsForForm<string[]>;

const UserFavsForm = (props: Props) => {
  const { data, onSubmit } = props;

  const definition = useMemo(() => getDefinition(), []);
  const { form, setValues, conditions } = useForm(definition);

  useEffect(() => {
    // whenever data changes reset the form and set the values
    form.reset();
    if (data) {
      setValues({
        peopleIds: data,
      });
    }
  }, [form.reset, data]);

  const handleSubmit = useCallback(
    form.handleSubmit((values) => {
      const { peopleIds } = values;
      onSubmit?.(peopleIds ?? []);
    }),
    [form, onSubmit]
  );

  return (
    <Form {...form}>
      <form onSubmit={handleSubmit} className="flex flex-col gap-y-6" id={FORM_ID}>
        {Object.keys(definition.fields)
          .map((key) => key as keyof typeof definition.fields)
          .map((name) => {
            return (
              <FormField
                {...definition.fields[name]}
                key={name}
                name={name}
                control={form.control}
                disabled={conditions?.disabled?.[name]}
                hidden={conditions?.hidden?.[name]}
              />
            );
          })}
      </form>
    </Form>
  );
};

UserFavsForm.id = FORM_ID;

export { UserFavsForm };

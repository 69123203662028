/* eslint-disable sonarjs/no-small-switch */

import { CommonPropsForModal } from '@client/lib/modal';
import { Button } from '@client/components/ui/button';
import { ModalLayout, ModalLayoutProps } from '@client/components/ui/custom/modal-layout';
import { Separator } from '@client/components/ui/separator';
import { SETTINGS_MODAL_PAGE_PROFILES, SettingsModalPage } from '@client/features/settings/const';
import { useScreenSize } from '@client/lib/useScreenSize';
import { ChevronRightIcon } from 'lucide-react';
import { useCallback, useMemo } from 'react';

const TITLE = 'Settings';

type Props = CommonPropsForModal & {
  onChangePage?: (page: SettingsModalPage) => void;
};

export const SettingsModal = (props: Props) => {
  const { ...restForModal } = props;

  const { isMobile } = useScreenSize();
  const navItems = useMemo(() => {
    return Object.values(SettingsModalPage).filter((page) => {
      if (page === SettingsModalPage.Account) {
        return !isMobile;
      }
      return true;
    });
  }, [isMobile]);

  const handlePageChange = useCallback(
    (page: SettingsModalPage) => {
      props.onChangePage?.(page);
    },
    [props.onChangePage]
  );

  const commonModalProps: ModalLayoutProps = {
    title: TITLE,
    description: 'Manage your account settings and set e-mail preferences.',
    closeText: 'Close',
    ...restForModal,
  };

  return (
    <ModalLayout {...commonModalProps}>
      <div className="mt-4">
        {navItems.map((page, i) => (
          <div key={page}>
            <Button
              className="w-full h-auto justify-start p-0 m-0"
              onClick={() => handlePageChange(page)}
              variant={'link'}>
              <div className="p-4 w-full flex justify-between items-center">
                {SETTINGS_MODAL_PAGE_PROFILES[page].title}
                <ChevronRightIcon size={20} />
              </div>
            </Button>
            {i !== navItems.length - 1 && <Separator />}
          </div>
        ))}
      </div>
    </ModalLayout>
  );
};

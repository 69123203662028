import { ModalLayout } from '@client/components/ui/custom/modal-layout';
import { NbhForm, NbhFormValues } from '@client/features/settings/components/nbh-form';

type Props = {
  data?: NbhFormValues;
  categories: string[];
  onClose: () => void;
  onSubmit: (values: NbhFormValues) => void;
};

export const NbhModal = ({ data, categories, onClose, onSubmit }: Props) => {
  const isUpdating = !!data;
  return (
    <ModalLayout
      title={isUpdating ? 'Edit Neighborhood' : 'Create Neighborhood'}
      submitText={isUpdating ? 'Update' : 'Create'}
      submitFormId={NbhForm.id}
      closeText="Cancel"
      onClose={onClose}>
      <NbhForm categories={categories} data={data} onSubmit={onSubmit} />
    </ModalLayout>
  );
};

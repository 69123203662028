import { createFileRoute } from '@tanstack/react-router';
import { ErrorLayout } from '@client/components/layout/ErrorLayout';
import { DirectoryContextWrapper } from '@client/context/directory';
import { intercomIdentify } from '@client/lib/intercom';
import { trpc } from '@client/lib/trpc';
import { Outlet } from '@tanstack/react-router';
import { posthog } from 'posthog-js';
import { useEffect } from 'react';
import { useRouteContext } from '@tanstack/react-router';
// import { getDistinctId } from '@client/shared/helpers';

const AuthenticatedContainer = () => {
  const { auth, appId, authErrors, teamsContext } = useRouteContext({
    from: '/_authenticated',
  });
  // const authSetSessionCookieMutation = trpc.auth.setSessionCookie.useMutation();
  const trackIndentifyMutation = trpc.track.identify.useMutation();
  const trackAppViewedMutation = trpc.track.appViewed.useMutation();

  useEffect(() => {
    if (auth) {
      // authSetSessionCookieMutation.mutate();
      intercomIdentify({
        email: auth.msUser.profile.email,
        name: auth.msUser.profile.displayName,
      });
    }
  }, [auth]);

  useEffect(() => {
    if (auth) {
      trackIndentifyMutation.mutate({
        distinctId: posthog.get_distinct_id(),
      });
      trackAppViewedMutation.mutate();
    }
  }, [auth]);

  const name = auth?.user?.profile?.firstName ?? auth?.user?.profile?.name ?? teamsContext?.user?.displayName;

  if (authErrors?.includes('unauthenticated')) {
    // redirect to login
    return <ErrorLayout variant={'unauthenticated'} />;
  }
  if (authErrors?.includes('missing_admin_consent') || !appId) {
    return <ErrorLayout variant={'admin-consent'} name={name} />;
  }
  if (authErrors?.includes('missing_team_install')) {
    return <ErrorLayout variant={'installation'} name={name} appId={appId} />;
  }
  if (!auth) {
    return <ErrorLayout variant={'unknown'} />;
  }

  const user = auth.user;

  return (
    <DirectoryContextWrapper
      currentPerson={{
        id: user.msUserId,
        name: user.profile.name,
        email: user.profile.email,
        avatar: user.profile.avatarSmall ?? undefined,
        user: { id: user.id },
      }}>
      <Outlet />
    </DirectoryContextWrapper>
  );
};

export const Route = createFileRoute('/_authenticated')({
  component: AuthenticatedContainer,
});

// import z from 'zod';
// import { createFileRoute, Link, Outlet, useRouter } from '@tanstack/react-router';
// import { UserWorkStatusType, UserWorkStatusTypeHelper, yyyyMmDdSchema } from '@officely/models';
// import { ButtonGroup } from '@client/components/ui/custom/button-group';
// import { Button } from '@client/components/ui/button';
// import { Emoji } from '@client/components/ui/custom/emoji';
// import { emojis } from '@client/lib/emojis';
// import { DefaultLayout } from '@client/components/layout/DefaultLayout';
// import { useNavigate } from '@tanstack/react-router';
// import { useCallback, useEffect, useMemo } from 'react';
// import { trpc } from '@client/lib/trpc';
// import { ScheduleView } from '@client/features/home/components/schedule-view';
// import { SchedulePeriod } from '@client/features/home/types';
// import { toast } from 'sonner';
// import { parse, startOfDay } from 'date-fns';
// import { yyyy_MM_dd } from '@client/lib/const';
// import { getTimezone } from '@client/lib/utils';
// import { useEffectOnce } from '@client/lib/useEffectOnce';
// import { Select } from '@client/components/ui/custom/select';
// import { MultiSelect } from '@client/components/ui/custom/multi-select';

import { createFileRoute } from '@tanstack/react-router';

// function LoadDemoBtn() {
//   if (window.location.href?.includes('localhost')) {
//     const router = useRouter();
//     const loadDemoMutation = trpc.account.loadDemo.useMutation();
//     const handleLoadDemo = useCallback(async () => {
//       const promise = loadDemoMutation.mutateAsync();
//       toast.promise(promise, {
//         loading: 'Loading...',
//         success: 'Demo loaded',
//         error: 'Failed to load demo',
//       });
//       await promise;
//       await router.invalidate();
//     }, [loadDemoMutation, router]);
//     return (
//       <Button variant="ghost" onClick={handleLoadDemo}>
//         Load Demo
//       </Button>
//     );
//   }
//   return null;
// }

// function Header() {
//   const { filters } = Route.useLoaderData();

//   const options = useMemo(() => {
//     return filters.map((x) => ({
//       value: x.value,
//       group: x.isOffice ? 'Offices' : 'Statuses',
//       label: `${x.profile.emoji}  ${x.profile.name}`,
//     }));
//   }, [filters]);

//   const selected = useMemo(() => {
//     return options.filter((x) => filters.some((y) => y.value === x.value));
//   }, [options, filters])[0];

//   return (
//     <div className="my-4">
//       <Select options={options} selected={selected} onChange={() => {}} />
//     </div>
//   );
// }

// function Footer() {
//   const searchParams = Route.useSearch();
//   return (
//     <div>
//       <ButtonGroup className="mt-4">
//         <Button variant={'outline'} asChild>
//           <Link to="/home/feedback" search={searchParams}>
//             <Emoji className="mr-2">{emojis.gem}</Emoji>
//             Suggest A Feature
//           </Link>
//         </Button>
//         <LoadDemoBtn />
//       </ButtonGroup>
//     </div>
//   );
// }

// function Body() {
//   const navigate = useNavigate({ from: Route.fullPath });
//   const search = Route.useSearch();
//   const { filters, schedule } = Route.useLoaderData();
//   const { periodPrev } = schedule;

//   useEffectOnce(() => {
//     console.log({ filters });
//   });

//   const handleChangePeriod = useCallback(
//     (value: SchedulePeriod) => {
//       void navigate({
//         to: '/home',
//         replace: true,
//         search: (prev) => ({
//           ...prev,
//           period: value,
//         }),
//       });
//     },
//     [navigate, periodPrev]
//   );

//   const handleSeeOverflow = useCallback(
//     (date: string, statusOrOfficeId: string) => {
//       // console.log({ date, statusOrOfficeId });
//       void navigate({
//         to: `/home/details`,
//         search: (prev) => ({
//           ...prev,
//           d: date,
//           s: statusOrOfficeId,
//         }),
//       });
//     },
//     [navigate]
//   );

//   // clear the period after the user returns to the default
//   useEffect(() => {
//     if (!periodPrev && search.period) {
//       void navigate({
//         to: '/home',
//         replace: true,
//         search: (prev) => ({
//           ...prev,
//           period: undefined,
//         }),
//       });
//     }
//   }, [periodPrev, search.period, navigate]);

//   return <ScheduleView {...schedule} onChangePeriod={handleChangePeriod} onSeeOverflow={handleSeeOverflow} />;
// }

// function Home() {
//   return (
//     <DefaultLayout>
//       <Header />
//       <Body />
//       <Footer />
//       <Outlet />
//     </DefaultLayout>
//   );
// }

// export const Route = createFileRoute('/_authenticated/home-v2')({
//   component: Home,
//   // staleTime: 1000 * 60 * 1, // 1 minute
//   validateSearch: z
//     .object({
//       filters: z.array(z.string()).optional(),
//       period: z
//         .object({
//           start: yyyyMmDdSchema,
//           end: yyyyMmDdSchema,
//         })
//         .refine((data) => {
//           const valid = new Date(data.start.replace(/_/g, '-')) < new Date(data.end.replace(/_/g, '-'));
//           const start = parse(data.start, yyyy_MM_dd, new Date());
//           const today = startOfDay(new Date());
//           const notInPast = start >= today;
//           return valid && notInPast;
//         })
//         .optional()
//         .catch(undefined),
//     })
//     .default({}),

//   loaderDeps: ({ search }) => ({
//     filters: search.filters,
//     period: search.period,
//   }),
//   beforeLoad: async ({ context }) => {
//     const [account, user] = await Promise.all([
//       context.trpcUtils.account.current.fetch(),
//       context.trpcUtils.user.current.fetch(),
//     ]);
//     return {
//       ...context,
//       account,
//       user,
//     };
//   },
//   loader: async ({ deps, context }) => {
//     const { user, account } = context;
//     const { filters: searchFilters = [] } = deps;

//     const filtersValues = new Set<string>(searchFilters);
//     const timezone = getTimezone();
//     const userOfficeId = user.officeIds[0];

//     const availableStatuses = Object.values(UserWorkStatusType)
//       .filter((x) => ![UserWorkStatusType.NO_STATUS, UserWorkStatusType.OFFICE].includes(x))
//       .filter((x) => !account.disabledStatuses.includes(x));

//     if (searchFilters.length === 0) {
//       // add the defaults
//       filtersValues.add(userOfficeId);
//       availableStatuses.forEach((x) => filtersValues.add(x));
//     }

//     const officeIds = Array.from(filtersValues).filter((x) => !UserWorkStatusTypeHelper.isValidStatus(x));
//     const statuses = Array.from(filtersValues).filter((x) => UserWorkStatusTypeHelper.isValidStatus(x));

//     const [schedule, offices, officeCount] = await Promise.all([
//       context.trpcUtils.schedule.statuses.fetch({
//         timezone,
//         period: deps?.period,
//       }),
//       Promise.all(officeIds.map((id) => context.trpcUtils.office.getById.fetch(id))),
//       context.trpcUtils.office.getOfficeCount.fetch(),
//     ]);

//     const isOfficeAdmin = offices.some((x) => x.managerIds.includes(user.id));
//     const isAccountAdmin = account.managers.includes(user.id);

//     return {
//       filters: Array.from(filtersValues).map((v) => {
//         const statusProfile = UserWorkStatusTypeHelper.isValidStatus(v)
//           ? new UserWorkStatusTypeHelper(v as UserWorkStatusType).profile
//           : undefined;
//         const office = offices.find((x) => x.id === v);
//         return {
//           value: v,
//           isOffice: !!office,
//           profile: {
//             name: office?.name ?? statusProfile?.name,
//             emoji: office?.emoji ?? statusProfile?.emoji,
//           },
//         };
//       }),
//       schedule,
//       userOfficeId,
//       officeCount,
//       isAccountAdmin,
//       isOfficeAdmin,
//     };
//   },
// });

export const Route = createFileRoute('/_authenticated/home-v2')({
  component: () => <div>Home v2</div>,
});

import { Emoji } from '@client/components/ui/custom/emoji';
import { TextAndEmojis } from '@client/components/ui/custom/text-and-emojis';
import { Person } from '@client/features/app/components/person';
import { getSortMethod, hasOwnRow } from '@client/features/home/helpers';
import { UserListItem } from '@client/features/home/types';
import pluralize from 'pluralize';
import { useCallback, useMemo } from 'react';
import { TooltipContent, TooltipTrigger, Tooltip } from '@client/components/ui/tooltip';
import { TooltipProvider } from '@radix-ui/react-tooltip';
import { Button } from '@client/components/ui/button';
import { Person as PersonType } from '@client/context/directory';
import { UserWorkStatusHelper } from '@officely/models';
import { cn } from '@client/lib/utils';
import { SortMethod } from '@client/features/home/const';

const Note = (props: { note: string; isSelf?: boolean; onEdit?: () => void }) => {
  const { note, isSelf = false, onEdit } = props;
  if (isSelf && onEdit) {
    return (
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <button onClick={onEdit}>
              <TextAndEmojis italic textClassName="hover:underline" className="ml-1">
                {note}
              </TextAndEmojis>
            </button>
          </TooltipTrigger>
          <TooltipContent>Edit note</TooltipContent>
        </Tooltip>
      </TooltipProvider>
    );
  }
  return (
    <TextAndEmojis italic className="ml-1">
      {note}
    </TextAndEmojis>
  );
};

const OverflowText = (props: { overflowCount: number; onSeeOverflow?: () => void }) => {
  const { overflowCount, onSeeOverflow } = props;

  const overflowText = useMemo(() => {
    return `${overflowCount} ${pluralize('other', overflowCount)}`;
  }, [overflowCount]);

  if (onSeeOverflow) {
    return (
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger asChild>
            <Button onClick={onSeeOverflow} size="sm" variant="ghost" className="h-7">
              {overflowText}
            </Button>
          </TooltipTrigger>
          <TooltipContent>{`See ${overflowCount} ${pluralize('other', overflowCount)}`}</TooltipContent>
        </Tooltip>
      </TooltipProvider>
    );
  }
  return (
    <TextAndEmojis italic className="ml-1">
      {overflowText}
    </TextAndEmojis>
  );
};

export const UserList = (props: {
  list: Array<UserListItem>;
  date: string;
  total?: number;
  sort?: SortMethod;
  minNumSingleItemRows?: number;
  maxItems?: number;
  personVariant?: 'tag' | 'tag-compressed';
  onSeeOverflow?: () => void;
  onEditNote?: () => void;
  onSelfClick?: (data: UserListItem) => void;
  onNonSelfClick?: (data: UserListItem) => void;
}) => {
  const {
    list,
    date,
    total,
    sort = SortMethod.ALPHABETICAL,
    minNumSingleItemRows = 2,
    maxItems = 10000,
    personVariant = 'tag',
    onSeeOverflow,
    onEditNote,
    onSelfClick,
    onNonSelfClick,
  } = props;

  const sortedList = useMemo(() => list.sort(getSortMethod(sort)), [list, sort]);
  const trimmedList = useMemo(() => sortedList.slice(0, maxItems), [sortedList, maxItems]);

  const [itemsOnTheirOwnRows, itemsThatCanBeWrapped] = useMemo(() => {
    const itemsWithOwnRows = trimmedList.filter(hasOwnRow);
    const itemsForWrapping = trimmedList.filter((x) => !hasOwnRow(x));
    const numberToAddToItemsOnTheirOwnRows = Math.max(0, minNumSingleItemRows - itemsWithOwnRows.length);
    const updatedItemsOnTheirOwnRows = [
      ...itemsWithOwnRows,
      ...itemsForWrapping.splice(0, numberToAddToItemsOnTheirOwnRows),
    ];
    return [updatedItemsOnTheirOwnRows, itemsForWrapping];
  }, [trimmedList, minNumSingleItemRows]);

  const overflowCount = useMemo(
    () => (total ?? list.length) - trimmedList.length,
    [total, list.length, trimmedList.length]
  );

  const handlePersonSelfClick = useMemo(() => {
    if (onSelfClick) {
      return (person: PersonType) => {
        const item = list.find((x) => x.userId === person.user?.id);
        if (item) {
          onSelfClick?.(item);
        }
      };
    }
  }, [onSelfClick, list]);

  const handlePersonNonSelfClick = useMemo(() => {
    if (onNonSelfClick) {
      return (person: PersonType) => {
        const item = list.find((x) => x.userId === person.user?.id);
        if (item) {
          onNonSelfClick?.(item);
        }
      };
    }
  }, [onNonSelfClick, list]);

  return list.length === 0 ? null : (
    <div className="flex flex-col items-start space-y-1">
      {itemsOnTheirOwnRows.length > 0 && (
        <div className="flex flex-col items-start space-y-1">
          {itemsOnTheirOwnRows.map((item) => {
            const description = UserWorkStatusHelper.getDescription({
              currentDate: date,
              fromDate: item.fromDate,
              toDate: item.toDate,
              note: item.note,
            });
            return (
              <div key={item.userId} className="flex items-center gap-x-1">
                <Person
                  userId={item.userId}
                  name={item.name}
                  className=""
                  variant={personVariant}
                  onClick={item.isSelf ? handlePersonSelfClick : handlePersonNonSelfClick}
                />
                {item.extraEmojis?.map((emoji) => <Emoji key={emoji}>{emoji}</Emoji>)}
                {description ? (
                  <Note note={description} isSelf={item.isSelf} onEdit={onEditNote} />
                ) : item.isSelf && onEditNote ? (
                  <Button
                    variant="ghost"
                    size="icon"
                    className={cn('h-7', {
                      'h-6': personVariant === 'tag-compressed',
                    })}
                    onClick={onEditNote}>
                    <Emoji sm>🖋️</Emoji>
                  </Button>
                ) : null}
              </div>
            );
          })}
        </div>
      )}

      {(itemsThatCanBeWrapped.length > 0 || overflowCount > 0) && (
        <div className="flex flex-wrap gap-x-1 gap-y-1">
          {itemsThatCanBeWrapped.map((item) => (
            <Person key={item.userId} userId={item.userId} name={item.name} className="" variant={personVariant} />
          ))}
          {overflowCount > 0 && <OverflowText overflowCount={overflowCount} onSeeOverflow={onSeeOverflow} />}
        </div>
      )}
    </div>
  );
};
